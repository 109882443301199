const tableBodySorts = ['ByColumnId', 'ByColumnName', 'ForeignKeysFirst', 'ByDistinctCount'] as const;
export type TableBodySort = typeof tableBodySorts[number];

export function isTableBodySort(maybeSort: unknown): maybeSort is TableBodySort {
  return typeof maybeSort === 'string' && tableBodySorts.includes(maybeSort as TableBodySort);
}

export function translateTableBodySort(apiValue: string) {
  if (isTableBodySort(apiValue)) {
    return apiValue;
  }

  return 'ByColumnId';
}
