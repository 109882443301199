import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';

type ArticleId = {
  projectId: string;
  articleId: string;
}

type ArticleState = ArticleId & {
  markdown: string;
}

function generateId(id: ArticleId) {
  return `${id.projectId}-${id.articleId}`;
}

const articleDraftAdapter = createEntityAdapter<ArticleState>({
  selectId: (article) => generateId(article),
});

type ArticleAction<T> = ArticleId & {
  data: T;
}

const articleDraftSlice = createSlice({
  name: 'articleDraft',
  initialState: articleDraftAdapter.getInitialState(),
  reducers: {
    createDraft: (state, action: PayloadAction<ArticleAction<string>>) => {
      articleDraftAdapter.upsertOne(state, {
        projectId: action.payload.projectId,
        articleId: action.payload.articleId,
        markdown: action.payload.data,
      });
    },
    updateDraft: (state, action: PayloadAction<ArticleAction<string>>) => {
      articleDraftAdapter.updateOne(state, {
        id: generateId(action.payload),
        changes: {
          markdown: action.payload.data,
        },
      });
    },
    closeDraft: (state, action: PayloadAction<ArticleId>) => {
      articleDraftAdapter.removeOne(state, generateId(action.payload));
    },
  },
});

export const {
  createDraft,
  updateDraft,
  closeDraft,
} = articleDraftSlice.actions;

const {
  selectById: selectByArticleId,
} = articleDraftAdapter.getSelectors((state: RootState) => state.sessionReducers.articleDraft);

export function useArticleDraft(id: ArticleId) {
  const dispatch = useAppDispatch();

  const draft = useAppSelector((state) => selectByArticleId(state, generateId(id)));

  const createNewDraft = useCallback((markdown: string) => {
    dispatch(createDraft({
      ...id,
      data: markdown,
    }));
  }, [dispatch, id]);

  const saveDraft = useCallback((markdown: string) => {
    dispatch(updateDraft({
      ...id,
      data: markdown,
    }));
  }, [dispatch, id]);

  const discardDraft = useCallback(() => {
    dispatch(closeDraft(id));
  }, [dispatch, id]);

  return {
    draft,
    createDraft: createNewDraft,
    updateDraft: saveDraft,
    closeDraft: discardDraft,
  };
}

export default articleDraftSlice.reducer;
