import { createUserManager } from '@ericlamb/redux-oidc';
import { WebStorageStateStore } from 'oidc-client-ts';

if (process.env.REACT_APP_AUTH_URL === undefined) throw new Error('process.env.REACT_APP_AUTH_URL is required');
if (process.env.REACT_APP_IDENTITY_CLIENT_ID === undefined) throw new Error('process.env.REACT_APP_IDENTITY_CLIENT_ID is required');

export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTH_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: `${window.location.origin}/signin-oidc`,
  post_logout_redirect_uri: `${window.location.origin}/signout-callback-oidc`,
  audience: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  response_type: 'code',
  automaticSilentRenew: true,
  loadUserInfo: true,
  scope: 'openid email name roles phone offline_access profile',
  accessTokenExpiringNotificationTime: 120,
  silentRequestTimeout: 30000,
};

const METADATA_OIDC = {
  issuer: `${process.env.REACT_APP_AUTH_URL}/`,
  jwks_uri:
        `${process.env.REACT_APP_AUTH_URL}/.well-known/jwks`,
  authorization_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/authorize`,
  token_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/token`,
  userinfo_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/userinfo`,
  end_session_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/logout`,
  check_session_iframe:
        `${process.env.REACT_APP_AUTH_URL}/connect/checksession`,
  revocation_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/revocation`,
  introspection_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/introspect`,
};

const userManagerConfig = {
  ...IDENTITY_CONFIG,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  metadata: {
    ...METADATA_OIDC,
  },
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
