import React, {
  createContext, ReactNode, useContext, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { useUserApiGetHarvestByIdQuery } from '../../app/api';

type DatabaseParams = 'databaseId';

export const DatabaseContext = createContext<
  { databaseId?: string; } | undefined
>(undefined);

export function DatabaseContextProvider({ children }: {
  children: ReactNode;
}) {
  const { databaseId } = useParams<DatabaseParams>();

  const contextValue = useMemo(() => ({ databaseId }), [databaseId]);

  return (
    <DatabaseContext.Provider value={contextValue}>
      {children}
    </DatabaseContext.Provider>
  );
}

export const useCurrentDatabase = () => {
  const database = useContext(DatabaseContext);

  if (database === undefined) {
    throw new Error('useCurrentDatabase must be used within a DatabaseContextProvider');
  }

  if (database.databaseId === undefined) {
    throw new Error('useCurrentDatabase must be used within a route with a databaseId param');
  }

  const {
    data: harvest,
  } = useUserApiGetHarvestByIdQuery({
    harvestId: database.databaseId,
  });

  return {
    databaseId: database.databaseId,
    databaseEngine: harvest?.databaseEngine,
  };
};
