import React, { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import {
  Expander, Icon, InfoLink, LoadingSpinner,
} from '../../../../../components/elements';
import styles from './DiagramTableHeader.module.css';
import { abbreviateCategory, getCategory } from '../../util';
import { TableDto } from '../../../../../app/api';

export function DiagramTableHeader({
  tableName,
  schema,
  table,
  isCollapsed,
  isLoading,
  showCategory,
  showFullyQualifiedName,
  showRowCount,
  backgroundColor,
  toggleCollapsed,
  onTableDetails,
}: {
  tableName: string;
  schema: string;
  table: Pick<TableDto, 'name' | 'schemaName' | 'rowCount' | 'projectCategory' | 'isView'> | undefined;
  isCollapsed: boolean;
  isLoading: boolean;
  showCategory: boolean;
  showFullyQualifiedName: boolean;
  showRowCount: boolean;
  backgroundColor: string;
  toggleCollapsed: () => void;
  onTableDetails?: () => void;
}) {
  return (
    <div
      className={`d-flex align-items-center ${styles['diagram-table-header']}`}
      style={{ background: backgroundColor ?? '#ffffff' }}>

      <LoadingSpinner isLoading={isLoading} className="">
        {table
          ? <Expander isCollapsed={isCollapsed} onToggle={toggleCollapsed} />
          : <ErrorIndicator />}
      </LoadingSpinner>

      <span className="mx-2 me-auto">
        <span
          onMouseDown={(e) => e.stopPropagation()}
          className="cursor-text">
          {showFullyQualifiedName && `${schema}.`}{tableName}
        </span>
      </span>

      {
        !isLoading && (
          <>
            {
              !!table && (
                <div className={`d-flex flex-column text-end mx-2 user-select-none ${styles['diagram-table-header-detail']}`}>
                  {showCategory
                    && (!!table.projectCategory || table.isView)
                    && <span>({abbreviateCategory(getCategory(table)?.name)})</span>}
                  {showRowCount
                    && table.rowCount !== undefined && table.rowCount !== null
                    && <span>Rows: {table.rowCount.toLocaleString()}</span>}
                </div>
              )
            }
            {onTableDetails
              ? <InfoLink onClick={onTableDetails} icon="table" />
              : <Icon icon="table" />}
          </>
        )
      }

    </div>
  );
}
function ErrorIndicator() {
  const iconRef = useRef(null);

  return (
    <>
      <span className="text-danger" ref={iconRef}><Icon icon="error" /></span>
      <UncontrolledTooltip target={iconRef}>
        Unable to load table from current harvest
      </UncontrolledTooltip>
    </>
  );
}

DiagramTableHeader.defaultProps = {
  onTableDetails: undefined,
};
