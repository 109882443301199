import { noop } from 'lodash';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  // eslint-disable-next-line max-len
  useTable, Column, useSortBy, ColumnInstance, UseSortByColumnProps, TableOptions, UseSortByOptions, TableState, UseSortByState, usePagination, TableInstance, UsePaginationInstanceProps, UseSortByInstanceProps, UsePaginationState, useGlobalFilter, UseGlobalFiltersInstanceProps, UseGlobalFiltersOptions,
} from 'react-table';
import { Table as BsTable } from 'reactstrap';
import { Icon, ScrollArea, SearchBox } from '..';
import { LoadingSpinner } from '../loadingSpinner';
import { DEFAULT_SEARCH_VALUE_ID, useSearchBoxValue } from '../searchBox/searchBoxSlice';
import { DEFAULT_TABLE_STATE_ID, useTablePageSize, useTableSorts } from './tablePageSlice';

type TableSize = 'sm' | undefined;

type TableProps<T extends object> = {
  data: T[],
  columns: Column<T>[],
  sort?: boolean,
  onRowClick?: (x: T) => void,
  initialState?: Partial<
    TableState<T>
    & UseSortByState<T>
    & Pick<UsePaginationState<T>, 'pageSize'>>,
  borderless?: boolean,
  tableClassName?: string,
  size?: TableSize,
  filterPlaceholder?: string,
  stateScopeId?: string,
}

type PaginatedTableInstance<T extends object> = TableInstance<T>
  & UsePaginationInstanceProps<T>
  & UseSortByInstanceProps<T>
  & UseGlobalFiltersInstanceProps<T>;

export function formatNumberColumn(value: number | null | undefined) {
  return (<>{value?.toLocaleString()}</>);
}

export function formatBooleanColumn(value: boolean | null | undefined) {
  return (<>{value ? 'Y' : ''}</>);
}

export function Table<T extends object>({
  columns,
  data,
  sort,
  onRowClick,
  initialState: configuration,
  borderless,
  tableClassName,
  size,
  filterPlaceholder,
  stateScopeId,
}: TableProps<T>) {
  const pageSize = configuration?.pageSize ?? 100;
  const [displayedItems, setPageSize] = useTablePageSize(
    stateScopeId ?? DEFAULT_TABLE_STATE_ID,
    pageSize,
  );

  const [tableSort, setTableSort] = useTableSorts(
    stateScopeId ?? DEFAULT_TABLE_STATE_ID,
    configuration?.sortBy,
  );

  const displayedItemsRef = useRef(displayedItems);
  displayedItemsRef.current = displayedItems;

  const [filter, setFilter] = useSearchBoxValue(stateScopeId ?? DEFAULT_SEARCH_VALUE_ID);

  const initialState = {
    disableGlobalFilter: true,
    ...configuration,
    pageSize: displayedItems,
  };

  const filterRef = useRef(filter);
  filterRef.current = filter;
  const tableSortRef = useRef(tableSort);
  tableSortRef.current = tableSort;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const controlledState = useCallback((state: any) => {
    return {
      ...state,
      globalFilter: filterPlaceholder ? filterRef.current : undefined,
      sortBy: tableSortRef.current ?? state.sortBy,
    };
  }, [filterPlaceholder]);

  const options: UseSortByOptions<T> & TableOptions<T> & UseGlobalFiltersOptions<T> = {
    columns,
    data,
    initialState,
    disableSortBy: !sort,
    autoResetSortBy: false,
    disableGlobalFilter: !filterPlaceholder,
    autoResetGlobalFilter: false,
    useControlledState: controlledState,
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    setPageSize: setTablePageSize,
  } = useTable(options, useGlobalFilter, useSortBy, usePagination) as PaginatedTableInstance<T>;

  const rowCursor = onRowClick ? 'pointer' : 'inherit';

  const incrementPageSize = useCallback((entries: IntersectionObserverEntry[]) => {
    const newPageSize = entries[0].isIntersecting && data.length > displayedItemsRef.current
      ? displayedItemsRef.current + pageSize
      : displayedItemsRef.current;
    if (newPageSize !== displayedItemsRef.current) {
      setPageSize(newPageSize);
    }
  }, [setPageSize, data.length, pageSize]);
  useEffect(() => setTablePageSize(displayedItems), [displayedItems, setTablePageSize]);
  const visibleRef = useIsVisible<HTMLTableRowElement>(incrementPageSize);

  return (
    <div className="d-flex flex-column flex-grow-1 gap-2">
      {
        !!filterPlaceholder
        && (
          <SearchBox
            initialSearch={filter}
            placeholder={filterPlaceholder}
            onFilterChange={setFilter} />
        )
      }
      <ScrollArea id={stateScopeId}>
        <BsTable
          {...getTableProps()}
          borderless={borderless}
          hover
          size={size}
          className={tableClassName}>
          <thead>
            {
              headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    headerGroup.headers.map((column: any) => (
                      <th
                        {...column.getHeaderProps((column).getSortByToggleProps())}
                        className={column.canSort ? 'hover' : undefined}
                        onClick={() => {
                          setTableSort([
                            {
                              id: column.id,
                              desc: column.isSortedDesc === undefined
                                ? false
                                : !column.isSortedDesc,
                            },
                          ]);
                        }}>
                        <span className="d-flex">
                          {
                            column.render('Header')
                          }
                          <SortIndicator column={column} />
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  style={{ cursor: rowCursor }}
                  {...row.getRowProps()}
                  onClick={() => onRowClick && onRowClick(row.original)}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {
              (page.length < rows.length)
              && (
                <tr ref={visibleRef}>
                  <td colSpan={1000}>
                    <span className="d-flex justify-content-center">
                      <LoadingSpinner isLoading><span>notShown</span></LoadingSpinner>
                    </span>
                  </td>
                </tr>
              )
            }
          </tbody>
        </BsTable>
      </ScrollArea>
    </div>
  );
}

Table.defaultProps = {
  sort: undefined,
  onRowClick: undefined,
  initialState: undefined,
  borderless: undefined,
  tableClassName: undefined,
  size: undefined,
  filterPlaceholder: undefined,
  stateScopeId: undefined,
};

function SortIndicator<T extends object>({ column }: {
  column: ColumnInstance<T>
}) {
  if (isSortedColumn(column) && column.canSort) {
    let sortIndicator: 'sort' | 'sortDown' | 'sortUp' = 'sort';
    if (column.isSorted) {
      sortIndicator = column.isSortedDesc
        ? 'sortDown'
        : 'sortUp';
    }

    return (
      <span className="text-muted ms-auto ps-1">
        {' '}
        <Icon icon={sortIndicator} />
      </span>
    );
  }
  return null;
}

function isSortedColumn<T extends object>(col: ColumnInstance<T>)
  : col is ColumnInstance<T> & UseSortByColumnProps<T> {
  return 'isSorted' in col && (col as unknown as UseSortByColumnProps<T>).isSorted !== undefined;
}

export function useIsVisible<E extends Element>(
  cb: IntersectionObserverCallback,
) {
  const [targetEl, setTargetEl] = useState<Element | null>(null);

  useEffect(() => {
    if (targetEl) {
      const observer = new IntersectionObserver((cb), {
        root: null,
      });

      observer.observe(targetEl);

      return () => {
        if (observer) {
          observer.disconnect();
        }
      };
    }

    return noop;
  }, [cb, targetEl]);

  return (elem: E) => {
    setTargetEl(elem);
  };
}
