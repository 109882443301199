import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ToastNotification } from './ToastNotification';
import { dismissNotification, selectNotifications } from '../notificationSlice';

export function NotificationList() {
  const notifications = useAppSelector((state) => selectNotifications(state));
  const dispatch = useAppDispatch();

  const dismiss = (id: string) => dispatch(dismissNotification(id));

  return (
    <div className="position-fixed top-0 end-0 p-3 pt-5 toast-container">
      {notifications.map((n) => (
        <ToastNotification
          {...n}
          key={n.id}
          onDismiss={() => dismiss(n.id)} />
      ))}
    </div>
  );
}
