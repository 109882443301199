import React, { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Icon, IconName } from '../../icons';

type IconButtonColor = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'

export type IconButtonProps = {
  className?: string;
  color?: IconButtonColor;
  iconName: IconName;
  toolTip: string;
  onClick: React.MouseEventHandler<HTMLSpanElement>;
  onMouseDown?: React.MouseEventHandler<HTMLSpanElement>;
};

export function IconButton({
  className, color, iconName, toolTip, onClick, onMouseDown,
}: IconButtonProps) {
  const buttonRef = useRef(null);

  const spanClass = ['cursor-pointer', `link-${color ?? 'primary'}`, className]
    .filter((s) => s !== undefined)
    .join(' ');

  return (
    <>
      <span
        ref={buttonRef}
        className={spanClass}
        onMouseDown={onMouseDown ? (e) => onMouseDown(e) : undefined}
        onClick={onClick}>
        <Icon icon={iconName} />
      </span>
      <UncontrolledTooltip target={buttonRef}>
        {toolTip}
      </UncontrolledTooltip>
    </>
  );
}

IconButton.defaultProps = {
  className: undefined,
  color: 'primary',
  onMouseDown: undefined,
};
