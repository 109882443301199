import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LicenseFooter } from '../components/LicenseFooter';
import { StandardFooter } from '../components/StandardFooter';

export function Footer() {
  return (
    <Routes>
      <Route path=":orgId/projects/:projectId/database/*" element={null} />
      <Route path=":orgId/projects/:projectId" element={<LicenseFooter />} />
      <Route path="*" element={<StandardFooter />} />
    </Routes>
  );
}
