import { toObjectMap } from '../../../../../util';
import { useTabHistory } from '../../../tabs';

type DiagramHistoryMap = { [k: string]: { id: string, index: number } };

export function useRecentDiagrams(): DiagramHistoryMap {
  const [recentTabs] = useTabHistory();

  const recentDiagrams = recentTabs
    .filter((t) => t.type === 'diagrams')
    .map((t) => t.id)
    .slice(0, 10);

  const recentDiagramMap = toObjectMap(
    recentDiagrams,
    (id, i) => [id, { id, index: i }],
  );

  return recentDiagramMap;
}
