import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignoutCallbackComponent } from '@ericlamb/redux-oidc';
import { LoadingPage } from '../components/elements';
import userManager from '../features/authentication/userManager';

export function LogoutCallback() {
  const navigate = useNavigate();
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (complete) {
      navigate('/', { replace: true });
    }
  });

  return (
    <SignoutCallbackComponent
      userManager={userManager}
      successCallback={() => setComplete(true)}>
      <LoadingPage message="Completing logout request..." />
    </SignoutCallbackComponent>
  );
}
