import React from 'react';
import { Icon } from '../icons';
import styles from './LoadingPage.module.css';

export function LoadingPage({ message }:
  {
    message: string;
  }) {
  return (
    <div className={styles['page-loading-container']}>
      <Icon icon="harvest" className={styles['page-loading-spinner']} />
      <h1>{message}</h1>
    </div>
  );
}
