import React, { ReactNode, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useHarvests } from '../../app/api';
import { ProjectContextConsumer } from '../project';
import { HarvestUploader } from '../project/components/HarvestUploader';
import { useFavoriteHarvests } from '../project/useFavoriteHarvests';
import { HarvestSwitcher } from './HarvestSwitcher';

type HarvestParams = 'databaseId';

export function HarvestMenu({ children }: {
  children?: ReactNode;
}) {
  return (
    <Routes>
      <Route
        path="database/:databaseId/*"
        element={(
          <ProjectContextConsumer>
            {() => (
              <>
                <LoadedHarvestSwitcher />
                {children}
              </>
            )}
          </ProjectContextConsumer>
        )} />
      <Route path="*" element={null} />
    </Routes>
  );
}

function LoadedHarvestSwitcher() {
  const { databaseId } = useParams<HarvestParams>();

  const { harvests: data } = useHarvests();

  const { favoriteHarvests } = useFavoriteHarvests(data);

  const harvest = useMemo(() => {
    return favoriteHarvests?.filter((h) => h.id === databaseId)[0];
  }, [favoriteHarvests, databaseId]);

  return !!data && !!harvest
    ? (
      <HarvestUploader>
        {(onUpload) => (
          <HarvestSwitcher
            harvests={favoriteHarvests}
            currentHarvest={harvest}
            onUpload={onUpload} />
        )}
      </HarvestUploader>
    )
    : null;
}

HarvestMenu.defaultProps = {
  children: undefined,
};
