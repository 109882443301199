import { toObjectMap } from '../../../../../util';
import { useDiagramListPreferences } from '../Diagrams';

export type FavoriteDiagramMap = { [index: string]: boolean; };

export function useDiagramFavorites() {
  const { preferences, setPreferences } = useDiagramListPreferences();

  const { favoriteDiagrams } = preferences;

  const addToFavorites = (diagramId: string) => {
    setPreferences({
      ...preferences,
      favoriteDiagrams: [
        diagramId,
        ...favoriteDiagrams,
      ],
    });
  };

  const removeFromFavorites = (diagramId: string) => {
    setPreferences({
      ...preferences,
      favoriteDiagrams: [
        ...favoriteDiagrams.filter((id) => id !== diagramId),
      ],
    });
  };

  const favoriteDiagramMap = toObjectMap(
    favoriteDiagrams,
    (id) => [id, true],
  );

  return {
    favoriteDiagrams: favoriteDiagramMap,
    addToFavorites,
    removeFromFavorites,
  };
}
