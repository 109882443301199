import React, { useMemo } from 'react';
import styles from './Connector.module.css';

export type Coordinate = {
  x: number;
  y: number;
}

export type Rectangle = Coordinate & {
  width: number;
  height: number;
}

type ConnectorProps = {
  parent: Rectangle | undefined,
  child: Rectangle | undefined,
  drawingOrigin: Coordinate,
  scale: number,
  markerSize: number,
  markerId?: string;
}

export function Connector({ markerId, ...props }: ConnectorProps) {
  const zeroBox = {
    x: 0, y: 0, width: 0, height: 0,
  };
  const parent = props.parent ?? zeroBox;
  const child = props.child ?? zeroBox;
  const origin = props.drawingOrigin ?? { x: 0, y: 0 };
  const path = useMemo(() => {
    const margin = 10;
    const markerOffset = props.markerSize;
    const capturedOrigin = { x: origin.x, y: origin.y };
    const parentBox = shiftPoint({
      x: parent.x, y: parent.y, width: parent.width, height: parent.height,
    }, capturedOrigin, props.scale);
    const childBox = shiftPoint({
      x: child.x, y: child.y, width: child.width, height: child.height,
    }, capturedOrigin, props.scale);

    let [
      parentY,
      childY,
    ] = findConnectionPoints(parentBox, childBox, margin, (x) => x.y, (x) => x.height);
    let [
      parentX,
      childX,
    ] = findConnectionPoints(parentBox, childBox, margin, (x) => x.x, (x) => x.width);

    if (parentX === childX || parentY === childY) {
      if (childX === parentX) {
        [
          parentY,
          childY,
        ] = offsetPoints(parentBox, childBox, (x) => x.y, (x) => x.height, markerOffset);
      } else if (childY === parentY) {
        [
          parentX,
          childX,
        ] = offsetPoints(parentBox, childBox, (x) => x.x, (x) => x.width, markerOffset);
      }

      return `${parentX},${parentY} ${childX},${childY}`;
    }

    if (parentY <= childY) {
      parentY = parentBox.y + parentBox.height;
    } else {
      parentY = parentBox.y;
    }

    if (Math.abs(parentY - childY) < markerOffset + margin) {
      childY = parentBox.y <= childBox.y
        ? Math.max(parentY + margin + markerOffset, childY)
        : Math.min(parentY - margin - markerOffset, childY);
    }

    if (childX <= parentX) {
      childX = childBox.x + childBox.width;
    } else {
      childX = childBox.x;
    }

    if (Math.abs(parentX - childX) < margin) {
      parentX = childBox.x <= parentBox.x
        ? Math.max(childX + margin, parentX)
        : Math.min(childX - margin, parentX);
    }

    parentY += parentY <= childY
      ? markerOffset
      : -markerOffset;

    return `${parentX},${parentY} ${parentX},${childY} ${childX},${childY}`;
  }, [parent.x, parent.y, parent.height, parent.width,
    child.x, child.y, child.height, child.width,
    origin.x, origin.y,
    props.scale, props.markerSize]);

  return (
    <g className={styles.connector}>
      <polyline
        className={styles.highlight}
        strokeWidth={5}
        points={path} />
      <polyline
        points={path}
        markerStart={`url(${markerId})`} />
    </g>
  );
}

Connector.defaultProps = {
  markerId: '#arrow',
};

function shiftPoint(point: Rectangle, origin: Coordinate, scale: number) {
  const shiftValue = (start: number, offset: number) => (start - offset) / scale;
  return {
    x: shiftValue(point.x, origin.x),
    y: shiftValue(point.y, origin.y),
    width: point.width / scale,
    height: point.height / scale,
  };
}

function checkLimit(point: number, origin: number, size: number, margin: number) {
  let limited = Math.max(point, origin + margin);
  limited = Math.min(limited, origin + size - margin);
  return limited;
}

/** Finds the points to draw a line from parallel sides of a box.
 * Attempts to find a straight line between sides.
 * Attempts to use the midpoint of the max and min x/y coordinate of both boxes.
 * Limits points to a margin from the sides of the edge.
 */
function findConnectionPoints(
  parent: Rectangle,
  child: Rectangle,
  margin: number,
  originAccessor: (x: Rectangle) => number,
  sizeAccessor: (x: Rectangle) => number,
): [parentPoint: number, childPoint: number] {
  const parentOrigin = originAccessor(parent);
  const childOrigin = originAccessor(child);
  const parentSize = sizeAccessor(parent);
  const childSize = sizeAccessor(child);

  const minOrigin = Math.min(parentOrigin, childOrigin);
  const innerMin = Math.max(parentOrigin - minOrigin, childOrigin - minOrigin);
  const innerMax = Math.min(
    parentOrigin + parentSize - minOrigin,
    childOrigin + childSize - minOrigin,
  );
  const midPoint = ((innerMin + innerMax)) / 2 + minOrigin;

  return [
    checkLimit(midPoint, parentOrigin, parentSize, margin),
    checkLimit(midPoint, childOrigin, childSize, margin),
  ];
}

/** Adjust points so they fall on the correct side of their boxes with the required marker offset */
function offsetPoints(
  parent: Rectangle,
  child: Rectangle,
  originAccessor: (x: Rectangle) => number,
  sizeAccessor: (x: Rectangle) => number,
  markerOffset: number,
): [parentPoint: number, childPoint: number] {
  const parentOrigin = originAccessor(parent);
  const childOrigin = originAccessor(child);
  const parentSize = sizeAccessor(parent);
  const childSize = sizeAccessor(child);

  let parentPoint = parentOrigin < childOrigin + childSize
    ? parentOrigin + parentSize
    : parentOrigin;
  let childPoint = parentOrigin < childOrigin
    ? childOrigin
    : childOrigin + childSize;

  const adjustedMarkerOffset = parentPoint < childPoint
    ? markerOffset
    : markerOffset * -1;

  childPoint += Math.abs(parentPoint - childPoint) > Math.abs(adjustedMarkerOffset)
    ? 0
    : adjustedMarkerOffset * 2;
  parentPoint += adjustedMarkerOffset;

  return [parentPoint, childPoint];
}
