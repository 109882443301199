import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import { ProjectWithCountsDto } from '../../../app/api';
import { Icon } from '../../../components/elements';
import { useSortedProjects } from '../hooks/useSortedProjects';
import { useProject } from '../ProjectContext';

export function ProjectSwitcher({ projects }: {
  projects: ProjectWithCountsDto[];
}) {
  const project = useProject();
  const sortedProjects = useSortedProjects(projects);
  const otherProjects = useMemo(() => {
    return sortedProjects.filter((x) => x.id !== project.projectId);
  }, [project.projectId, sortedProjects]);

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        <Icon icon="project" /> {project.projectName}
      </DropdownToggle>
      <DropdownMenu>
        <Link to="../newProject">
          <DropdownItem>
            <Icon icon="newProject" /> New Project
          </DropdownItem>
        </Link>
        {otherProjects.length > 0
          && (
            <>
              <Link to="..">
                <DropdownItem>
                  <Icon icon="projects" /> Project List
                </DropdownItem>
              </Link>
              <DropdownItem divider />
              {otherProjects.map((p) => <ProjectLink project={p} key={p.id} />)}
            </>
          )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function ProjectLink({ project }: {
  project: ProjectWithCountsDto;
}) {
  return (
    <Link to={`../projects/${project.id}`}>
      <DropdownItem>
        {project.name}
      </DropdownItem>
    </Link>
  );
}
