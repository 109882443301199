import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { noop } from 'lodash';
import {
  taggedApi,
} from './api-tagged';
import { optimisticUpdate, pessimisticCreate } from './api-util';
import { ErrorMap } from './rtkQueryErrorLogger';
import { ActivityFeedTag } from './api-activityLog';

type DiagramErrorMap = Pick<ErrorMap,
  'userApiUpdateDiagram'
  | 'userApiCreateDiagram'
  | 'userApiGetDiagramById'
  | 'userApiListDiagrams'
  | 'userApiDeleteDiagram'
  | 'userApiCreateDiagramTable'
  | 'userApiDeleteDiagramTable'
  | 'userApiUpdateDiagramTable'
  | 'userApiListDiagramTables'
  | 'userApiUpdateDiagramPreferences'
  | 'userApiUpdateCurrentAccountDiagramPreferences'
  | 'userApiCreateDiagramTag'
  | 'userApiDeleteDiagramTag'
  | 'userApiCreateDiagramRectangle'
  | 'userApiDeleteDiagramRectangle'
  | 'userApiUpdateDiagramRectangle'
  | 'userApiUpdateDiagramOwner'>;

export const diagramErrorMap: DiagramErrorMap = {
  userApiUpdateDiagram: (args) => `Update Diagram ${args.diagramCreateDto.name}`,
  userApiCreateDiagram: (args) => `Create Diagram ${args.diagramCreateDto.name}`,
  userApiGetDiagramById: () => 'Get Diagram',
  userApiListDiagrams: () => 'List Diagrams',
  userApiDeleteDiagram: () => 'Delete Diagram',
  userApiCreateDiagramTable: (args) => `Adding Table ${args.diagramTableCreateDto.tableName} to Diagram`,
  userApiDeleteDiagramTable: () => 'Removing Table From Diagram',
  userApiUpdateDiagramTable: (args) => `Moving Table ${args.diagramTableUpdateDto.tableName}`,
  userApiListDiagramTables: () => 'Loading Diagram Tables',
  userApiUpdateDiagramPreferences: () => 'Update Diagram Preferences',
  userApiUpdateCurrentAccountDiagramPreferences: () => 'Update Diagram User Preferences',
  userApiCreateDiagramTag: (args) => `Add diagram tag ${args.diagramTagCreateDto.tagName}`,
  userApiDeleteDiagramTag: () => 'Delete diagram tag',
  userApiCreateDiagramRectangle: () => 'Adding Rectangle to Diagram',
  userApiDeleteDiagramRectangle: () => 'Removing Rectangle from Diagram',
  userApiUpdateDiagramRectangle: () => 'Updating Rectangle on Diagram',
  userApiUpdateDiagramOwner: () => 'Update Diagram Owner',
};

export function addDiagramApiExtensions(api: typeof taggedApi) {
  return api.enhanceEndpoints({
    endpoints: {
      userApiListDiagrams: {
        providesTags: (result, _, { projectId }) => (result
          ? [
            ...result.map((h) => CreateDiagramTag(h.id)),
            CreateProjectTag(projectId),
          ]
          : [CreateProjectTag(projectId)]),
      },
      userApiGetDiagramById: {
        providesTags: (result, __, { diagramId }) => (result
          ? [
            ...result.tables.map((t) => CreateDiagramTableTag(t.id)),
            ...result.rectangles.map((r) => CreateDiagramRectangleTag(r.id)),
            CreateDiagramTag(diagramId),
          ]
          : [CreateDiagramTag(diagramId)]),
      },
      userApiDeleteDiagram: {
        onQueryStarted: async ({ diagramId }, mutation) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [CreateDiagramTag(diagramId)],
            updateHandlers: {
              userApiListDiagrams: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListDiagrams', originalArgs, (draft) => {
                  const index = draft.findIndex((d) => d.id === diagramId);
                  draft.splice(index, 1);
                });
              },
            },
          });
        },
      },
      userApiCreateDiagram: {
        onQueryStarted: async ({ projectId }, mutation) => {
          await pessimisticCreate(mutation, api, {
            invalidatedTags: [
              CreateProjectTag(projectId),
              ActivityFeedTag,
            ],
            updateHandlers: {
              userApiListDiagrams: ({ originalArgs }, created) => {
                return api.util.updateQueryData('userApiListDiagrams', originalArgs, (draft) => {
                  draft.push({
                    tags: [],
                    tables: [],
                    rectangles: [],
                    globalPreferences: '{}',
                    instancePreferences: '{}',
                    ...created,
                  });
                });
              },
              userApiListActivityLogEntriesByProject: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListActivityLogEntriesByProject', originalArgs, noop);
              },
            },
          });
        },
      },
      userApiUpdateDiagram: {
        onQueryStarted: async (
          { diagramId, diagramCreateDto },
          mutation,
        ) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [CreateDiagramTag(diagramId)],
            updateHandlers: {
              userApiListDiagrams: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListDiagrams', originalArgs, (draft) => {
                  const diagram = draft.find((d) => d.id === diagramId);
                  if (diagram) {
                    Object.assign(diagram, diagramCreateDto);
                  }
                });
              },
              userApiGetDiagramById: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetDiagramById', originalArgs, (draft) => {
                  Object.assign(draft, diagramCreateDto);
                });
              },
            },
          });
        },
      },
      userApiUpdateDiagramOwner: {
        invalidatesTags: (_, __, arg) => [CreateDiagramTag(arg.diagramId)],
      },
      userApiListDiagramTables: {
        providesTags: (result, _, { diagramId }) => (result
          ? [
            ...result.map((t) => CreateDiagramTableTag(t.id)),
            CreateDiagramTag(diagramId),
          ]
          : [CreateDiagramTag(diagramId)]),
      },
      userApiCreateDiagramTable: {
        onQueryStarted: async (
          { diagramId, diagramTableCreateDto },
          {
            dispatch, queryFulfilled, getState, requestId,
          },
        ) => {
          const state = getState();
          const invalidatedTags = [CreateDiagramTag(diagramId), ActivityFeedTag];
          const invalidated = api.util.selectInvalidatedBy(state, invalidatedTags);

          const patchResults: PatchCollection[] = [];

          const tempTable = {
            ...diagramTableCreateDto,
            order: 0,
            id: requestId,
          };

          invalidated.filter((i) => i.endpointName === 'userApiGetDiagramById')
            .forEach((i) => {
              patchResults.push(dispatch(
                api.util.updateQueryData('userApiGetDiagramById', i.originalArgs, (draft) => {
                  draft.tables.push(tempTable);
                }),
              ));
            });
          invalidated.filter((i) => i.endpointName === 'userApiListDiagramTables')
            .forEach((i) => {
              patchResults.push(dispatch(
                api.util.updateQueryData('userApiListDiagramTables', i.originalArgs, (draft) => {
                  draft.push(tempTable);
                }),
              ));
            });

          try {
            const result = await queryFulfilled;
            invalidated.filter((i) => i.endpointName === 'userApiGetDiagramById')
              .forEach((i) => {
                patchResults.push(dispatch(
                  api.util.updateQueryData('userApiGetDiagramById', i.originalArgs, (draft) => {
                    const index = draft.tables.findIndex((d) => d.id === requestId);
                    draft.tables.splice(index, 1, result.data);
                  }),
                ));
              });
            invalidated.filter((i) => i.endpointName === 'userApiListDiagramTables')
              .forEach((i) => {
                patchResults.push(dispatch(
                  api.util.updateQueryData('userApiListDiagramTables', i.originalArgs, (draft) => {
                    const index = draft.findIndex((d) => d.id === requestId);
                    draft.splice(index, 1, result.data);
                  }),
                ));
              });
            dispatch(api.util.invalidateTags(invalidatedTags));
          } catch {
            patchResults.forEach((p) => p.undo());
          }
        },
      },
      userApiDeleteDiagramTable: {
        onQueryStarted: async ({ diagramTableId }, mutation) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [
              CreateDiagramTableTag(diagramTableId),
              ActivityFeedTag,
            ],
            updateHandlers: {
              userApiGetDiagramById: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetDiagramById', originalArgs, (draft) => {
                  const index = draft.tables.findIndex((d) => d.id === diagramTableId);
                  draft.tables.splice(index, 1);
                });
              },
              userApiListDiagramTables: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListDiagramTables', originalArgs, (draft) => {
                  const index = draft.findIndex((d) => d.id === diagramTableId);
                  draft.splice(index, 1);
                });
              },
              userApiListActivityLogEntriesByProject: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListActivityLogEntriesByProject', originalArgs, noop);
              },
            },
          });
        },
      },
      userApiUpdateDiagramTable: {
        onQueryStarted: async (
          { diagramTableId, diagramTableUpdateDto },
          mutation,
        ) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [CreateDiagramTableTag(diagramTableId)],
            updateHandlers: {
              userApiGetDiagramById: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetDiagramById', originalArgs, (draft) => {
                  const diagram = draft.tables.find((d) => d.id === diagramTableId);
                  if (diagram) {
                    Object.assign(diagram, diagramTableUpdateDto);
                  }
                });
              },
              userApiListDiagramTables: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListDiagramTables', originalArgs, (draft) => {
                  const diagram = draft.find((d) => d.id === diagramTableId);
                  if (diagram) {
                    Object.assign(diagram, diagramTableUpdateDto);
                  }
                });
              },
            },
          });
        },
      },
      userApiCreateDiagramTag: {
        onQueryStarted: async (
          { diagramId, diagramTagCreateDto },
          {
            dispatch, queryFulfilled, getState, requestId,
          },
        ) => {
          const state = getState();
          const invalidatedTags = [CreateDiagramTag(diagramId)];
          const invalidated = api.util.selectInvalidatedBy(state, invalidatedTags);

          const patchResults: PatchCollection[] = [];

          const tempTag = {
            ...diagramTagCreateDto,
            id: requestId,
          };

          invalidated.filter((i) => i.endpointName === 'userApiGetDiagramById')
            .forEach((i) => {
              patchResults.push(dispatch(
                api.util.updateQueryData('userApiGetDiagramById', i.originalArgs, (draft) => {
                  draft.tags.push(tempTag);
                }),
              ));
            });
          invalidated.filter((i) => i.endpointName === 'userApiListDiagrams')
            .forEach((i) => {
              patchResults.push(dispatch(
                api.util.updateQueryData('userApiListDiagrams', i.originalArgs, (draft) => {
                  const diagram = draft.find((d) => d.id === diagramId);
                  if (diagram) {
                    diagram.tags.push(tempTag);
                  }
                }),
              ));
            });

          try {
            const result = await queryFulfilled;
            invalidated.filter((i) => i.endpointName === 'userApiGetDiagramById')
              .forEach((i) => {
                patchResults.push(dispatch(
                  api.util.updateQueryData('userApiGetDiagramById', i.originalArgs, (draft) => {
                    const index = draft.tables.findIndex((d) => d.id === requestId);
                    draft.tags.splice(index, 1, result.data);
                  }),
                ));
              });
            invalidated.filter((i) => i.endpointName === 'userApiListDiagrams')
              .forEach((i) => {
                patchResults.push(dispatch(
                  api.util.updateQueryData('userApiListDiagrams', i.originalArgs, (draft) => {
                    const diagram = draft.find((d) => d.id === diagramId);
                    if (diagram) {
                      const index = diagram.tags.findIndex((t) => t.id === requestId);
                      diagram.tags.splice(index, 1, result.data);
                    }
                  }),
                ));
              });
            dispatch(api.util.invalidateTags(invalidatedTags));
          } catch {
            patchResults.forEach((p) => p.undo());
          }
        },
      },
      userApiDeleteDiagramTag: {
        onQueryStarted: async ({ diagramId, diagramTagId }, mutation) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [CreateDiagramTag(diagramId)],
            updateHandlers: {
              userApiGetDiagramById: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetDiagramById', originalArgs, (draft) => {
                  const index = draft.tags.findIndex((d) => d.id === diagramTagId);
                  draft.tables.splice(index, 1);
                });
              },
              userApiListDiagrams: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListDiagrams', originalArgs, (draft) => {
                  const diagram = draft.find((d) => d.id === diagramId);
                  if (diagram) {
                    const index = diagram.tags.findIndex((d) => d.id === diagramTagId);
                    diagram.tags.splice(index, 1);
                  }
                });
              },
            },
          });
        },
      },
      userApiCreateDiagramRectangle: {
        onQueryStarted: async (
          { diagramId, diagramRectangleCreateDto },
          {
            dispatch, queryFulfilled, getState, requestId,
          },
        ) => {
          const state = getState();
          const invalidatedTags = [
            CreateDiagramTag(diagramId),
            ActivityFeedTag,
          ];
          const invalidated = api.util.selectInvalidatedBy(state, invalidatedTags);

          const patchResults: PatchCollection[] = [];

          const tempRectangle = {
            ...diagramRectangleCreateDto,
            order: 0,
            id: requestId,
          };

          invalidated.filter((i) => i.endpointName === 'userApiGetDiagramById')
            .forEach((i) => {
              patchResults.push(dispatch(
                api.util.updateQueryData('userApiGetDiagramById', i.originalArgs, (draft) => {
                  draft.rectangles.push(tempRectangle);
                }),
              ));
            });

          try {
            const result = await queryFulfilled;
            invalidated.filter((i) => i.endpointName === 'userApiGetDiagramById')
              .forEach((i) => {
                patchResults.push(dispatch(
                  api.util.updateQueryData('userApiGetDiagramById', i.originalArgs, (draft) => {
                    const index = draft.rectangles.findIndex((d) => d.id === requestId);
                    draft.rectangles.splice(index, 1, result.data);
                  }),
                ));
              });
            dispatch(api.util.invalidateTags(invalidatedTags));
          } catch {
            patchResults.forEach((p) => p.undo());
          }
        },
      },
      userApiDeleteDiagramRectangle: {
        onQueryStarted: async ({ diagramRectangleId }, mutation) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [
              CreateDiagramRectangleTag(diagramRectangleId),
              ActivityFeedTag,
            ],
            updateHandlers: {
              userApiGetDiagramById: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetDiagramById', originalArgs, (draft) => {
                  const index = draft.rectangles.findIndex((r) => r.id === diagramRectangleId);
                  draft.rectangles.splice(index, 1);
                });
              },
              userApiListActivityLogEntriesByProject: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListActivityLogEntriesByProject', originalArgs, noop);
              },
            },
          });
        },
      },
      userApiUpdateDiagramRectangle: {
        onQueryStarted: async (
          { diagramRectangleId, diagramRectangleUpdateDto },
          mutation,
        ) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [CreateDiagramRectangleTag(diagramRectangleId)],
            updateHandlers: {
              userApiGetDiagramById: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetDiagramById', originalArgs, (draft) => {
                  const rectangle = draft.rectangles.find((r) => r.id === diagramRectangleId);
                  if (rectangle) {
                    Object.assign(rectangle, diagramRectangleUpdateDto);
                  }
                });
              },
            },
          });
        },
      },
    },
  });
}

function CreateProjectTag(projectId: string): { type: 'Diagrams', id: string } {
  return { type: 'Diagrams', id: `project-${projectId}` };
}

function CreateDiagramTag(diagramId: string): { type: 'Diagram', id: string } {
  return { type: 'Diagram', id: `${diagramId}` };
}

function CreateDiagramTableTag(diagramTableID: string): { type: 'DiagramTable', id: string } {
  return { type: 'DiagramTable', id: diagramTableID };
}

function CreateDiagramRectangleTag(diagramRectangleID: string): { type: 'DiagramRectangle', id: string } {
  return { type: 'DiagramRectangle', id: diagramRectangleID };
}
