import { noop } from 'lodash';
import { insensitiveEquals } from '../../util';
import { ActivityFeedTag } from './api-activityLog';
import { CreateTableReferencedByTag, CreateTableTag, CreateTableOverriddenByTag } from './api-harvests';
import { taggedApi } from './api-tagged';
import { optimisticUpdate } from './api-util';
import { ErrorMap } from './rtkQueryErrorLogger';

type RootEntityOverrideErrorMap = Pick<ErrorMap,
  'userApiCreateMmRootEntityOverride'
  | 'userApiDeleteMmRootEntityOverride'>;

export const rootEntityErrorMap: RootEntityOverrideErrorMap = {
  userApiCreateMmRootEntityOverride: (args) => `Create Override ${args.mmRootEntityOverrideCreateDto.sourceTable}.${args.mmRootEntityOverrideCreateDto.sourceColumn}`,
  userApiDeleteMmRootEntityOverride: (args) => `Delete Override ${args.rootEntityOverrideId}`,
};

export function addRootEntityOverrideExtensions(api: typeof taggedApi) {
  return api.enhanceEndpoints({
    endpoints: {
      userApiCreateMmRootEntityOverride: {
        invalidatesTags: (_, __, { mmRootEntityOverrideCreateDto }) => (
          mmRootEntityOverrideCreateDto.rootEntityName
            ? [
              CreateTableTag(mmRootEntityOverrideCreateDto.sourceTable),
              CreateTableTag(mmRootEntityOverrideCreateDto.rootEntityName),
              CreateTableReferencedByTag(mmRootEntityOverrideCreateDto.sourceTable),
              ActivityFeedTag,
            ]
            : [
              CreateTableTag(mmRootEntityOverrideCreateDto.sourceTable),
              ActivityFeedTag,
            ]
        ),
        onQueryStarted: async ({ mmRootEntityOverrideCreateDto: request }, mutation) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags:
              request.rootEntityName
                ? [
                  CreateTableTag(request.sourceTable),
                  CreateTableTag(request.rootEntityName),
                  CreateTableReferencedByTag(request.sourceTable),
                  ActivityFeedTag,
                ]
                : [
                  CreateTableTag(request.sourceTable),
                  ActivityFeedTag,
                ],
            updateHandlers: {
              userApiGetMmTableById: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetMmTableById', originalArgs, (draft) => {
                  if (insensitiveEquals(draft.name, request.sourceTable)) {
                    const column = draft.mmTableColumns
                      .find((c) => insensitiveEquals(
                        c.name,
                        request.sourceColumn,
                      ));
                    if (column) {
                      column.rootEntityOverride = {
                        id: 'temp',
                        createdBy: 'temp',
                        createdById: 'temp',
                        createdOn: 'temp',
                        reason: request.reason,
                        rootEntityName: request.rootEntityName,
                        rootEntityAttr: request.rootEntityAttr,
                      };
                    }
                  } else if (insensitiveEquals(draft.name, request.rootEntityName)) {
                    draft.childReferences.push({
                      tableName: request.sourceTable,
                      columnName: request.sourceColumn,
                    });
                  } else {
                    const index = draft.childReferences
                      .findIndex((r) => insensitiveEquals(r.columnName, request.sourceColumn)
                        && insensitiveEquals(r.tableName, request.sourceTable));
                    if (index >= 0) draft.childReferences.splice(index, 1);
                  }
                });
              },
              userApiListActivityLogEntriesByProject: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListActivityLogEntriesByProject', originalArgs, noop);
              },
            },
          });
        },
      },
      userApiDeleteMmRootEntityOverride: {
        onQueryStarted: async ({ rootEntityOverrideId }, mutation) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [
              CreateTableOverriddenByTag(rootEntityOverrideId),
              ActivityFeedTag,
            ],
            updateHandlers: {
              userApiGetMmTableById: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetMmTableById', originalArgs, (draft) => {
                  const column = draft.mmTableColumns
                    .find((c) => c.rootEntityOverride?.id === rootEntityOverrideId);
                  if (column) column.rootEntityOverride = undefined;

                  const overrideIndex = draft.childReferences
                    .findIndex((c) => c.overrideId === rootEntityOverrideId);
                  if (overrideIndex >= 0) draft.childReferences.splice(overrideIndex, 1);
                });
              },
              userApiListActivityLogEntriesByProject: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListActivityLogEntriesByProject', originalArgs, noop);
              },
            },
          });
        },
      },
    },
  });
}
