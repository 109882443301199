import { noop } from 'lodash';
import { insensitiveEquals } from '../../util';
import { ActivityFeedTag } from './api-activityLog';
import { CreateTableTag, CreateCodeSetOverriddenByTag, CreateCodeSetTag } from './api-harvests';
import { taggedApi } from './api-tagged';
import { optimisticUpdate } from './api-util';
import { ErrorMap } from './rtkQueryErrorLogger';

type CodeSetOverrideErrorMap = Pick<ErrorMap,
  'userApiCreateMmCodeSetOverride'
  | 'userApiDeleteMmCodeSetOverride'>;

export const codeSetOverrideErrorMap: CodeSetOverrideErrorMap = {
  userApiCreateMmCodeSetOverride: (args) => `Create Override ${args.mmCodeSetOverrideCreateDto.sourceTable}.${args.mmCodeSetOverrideCreateDto.sourceColumn}`,
  userApiDeleteMmCodeSetOverride: (args) => `Delete Override ${args.codeSetOverrideId}`,
};

export function addCodeSetOverrideExtensions(api: typeof taggedApi) {
  return api.enhanceEndpoints({
    endpoints: {
      userApiCreateMmCodeSetOverride: {
        invalidatesTags: (_, __, { mmCodeSetOverrideCreateDto }) => (
          [
            CreateTableTag(mmCodeSetOverrideCreateDto.sourceTable),
            CreateCodeSetTag(mmCodeSetOverrideCreateDto.code ?? -1),
            ActivityFeedTag,
          ]
        ),
        onQueryStarted: async ({ mmCodeSetOverrideCreateDto: requestData }, mutation) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [
              CreateTableTag(requestData.sourceTable),
              CreateCodeSetTag(requestData.code ?? -1),
              ActivityFeedTag,
            ],
            updateHandlers: {
              userApiGetMmTableById: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetMmTableById', originalArgs, (draft) => {
                  if (insensitiveEquals(draft.name, requestData.sourceTable)) {
                    const column = draft.mmTableColumns
                      .find((c) => insensitiveEquals(
                        c.name,
                        requestData.sourceColumn,
                      ));
                    if (column) {
                      column.mmCodeSetOverride = {
                        id: mutation.requestId,
                        createdBy: 'temp',
                        createdById: 'temp',
                        createdOn: 'temp',
                        reason: requestData.reason,
                        code: requestData.code,
                      };
                    }
                  }
                });
              },
              userApiListActivityLogEntriesByProject: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListActivityLogEntriesByProject', originalArgs, noop);
              },
            },
          });
        },
      },
      userApiDeleteMmCodeSetOverride: {
        onQueryStarted: async ({ codeSetOverrideId }, mutation) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [CreateCodeSetOverriddenByTag(codeSetOverrideId), ActivityFeedTag],
            updateHandlers: {
              userApiGetMmTableById: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetMmTableById', originalArgs, (draft) => {
                  const column = draft.mmTableColumns
                    .find((c) => c.rootEntityOverride?.id === codeSetOverrideId);
                  if (column) column.mmCodeSetOverride = undefined;
                });
              },
            },
          });
        },
      },
    },
  });
}
