import React, { ComponentType, forwardRef } from 'react';
import { TableDto } from '../../../app/api';

type HeaderColorable = {
  table: Pick<TableDto, 'rowCount'> | undefined;
  headerColor: string;
};

type WithHeaderColorProps = Omit<HeaderColorable, 'headerColor'> & {
  headerColors: DiagramTableHeaderColor[];
};

export type DiagramTableHeaderColor = {
  threshold: number;
  color: string;
}

export const withHeaderRowCountColorization = <P extends HeaderColorable>
  (Component: ComponentType<P>) => {
  return forwardRef((props: Omit<P, 'headerColor'> & WithHeaderColorProps, ref) => {
    const { headerColors, ...passThroughProps } = props;
    const headerColor = headerColors
      .filter((c) => c.threshold >= (props.table?.rowCount ?? Number.MAX_SAFE_INTEGER))
      .sort((a, b) => a.threshold - b.threshold)[0]?.color ?? '#ffffff';

    const newProps = { ...passThroughProps, headerColor } as P;
    return (<Component {...newProps} ref={ref} />);
  });
};
