import React, { useMemo } from 'react';
import { Button, ListGroup } from 'reactstrap';
import { MmDataModelDto, TableSummaryDto } from '../../../app/api';
import {
  ErrorWrapper, ExpansionMap, Icon, LoadingSpinner, ScrollArea, SearchBox, useExpansionMap,
} from '../../../components/elements';
import { useSearchBoxValue } from '../../../components/elements/searchBox/searchBoxSlice';
import { useDebouncedEventHandler } from '../../../hooks';
import { insensitiveCompare } from '../../../util/stringUtil';
import { ProjectPreferences, useProject } from '../../project';
import { getCategory } from '../diagram/util';
import { CategoryPreferenceMap } from '../settings';
import { TableGroup } from '../tables';
import { OnTablePrefetch } from '../tables/useTablePrefetch';

export function ModelList({
  models, isLoading, error, preferences, categories,
  onTableDetails, onOpenFilter, onTablePrefetch,
}: {
  models: MmDataModelDto[];
  isLoading: boolean;
  error: unknown;
  preferences: ProjectPreferences | undefined;
  categories: CategoryPreferenceMap;
  onTableDetails: (tableName: string) => void;
  onOpenFilter: () => void;
  onTablePrefetch: OnTablePrefetch;
}) {
  const { projectId } = useProject();
  const stateScopeId = `${projectId}-modelList`;

  const [filter, setFilter] = useSearchBoxValue(stateScopeId);
  const debouncedFilter = useDebouncedEventHandler(setFilter, 300);
  const filteredModels = useFilteredModels(models, filter, preferences);

  const sortedModels = useMemo(() => {
    return filteredModels.sort((a, b) => insensitiveCompare(a.name, b.name));
  }, [filteredModels]);
  const { expansionMap, setExpansion } = useExpansionMap(stateScopeId);

  return (
    <div className="d-flex flex-grow-1 flex-column gap-2">
      <div className="d-flex flex-row gap-1">
        <SearchBox
          placeholder="Find a table..."
          initialSearch={filter}
          onFilterChange={debouncedFilter} />

        <Button onClick={onOpenFilter}>
          <Icon icon="filter" />
        </Button>
      </div>

      <LoadingSpinner isLoading={isLoading}>
        <ErrorWrapper error={error} message={<h5>Unable to load table list</h5>}>
          <ScrollArea id={stateScopeId}>
            <ListGroup>
              {sortedModels
                .map((m) => (
                  <ModelGroup
                    key={m.id}
                    model={m}
                    filter={filter}
                    defaultExpanded={models?.length <= 1}
                    categories={categories}
                    expansionMap={expansionMap}
                    onTableDetails={onTableDetails}
                    onTablePrefetch={onTablePrefetch}
                    onSetExpanded={setExpansion} />
                ))}
            </ListGroup>
          </ScrollArea>
        </ErrorWrapper>
      </LoadingSpinner>
    </div>
  );
}

function ModelGroup({
  model, filter, defaultExpanded, categories, expansionMap,
  onTableDetails, onTablePrefetch, onSetExpanded,
}: {
  model: MmDataModelDto & { tables: TableSummaryDto[] },
  filter: string,
  defaultExpanded: boolean;
  categories: CategoryPreferenceMap,
  expansionMap: ExpansionMap;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
  onSetExpanded: (nodeId: string, expanded: boolean) => void;
}) {
  return (
    <TableGroup
      groupName={model.name}
      id={`model-${model.name}`}
      tables={model.tables}
      filter={filter}
      defaultExpanded={defaultExpanded}
      className="pe-2"
      categories={categories}
      expansionMap={expansionMap}
      onTableDetails={onTableDetails}
      onTablePrefetch={onTablePrefetch}
      onSetExpanded={onSetExpanded} />
  );
}

function useFilteredModels(
  models: MmDataModelDto[],
  filter: string,
  preferences?: ProjectPreferences,
) {
  const categoryMap = useMemo(() => (
    preferences?.categories?.reduce((acc: { [k: string]: boolean; }, c) => {
      const index = c.id;
      acc[index] = c.isHidden;
      return acc;
    }, {}) ?? {}
  ), [preferences?.categories]);

  const filteredSchemas = useMemo(() => {
    const filterText = filter.toUpperCase();
    const tableNameMatchesFilter = (t: TableSummaryDto, m: MmDataModelDto) => {
      return `${m.name}.${t.name}`.toUpperCase().includes(filterText);
    };

    const hasRowCount = (t: TableSummaryDto) => (
      preferences?.rowCountFilter === undefined
      || preferences?.rowCountFilter === null
      || t.isView
      || (t.rowCount ?? 0) >= preferences.rowCountFilter);

    const isCategoryShown = (t: TableSummaryDto) => !(categoryMap[getCategory(t)?.id ?? ''] ?? false);

    return models.map((m) => (
      {
        ...m,
        tables: m.tables.filter((t) => (
          tableNameMatchesFilter(t, m)
          && hasRowCount(t)
          && isCategoryShown(t)
        )),
      }));
  }, [filter, models, preferences?.rowCountFilter, categoryMap]);

  return filteredSchemas;
}
