import { taggedApi } from './api-tagged';
import { ErrorMap } from './rtkQueryErrorLogger';

type ActivityLogErrorMap = Pick<ErrorMap,
  'userApiListActivityLogEntriesByProject'>;

export const activityLogErrorMap: ActivityLogErrorMap = {
  userApiListActivityLogEntriesByProject: () => 'List Activity Log Entries',
};

export function addActivityLogApiExtensions(api: typeof taggedApi) {
  return api.enhanceEndpoints({
    endpoints: {
      userApiListActivityLogEntriesByProject: {
        providesTags: () => [ActivityFeedTag],
      },
    },
  });
}

export const ActivityFeedTag = { type: 'ActivityFeed' as const, id: 'ALL' };
