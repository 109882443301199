import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as freeSolidSvgIcons from '@fortawesome/free-solid-svg-icons';
import * as freeRegularSvgIcons from '@fortawesome/free-regular-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

export type IconName =
  'addProject' |
  'article' |
  'caretDown' |
  'caretUp' |
  'changeUser' |
  'check' |
  'chevronCircleUp' |
  'chevronCircleDown' |
  'chevronDown' |
  'chevronRight' |
  'clear' |
  'copy' |
  'codeSets' |
  'colors' |
  'columns' |
  'compare' |
  'diagram' |
  'download' |
  'drawRectangle' |
  'edit' |
  'error' |
  'exit' |
  'export' |
  'favorite' |
  'filter' |
  'flag' |
  'harvest' |
  'helpTip' |
  'hidden' |
  'history' |
  'home' |
  'index' |
  'info' |
  'locked' |
  'models' |
  'newProject' |
  'not-favorite' |
  'openArticle' |
  'openProject' |
  'openSearch' |
  'organization' |
  'organizations' |
  'plus' |
  'project' |
  'projects' |
  'recent' |
  'relationships' |
  'remove' |
  'retry' |
  'save' |
  'search' |
  'select' |
  'settings' |
  'sort' |
  'sortDown' |
  'sortUp' |
  'spinner' |
  'table' |
  'tag' |
  'trash' |
  'unchecked' |
  'unlock' |
  'unlocked' |
  'upload' |
  'user' |
  'visible';

const iconMap: { [K in IconName]: freeSolidSvgIcons.IconDefinition } = {
  addProject: freeSolidSvgIcons.faFolderPlus,
  article: freeSolidSvgIcons.faFileLines,
  caretDown: freeSolidSvgIcons.faCaretDown,
  caretUp: freeSolidSvgIcons.faCaretUp,
  changeUser: freeSolidSvgIcons.faUserEdit,
  check: freeSolidSvgIcons.faCheckSquare,
  chevronCircleUp: freeSolidSvgIcons.faChevronCircleUp,
  chevronCircleDown: freeSolidSvgIcons.faChevronCircleDown,
  chevronDown: freeSolidSvgIcons.faChevronDown,
  chevronRight: freeSolidSvgIcons.faChevronRight,
  clear: freeSolidSvgIcons.faXmark,
  copy: freeSolidSvgIcons.faClone,
  codeSets: freeSolidSvgIcons.faListOl,
  colors: freeSolidSvgIcons.faPalette,
  columns: freeSolidSvgIcons.faColumns,
  compare: freeSolidSvgIcons.faCodeCompare,
  diagram: freeSolidSvgIcons.faProjectDiagram,
  download: freeSolidSvgIcons.faDownload,
  drawRectangle: freeRegularSvgIcons.faSquare,
  edit: freeSolidSvgIcons.faEdit,
  error: freeSolidSvgIcons.faExclamationTriangle,
  exit: freeSolidSvgIcons.faXmark,
  export: freeSolidSvgIcons.faFileDownload,
  favorite: freeSolidSvgIcons.faStar,
  filter: freeSolidSvgIcons.faFilter,
  flag: freeSolidSvgIcons.faFlag,
  harvest: freeSolidSvgIcons.faDatabase,
  helpTip: freeSolidSvgIcons.faQuestionCircle,
  hidden: freeSolidSvgIcons.faEyeSlash,
  history: freeSolidSvgIcons.faClockRotateLeft,
  home: freeSolidSvgIcons.faHome,
  index: freeSolidSvgIcons.faIndent,
  info: freeSolidSvgIcons.faInfoCircle,
  locked: freeSolidSvgIcons.faLock,
  models: freeSolidSvgIcons.faObjectGroup,
  newProject: freeSolidSvgIcons.faFolderPlus,
  'not-favorite': freeRegularSvgIcons.faStar,
  openArticle: freeSolidSvgIcons.faBookOpen,
  openProject: freeSolidSvgIcons.faFolderOpen,
  openSearch: freeSolidSvgIcons.faMagnifyingGlassArrowRight,
  organization: freeSolidSvgIcons.faBuilding,
  organizations: freeSolidSvgIcons.faCity,
  plus: freeSolidSvgIcons.faPlus,
  project: freeSolidSvgIcons.faFolder,
  projects: freeSolidSvgIcons.faArchive,
  recent: freeRegularSvgIcons.faClock,
  relationships: freeSolidSvgIcons.faSitemap,
  remove: freeSolidSvgIcons.faXmark,
  retry: freeSolidSvgIcons.faRepeat,
  save: freeRegularSvgIcons.faSave,
  search: freeSolidSvgIcons.faSearch,
  select: freeSolidSvgIcons.faMousePointer,
  settings: freeSolidSvgIcons.faCog,
  sort: freeSolidSvgIcons.faSort,
  sortDown: freeSolidSvgIcons.faSortDown,
  sortUp: freeSolidSvgIcons.faSortUp,
  spinner: freeSolidSvgIcons.faSpinner,
  table: freeSolidSvgIcons.faTable,
  tag: freeSolidSvgIcons.faTag,
  trash: freeSolidSvgIcons.faTrash,
  unchecked: freeSolidSvgIcons.faXmarkSquare,
  unlock: freeSolidSvgIcons.faUnlock,
  unlocked: freeSolidSvgIcons.faLockOpen,
  upload: freeSolidSvgIcons.faUpload,
  user: freeSolidSvgIcons.faUser,
  visible: freeSolidSvgIcons.faEye,
};

export function Icon({
  cursor = undefined,
  icon,
  size,
  spin,
  className,
  fixedWidth,
  onMouseDown,
  onClick,
}: {
  cursor?: string | number,
  icon: IconName,
  size?: SizeProp,
  spin?: boolean,
  className?: string,
  fixedWidth?: boolean,
  onMouseDown?: React.MouseEventHandler,
  onClick?: React.MouseEventHandler,
}) {
  return (
    <FontAwesomeIcon
      cursor={cursor}
      icon={iconMap[icon]}
      size={size ?? '1x'}
      spin={spin}
      className={className}
      fixedWidth={fixedWidth}
      onMouseDown={onMouseDown}
      onClick={onClick} />
  );
}

Icon.defaultProps = {
  cursor: undefined,
  size: '1x',
  spin: false,
  className: undefined,
  fixedWidth: false,
  onMouseDown: undefined,
  onClick: undefined,
};
