import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { selectAuth } from '../features/authentication/authSlice';

export function RequireAuth({ children, redirect }: { children: JSX.Element, redirect?: boolean }) {
  const auth = useAppSelector(selectAuth);
  const location = useLocation();

  if (auth.isLoadingUser) {
    return (<p>loading...</p>);
  }

  if (!!auth.user && !auth.user.expired && !!auth.user.access_token) {
    return children;
  }

  if (redirect) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return null;
}

RequireAuth.defaultProps = {
  redirect: true,
};
