import { useMemo } from 'react';
import { OrganizationDto } from '../../../app/api';
import { toObjectMap } from '../../../util';

export function useRecentOrganizations<T extends OrganizationDto>(
  organizations: T[],
  recentOrganizationIds: string[],
) {
  const decoratedOrganizations = useMemo(() => {
    const recentOrganizationsMap = toObjectMap(
      recentOrganizationIds,
      (id, i) => [id, { id, index: i }],
    );

    return organizations.map((o) => {
      return ({
        ...o,
        recentIndex: recentOrganizationsMap[o.id]?.index ?? -1,
      }
      );
    });
  }, [organizations, recentOrganizationIds]);

  return decoratedOrganizations;
}
