import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectCreateDto, useUserApiCreateProjectMutation, isSuccessResponse } from '../../../../app/api';
import { OrganizationContext } from '../../../organizations';

import { CreateProjectForm } from './CreateProjectForm';

export type CreateProjectInputs = {
  projectName: string;
  projectDescription: string;
};

export function CreateProject() {
  const { onCreateProject, isLoading, error } = useCreateProject();

  return (
    <CreateProjectForm
      isLoading={isLoading}
      error={error}
      onCreateProject={onCreateProject} />
  );
}

function useCreateProject() {
  const [createProject, { isLoading, error, isError }] = useUserApiCreateProjectMutation();
  const { organization } = useContext(OrganizationContext);
  const navigate = useNavigate();

  const onCreateProject = useCallback(async (name: string, description: string) => {
    if (organization) {
      const response = await createProject({
        organizationId: organization.id,
        projectCreateDto: { name, description },
      });
      if (isSuccessResponse<ProjectCreateDto>(response)) {
        navigate(`../projects/${response.data.id}`);
      }
    }
  }, [createProject, navigate, organization]);

  return { onCreateProject, isLoading, error: isError && error };
}
