import { useCallback } from 'react';
import { TableSummaryDto } from '../../../app/api';
import { usePrefetch } from '../../../app/hooks';
import { useCurrentDatabase } from '../DatabaseContext';

type TableInfo = Pick<TableSummaryDto, 'schemaName' | 'name'>;

export type OnTablePrefetch = (table: TableInfo) => void;

export function useTablePrefetch(): OnTablePrefetch {
  const { databaseId } = useCurrentDatabase();

  const prefetchTable = usePrefetch('userApiGetTableByName');

  return useCallback((table: TableInfo) => {
    prefetchTable({
      harvestId: databaseId,
      schemaName: table.schemaName,
      tableName: table.name,
    });
  }, [databaseId, prefetchTable]);
}
