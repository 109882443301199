import { useProject } from '..';
import { useAppSelector } from '../../../app/hooks';
import { getInitialProjectState, selectProjectById } from '../projectSlice';

export function useProjectSettings() {
  const { projectId } = useProject();
  const projectState = useAppSelector((state) => selectProjectById(state, projectId));

  return projectState ?? getInitialProjectState(projectId);
}
