import {
  api,
} from './api-generated';

const apiTags = [
  'Account',
  'ActivityFeed',
  'Diagrams',
  'Diagram',
  'DiagramRectangle',
  'DiagramTable',
  'DiagramTables',
  'Harvests',
  'Harvest',
  'OrganizationAccounts',
  'Organizations',
  'Project',
  'ProjectPreferences',
  'Projects',
  'Articles',
  'Article',
  'HarvestTable',
  'CodeSet',
  'HarvestJob',
  'UserPreferences',
] as const;

export type ApiTag = typeof apiTags[number];

export const taggedApi = api.enhanceEndpoints({
  addTagTypes: apiTags,
});
