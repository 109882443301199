import React, { ReactNode } from 'react';
import { useModalDialog } from '../../../../hooks';
import { DiagramWithChildrenDto, DiagramRectangleDto, useUserApiUpdateDiagramRectangleMutation } from '../../../../app/api';
import { EditDiagramRectangleDialog } from './EditDiagramRectangleDialog/EditDiagramRectangleDialog';

export function EditDiagramRectangle({ children, diagram }: {
  children: (openDialog: (newData: DiagramRectangleDto) => void) => ReactNode;
  diagram: DiagramWithChildrenDto | undefined;
}) {
  const {
    data: rectangle,
    openDialog,
    closeDialog: closeModal,
    isOpen,
  } = useModalDialog<DiagramRectangleDto>();

  const [
    persistRectangleChanges,
    { isLoading, error },
  ] = useUserApiUpdateDiagramRectangleMutation();

  const saveRectangle = async (newRectangle: DiagramRectangleDto) => {
    if (!rectangle || !diagram) return;

    await persistRectangleChanges({
      diagramId: diagram.id,
      diagramRectangleId: rectangle.id,
      diagramRectangleUpdateDto: newRectangle,
    });
    closeModal();
  };

  return (
    <>
      {children(openDialog)}
      <EditDiagramRectangleDialog
        error={error}
        isOpen={isOpen}
        onClose={closeModal}
        onUpdateRectangle={saveRectangle}
        isSaving={isLoading}
        rectangle={diagram?.rectangles.find((r) => r.id === rectangle?.id) ?? rectangle} />
    </>
  );
}
