import React, { ComponentType } from 'react';
import {
  // eslint-disable-next-line max-len
  DiagramWithChildrenDto, AccountDto, useUserApiListOrganizationAccountsQuery, useUserApiUpdateDiagramOwnerMutation,
} from '../../../../../app/api';
import { useModalDialog } from '../../../../../hooks';
import { useCurrentUser } from '../../../../authentication/useCurrentUser';
import { useOrganization } from '../../../../organizations';
import { TransferOwnershipDialog } from './TransferOwnershipDialog';

type DiagramOwnershipTransferable = {
  onTransferOwnership: (diagram: DiagramWithChildrenDto) => void;
};

type WithDiagramEditTagsProps = Omit<DiagramOwnershipTransferable, 'onTransferOwnership'>;

export const withTransferDiagramOwnership = <P extends DiagramOwnershipTransferable>(
  Component: ComponentType<P>,
) => {
  return function (props: Omit<P, 'onTransferOwnership'> & WithDiagramEditTagsProps) {
    const { id: organizationId } = useOrganization();
    const user = useCurrentUser();

    const [
      transferOwnership,
      {
        isLoading: isSavingTransfer,
        error,
        reset,
      },
    ] = useUserApiUpdateDiagramOwnerMutation({});

    const {
      data: diagram,
      openDialog,
      closeDialog: closeModal,
      isOpen,
    } = useModalDialog<DiagramWithChildrenDto>();

    const closeDialog = () => {
      closeModal();
      reset();
    };

    const { data: users, isLoading } = useUserApiListOrganizationAccountsQuery({
      organizationId,
    }, {
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.filter((u) => u.id !== user.profile.sub),
      }),
      skip: !isOpen,
    });

    const executeTransfer = async (newOwner: AccountDto) => {
      if (diagram) {
        const result = await transferOwnership({
          diagramId: diagram?.id,
          diagramOwnerUpdateDto: {
            ownerId: newOwner.id,
          },
        });

        if ('data' in result) {
          closeDialog();
        }
      }
    };

    return (
      <>
        <TransferOwnershipDialog
          diagram={diagram}
          error={error}
          users={users ?? []}
          isLoading={isLoading}
          isOpen={isOpen}
          isSaving={isSavingTransfer}
          onTransferOwnership={executeTransfer}
          onClose={() => closeDialog()} />
        <Component
          {...props as P}
          onTransferOwnership={(d) => openDialog(d)} />
      </>
    );
  };
};
