import React, { useMemo } from 'react';
import { Column } from 'react-table';
import {
  // eslint-disable-next-line max-len
  CheckConstraintDto, ConstraintColumnDto, ConstraintDto, ReferentialConstraintDto, UniqueConstraintDto, useTableInfo,
} from '../../../app/api';
import { formatBooleanColumn, LoadingSpinner, Table as SortTable } from '../../../components/elements';
import { TableSubDetailProps } from './Tables';

export function TableConstraints({ tableName, parentTabId }: TableSubDetailProps) {
  const { table: data, isLoading } = useTableInfo(tableName);

  const columns: Column<ConstraintDto>[] = useMemo(
    () => [
      {
        Header: 'Constraint Name',
        accessor: 'name',
      },
      {
        Header: 'Is Enabled',
        accessor: 'isEnabled',
        Cell: ({ value }) => (
          formatBooleanColumn(value)
        ),
        sortType: 'basic',
      },
      {
        Header: 'Constraint Type',
        accessor: 'constraintType',
      },
      {
        Header: 'Constraint Columns',
        accessor: 'constraintColumns',
        // eslint-disable-next-line max-len
        // eslint-disable-next-line react/no-unstable-nested-components, react/destructuring-assignment
        Cell: (cellProps) => (<ColumnList columns={cellProps.value} />),
        disableSortBy: true,
      },
      {
        Header: 'Details',
        id: 'details',
        accessor: 'id',
        // eslint-disable-next-line max-len
        // eslint-disable-next-line react/no-unstable-nested-components, react/destructuring-assignment
        Cell: (cellProps) => (<ConstraintDetails constraint={cellProps.row.original} />),
      },
    ],
    [],
  );

  const initialState = {
    sortBy: [
      { id: 'constraintType', desc: false },
      { id: 'name', desc: false },
    ],
    pageSize: 500,
  };

  return (
    <div className="m-2 flex-grow-1 d-flex flex-column">
      <LoadingSpinner isLoading={isLoading} centered>
        <SortTable
          initialState={initialState}
          stateScopeId={`${parentTabId}-constraints`}
          data={[
            ...data?.checkConstraints ?? [],
            ...data?.otherConstraints ?? [],
            ...data?.primaryKeyConstraints ?? [],
            ...data?.referentialConstraints ?? [],
            ...data?.uniqueConstraints ?? [],
          ]}
          columns={columns}
          sort
          borderless
          tableClassName="table-striped table-hover" />
      </LoadingSpinner>
    </div>
  );
}

function ColumnList({ columns }: {
  columns: ConstraintColumnDto[]
}) {
  return (
    <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
      {[...columns].sort((a, b) => a.position - b.position)
        .map((c) => <li key={c.id}>{c.name}</li>)}
    </ul>
  );
}

function ConstraintDetails({ constraint }: {
  constraint: ConstraintDto;
}) {
  if (isCheckConstraintDto(constraint)) {
    return <>{constraint.searchCondition}</>;
  }

  if (isReferentialConstraintDto(constraint)) {
    return (
      <div>
        <div>
          Table: {constraint.parentConstraint?.tableName}
        </div>
        <div className="d-flex flex-row">
          Columns:&nbsp;
          <ColumnList columns={constraint.parentConstraint?.constraintColumns ?? []} />
        </div>
      </div>
    );
  }

  if (isUniqueConstraintDto(constraint)) {
    return <>Index: {constraint.tableIndex?.name}</>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

function isCheckConstraintDto(constraint: ConstraintDto): constraint is CheckConstraintDto {
  return (constraint as CheckConstraintDto).searchCondition !== undefined;
}

function isReferentialConstraintDto(
  constraint: ConstraintDto,
): constraint is ReferentialConstraintDto {
  return (constraint as ReferentialConstraintDto).parentConstraint !== undefined;
}

function isUniqueConstraintDto(
  constraint: ConstraintDto,
): constraint is UniqueConstraintDto {
  return (constraint as UniqueConstraintDto).tableIndex !== undefined;
}
