import React from 'react';
import {
  Col, Form, FormGroup, Label,
} from 'reactstrap';
import { ColorInput, LoadingSpinner } from '../../../../components/elements';
import { insensitiveCompare } from '../../../../util/stringUtil';
import { abbreviateCategory } from '../../diagram/util';
import { CategoryPreferenceMap, DEFAULT_CATEGORY_COLOR } from '..';

export type Category = {
  id: string;
  name: string;
  color: string;
  isHidden: boolean;
}

export function CategorySettings({ categories, isLoading, onChangeColor }: {
  categories: CategoryPreferenceMap;
  isLoading: boolean;
  onChangeColor: (category: Category) => void;
}) {
  return (
    <>
      <h5 className="m-0">Type/Table Body Colors</h5>
      <LoadingSpinner isLoading={isLoading}>
        <Form>
          {Object.values(categories)
            .sort((a, b) => insensitiveCompare(a.name, b.name))
            .map((c) => (
              <CategorySetting
                key={c.id}
                category={c}
                onChangeColor={(color) => onChangeColor({ ...c, color })} />
            ))}
        </Form>
      </LoadingSpinner>
    </>
  );
}

function CategorySetting({ category, onChangeColor }: {
  category: Category;
  onChangeColor: (color: string) => void;
}) {
  const id = `category-color-${category.id}`;

  return (
    <FormGroup row>
      <Label sm={4} for={id}>
        {category.name} {`(${abbreviateCategory(category.name)})`}
      </Label>

      <Col sm={8}>
        <ColorInput
          id={id}
          defaultColor={DEFAULT_CATEGORY_COLOR}
          value={category.color}
          onChange={onChangeColor} />
      </Col>
    </FormGroup>
  );
}
