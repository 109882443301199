import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { Icon } from '../../components/elements';
import { HarvestDescription } from '../project/components/HarvestDescription';
import { HarvestName } from '../project/components/HarvestName';
import { HarvestListItem, useSortedHarvests } from './useSortedHarvests';

export function HarvestSwitcher({ currentHarvest, harvests, onUpload }: {
  currentHarvest: HarvestListItem;
  harvests: HarvestListItem[];
  onUpload: () => void;
}) {
  const sortedHarvests = useSortedHarvests(harvests);
  const otherHarvests = useMemo(() => {
    return sortedHarvests.filter((x) => x.id !== currentHarvest.id)
      .slice(0, 10);
  }, [currentHarvest.id, sortedHarvests]);

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        <Icon icon="harvest" />
        {' '}
        <HarvestName harvest={currentHarvest} />
      </DropdownToggle>
      <DropdownMenu>
        <UploadHarvest onUpload={onUpload} />
        <Link to="..">
          <DropdownItem>
            Harvest List
          </DropdownItem>
        </Link>
        {otherHarvests.length > 0 && (
          <>
            <DropdownItem divider />
            {otherHarvests.map((harvest) => <HarvestLink harvest={harvest} key={harvest.id} />)}
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function UploadHarvest({ onUpload }: {
  onUpload: () => void;
}) {
  return (
    <DropdownItem onClick={onUpload}>
      <Icon icon="upload" /> Upload Harvest
    </DropdownItem>
  );
}

function HarvestLink({ harvest }: {
  harvest: HarvestListItem;
}) {
  return (
    <Link to={`../database/${harvest.id}`}>
      <DropdownItem>
        <div className="d-flex flex-column">
          <span>
            <Icon icon={harvest.isFavorite ? 'favorite' : 'not-favorite'} />
            <HarvestDescription harvest={harvest} />
          </span>
          <div className="d-flex flex-row gap-1">
            {harvest.tags.map((t) => (
              <Badge key={t} pill color="primary" className="me-1">
                <Icon icon="tag" /> {t}
              </Badge>
            ))}
          </div>
          <span>Extracted By: {harvest.harvestedBy}</span>
        </div>
      </DropdownItem>
    </Link>
  );
}
