import React from 'react';

export function CodeSetLink({ code, onOpenCodeSet }: {
  code: number | null | undefined;
  onOpenCodeSet: (set: number) => void;
}) {
  return !code
    ? null
    : (
      <span
        className="link-primary text-decoration-underline cursor-pointer"
        onMouseDown={(e) => e.stopPropagation()}
        onClick={() => {
          onOpenCodeSet(code);
        }}>
        {code}
      </span>
    );
}
