import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { UserPreference } from '../UserPreference';

type SetUserPreferenceRequest = {
  name: UserPreference,
  value: unknown,
}

export type UserPreferenceValue = {
  id: UserPreference,
  value: unknown,
}

const preferenceAdapter = createEntityAdapter<UserPreferenceValue>();

export const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState: preferenceAdapter.getInitialState(),
  reducers: {
    setPreferenceValue: (state, action: PayloadAction<SetUserPreferenceRequest>) => {
      preferenceAdapter.upsertOne(state, {
        id: action.payload.name,
        value: action.payload.value,
      });
    },
  },
});

export const {
  setPreferenceValue,
} = userPreferencesSlice.actions;

export const {
  selectById: selectUserPreference,
} = preferenceAdapter.getSelectors((state: RootState) => state.userPreferences);

export default userPreferencesSlice.reducer;
