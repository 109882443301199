import React from 'react';
import { range } from 'lodash';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export function Paginator({
  currentPage, pageCount, onNextPage, onPreviousPage, onFirstPage, onLastPage, onSetPage,
}:
  {
    currentPage: number,
    pageCount: number,
    onNextPage: () => void,
    onPreviousPage: () => void,
    onFirstPage: () => void,
    onLastPage: () => void,
    onSetPage: (newPageNum: number) => void,
  }) {
  const lowButton = Math.max(1, currentPage - 2);
  const highButton = Math.min(pageCount, lowButton + 5) + 1;

  return (
    <Pagination>
      {currentPage !== 1
        && (
          <>
            <PaginationItem>
              <PaginationLink
                first
                onClick={onFirstPage} />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                onClick={onPreviousPage}
                previous />
            </PaginationItem>
          </>
        )}
      {range(lowButton, highButton).map((page) => (
        <PaginationItem active={page === currentPage}>
          <PaginationLink onClick={() => onSetPage(page)}>
            {page}
          </PaginationLink>
        </PaginationItem>
      ))}
      {currentPage < pageCount
        && (
          <>
            <PaginationItem>
              <PaginationLink
                onClick={onNextPage}
                next />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                onClick={onLastPage}
                last />
            </PaginationItem>
          </>
        )}
    </Pagination>
  );
}
