import React, {
  createContext, ReactNode, useContext, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { useUserApiGetProjectByIdQuery } from '../../app/api';

type ProjectParams = 'projectId';
type OrganizationParams = 'organizationId';

type ProjectIdContextValue = {
  projectId?: string;
}
type ProjectContextValue = {
  projectId?: string;
  projectName?: string;
  projectDescription?: string;
  organizationId?: string;
  isLoading: boolean;
  error?: unknown;
}

export const ProjectIdContext = createContext<ProjectIdContextValue>({});
export const ProjectContext = createContext<ProjectContextValue>({ isLoading: false });

export function ProjectIdContextProvider({ children }: {
  children: ReactNode;
}) {
  const { projectId } = useParams<ProjectParams>();

  if (projectId === undefined) {
    throw new Error('projectId could not be found in the route');
  }
  const contextValue = useMemo(() => ({ projectId }), [projectId]);

  return (
    <ProjectIdContext.Provider value={contextValue}>
      {children}
    </ProjectIdContext.Provider>
  );
}

export function ProjectContextProvider({ children }: {
  children: ReactNode;
}) {
  const { organizationId } = useParams<OrganizationParams>();
  const { projectId } = useParams<ProjectParams>();

  if (organizationId === undefined) {
    throw new Error('organizationId could not be found in the route');
  }

  if (projectId === undefined) {
    throw new Error('projectId could not be found in the route');
  }

  const {
    data: project,
    isLoading,
    error,
  } = useUserApiGetProjectByIdQuery({ projectId, organizationId });

  const contextValue = useMemo(() => ({
    projectId,
    projectName: project?.name,
    projectDescription: project?.description,
    organizationId: project?.organizationId,
    isLoading,
    error,
  }), [error, isLoading, projectId, project?.name, project?.description, project?.organizationId]);

  return (
    <ProjectContext.Provider value={contextValue}>
      {children}
    </ProjectContext.Provider>
  );
}

export function ProjectContextConsumer({ children }: {
  children: (projectId: string) => ReactNode;
}) {
  return (
    <ProjectContext.Consumer>
      {(project) => (
        !project.isLoading && !!project.projectId && children(project.projectId)
      )}
    </ProjectContext.Consumer>
  );
}

export function useProjectId() {
  const projectIdContextValue = useContext(ProjectIdContext);

  if (projectIdContextValue === undefined) {
    throw new Error('useProjectId must be used within a ProjectIdContextProvider');
  }

  if (projectIdContextValue.projectId === undefined) {
    throw new Error('useProjectId must be used within a route with a projectId param');
  }

  return projectIdContextValue;
}

export function useProject() {
  const project = useContext(ProjectContext);

  if (project === undefined) {
    throw new Error('useProject must be used within a ProjectProvider');
  }

  if (project.projectId === undefined || project.error) {
    throw new Error('useProject must be used for a valid project');
  }

  return {
    projectId: project.projectId,
    projectName: project.projectName ?? null,
    projectDescription: project.projectDescription,
    organizationId: project.organizationId,
  };
}
