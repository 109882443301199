import React, { useMemo } from 'react';
import {
  Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import Downshift from 'downshift';
import { matchSorter } from 'match-sorter';
import {
  RemoveButton, Icon, LoadingSpinner,
} from '../../../../../../components/elements';
import { DiagramWithChildrenDto, DiagramTagDto } from '../../../../../../app/api';
import styles from './EditDiagramTagsDialog.module.scss';
import { insensitiveCompare, insensitiveEquals } from '../../../../../../util';
import { DiagramTag } from '../../DiagramTag';

export type WeightedTag = {
  tagName: string;
  count: number
};

function compareWeightedTags(a: WeightedTag, b: WeightedTag) {
  return a.count === b.count
    ? insensitiveCompare(a.tagName, b.tagName)
    : b.count - a.count;
}

export function EditDiagramTagsDialog({
  diagram, allTags, isLoading, isOpen, isSaving,
  onClose, onAddTag, onRemoveTag,
}: {
  diagram: DiagramWithChildrenDto | undefined;
  allTags: WeightedTag[];
  isLoading: boolean;
  isOpen: boolean;
  isSaving: boolean;
  onAddTag: (tagName: string) => void;
  onClose: () => void;
  onRemoveTag: (tag: DiagramTagDto) => void;
}) {
  return (
    <Modal isOpen={isOpen} toggle={onClose} autoFocus={false}>
      <ModalHeader
        toggle={onClose}>
        <LoadingSpinner isLoading={isSaving || isLoading}><Icon icon="tag" /></LoadingSpinner>
        {' '}Edit Tags for {diagram?.name}
      </ModalHeader>
      <ModalBody>
        <EditTagsList
          allTags={allTags}
          tags={diagram?.tags ?? []}
          isLoading={isLoading}
          onAddTag={onAddTag}
          onRemoveTag={onRemoveTag} />
      </ModalBody>
    </Modal>
  );
}

function EditTagsList({
  allTags, tags, isLoading, onAddTag, onRemoveTag,
}: {
  allTags: WeightedTag[];
  tags: DiagramTagDto[];
  isLoading: boolean;
  onAddTag: (tagName: string) => void;
  onRemoveTag: (tag: DiagramTagDto) => void;
}) {
  const sortedTags = useMemo(() => {
    return [...tags].sort((a, b) => insensitiveCompare(a.tagName, b.tagName));
  }, [tags]);

  const availableTags = useMemo(() => {
    return allTags.filter(((t) => !tags.find((t2) => insensitiveEquals(t2.tagName, t.tagName))));
  }, [allTags, tags]);

  return (
    <Downshift
      onChange={(i, s) => {
        if (i) onAddTag(i.tagName);
        s.clearSelection();
      }}
      stateReducer={(_, changes) => {
        switch (changes.type) {
          case Downshift.stateChangeTypes.changeInput:
            return {
              ...changes,
              inputValue: changes.inputValue?.trim(),
            };
          default:
            return changes;
        }
      }}
      itemToString={(i: WeightedTag | null) => i?.tagName ?? ''}>
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        openMenu,
        clearSelection,
      }) => (
        <div className="form-control d-flex gap-2 flex-wrap">
          {sortedTags.map((t) => (
            <DiagramTag tag={t}>
              <RemoveButton onClick={() => onRemoveTag(t)} className="link-light" />
            </DiagramTag>
          ))}
          <div>
            <input
              {...getInputProps({
                onKeyUp: (e) => {
                  switch (e.code) {
                    case 'Enter':
                    case 'Space':
                      if (inputValue) onAddTag(inputValue.trim());
                      clearSelection();
                      break;
                    case 'Backspace':
                      if (!inputValue) {
                        clearSelection();
                        onRemoveTag(sortedTags[sortedTags.length - 1]);
                      }
                      break;
                    default:
                  }
                },
              })}
              type="text"
              autoComplete="off"
              disabled={isLoading}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              className={styles['tag-input']}
              onFocus={() => openMenu()} />
            <ul
              {...getMenuProps()}
              className="dropdown-menu"
              style={{
                position: 'absolute',
                display: isOpen ? 'block' : 'none',
              }}>
              {
                !isOpen
                  ? null
                  : matchSorter(
                    availableTags,
                    inputValue ?? '',
                    {
                      baseSort: (a, b) => compareWeightedTags(a.item, b.item),
                      keys: ['tagName'],
                    },
                  )
                    .slice(0, 9)
                    .map((t, index) => (
                      <li
                        className="dropdown-item"
                        {...getItemProps({
                          key: t.tagName,
                          index,
                          item: t,
                          style: {
                            backgroundColor: highlightedIndex === index ? 'var(--bs-primary)' : 'var(--bs-white)',
                            color: highlightedIndex === index ? 'var(--bs-white)' : 'var(--bs-body-color)',
                            fontWeight: selectedItem === t ? 'bold' : 'normal',
                            maxWidth: '30em',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          },
                        })}>
                        {t.tagName}
                      </li>
                    ))
              }
            </ul>
          </div>
        </div>
      )}
    </Downshift>
  );
}
