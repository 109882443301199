import React, { useMemo } from 'react';
import { Column } from 'react-table';
import { TableIndexColumnDto, TableIndexDto, useTableInfo } from '../../../app/api';
import { TableSubDetailProps } from './Tables';
import { formatBooleanColumn, LoadingSpinner, Table as SortTable } from '../../../components/elements';

export function TableIndexes({ tableName, parentTabId }: TableSubDetailProps) {
  const { table: data, isLoading } = useTableInfo(tableName);

  const columns: Column<TableIndexDto>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Is Unique',
        accessor: 'isUnique',
        Cell: ({ value }) => (
          formatBooleanColumn(value)
        ),
        sortType: 'basic',
      },
      {
        Header: 'Columns',
        accessor: 'tableIndexColumns',
        // eslint-disable-next-line max-len
        // eslint-disable-next-line react/no-unstable-nested-components, react/destructuring-assignment
        Cell: (cellProps) => (<ColumnList columns={cellProps.value} />),
        disableSortBy: true,
      },
    ],
    [],
  );

  const initialState = {
    sortBy: [
      { id: 'name', desc: false },
    ],
    pageSize: 500,
  };

  return (
    <div className="m-2 flex-grow-1 d-flex flex-column">
      <LoadingSpinner isLoading={isLoading} centered>
        <SortTable
          initialState={initialState}
          stateScopeId={`${parentTabId}-indexes`}
          data={data?.tableIndexes ?? []}
          columns={columns}
          sort
          borderless
          tableClassName="table-striped table-hover" />
      </LoadingSpinner>
    </div>
  );
}

function ColumnList({ columns }: {
  columns: TableIndexColumnDto[]
}) {
  return (
    <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
      {[...columns].sort((a, b) => a.position - b.position)
        .map((c) => <li key={c.id}>{getColumnText(c)}</li>)}
    </ul>
  );
}

function getColumnText(column: TableIndexColumnDto) {
  const order = column.isDescending
    ? 'DESC'
    : 'ASC';

  return `${column.name} (${order})`;
}
