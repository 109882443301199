import { useEffect, useRef } from 'react';

export function useFirstUpdate() {
  const firstUpdate = useRef(true);

  useEffect(() => {
    firstUpdate.current = false;
  }, []);

  return firstUpdate.current;
}
