import React from 'react';
import { CopyrightInfo } from '../../about/components/CopyrightInfo';
import { FooterContainer } from './FooterContainer';
import { LicenseLink } from '../../about';

export function LicenseFooter() {
  return (
    <FooterContainer>
      <CopyrightInfo />
      <LicenseLink />
    </FooterContainer>
  );
}
