import React from 'react';
import { CreateProject } from './CreateProject';
import { PROJECT_DESCRIPTION } from './projectDescription';

export function Welcome({ orgName }: {
  orgName: string;
}) {
  return (
    <>
      <h1>Welcome {orgName}</h1>
      <p>
        To get started using Database Explorer create a project for your Organization.
        {' '}
        {PROJECT_DESCRIPTION}
      </p>
      <div className="border p-3">
        <CreateProject />
      </div>
    </>
  );
}
