import { TableDto } from '../../../../app/api';

export function abbreviateCategory(categoryName: string | undefined) {
  return categoryName
    ? categoryName[0]
    : categoryName;
}

export const VIEW_CATEGORY = { id: 'View', name: 'View' };
export const UNCATEGORIZED_CATEGORY = { id: 'Uncategorized', name: 'Uncategorized' };

export function getCategory(table: Partial<Pick<TableDto, 'projectCategory' | 'isView'>> | undefined) {
  return table?.isView
    ? VIEW_CATEGORY
    : table?.projectCategory ?? UNCATEGORIZED_CATEGORY;
}
