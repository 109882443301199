import React, { useCallback } from 'react';
import { useModalDialog } from '../../../hooks';
import { useDebouncedEventHandler } from '../../../hooks/useDebouncedEvent';
import { ThresholdPreference, useProjectPreferences } from '../../project';
import { ColorSettingsButton } from './ColorSettingsButton';
import { ColorSettingsDialog } from './ColorSettingsDialog';
import { useLoadedCategoryColors, useLoadedThresholdColors } from './hooks';

export function ColorSettingsControl() {
  const { categories, isLoading } = useLoadedCategoryColors();

  const { setThresholds: persistThresholds, setCategory } = useProjectPreferences();
  const debouncedOnChangeColor = useDebouncedEventHandler(setCategory, 300);

  const { thresholds, isLoading: isLoadingThresholds } = useLoadedThresholdColors();
  const changeThresholds = useCallback((newThresholds: ThresholdPreference[]) => {
    persistThresholds(newThresholds);
  }, [persistThresholds]);
  const debouncedChangeThresholds = useDebouncedEventHandler(changeThresholds, 300);

  const { isOpen, openDialog, closeDialog } = useModalDialog();

  return (
    <>
      <ColorSettingsButton onClick={() => openDialog({})} />
      <ColorSettingsDialog
        categories={categories}
        thresholds={thresholds}
        isOpen={isOpen}
        isLoading={isLoading}
        isLoadingThresholds={isLoadingThresholds}
        onChangeColor={debouncedOnChangeColor}
        onChangeThresholds={debouncedChangeThresholds}
        onClose={closeDialog} />
    </>
  );
}
