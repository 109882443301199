import {
  configureStore, ThunkAction, Action, combineReducers,
} from '@reduxjs/toolkit';
import { loadUser, reducer as oidcReducer } from '@ericlamb/redux-oidc';

import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { setupListeners } from '@reduxjs/toolkit/query';
import userManager from '../features/authentication/userManager';
import { api, rtkQueryErrorLogger, rtkQueryUnauthorizedHandler } from './api';
import projectReducer from '../features/project/projectSlice';
import notificationReducer from '../features/notifications/notificationSlice';
import diagramReducer from '../features/database/diagram/diagramSlice';
import userPreferencesReducer from '../features/userPreferences';
import treeExpansionReducer from '../components/elements/tree/treeSlice';
import scrollPositionReducer from '../components/elements/scrollArea/scrollSlice';
import tablePageSizeReducer from '../components/elements/table/tablePageSlice';
import searchBoxReducer from '../components/elements/searchBox/searchBoxSlice';
import childTabReducer from '../features/database/tabs/childTabSlice';
import articleDraftReducer from '../features/database/tables/articleDraftSlice';
import dragOffsetReducer from '../features/database/diagram/dragOffsetSlice';
import resizeReducer from '../features/database/diagram/ResizableItem/sizeSlice';

const sessionStorageConfig = {
  key: 'sessionRoot',
  storage: storageSession,
};

const sessionReducers = persistReducer(sessionStorageConfig, combineReducers({
  project: projectReducer,
  treeExpansion: treeExpansionReducer,
  scrollPosition: scrollPositionReducer,
  tablePageSize: tablePageSizeReducer,
  searchBoxValue: searchBoxReducer,
  childTab: childTabReducer,
  articleDraft: articleDraftReducer,
}));

const reducers = combineReducers({
  auth: oidcReducer,
  [api.reducerPath]: api.reducer,
  notifications: notificationReducer,
  userPreferences: userPreferencesReducer,
  diagram: diagramReducer,
  dragOffset: dragOffsetReducer,
  resize: resizeReducer,
  sessionReducers,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['redux-oidc/USER_FOUND'],
      ignoredPaths: ['auth.user', 'api'],
    },
  }).concat(api.middleware)
    .concat(rtkQueryErrorLogger)
    .concat(rtkQueryUnauthorizedHandler),
});

loadUser(store, userManager);

setupListeners(store.dispatch);

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
