import React from 'react';
import { DateTime } from 'luxon';
import { HarvestDto } from '../../../app/api';
import { HarvestName } from './HarvestName';

export function HarvestDescription({ harvest }: {
  harvest: HarvestDto;
}) {
  return (
    <>
      <HarvestName harvest={harvest} />
      {' - '}
      {DateTime.fromISO(harvest.completedOn).toLocaleString(DateTime.DATETIME_MED)}
    </>
  );
}
