import React from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { Icon, LoadingSpinner } from '../../../../../components/elements';

export function DeleteDiagramDialog({
  diagramName, isLoading, isOpen, onClose, onDelete,
}: {
  diagramName: string;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}) {
  const close = !isLoading
    ? () => onClose()
    : undefined;

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader toggle={close}>Delete Diagram {diagramName}</ModalHeader>
      <ModalBody>
        Are you sure you want to delete the diagram {diagramName}.
        This action cannot be undone.
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onDelete} disabled={isLoading}>
          <LoadingSpinner isLoading={isLoading}><Icon icon="trash" /></LoadingSpinner> Delete
        </Button>{' '}
        <Button color="secondary" onClick={close} disabled={isLoading}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}
