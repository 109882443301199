import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
} from 'reactstrap';
import { Privileged } from '../../../auth';
import {
  Icon, LoadingSpinner, SearchBox, ErrorWrapper, DeleteButton,
} from '../../../components/elements';
import { insensitiveCompare } from '../../../util';
import { HarvestListItem, useSortedHarvests } from '../../database/useSortedHarvests';
import { useFilteredHarvests } from '../hooks';
import { HarvestDescription } from './HarvestDescription';
import { UploadHarvestButton } from './UploadHarvestButton';

export function HarvestList({
  harvests, isLoading, error,
  onUpload, onFavoriteHarvest, onUnFavoriteHarvest, onDeleteHarvest,
}: {
  harvests: HarvestListItem[];
  onUpload: () => void;
  onFavoriteHarvest: (harvestId: string) => void;
  onUnFavoriteHarvest: (harvestId: string) => void;
  onDeleteHarvest: (harvest: HarvestListItem) => void;
  isLoading: boolean;
  error: unknown;
}) {
  const sortedHarvests = useSortedHarvests(harvests);
  const [filteredHarvests, setFilter] = useFilteredHarvests(sortedHarvests);

  const onToggleFavorite = (harvestId: string, isFavorite: boolean) => {
    return isFavorite
      ? () => onUnFavoriteHarvest(harvestId)
      : () => onFavoriteHarvest(harvestId);
  };

  return (
    <>
      <div className="p-0 d-flex mb-4 gap-2">
        <div className="flex-grow-1">
          <SearchBox
            placeholder="Find a harvest..."
            onFilterChange={setFilter} />
        </div>
        <UploadHarvestButton onClick={onUpload} />
        <a className="btn btn-primary" href="/SchemaExtractor/SchemaExtractor.exe"><Icon icon="download" /> Download Schema Extractor</a>
      </div>

      <LoadingSpinner isLoading={isLoading} centered>
        <ErrorWrapper error={error} message={<h4>Failed to load harvests</h4>}>
          <ListGroup>
            {filteredHarvests.map((h) => (
              <Harvest
                key={h.id}
                harvest={h}
                onToggleFavorite={onToggleFavorite(h.id, h.isFavorite)}
                onDelete={() => onDeleteHarvest(h)} />
            ))}
          </ListGroup>
        </ErrorWrapper>
      </LoadingSpinner>
    </>
  );
}

function Harvest({ harvest, onToggleFavorite, onDelete }: {
  harvest: HarvestListItem;
  onToggleFavorite: () => void;
  onDelete: () => void;
}) {
  return (
    <ListGroupItem action>
      <ListGroupItemHeading>
        <div className="d-flex align-items-end">
          <span className="me-auto">
            <Icon
              icon={harvest.isFavorite ? 'favorite' : 'not-favorite'}
              className="cursor-pointer"
              onClick={onToggleFavorite} />
            <Link className="me-2" to={`database/${harvest.id}`}>
              <Icon icon="harvest" /> <HarvestDescription harvest={harvest} />
            </Link>
            <HarvestTags tags={harvest.tags} />
          </span>
          <Privileged role="Explorer Admin">
            <DeleteButton onClick={onDelete} />
          </Privileged>
        </div>
      </ListGroupItemHeading>
      <ListGroupItemText>
        Extracted By: {harvest.harvestedBy}
        {'domainName' in harvest && `, Domain: ${harvest.domainName}`}
      </ListGroupItemText>
    </ListGroupItem>
  );
}

function HarvestTags({ tags }: {
  tags: string[]
}) {
  const sortedTags = useMemo(() => {
    return [...tags].sort(insensitiveCompare);
  }, [tags]);

  return (
    <>
      {
        sortedTags.map((t) => (
          <Badge key={t} pill color="primary" className="me-1">
            <Icon icon="tag" /> {t}
          </Badge>
        ))
      }
    </>
  );
}
