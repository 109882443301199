import React from 'react';
import { useUserApiListMmCodeSetsQuery } from '../../../app/api';
import { DbTabHeader, TabHeaderProps } from '../tabs';
import { useCurrentDatabase } from '../DatabaseContext';
import { Tab } from '../../project/projectSlice';

export function CodeSetDetailTabHeader({ tab }: TabHeaderProps) {
  const {
    isLoading,
    error,
    title,
  } = useCodeSetTitle(tab.id);

  return (
    <DbTabHeader
      tab={tab}
      isError={!!error}
      display={title}
      isLoading={isLoading} />
  );
}

export function CodeSetDetailTabHistoryItem({ tab }: {
  tab: Tab,
}) {
  const {
    title,
  } = useCodeSetTitle(tab.id);

  return <>{title}</>;
}

export function useCodeSetTitle(id: string) {
  const { databaseId } = useCurrentDatabase();

  const codeSetId = Number.parseInt(id, 10);

  const { currentSet, isLoading, error } = useUserApiListMmCodeSetsQuery({
    harvestId: databaseId,
  }, {
    selectFromResult: ({ data, ...rest }) => ({
      currentSet: data?.find((s) => s.code === codeSetId),
      ...rest,
    }),
  });

  return {
    isLoading,
    title: currentSet
      ? `${currentSet.display ?? ''} (Code Set: ${codeSetId})`
      : `Code Set: ${codeSetId}`,
    error,
  };
}
