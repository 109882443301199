import React, { ComponentType } from 'react';
import { DiagramWithChildrenDto, useUserApiUpdateDiagramMutation } from '../../../app/api';
import { useAppDispatch } from '../../../app/hooks';
import { addNotification } from '../../notifications/notificationSlice';
import { useProject } from '../../project';

type DiagramHidable = {
  onHide: (diagram: DiagramWithChildrenDto) => void;
  onShare: (diagram: DiagramWithChildrenDto) => void;
};

type withDiagramLockProps = Omit<DiagramHidable, 'onShare' | 'onHide'>;

export const withDiagramVisibility = <P extends DiagramHidable>(Component: ComponentType<P>) => {
  return function (props: Omit<P, 'onShare' | 'onHide'> & withDiagramLockProps) {
    const { projectId } = useProject();

    const [editDiagram] = useUserApiUpdateDiagramMutation();
    const dispatch = useAppDispatch();

    const executeSetVisibility = async (diagram: DiagramWithChildrenDto, isPrivate: boolean) => {
      const result = await editDiagram({
        projectId,
        diagramId: diagram.id,
        diagramCreateDto: {
          ...diagram,
          isPrivate,
        },
      });

      if ('error' in result) {
        dispatch(addNotification({
          type: 'error',
          title: `Error ${isPrivate ? 'hiding' : 'sharing'} diagram ${diagram.name}`,
          error: result.error,
        }));
      }
    };

    return (
      <Component
        {...props as P}
        onHide={(id) => executeSetVisibility(id, true)}
        onShare={(id) => executeSetVisibility(id, false)} />
    );
  };
};
