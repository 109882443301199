import { useMemo } from 'react';
import {
  MmHarvestDto, useUserApiListHarvestsQuery, useUserApiListMmHarvestsQuery,
} from '..';
import { useProject } from '../../../features/project/ProjectContext';

export function useHarvests(options?: { pollingInterval?: number; }) {
  const project = useProject();
  const {
    data: mmHarvests, error: mMError, isLoading: isMmLoading,
  } = useUserApiListMmHarvestsQuery({
    projectId: project.projectId,
  }, options);

  const { data: harvests, error, isLoading } = useUserApiListHarvestsQuery({
    projectId: project.projectId,
  }, options);

  const mergedHarvests = useMemo(() => {
    const mmHarvestMap = mmHarvests?.reduce(
      (acc: { [k: string]: MmHarvestDto | undefined; }, h) => {
        acc[h.id] = h;
        return acc;
      },
      {},
    ) ?? {};

    return harvests?.map((h) => mmHarvestMap[h.id] ?? h);
  }, [harvests, mmHarvests]);

  return {
    error: error ?? mMError,
    isLoading: isLoading || isMmLoading,
    harvests: mergedHarvests,
  };
}
