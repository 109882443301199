import React, { ComponentType } from 'react';
import { CreateDiagram } from './CreateDiagram';

type DiagramCreatable = {
  onCreate: () => void;
};

type WithDiagramCreationProps = Omit<DiagramCreatable, 'onCreate'>;

export const withDiagramCreation = <P extends DiagramCreatable>(Component: ComponentType<P>) => {
  return function (props: Omit<P, 'onCreate'> & WithDiagramCreationProps) {
    return (
      <CreateDiagram>
        {(onCreate) => (
          <Component
            {...props as P}
            onCreate={onCreate} />
        )}
      </CreateDiagram>
    );
  };
};
