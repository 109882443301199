import React from 'react';
import {
  Button, Container, Form, FormFeedback, FormGroup, Input, Label,
} from 'reactstrap';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Icon, LoadingSpinner } from '../../../../components/elements';
import { ErrorAlert, ErrorMessage } from '../../../../components/elements/form';
import { CreateProjectInputs } from './CreateProject';

export function CreateProjectForm({ onCreateProject, isLoading, error }: {
  onCreateProject: (name: string, description: string) => void;
  isLoading: boolean;
  error: unknown;
}) {
  const {
    control, handleSubmit, formState: { errors },
  } = useForm<CreateProjectInputs>({ disabled: isLoading });
  const submitForm: SubmitHandler<CreateProjectInputs> = (formData) => {
    onCreateProject(formData.projectName, formData.projectDescription);
  };

  return (
    <Container fluid className="p-5">
      <div>
        <h3><Icon icon="newProject" /> Create a New Project</h3>
        <ErrorAlert error={error}>
          <ErrorMessage statusCode={409}>Project name is already taken.</ErrorMessage>
          <ErrorMessage statusCode={404}>Client could not be found.</ErrorMessage>
          <ErrorMessage>Unable to create project</ErrorMessage>
        </ErrorAlert>
        <Form onSubmit={handleSubmit(submitForm)}>
          <FormGroup>
            <Label for="projectName">Project Name</Label>
            <Controller
              name="projectName"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  invalid={!!errors.projectName}
                  {...field}
                  id="projectName"
                  type="text"
                  autoFocus />
              )} />
            <FormFeedback>Project name is required</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="projectDescription">Project Description</Label>
            <Controller
              name="projectDescription"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  invalid={!!errors.projectDescription}
                  {...field}
                  id="projectDescription"
                  type="text" />
              )} />
          </FormGroup>
          <Button disabled={isLoading}>
            <LoadingSpinner isLoading={isLoading}><Icon icon="newProject" /></LoadingSpinner>
            <span> Submit</span>
          </Button>
        </Form>
      </div>
    </Container>
  );
}
