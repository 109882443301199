import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { HarvestDto } from '../../app/api';

export type HarvestListItem = { isFavorite: boolean } & HarvestDto;

export function useSortedHarvests(harvests: HarvestListItem[]) {
  return useMemo(() => {
    return [...harvests].sort((a, b) => {
      if (a.isFavorite !== b.isFavorite) {
        return b.isFavorite ? 1 : -1;
      }
      return getMilliseconds(b.completedOn) - getMilliseconds(a.completedOn);
    });
  }, [harvests]);
}

function getMilliseconds(date: string) {
  return DateTime.fromISO(date).toMillis();
}
