import React, { useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { Icon } from '../../../../components/elements';

type CodeValueSearchInputs = {
  filter: string;
};

export function CodeValueSearchDialog({ isOpen, onClose, onSearch }: {
  isOpen: boolean;
  onClose: () => void;
  onSearch: (filter: string) => void;
}) {
  const {
    control, handleSubmit, formState: { errors }, reset,
  } = useForm<CodeValueSearchInputs>();

  const submitForm: SubmitHandler<CodeValueSearchInputs> = (formData) => {
    onSearch(formData.filter);
    onClose();
    reset();
  };

  const closeDialog = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  return (
    <Modal isOpen={isOpen} autoFocus={false} toggle={closeDialog}>
      <ModalHeader toggle={closeDialog}><Icon icon="openSearch" /> Search Code Values</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitForm)}>
          <FormGroup>
            <Label for="filter">Filter text</Label>
            <Controller
              name="filter"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  invalid={!!errors.filter}
                  {...field}
                  autoFocus
                  type="text" />
              )} />
            <FormFeedback>Filter text is required</FormFeedback>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit(submitForm)}>
          <Icon icon="openSearch" />
          <span> Search</span>
        </Button>
        <Button color="secondary" onClick={closeDialog}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}
