import React from 'react';
import { IconButton, IconButtonProps } from '../iconButton';

export function EditButton(props: Omit<IconButtonProps, 'iconName' | 'toolTip'>) {
  return (
    <IconButton
      {...props}
      iconName="edit"
      toolTip="Edit" />
  );
}
