import React from 'react';
import { MmCodeSetWithValueCountDto } from '../../../../app/api';
import { ScrollArea } from '../../../../components/elements';
import { BooleanProperty, TextProperty, PropertyGroup } from '../../../../components/elements/summary';
import { useParentTabId } from '../../../project';

export function CodeSetSummary({ codeSet }: {
  codeSet: MmCodeSetWithValueCountDto | undefined;
}) {
  const tabId = useParentTabId();
  const stateScopeId = `${tabId}-code-set-summary`;

  return (
    <ScrollArea id={stateScopeId}>
      <div className="m-2 d-flex flex-column flex-grow-1 gap-2">
        <PropertyGroup title="General">
          <TextProperty name="Code Set" value={codeSet?.code.toString()} />
          <TextProperty name="Definition" value={codeSet?.definition} />
          <TextProperty name="Display" value={codeSet?.display} />
          <TextProperty name="Display Key" value={codeSet?.displayKey} />
          <TextProperty name="Values" value={codeSet?.valueCount?.toLocaleString()} />
          <BooleanProperty name="Has Extension" value={codeSet?.hasExtension} />
        </PropertyGroup>
        <div className="d-flex flex-wrap gap-2">
          <PropertyGroup title="Uniqueness" className="flex-grow-1">
            <BooleanProperty name="Is Active Status Unique" value={codeSet?.isActiveStatusUnique} />
            <BooleanProperty name="Is Alias Unique" value={codeSet?.isAliasUnique} />
            <BooleanProperty name="Is CDF Meaning Unique" value={codeSet?.isCdfMeaningUnique} />
            <BooleanProperty name="Is Definition Unique" value={codeSet?.isDefinitionUnique} />
            <BooleanProperty name="Is Display Unique" value={codeSet?.isDisplayUnique} />
            <BooleanProperty name="Is Display Key Unique" value={codeSet?.isDisplayKeyUnique} />
          </PropertyGroup>
          <PropertyGroup title="User Permissions" className="flex-grow-1">
            <BooleanProperty name="Can Users Add Values" value={codeSet?.canUsersAddValues} />
            <BooleanProperty name="Can Users Change Values" value={codeSet?.canUsersChangeValues} />
            <BooleanProperty name="Can Users Delete Values" value={codeSet?.canUsersDeleteValues} />
            <BooleanProperty name="Can Users View Values" value={codeSet?.canUsersViewValues} />
          </PropertyGroup>
        </div>
      </div>
    </ScrollArea>
  );
}
