import { useCallback } from 'react';
import { useTab } from '../../tabs';

export function useCodeSetDetails() {
  const { setTab } = useTab();
  const openCodeSetDetails = useCallback((codeSet: number) => {
    setTab({ id: `${codeSet}`, type: 'codeSets' });
  }, [setTab]);

  return openCodeSetDetails;
}
