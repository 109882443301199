import React from 'react';
import {
  Route, Routes,
} from 'react-router-dom';
import { Container } from 'reactstrap';
import { useUserApiListOrganizationsQuery } from '../../../app/api';
import { LoadingSpinner, ErrorWrapper } from '../../../components/elements';
import { OrganizationsView } from '../components/OrganizationsView';
import { RequireAuth } from '../../../auth/requireAuth';
import { useOrganizationDetailsPrefetch, OrganizationRoutes } from '../../organization';
import { useNavigateIfSingleOrganization } from '../hooks/useNavigateIfSingleOrganization';
import { useDebouncedEventHandler } from '../../../hooks';
import { useFavoriteOrganizations } from '../hooks/useFavoriteOrganizations';
import { useOrganizationHistory } from '../hooks/useOrganizationHistory';
import { useFavoritedOrganizations, useRecentOrganizations } from '../hooks';

export function Organizations() {
  return (
    <RequireAuth>
      <Routes>
        <Route index element={<OrganizationsPage />} />
        <Route path=":organizationId/*" element={<OrganizationRoutes />} />
      </Routes>
    </RequireAuth>
  );
}

function OrganizationsPage() {
  const { data: organizations, error, isLoading } = useUserApiListOrganizationsQuery();
  const prefetchOrganization = useOrganizationDetailsPrefetch();
  useNavigateIfSingleOrganization(organizations);

  const debouncedPrefetch = useDebouncedEventHandler(prefetchOrganization, 500);

  const { favorites, addFavorite, removeFavorite } = useFavoriteOrganizations();
  const [recentOrganizations] = useOrganizationHistory();

  const decoratedOrganizations = useRecentOrganizations(
    useFavoritedOrganizations(
      organizations ?? [],
      favorites,
    ),
    recentOrganizations,
  );

  return (
    <Container fluid className="p-5">
      <LoadingSpinner isLoading={isLoading} centered>
        <ErrorWrapper error={error} message={<p><em>Failed to load organizations</em></p>}>
          <OrganizationsView
            organizations={decoratedOrganizations}
            onFavoriteOrganization={addFavorite}
            onUnfavoriteOrganization={removeFavorite}
            onFetchOrganization={debouncedPrefetch} />
        </ErrorWrapper>
      </LoadingSpinner>
    </Container>
  );
}
