import { User } from 'oidc-client-ts';
import { UserState } from '@ericlamb/redux-oidc';
import { RootState } from '../../app/store';

export const isAuthenticated = (state: UserState) => state.user && !state.user.expired;

export const isAdmin = (user: User | undefined) => {
  const rawClaims = user?.profile['https://www.softekinc.com/claims/global/claim'];
  const claims = Array.isArray(rawClaims)
    ? rawClaims
    : [rawClaims];

  return !!claims?.find((x: string) => x === 'SoftekAdmin' || x === 'Developer');
};

export const selectAuth = (state: RootState) => state.auth;
