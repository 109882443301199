import React from 'react';
import { HarvestDto, MmHarvestDto } from '../../../app/api';

export function HarvestName({ harvest }: {
  harvest: HarvestDto | MmHarvestDto;
}) {
  const catalogSuffix = harvest.databaseInstance !== harvest.catalogName
    ? `.${harvest.catalogName}`
    : '';

  return (
    <>
      {harvest.databaseInstance}{catalogSuffix}
      {'clientMnemonic' in harvest && ` (${harvest.clientMnemonic})`}
    </>
  );
}
