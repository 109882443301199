import React, { ComponentType, useState } from 'react';
import { DiagramWithChildrenDto, useUserApiDeleteDiagramMutation } from '../../../../../app/api';
import { useProject } from '../../../../project';
import { useTab } from '../../../tabs';
import { DeleteDiagramDialog } from './DeleteDiagramDialog';

type DiagramDeletable = {
  onDelete: (diagram: DiagramWithChildrenDto) => void;
};

type WithDiagramDeletionProps = Omit<DiagramDeletable, 'onDelete'>;

export const withDiagramDeletion = <P extends DiagramDeletable>(Component: ComponentType<P>) => {
  return function (props: Omit<P, 'onDelete'> & WithDiagramDeletionProps) {
    const { projectId } = useProject();

    const [deleteDiagram, { isLoading }] = useUserApiDeleteDiagramMutation();
    const { exitTab } = useTab();

    const [diagram, setDiagram] = useState<DiagramWithChildrenDto>();

    const [modal, setModal] = useState(false);

    const executeDelete = async () => {
      if (diagram) {
        await deleteDiagram({
          projectId,
          diagramId: diagram?.id,
        }).then((x) => {
          if ('data' in x) {
            setModal(false);
            exitTab({ id: diagram?.id, type: 'diagrams' });
          }
        });
      }
    };

    return (
      <>
        <DeleteDiagramDialog
          diagramName={diagram?.name ?? ''}
          isLoading={isLoading}
          isOpen={modal}
          onDelete={executeDelete}
          onClose={() => setModal(false)} />
        <Component
          {...props as P}
          onDelete={(d) => {
            setDiagram(d);
            setModal(true);
          }} />
      </>
    );
  };
};
