import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { MutationDefinition, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { ApiEndpointMutation, ApiEndpointQuery } from '@reduxjs/toolkit/dist/query/core/module';
import { addNotification } from '../../features/notifications/notificationSlice';
import { diagramErrorMap } from './api-diagrams';
import { harvestErrorMap } from './api-harvests';
import { organizationErrorMap } from './api-organization';
import { projectErrorMap } from './api-project';
import { wikiErrorMap } from './api-wiki';
import { taggedApi } from './api-tagged';
import { rootEntityErrorMap } from './api-rootEntityOverrides';
import { codeSetOverrideErrorMap } from './api-codeSetOverrides';
import { accountErrorMap } from './api-account';
import { activityLogErrorMap } from './api-activityLog';

/* eslint-disable @typescript-eslint/no-explicit-any */
type ApiArgTransformer<Definitions, T> = {
  [K in keyof Definitions]:
  Definitions[K] extends ApiEndpointQuery<QueryDefinition<infer QueryArg, any, any, any>, any>
    | ApiEndpointMutation<MutationDefinition<infer QueryArg, any, any, any, any>, any>
  ? (args: QueryArg) => T
  : never;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export type ErrorMap =
  ApiArgTransformer<typeof taggedApi.endpoints, string>

const errorTitleMap: ErrorMap = {
  ...projectErrorMap,
  ...organizationErrorMap,
  ...diagramErrorMap,
  ...harvestErrorMap,
  ...wikiErrorMap,
  ...rootEntityErrorMap,
  ...codeSetOverrideErrorMap,
  ...accountErrorMap,
  ...activityLogErrorMap,
  userApiListProjectCategories: () => 'List Project Categories',
};

export const rtkQueryErrorLogger: Middleware = (wrappedApi: MiddlewareAPI) => {
  return (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const endpoint = <keyof typeof taggedApi.endpoints>action.meta.arg.endpointName;
      const args = action.meta.arg.originalArgs;
      const endpointFunc = errorTitleMap[endpoint];
      const title = endpointFunc
        ? endpointFunc(args)
        : endpoint;
      wrappedApi.dispatch(addNotification({
        type: 'error',
        title: `${title} failed`,
        body: JSON.stringify(args),
        error: action.payload,
      }));
    }

    return next(action);
  };
};
