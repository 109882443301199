import React, { useRef } from 'react';
import { useUserApiGetDiagramByIdQuery } from '../../../app/api';
import { LoadingSpinner } from '../../../components/elements';
import { useProject } from '../../project';
import { useLoadedCategoryColors, useLoadedThresholdColors } from '../settings';
import { DiagramDrawing } from './DiagramViewer';
import { useBoundingRectangle } from './useBoundingRectangle';

export function DiagramPreview({ diagramId }: {
  diagramId: string
}) {
  const { projectId } = useProject();

  const { data, isLoading } = useUserApiGetDiagramByIdQuery(
    {
      projectId,
      diagramId,
    },
  );

  const { categories } = useLoadedCategoryColors();
  const { thresholds } = useLoadedThresholdColors();

  const ref = useRef<SVGSVGElement>(null);
  const diagramBox = useBoundingRectangle(ref.current);

  return (
    <LoadingSpinner isLoading={isLoading}>
      <DiagramDrawing
        diagram={data}
        zoom={0.1}
        ref={ref}
        origin={diagramBox ?? { x: 0, y: 0 }}
        selected={{}}
        canEdit={false}
        categoryColors={categories}
        thresholdColors={thresholds} />
    </LoadingSpinner>
  );
}
