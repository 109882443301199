import { MmCodeSetOverrideDto, MmRootEntityOverrideDto, UserApiGetMmTableByIdApiResponse } from './api-generated';
import {
  taggedApi,
} from './api-tagged';
import { ErrorMap } from './rtkQueryErrorLogger';
import { optimisticUpdate } from './api-util';

type HarvestErrorMap = Pick<ErrorMap,
  'userApiGetHarvestById'
  | 'userApiListMmHarvests'
  | 'userApiListMmCodeSets'
  | 'userApiListMmCodeValuesByCodeSet'
  | 'userApiGetMmHarvestById'
  | 'userApiGetTableById'
  | 'userApiGetTableByName'
  | 'userApiDeleteHarvest'
  | 'userApiListHarvests'
  | 'userApiDeleteImportJob'
  | 'userApiListImportJobs'
  | 'userApiGetViewById'
  | 'userApiGetViewByName'
  | 'userApiListHarvestTables'
  | 'userApiListSchemas'
  | 'userApiListTables'
  | 'userApiGetMmTableById'
  | 'userApiGetMmTableByName'
  | 'userApiListMmDataModels'
  | 'userApiListMmTableColumnsByCodeSet'
  | 'userApiSearchColumns'
  | 'userApiSearchMmCodeValues'
  | 'userApiListTableChildConstraintsByTableId'
  | 'userApiListTableChildConstraintsByTableName'>;

export const harvestErrorMap: HarvestErrorMap = {
  userApiGetHarvestById: () => 'Get Harvest',
  userApiListMmHarvests: () => 'List Harvests',
  userApiListMmCodeSets: () => 'List Code Sets',
  userApiListMmCodeValuesByCodeSet: () => 'List Code Values',
  userApiGetMmHarvestById: () => 'Get Millennium Harvest',
  userApiGetTableById: () => 'Get Table',
  userApiGetTableByName: (args) => `Get Table ${args.tableName}`,
  userApiDeleteHarvest: () => 'Delete Harvest',
  userApiListHarvests: () => 'List Harvests',
  userApiDeleteImportJob: () => 'Delete Import Job',
  userApiListImportJobs: () => 'List Import Jobs',
  userApiGetViewById: () => 'Get View',
  userApiGetViewByName: (args) => `Get View ${args.viewName}`,
  userApiListHarvestTables: () => 'List Tables',
  userApiListSchemas: () => 'List Schemas',
  userApiListTables: () => 'List Tables',
  userApiGetMmTableById: () => 'Get Millennium Table',
  userApiGetMmTableByName: (args) => `Get Millennium Table ${args.tableName}`,
  userApiListMmDataModels: () => 'Get Data Models',
  userApiListMmTableColumnsByCodeSet: () => 'List Code Set Usages',
  userApiSearchColumns: (args) => `Search Columns (Filter: ${args.filter}`,
  userApiSearchMmCodeValues: (args) => `Search Code Values (Filter: ${args.filter}`,
  userApiListTableChildConstraintsByTableId: () => 'Load Child Constraints',
  userApiListTableChildConstraintsByTableName: (args) => `Load Child Constraints ${args.tableName}`,
};

export function addHarvestApiExtensions(api: typeof taggedApi) {
  return api.enhanceEndpoints({
    endpoints: {
      userApiListMmHarvests: {
        providesTags: (result, _, { projectId }) => (result
          ? [
            ...result.map((h) => CreateHarvestTag(h.id)),
            CreateProjectTag(projectId),
          ]
          : [CreateProjectTag(projectId)]),
      },
      userApiListHarvests: {
        providesTags: (result, _, { projectId }) => (result
          ? [
            ...result.map((h) => CreateHarvestTag(h.id)),
            CreateProjectTag(projectId),
          ]
          : [CreateProjectTag(projectId)]),
      },
      userApiGetMmHarvestById: {
        providesTags: (result, _, { harvestId }) => (result
          ? [
            CreateHarvestTag(result.id),
          ]
          : [CreateHarvestTag(harvestId)]),
      },
      userApiGetMmTableById: {
        providesTags: (result) => (result
          ? [
            ...result.childReferences.map((r) => CreateTableReferencedByTag(r.tableName)),
            ...result.childReferences.map((r) => r.overrideId)
              .filter((oid): oid is string => !!oid)
              .map((oid) => CreateTableOverriddenByTag(oid)),
            ...result.mmTableColumns.map((c) => c.rootEntityOverride)
              .filter((o): o is MmRootEntityOverrideDto => !!o)
              .map((o) => CreateTableOverriddenByTag(o.id)),
            ...result.mmTableColumns.map((c) => c.mmCodeSetOverride)
              .filter((o): o is MmCodeSetOverrideDto => !!o)
              .map((o) => CreateCodeSetOverriddenByTag(o.id)),
            CreateTableTag(result.name),
          ]
          : []),
        transformResponse: ((response: UserApiGetMmTableByIdApiResponse)
          : UserApiGetMmTableByIdApiResponse => {
          return {
            ...response,
            mmTableColumns: response.mmTableColumns
              .map((c) => (c.rootEntityAttr !== c.name || c.rootEntityName !== response.name
                ? c
                : {
                  ...c,
                  rootEntityAttr: undefined,
                  rootEntityName: undefined,
                })),
          };
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any,
      },
      userApiDeleteHarvest: {
        onQueryStarted: async ({ harvestId }, mutation) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [CreateHarvestTag(harvestId)],
            updateHandlers: {
              userApiListHarvests: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListHarvests', originalArgs, (draft) => {
                  const index = draft.findIndex((d) => d.id === harvestId);
                  draft.splice(index, 1);
                });
              },
              userApiListMmHarvests: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListMmHarvests', originalArgs, (draft) => {
                  const index = draft.findIndex((d) => d.id === harvestId);
                  draft.splice(index, 1);
                });
              },
            },
          });
        },
      },
      userApiListMmTableColumnsByCodeSet: {
        providesTags: (result, _, { codeSetCode }) => (result
          ? [
            ...result.map((c) => CreateTableTag(c.tableName)),
            ...result.map((c) => c.mmCodeSetOverrideId)
              .filter((id): id is string => !!id)
              .map((id) => CreateCodeSetOverriddenByTag(id)),
            CreateCodeSetTag(codeSetCode),
          ]
          : [CreateCodeSetTag(codeSetCode)]),
      },
      userApiListImportJobs: {
        providesTags: (result, _, { projectId }) => (result
          ? [
            ...result.map((j) => CreateHarvestJobTag(j.id)),
            CreateHarvestJobProjectTag(projectId),
          ]
          : [CreateHarvestJobProjectTag(projectId)]),
      },
      userApiDeleteImportJob: {
        onQueryStarted: async (
          { projectId, importJobId },
          mutation,
        ) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [
              CreateHarvestJobTag(projectId),
              CreateHarvestJobProjectTag(projectId),
            ],
            updateHandlers: {
              userApiListImportJobs: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListImportJobs', originalArgs, (draft) => {
                  const index = draft.findIndex((j) => j.id === importJobId);
                  draft.splice(index, 1);
                });
              },
            },
          });
        },
      },
    },
  });
}

function CreateProjectTag(projectId: string): { type: 'Harvests', id: string } {
  return { type: 'Harvests', id: `project-${projectId}` };
}

function CreateHarvestTag(harvestId: string): { type: 'Harvest', id: string } {
  return { type: 'Harvest', id: `${harvestId}` };
}

export function CreateTableTag(tableName: string): { type: 'HarvestTable', id: string } {
  return { type: 'HarvestTable', id: `table-${tableName}` };
}

export function CreateTableReferencedByTag(tableName: string): { type: 'HarvestTable', id: string } {
  return { type: 'HarvestTable', id: `referencedBy-${tableName}` };
}

export function CreateTableOverriddenByTag(overrideId: string): { type: 'HarvestTable', id: string } {
  return { type: 'HarvestTable', id: `overriddenBy-${overrideId}` };
}

export function CreateCodeSetOverriddenByTag(overrideId: string): { type: 'HarvestTable', id: string } {
  return { type: 'HarvestTable', id: `overriddenBy-${overrideId}` };
}

export function CreateCodeSetTag(codeSetCode: number): { type: 'CodeSet', id: string } {
  return { type: 'CodeSet', id: `code-${codeSetCode}` };
}

export function CreateHarvestJobProjectTag(projectId: string): { type: 'HarvestJob', id: string } {
  return { type: 'HarvestJob', id: `project-${projectId}` };
}

export function CreateHarvestJobTag(jobId: string): { type: 'HarvestJob', id: string } {
  return { type: 'HarvestJob', id: `job-${jobId}` };
}
