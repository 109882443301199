import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { FavoriteButton } from '../../../../components/elements/buttons/favoriteButton/FavoriteButton';
import { OrganizationListItem } from './OrganizationListItem';

export function OrganizationsList({
  title, organizations,
  onFetchOrganization, onFavoriteOrganization, onUnfavoriteOrganization,
}: {
  title: string;
  organizations: OrganizationListItem[];
  onFetchOrganization: (id: string) => void;
  onFavoriteOrganization: (id: string) => void;
  onUnfavoriteOrganization: (id: string) => void;
}) {
  return organizations.length <= 0
    ? null
    : (
      <div className="flex-grow-1">
        <h2>{title}</h2>
        <ListGroup>
          {
            organizations
              .map((organization) => (
                <OrganizationLink
                  key={organization.id}
                  organization={organization}
                  onFetchOrganization={() => onFetchOrganization(organization.id)}
                  onFavoriteOrganization={() => onFavoriteOrganization(organization.id)}
                  onUnfavoriteOrganization={() => onUnfavoriteOrganization(organization.id)} />
              ))
          }
        </ListGroup>
      </div>
    );
}

function OrganizationLink({
  organization,
  onFetchOrganization, onFavoriteOrganization, onUnfavoriteOrganization,
}: {
  organization: OrganizationListItem,
  onFetchOrganization: () => void,
  onFavoriteOrganization: () => void;
  onUnfavoriteOrganization: () => void;
}) {
  return (
    <ListGroupItem action>
      <ListGroupItemHeading>
        <div className="d-flex align-items-end">
          <span className="d-flex gap-1">
            <FavoriteButton
              isFavorite={organization.isFavorite}
              onAddFavorite={onFavoriteOrganization}
              onRemoveFavorite={onUnfavoriteOrganization} />
            <Link
              to={{ pathname: `/organizations/${organization.id}` }}
              onMouseEnter={onFetchOrganization}
              onFocus={onFetchOrganization}>
              {organization.name} ({organization.mnemonic})
            </Link>
          </span>
        </div>
      </ListGroupItemHeading>
    </ListGroupItem>
  );
}
