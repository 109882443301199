import React, { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Icon } from '..';

export function CheckIndicator({ isChecked }: {
  isChecked: boolean | undefined | null;
}) {
  const iconRef = useRef(null);

  const icon = isChecked
    ? 'check'
    : 'unchecked';

  const color = isChecked
    ? 'text-success'
    : 'text-danger';

  return (
    <>
      <span ref={iconRef} className={color}><Icon icon={icon} /></span>
      <UncontrolledTooltip target={iconRef}>
        {isChecked ? 'Yes' : 'No'}
      </UncontrolledTooltip>
    </>
  );
}
