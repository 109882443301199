import { taggedApi } from './api-tagged';
import { ErrorMap } from './rtkQueryErrorLogger';
import { optimisticUpdate } from './api-util';

type AccountErrorMap = Pick<ErrorMap,
  'userApiGetCurrentAccountPreferences'
  | 'userApiUpdateCurrentAccountPreferences'>;

export const accountErrorMap: AccountErrorMap = {
  userApiGetCurrentAccountPreferences: () => 'Get User Preferences',
  userApiUpdateCurrentAccountPreferences: () => 'Update User Preferences',
};

export function addAccountApiExtensions(api: typeof taggedApi) {
  return api.enhanceEndpoints({
    endpoints: {
      userApiGetCurrentAccountPreferences: {
        providesTags: ['UserPreferences'],
      },
      userApiUpdateCurrentAccountPreferences: {
        onQueryStarted: async (
          { updateCurrentAccountPreferencesRequest },
          mutation,
        ) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: ['UserPreferences'],
            updateHandlers: {
              userApiGetCurrentAccountPreferences: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetCurrentAccountPreferences', originalArgs, (draft) => {
                  draft.preferences = updateCurrentAccountPreferencesRequest.jsonData;
                });
              },
            },
          });
        },
      },
    },
  });
}
