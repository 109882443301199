import React, { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useUserApiListOrganizationsQuery } from '../../../app/api';
import { OrganizationSwitcher } from '../components/OrganizationSwitcher';
import { OrganizationContextConsumer, OrganizationContextProvider } from '..';
import {
  // eslint-disable-next-line max-len
  useFavoritedOrganizations, useFavoriteOrganizations, useOrganizationHistory, useRecentOrganizations,
} from '../hooks';

export function OrganizationsMenu({ children }: {
  children: ReactNode;
}) {
  const { data, error, isLoading } = useUserApiListOrganizationsQuery();

  const { favorites } = useFavoriteOrganizations();
  const [recentOrganizations] = useOrganizationHistory();

  const decoratedOrganizations = useRecentOrganizations(
    useFavoritedOrganizations(
      data ?? [],
      favorites,
    ),
    recentOrganizations,
  );

  return (
    isLoading || error
      ? null
      : (
        <Routes>
          <Route path="/">
            <Route index element={null} />
            <Route
              path=":organizationId/*"
              element={(
                <OrganizationContextProvider>
                  <OrganizationContextConsumer>
                    {(org) => (
                      <>
                        <OrganizationSwitcher
                          organizations={decoratedOrganizations}
                          selectedOrganization={org} />
                        {children}
                      </>
                    )}
                  </OrganizationContextConsumer>
                </OrganizationContextProvider>
              )} />
          </Route>
        </Routes>
      )
  );
}
