import React, { useCallback, useContext } from 'react';
import {
  ProjectWithCountsDto,
  useUserApiDeleteProjectMutation,
} from '../../../app/api';
import { useToggle } from '../../../hooks';
import { DeleteButton } from '../../../components/elements';
import { OrganizationContext } from '../../organizations';
import { DeleteProjectDialog } from './DeleteProjectDialog';

export function DeleteProject({ project }: {
  project: ProjectWithCountsDto,
}) {
  const [deleteProject] = useUserApiDeleteProjectMutation();
  const { organization } = useContext(OrganizationContext);
  const { value: modal, toggleValue: toggle, setValue: setModal } = useToggle(false);

  const executeDelete = useCallback(() => {
    if (organization) {
      deleteProject({
        organizationId: organization.id,
        projectId: project.id,
      });
    }
    setModal(false);
  }, [deleteProject, organization, project.id, setModal]);

  return (
    <div>
      <DeleteButton onClick={toggle} />
      <DeleteProjectDialog
        projectName={project.name}
        isOpen={modal}
        onDelete={executeDelete}
        onClose={toggle} />
    </div>
  );
}
