import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { Icon, LoadingSpinner } from '../../../../../../components/elements';

type RenameDiagramDialogProps = {
  diagramName: string;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onRename: (name: string) => void;
};

type RenameDiagramInputs = {
  diagramName: string;
};

export function RenameDiagramDialog({
  diagramName, isLoading, isOpen, onClose, onRename,
}: RenameDiagramDialogProps) {
  const {
    control, handleSubmit, formState: { errors }, reset,
  } = useForm<RenameDiagramInputs>({ disabled: isLoading });

  useEffect(() => {
    reset({ diagramName });
  }, [diagramName, reset]);

  const submitForm: SubmitHandler<RenameDiagramInputs> = (formData) => {
    onRename(formData.diagramName);
  };

  const close = !isLoading
    ? () => onClose()
    : undefined;

  return (
    <Modal isOpen={isOpen} toggle={close} autoFocus={false}>
      <ModalHeader toggle={close}>Edit Diagram {diagramName}</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitForm)}>
          <FormGroup>
            <Label for="diagramName">Diagram Name</Label>
            <Controller
              name="diagramName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  invalid={!!errors.diagramName}
                  {...field}
                  autoFocus
                  type="text" />
              )} />
            <FormFeedback>Diagram name is required</FormFeedback>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={isLoading} onClick={handleSubmit(submitForm)}>
          <LoadingSpinner isLoading={isLoading}><Icon icon="edit" /></LoadingSpinner>
          <span> Rename</span>
        </Button>
        <Button color="secondary" disabled={isLoading} onClick={close}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}
