import { useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

// eslint-disable-next-line function-paren-newline, @typescript-eslint/no-explicit-any
export function useDebouncedEventHandler<P extends(...args: any) => any>(
  eventHandler: P,
  waitMs: number,
) {
  const debouncedEventHandler = useMemo(
    () => debounce(eventHandler, waitMs),
    [eventHandler, waitMs],
  );

  useEffect(() => {
    return () => {
      debouncedEventHandler.cancel();
    };
  }, [debouncedEventHandler]);

  return debouncedEventHandler;
}
