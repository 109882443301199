import React, { useCallback } from 'react';
import { DraggableData } from 'react-draggable';
import { DraggableItem, DraggableItemProps } from './DraggableItem';
import { useTriggerUpdate } from './forceUpdates';

export function UpdatableDraggableItem({ onMove, ...props }: DraggableItemProps) {
  const triggerUpdate = useTriggerUpdate();

  const updateOnMove = useCallback((data: DraggableData) => {
    if (onMove) {
      onMove(data);
    }

    triggerUpdate();
  }, [onMove, triggerUpdate]);

  return (
    <DraggableItem
      {...props}
      onMove={updateOnMove} />
  );
}
