import React from 'react';
import { Container } from 'reactstrap';
import { CopyrightInfo } from './CopyrightInfo';
import { LicenseLink } from './LicenseLink';

export function AboutPage() {
  return (
    <Container fluid className="p-5">
      <h1>About Database Explorer</h1>
      <div className="d-flex flex-column gap-3">
        <CopyrightInfo />
        <LicenseLink />
      </div>
    </Container>
  );
}
