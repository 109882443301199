import { NavigateOptions, useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export function useQueryParam<T>(
  key: string,
): [T | undefined, (newQuery?: T, options?: NavigateOptions) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);

  const value = useMemo(() => (
    paramValue ? JSON.parse(paramValue) : undefined
  ), [paramValue]);

  const setValue = useCallback((newValue?: T, options?: NavigateOptions) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (newValue) {
      newSearchParams.set(key, JSON.stringify(newValue));
    } else {
      newSearchParams.delete(key);
    }
    setSearchParams(newSearchParams, options);
  }, [key, searchParams, setSearchParams]);

  return [value, setValue];
}
