import React, { useEffect, useRef, useState } from 'react';
import {
  Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, UncontrolledTooltip,
} from 'reactstrap';
import { insensitiveCompare } from '../../../util';
import { Icon, LoadingSpinner } from '../../../components/elements';
import { ProjectPreferences } from '../../project';
import { Category, CategoryPreferenceMap } from '../settings';

export function TableFilterDialog({
  isOpen, isSaving, isLoading, preferences, categories,
  onClose, onSaveCategory, onSetRowCountFilter,
}: {
  isOpen: boolean;
  isSaving: boolean;
  isLoading: boolean;
  preferences: Pick<ProjectPreferences, 'categories' | 'rowCountFilter'> | undefined;
  categories: CategoryPreferenceMap | undefined,
  onClose: () => void;
  onSaveCategory: (value: Category) => void;
  onSetRowCountFilter: (minRowsToDisplay?: number) => void;
}) {
  const headerHelp = useRef(null);

  return (
    <Modal isOpen={isOpen} toggle={onClose} autoFocus={false}>
      <ModalHeader
        toggle={onClose}>
        <LoadingSpinner isLoading={isSaving}><Icon icon="filter" /></LoadingSpinner>
        {' '}Filter Tables <span ref={headerHelp}><Icon icon="helpTip" /></span>
      </ModalHeader>
      <ModalBody className="d-flex flex-column gap-3">
        <LoadingSpinner isLoading={isLoading} centered>
          <div>
            <h6>Row Count Filter</h6>
            <RowCountFilter
              onSetFilter={onSetRowCountFilter}
              filter={preferences?.rowCountFilter}
              isEnabled />
          </div>
          <div>
            <h6>Show Tables Types</h6>
            <Form>
              {Object.values(categories ?? {})
                .sort((a, b) => insensitiveCompare(a.name, b.name))
                .map((c) => (
                  <Toggle
                    key={c.id}
                    category={c}
                    isEnabled
                    onChange={(value) => onSaveCategory({ ...c, isHidden: !value })} />
                ))}
            </Form>
          </div>
        </LoadingSpinner>
      </ModalBody>
      <UncontrolledTooltip target={headerHelp} placement="bottom">
        Settings are applied to a project and are specific to you.
        Other users will not be affected by these settings.
      </UncontrolledTooltip>
    </Modal>
  );
}

function Toggle({ category, onChange, isEnabled }: {
  category: Category;
  isEnabled: boolean;
  onChange: (value: boolean) => void;
}) {
  const id = `category-visible-${category.id}`;

  return (
    <FormGroup check>
      <Input
        type="checkbox"
        id={id}
        disabled={!isEnabled}
        checked={!category.isHidden}
        onChange={(e) => onChange(e.target.checked)} />
      <Label check for={id}>
        {' '}
        {category.name}
      </Label>
    </FormGroup>
  );
}

function RowCountFilter({ filter: defaultFilter, isEnabled, onSetFilter }: {
  filter: number | null | undefined;
  isEnabled: boolean;
  onSetFilter: (minRowsToDisplay?: number) => void;
}) {
  const [filter, setFilter] = useState(defaultFilter);

  useEffect(() => {
    setFilter(defaultFilter);
  }, [defaultFilter]);

  const setAndParseRowFilter = (value: string) => {
    const parsedValue = Number.parseInt(value, 10);
    if (Number.isNaN(parsedValue)) {
      onSetFilter(undefined);
      setFilter(undefined);
    } else {
      setFilter(parsedValue);
      onSetFilter(parsedValue);
    }
  };

  return (
    <FormGroup>
      <Label for="row-count-filter">
        Hide tables with fewer rows than
      </Label>
      <Input
        id="row-count-filter"
        type="number"
        autoFocus
        min={0}
        value={filter ?? ''}
        placeholder="Minimum row count..."
        onChange={(e) => setAndParseRowFilter(e.target.value)}
        disabled={!isEnabled} />
    </FormGroup>
  );
}
