import {
  createEntityAdapter, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { noop } from 'lodash';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { closeTab } from '../../../features/project/projectSlice';
import { createTabIdPrefix } from '../../../util/createTabIdPrefix';
import { setSearch } from '../searchBox/searchBoxSlice';

export type ScrollPosition = {
  left: number;
  top: number;
};

type State = {
  id: string,
  position: ScrollPosition,
}

const scrollPositionAdapter = createEntityAdapter<State>();

type Action = {
  id: string;
  position: ScrollPosition;
}

const scrollPositionSlice = createSlice({
  name: 'scrollPosition',
  initialState: scrollPositionAdapter.getInitialState(),
  reducers: {
    setPosition: (state, action: PayloadAction<Action>) => {
      scrollPositionAdapter.upsertOne(state, {
        id: action.payload.id,
        position: action.payload.position,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSearch, (state, action) => {
      scrollPositionAdapter.removeOne(state, action.payload.id);
    });
    builder.addCase(closeTab, (state, action) => {
      const ids = state.ids.filter((id) => (
        id.toString()
          .startsWith(createTabIdPrefix(action.payload.projectId, action.payload.data.id))));

      scrollPositionAdapter.removeMany(state, ids);
    });
  },
});

const {
  setPosition,
} = scrollPositionSlice.actions;

const {
  selectById,
} = scrollPositionAdapter.getSelectors((state: RootState) => state.sessionReducers.scrollPosition);

type SetScrollPosition = (position: ScrollPosition) => void;

const IgnoreScroll: [ScrollPosition, SetScrollPosition] = [
  { left: 0, top: 0 },
  noop,
];
export const DEFAULT_SCROLL_ID = 'DEFAULT_SCROLL_ID';
const DEFAULT_POSITION = {
  left: 0,
  top: 0,
};

export function useScrollPosition(id: string): [ScrollPosition, SetScrollPosition] {
  const dispatch = useAppDispatch();

  const scrollPosition = useAppSelector((state) => selectById(state, id)) ?? {
    id,
    position: DEFAULT_POSITION,
  };

  const setScrollPosition = useCallback((position: ScrollPosition) => {
    dispatch(setPosition({
      id,
      position,
    }));
  }, [dispatch, id]);

  return id !== DEFAULT_SCROLL_ID
    ? [scrollPosition.position, setScrollPosition]
    : IgnoreScroll;
}

export default scrollPositionSlice.reducer;
