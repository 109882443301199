import React from 'react';
import { useLocation } from 'react-router-dom';
import { LoadingPage } from '../components/elements';
import userManager from '../features/authentication/userManager';

export function Login() {
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const returnUrl = params.get('returnUrl');
  const state = (isStateWithFromPathname(location.state) && location.state)
    || { from: { pathname: returnUrl ?? '/' } };

  localStorage.setItem('redirectUri', state.from.pathname);

  userManager.signinRedirect({});

  return (
    <LoadingPage message="Redirecting to login page..." />
  );
}

type From = {
  pathname: string
}

type StateWithFromPathname = {
  from: From,
}

const isStateWithFromPathname = (value: unknown): value is StateWithFromPathname => {
  if (value && typeof value === 'object' && 'from' in value) {
    const { from } = value as { from: unknown };

    if (from && typeof from === 'object' && 'pathname' in from) {
      return true;
    }
  }

  return false;
};
