import React, {
  createContext, ReactNode, useContext, useMemo,
} from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';
import {
  OrganizationDto, useUserApiListOrganizationsQuery,
} from '../../app/api';

type OrganizationParams = 'organizationId';

type OrganizationContextValue = {
  organizationId?: string;
  organization?: OrganizationDto;
  isLoading: boolean;
  organizations?: OrganizationDto[];
  error?: FetchBaseQueryError | SerializedError;
};

export const OrganizationContext = createContext<OrganizationContextValue>({ isLoading: false });

export function OrganizationContextProvider({ children }: {
  children: ReactNode;
}) {
  const { data: organizations, isLoading, error } = useUserApiListOrganizationsQuery();
  const { organizationId } = useParams<OrganizationParams>();

  const contextValue = useMemo(() => {
    const currentOrg = organizations?.filter((x) => x.id === organizationId)[0];
    return {
      organizationId,
      organization: currentOrg,
      isLoading,
      organizations,
      error,
    };
  }, [error, isLoading, organizationId, organizations]);

  return (
    <OrganizationContext.Provider value={contextValue}>
      {children}
    </OrganizationContext.Provider>
  );
}

export function OrganizationContextConsumer(
  { children }: { children: (org: OrganizationDto) => ReactNode; },
) {
  return (
    <OrganizationContext.Consumer>
      {(org) => (
        !org.isLoading && !!org.organization && children(org.organization)
      )}
    </OrganizationContext.Consumer>
  );
}

export function useOrganizations() {
  const { organizationId, ...organization } = useContext(OrganizationContext);

  if (organization === undefined) {
    throw new Error('useOrganization must be used within an OrganizationContextProvider');
  }

  if (organizationId === undefined) {
    throw new Error('useOrganization must be used within a route with an orgname param');
  }

  return organization;
}

export function useOrganization() {
  const { organization } = useOrganizations();

  if (organization === undefined) {
    throw new Error('useOrganization');
  }

  return organization;
}
