import React from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { Icon } from '../../../components/elements';

export function DeleteProjectDialog({
  projectName, isOpen, onClose, onDelete,
}: {
  projectName: string;
  isOpen: boolean;
  onDelete: () => void;
  onClose: () => void;
}) {
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Delete Project {projectName}</ModalHeader>
      <ModalBody>
        Are you sure you want to delete the project {projectName}?
        This action cannot be undone.
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onDelete}><Icon icon="trash" /> Delete</Button>{' '}
        <Button color="secondary" onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}
