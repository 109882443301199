import React, { useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import { useUserApiListProjectsQuery } from '../../../app/api';
import { usePrefetch } from '../../../app/hooks';
import { LoadingSpinner, ErrorWrapper } from '../../../components/elements';
import { useOrganization } from '../../organizations';
import { Welcome } from './Welcome';
import { ProjectList } from './ProjectList';

export function Organization() {
  return (
    <Container fluid className="p-5">
      <OrganizationBody />
    </Container>
  );
}

function OrganizationBody() {
  const organization = useOrganization();
  const organizationName = organization.name ?? organization.mnemonic ?? organization.shortName;

  const { currentData, error, isFetching } = useUserApiListProjectsQuery({
    organizationId: organization.id,
  });

  const fetchProject = useProjectPrefetch();

  const navigate = useNavigate();
  const createProject = useCallback(() => navigate('newproject'), [navigate]);

  switch (currentData?.length) {
    case 0: {
      return (
        <Welcome orgName={organizationName} />
      );
    }
    case 1: {
      return (<Navigate replace to={`projects/${currentData[0].id}`} />);
    }
    default: {
      return (
        <>
          <h1>{organizationName}</h1>
          <LoadingSpinner isLoading={isFetching && !currentData} centered>
            <ErrorWrapper error={error} message={<h4>Unable to load project list</h4>}>
              <ProjectList
                projects={currentData ?? []}
                onCreateProject={createProject}
                onFetchProject={fetchProject} />
            </ErrorWrapper>
          </LoadingSpinner>
        </>
      );
    }
  }
}

function useProjectPrefetch() {
  const prefetchHarvests = usePrefetch('userApiListMmHarvests');
  return useCallback((projectId: string) => {
    prefetchHarvests({ projectId });
  }, [prefetchHarvests]);
}
