import { useMemo } from 'react';
import { MmCodeSetDto, MmCodeSetWithValueCountDto } from '../../../../app/api';

export function useFilteredCodeSets(
  codeSets: MmCodeSetWithValueCountDto[],
  filter: string,
  isEmptyHidden = false,
) {
  const filteredSets = useMemo(() => {
    if (!filter && !isEmptyHidden) {
      return codeSets;
    }

    const matchesFilterText = createCodeSetTextFilter(filter);

    return codeSets.filter(
      (s) => matchesFilterText(s)
        && (!isEmptyHidden || (s.valueCount ?? 0) > 0),
    );
  }, [filter, codeSets, isEmptyHidden]);

  return filteredSets;
}

export function createCodeSetTextFilter(filter: string | null) {
  if (!filter) {
    return () => true;
  }

  const upperFilter = filter.toUpperCase();

  return (codeSet: MmCodeSetDto) => {
    return (codeSet.display?.toUpperCase().includes(upperFilter)
      || codeSet.code.toString().includes(upperFilter));
  };
}
