import {
  taggedApi,
} from './api-tagged';
import { ErrorMap } from './rtkQueryErrorLogger';

type OrganizationErrorMap = Pick<ErrorMap,
  'userApiListOrganizations'
  | 'userApiUpdateCurrentAccount'
  | 'userApiGetOrganizationById'
  | 'userApiListOrganizationAccounts'>;

export const organizationErrorMap: OrganizationErrorMap = {
  userApiListOrganizations: () => 'List Organizations',
  userApiGetOrganizationById: () => 'Get Organization',
  userApiUpdateCurrentAccount: () => 'Update Organization Memberships',
  userApiListOrganizationAccounts: () => 'List Organization Accounts',
};

export function addOrganizationApiExtensions(api: typeof taggedApi) {
  return api.enhanceEndpoints({
    endpoints: {
      userApiListOrganizations: {
        providesTags: ['Organizations'],
      },
      userApiUpdateCurrentAccount: {
        invalidatesTags: ['Organizations'],
      },
      userApiListOrganizationAccounts: {
        providesTags: (result, _, { organizationId }) => (result
          ? [
            ...result.map((a) => CreateAccountTag(a.id)),
            CreateOrganizationAccountsTag(organizationId),
          ]
          : [CreateOrganizationAccountsTag(organizationId)]),
      },
    },
  });
}

function CreateOrganizationAccountsTag(organizationId: string): { type: 'OrganizationAccounts', id: string } {
  return { type: 'OrganizationAccounts', id: `${organizationId}` };
}

function CreateAccountTag(accountId: string): { type: 'Account', id: string } {
  return { type: 'Account', id: `${accountId}` };
}
