import React, { useRef } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, UncontrolledTooltip,
} from 'reactstrap';
import { areTabsEqual, Tab, TabType } from '../../../project/projectSlice';
import { useUrlTab } from '../hooks/useUrlTab';
import { TableDetail, TableDetailTabHeader } from '../../tables/Tables';
import { DiagramViewer, DiagramTabHeader } from '../../diagram';
import { CodeSetDetail, CodeSetDetailTabHeader } from '../../codeSets';
import { iconMap } from '../../Database';
import { HomeTab, HomeTabHeader } from '../../home';
import { Icon, IconName, LoadingSpinner } from '../../../../components/elements';
import { useTab } from '../hooks/useTab';
import styles from './Tabs.module.scss';
import { CodeValueSearchTabHeader, CodeValueSearchResult } from '../../codeSets/codeValueSearch';

const tabMap: { [K in TabType]: (id: string) => JSX.Element } = {
  tables: (id) => <TableDetail tableName={id} key={id} />,
  diagrams: (id) => <DiagramViewer diagramId={id} key={id} />,
  codeSets: (id) => <CodeSetDetail codeSetId={Number.parseInt(id, 10)} key={id} />,
  codeSetSearch: (id) => <CodeValueSearchResult filter={id} />,
  home: () => <HomeTab />,
};

const tabHeaderMap: { [K in TabType]: (props: TabHeaderProps) => JSX.Element } = {
  tables: (props) => <TableDetailTabHeader {...props} />,
  diagrams: (props) => <DiagramTabHeader {...props} />,
  codeSets: (props) => (<CodeSetDetailTabHeader {...props} />),
  codeSetSearch: (props) => <CodeValueSearchTabHeader {...props} />,
  home: (props) => <HomeTabHeader {...props} />,
};

export function Tabs() {
  const { tabs, activeTab } = useTab();
  useUrlTab();

  return (
    <div className="d-flex flex-grow-1 flex-column pt-2">
      <Nav tabs>
        {tabs?.map((t) => <RenderTab tab={t} key={t.id} />)}
      </Nav>
      <TabContent className="d-flex flex-grow-1">
        {
          !!activeTab && tabMap[activeTab.type](activeTab.id)
        }
      </TabContent>
    </div>
  );
}

function RenderTab({ tab }: {
  tab: Tab;
}) {
  return tabHeaderMap[tab.type]({ tab });
}

export type TabHeaderProps = {
  tab: Tab;
}

export function DbTabHeader({
  tab, display, isError, isLoading,
}: TabHeaderProps & {
  isError: boolean;
  display: JSX.Element | string;
  isLoading: boolean;
}) {
  const { setTab, exitTab, activeTab } = useTab();

  return (
    <TabHeader
      isActive={areTabsEqual(activeTab, tab)}
      isError={isError}
      isLoading={isLoading}
      title={display}
      icon={iconMap[tab.type]}
      onClick={() => setTab(tab)}
      onClose={() => exitTab(tab)} />
  );
}

export function TabHeader({
  isActive,
  isLoading = false,
  isError = false,
  title,
  icon: iconName,
  onClick,
  onClose,
}: {
  isActive: boolean;
  isLoading?: boolean;
  isError?: boolean;
  title: JSX.Element | string;
  icon?: IconName;
  onClick: () => void;
  onClose?: () => void;
}) {
  const icon = isError ? 'error' : iconName;
  const titleRef = useRef(null);

  return (
    <>
      <div ref={titleRef}>
        <NavItem className={styles.tab}>
          <NavLink
            className={`d-flex flex-row align-items-center gap-2 ${styles['tab-link']}`}
            active={isActive}
            onClick={onClick}>
            <LoadingSpinner
              isLoading={isLoading}> {icon && <Icon icon={icon} />}
            </LoadingSpinner>
            <span className={styles['tab-title']}>{title}</span>
            {onClose && (
              <Icon
                className={styles['close-button']}
                icon="clear"
                onClick={(e) => { e.stopPropagation(); onClose(); }} />
            )}
          </NavLink>
        </NavItem>
      </div>
      <UncontrolledTooltip target={titleRef}>{title}</UncontrolledTooltip>
    </>
  );
}

TabHeader.defaultProps = {
  icon: undefined,
  isError: false,
  isLoading: false,
  onClose: undefined,
};
