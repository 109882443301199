import React, {
  forwardRef, Ref, useImperativeHandle, useMemo, useRef,
} from 'react';
import { noop } from 'lodash';
import { DiagramTableHeader } from './DiagramTableHeader';
import { DiagramTableBody, DiagramTableColumnStyles } from './DiagramTableBody';
import { DiagramTableDto, TableDto } from '../../../../app/api';
import { useToggle } from '../../../../hooks';
import { useBoundingRectangle } from '../useBoundingRectangle';
import { TableBodySort } from './util';
import { OnTablePrefetch } from '../../tables/useTablePrefetch';
import { DiagramElementRef } from '..';

export type DiagramTableProps = {
  tableName: string;
  isLoading: boolean;
  table: TableDto | undefined;
  columnStyles: DiagramTableColumnStyles;
  bodyColor: string;
  isSelected: boolean;
  headerColor: string;
  showCategory?: boolean;
  showCodeSet?: boolean;
  showDataType?: boolean;
  showDistinctValues?: boolean;
  showFullyQualifiedName?: boolean;
  showPkFkIndicator?: boolean;
  showRowCount?: boolean;
  showNullableColumns?: boolean;
  showIndexIndicator?: boolean;
  showFlagsIndicator?: boolean;
  showDisabledForeignKeys?: boolean;
  showInferredForeignKeys?: boolean;
  showOverriddenReferences?: boolean;
  sort: TableBodySort;
  onOpenCodeSet?: (id: number) => void;
  onTableDetails?: (tableName: string) => void
  onTablePrefetch?: OnTablePrefetch;
}

type DrawingTableProps = DiagramTableProps & {
  onSelect: (toggle: boolean) => void;
  diagramTable: DiagramTableDto;
  scale: number;
}

export const DrawingTable = forwardRef(({
  diagramTable, scale, onSelect, ...props
}: DrawingTableProps, ref: Ref<DiagramElementRef>) => {
  const divRef = useRef<HTMLDivElement>(null);
  const bounds = useBoundingRectangle(divRef.current);
  const size = useMemo(() => ({
    width: (bounds?.width ?? 0) / scale,
    height: (bounds?.height ?? 0) / scale,
  }), [bounds, scale]);

  const elementRef = useRef(null);
  useImperativeHandle(ref, () => {
    return {
      id: diagramTable.id,
      name: diagramTable.tableName,
      element: elementRef.current,
      bounds: {
        x: diagramTable.x,
        y: diagramTable.y,
        height: size.height,
        width: size.width,
        isScaled: false,
      },
    };
  }, [diagramTable, size]);

  return (
    <g
      onMouseDown={(e) => {
        onSelect(e.ctrlKey);
      }}
      ref={elementRef}>
      <foreignObject height={size.height} width={size.width} style={{ overflow: 'visible' }}>
        <DiagramTable ref={divRef} {...props} />
      </foreignObject>
    </g>
  );
});

export const DiagramTable = forwardRef(({
  table, tableName, sort, isLoading,
  bodyColor, isSelected, columnStyles, headerColor,
  showCategory, showCodeSet, showDataType, showDisabledForeignKeys, showDistinctValues,
  showFlagsIndicator, showFullyQualifiedName, showIndexIndicator, showInferredForeignKeys,
  showNullableColumns, showOverriddenReferences, showPkFkIndicator, showRowCount,
  onTableDetails, onOpenCodeSet, onTablePrefetch,
}: DiagramTableProps, ref: Ref<HTMLDivElement>) => {
  const { value: collapsed, toggleValue: toggleCollapsed } = useToggle();

  const body = useMemo(() => {
    return table
      ? (
        <DiagramTableBody
          table={table}
          showDataType={showDataType ?? true}
          showCodeSet={showCodeSet ?? true}
          showDistinctValues={showDistinctValues ?? true}
          showPkFkIndicator={showPkFkIndicator ?? true}
          showNullableColumns={showNullableColumns ?? true}
          showIndexIndicator={showIndexIndicator ?? true}
          showFlagsIndicator={showFlagsIndicator ?? true}
          showDisabledForeignKeys={showDisabledForeignKeys ?? true}
          showInferredForeignKeys={showInferredForeignKeys ?? true}
          showOverriddenReferences={showOverriddenReferences ?? true}
          sort={sort}
          backgroundColor={bodyColor}
          columnStyles={columnStyles}
          onOpenCodeSet={(id) => onOpenCodeSet && onOpenCodeSet(id)}
          onTableDetails={onTableDetails ?? noop}
          onTablePrefetch={onTablePrefetch ?? noop} />
      )
      : null;
  }, [
    bodyColor,
    columnStyles,
    onOpenCodeSet,
    onTableDetails,
    onTablePrefetch,
    showCodeSet,
    showDataType,
    showDisabledForeignKeys,
    showDistinctValues,
    showFlagsIndicator,
    showIndexIndicator,
    showInferredForeignKeys,
    showNullableColumns,
    showOverriddenReferences,
    showPkFkIndicator,
    sort,
    table,
  ]);

  return (
    <div
      ref={ref}
      style={{
        border: '1px black solid',
        whiteSpace: 'nowrap',
        width: 'fit-content',
        maxWidth: 'fit-content',
        outlineColor: isSelected ? '#0dcaf080' : 'transparent',
        outlineWidth: '5px',
        outlineStyle: 'solid',
      }}>
      <DiagramTableHeader
        table={table}
        tableName={tableName.split('.')[1]}
        schema={tableName.split('.')[0]}
        isCollapsed={collapsed}
        isLoading={isLoading}
        backgroundColor={headerColor}
        showFullyQualifiedName={showFullyQualifiedName ?? true}
        showCategory={showCategory ?? true}
        showRowCount={showRowCount ?? true}
        toggleCollapsed={toggleCollapsed}
        onTableDetails={onTableDetails
          ? () => onTableDetails(tableName)
          : undefined} />
      {!collapsed && body}
    </div>
  );
});

DiagramTable.defaultProps = {
  showCategory: true,
  showCodeSet: true,
  showDataType: true,
  showDistinctValues: true,
  showFullyQualifiedName: true,
  showPkFkIndicator: true,
  showRowCount: true,
  showNullableColumns: true,
  showIndexIndicator: true,
  showFlagsIndicator: true,
  showDisabledForeignKeys: true,
  showInferredForeignKeys: true,
  showOverriddenReferences: true,
  onOpenCodeSet: undefined,
  onTableDetails: undefined,
  onTablePrefetch: undefined,
};

DrawingTable.defaultProps = {
  showCategory: true,
  showCodeSet: true,
  showDataType: true,
  showDistinctValues: true,
  showFullyQualifiedName: true,
  showPkFkIndicator: true,
  showRowCount: true,
  showNullableColumns: true,
  showIndexIndicator: true,
  showFlagsIndicator: true,
  showDisabledForeignKeys: true,
  showInferredForeignKeys: true,
  showOverriddenReferences: true,
  onOpenCodeSet: undefined,
  onTableDetails: undefined,
  onTablePrefetch: undefined,
};
