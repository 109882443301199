import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrganizationDto } from '../../../app/api';

export function useNavigateIfSingleOrganization(
  organizations: OrganizationDto[] | undefined,
) {
  const navigate = useNavigate();
  useEffect(() => {
    if (organizations?.length === 1) {
      navigate(organizations[0].id, { replace: true });
    }
  }, [navigate, organizations]);
}
