import { useCallback, useMemo } from 'react';
import { useUserApiGetProjectByIdQuery, useUserApiUpdateProjectPreferencesMutation } from '../../../app/api';
import { useOrganization } from '../../organizations';
import { useProject } from '..';
import { Tab } from '../projectSlice';
import { DiagramListPreferences } from '../../database/diagram/Diagrams';

export type ProjectCategoryPreference = {
  id: string;
  color: string;
  isHidden: boolean;
};

export type ThresholdPreference = {
  id: string;
  threshold: number;
  color: string;
};

export type ProjectPreferences = {
  rowCountFilter: number;
  hideEmptyCodeSets: boolean;
  categories?: ProjectCategoryPreference[];
  thresholds?: ThresholdPreference[];
  favoriteHarvests?: string[];
  recentTabs?: Tab[];
  diagramListPreferences?: DiagramListPreferences;
};

export function useProjectPreferences() {
  const { id: organizationId } = useOrganization();
  const { projectId } = useProject();

  const { data: project, isLoading } = useUserApiGetProjectByIdQuery({
    organizationId,
    projectId,
  });

  const preferences: ProjectPreferences = useMemo(() => ({
    rowCountFilter: 0,
    hideEmptyCodeSets: false,
    ...JSON.parse(project?.instancePreferences ?? '{}'),
  }), [project?.instancePreferences]);

  const [
    persistPreferences,
    { isLoading: isSaving },
  ] = useUserApiUpdateProjectPreferencesMutation();

  const setPreferences = useCallback((request: ProjectPreferences) => persistPreferences({
    projectId,
    updateProjectPreferencesRequest: {
      jsonData: JSON.stringify(request),
    },
  }), [persistPreferences, projectId]);

  const setCategory = useCallback((category: ProjectCategoryPreference) => setPreferences({
    ...preferences,
    categories: [
      ...(preferences?.categories ?? []).filter((c) => c.id !== category.id),
      category,
    ],
  }), [setPreferences, preferences]);

  const setRowCount = useCallback((value) => {
    if (preferences) {
      setPreferences({ ...preferences, rowCountFilter: value });
    }
  }, [preferences, setPreferences]);

  const setThresholds = useCallback((thresholds: ThresholdPreference[]) => setPreferences({
    ...preferences,
    thresholds,
  }), [setPreferences, preferences]);

  return {
    preferences, isLoading, setPreferences, isSaving, setCategory, setRowCount, setThresholds,
  };
}
