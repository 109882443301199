import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useFirstUpdate } from '../../../../hooks';
import { ThresholdPreference, useProjectPreferences } from '../../../project';

const defaultColors: ThresholdPreference[] = [
  { id: uuid(), threshold: 0, color: '#d3d3d3' },
  { id: uuid(), threshold: 1, color: '#ffb6c1' },
  { id: uuid(), threshold: Number.MAX_SAFE_INTEGER, color: '#f0f8ff' },
];

export function useLoadedThresholdColors() {
  const {
    preferences,
    isLoading: preferencesLoading,
  } = useProjectPreferences();

  const isFirstLoad = useFirstUpdate();
  const colors = useMemo(() => {
    return preferences?.thresholds && (preferences.thresholds.length > 0 || !isFirstLoad)
      ? [...preferences.thresholds]
      : [...defaultColors];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences?.thresholds]);

  return {
    thresholds: colors,
    isLoading: preferencesLoading,
  };
}
