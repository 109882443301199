import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { userExpired } from '@ericlamb/redux-oidc';

export const rtkQueryUnauthorizedHandler: Middleware = (wrappedApi: MiddlewareAPI) => {
  return (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action?.payload?.status === 401) {
        wrappedApi.dispatch(userExpired());
      }
    }

    return next(action);
  };
};
