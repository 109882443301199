import React from 'react';

export function EmptyOrganizationList() {
  return (
    <>
      <h1>Welcome to the Database Explorer</h1>
      <p>
        To use Database Explorer you need to be a member of an organization.
      </p>
      <ul>
        <li>If your organization has a subscription, please, contact your organization&apos;s admin or the <a href="https://www.softekinc.com/customer-support">Softek Support Team</a> to be added to your organization.</li>
        <li>You can request a demo account by <a href="https://www.softekinc.com/contact-us">contacting our sales team here</a>.</li>
      </ul>
    </>
  );
}
