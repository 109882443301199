import {
  createEntityAdapter, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { closeTab } from '../../../features/project/projectSlice';
import { createTabIdPrefix } from '../../../util/createTabIdPrefix';

type State = {
  id: string,
  search: string,
}

const searchBoxAdapter = createEntityAdapter<State>();

type Action = {
  id: string;
  search: string,
}

const searchBoxSlice = createSlice({
  name: 'searchBoxValue',
  initialState: searchBoxAdapter.getInitialState(),
  reducers: {
    setSearch: (state, action: PayloadAction<Action>) => {
      searchBoxAdapter.upsertOne(state, {
        id: action.payload.id,
        search: action.payload.search,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeTab, (state, action) => {
      const ids = state.ids.filter((id) => (
        id.toString()
          .startsWith(createTabIdPrefix(action.payload.projectId, action.payload.data.id))));

      searchBoxAdapter.removeMany(state, ids);
    });
  },
});

export const {
  setSearch,
} = searchBoxSlice.actions;

const {
  selectById,
} = searchBoxAdapter.getSelectors((state: RootState) => state.sessionReducers.searchBoxValue);

type SetPageSize = (search: string) => void;

export const DEFAULT_SEARCH_VALUE_ID = 'DEFAULT_SEARCH_VALUE_ID';

export function useSearchBoxValue(id: string): [string, SetPageSize] {
  const dispatch = useAppDispatch();

  const currentState = useAppSelector((state) => selectById(state, id)) ?? {
    id,
    search: '',
  };

  const setPersistentSearch = useCallback((search: string) => {
    dispatch(setSearch({
      id,
      search,
    }));
  }, [dispatch, id]);

  const [transientSearch, setTransientSearch] = useState('');

  return id !== DEFAULT_SEARCH_VALUE_ID
    ? [currentState.search, setPersistentSearch]
    : [transientSearch, setTransientSearch];
}

export default searchBoxSlice.reducer;
