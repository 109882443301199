import React, { useRef } from 'react';
import {
  Modal, ModalBody, ModalHeader, UncontrolledTooltip,
} from 'reactstrap';
import { Icon } from '../../../components/elements';
import { ThresholdPreference } from '../../project';
import { Category, CategorySettings } from './CategorySettings';
import { ThresholdSettings } from './ThresholdSettings';
import { CategoryPreferenceMap } from '.';

export function ColorSettingsDialog({
  categories, thresholds, isOpen, isLoading, isLoadingThresholds,
  onChangeColor, onChangeThresholds, onClose,
}: {
  categories: CategoryPreferenceMap;
  thresholds: ThresholdPreference[];
  isOpen: boolean;
  isLoading: boolean;
  isLoadingThresholds: boolean;
  onChangeColor: (category: Category) => void;
  onChangeThresholds: (newThresholds: ThresholdPreference[]) => void;
  onClose: () => void;
}) {
  const headerHelp = useRef(null);

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader
        toggle={onClose}>
        <Icon icon="colors" />
        {' '}Color Settings <span ref={headerHelp}><Icon icon="helpTip" /></span>
      </ModalHeader>
      <ModalBody className="d-flex flex-column gap-2">
        <CategorySettings
          categories={categories}
          isLoading={isLoading}
          onChangeColor={onChangeColor} />
        <ThresholdSettings
          thresholds={thresholds}
          isLoading={isLoadingThresholds}
          onChangeThreshold={onChangeThresholds} />

      </ModalBody>

      <UncontrolledTooltip target={headerHelp} placement="bottom">
        Settings are applied to all diagrams in a project for you.
        Other users viewing the diagram will <em>not</em> be affected by these settings.
      </UncontrolledTooltip>
    </Modal>
  );
}
