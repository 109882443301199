import { taggedApi } from './api-tagged';
import { ErrorMap } from './rtkQueryErrorLogger';
import { optimisticUpdate, pessimisticCreate } from './api-util';

type ProjectErrorMap = Pick<ErrorMap,
  'userApiDeleteProject'
  | 'userApiCreateProject'
  | 'userApiUpdateProject'
  | 'userApiListProjects'
  | 'userApiGetProjectById'
  | 'userApiUpdateCurrentAccountProjectPreferences'
  | 'userApiUpdateProjectPreferences'>;

export const projectErrorMap: ProjectErrorMap = {
  userApiDeleteProject: (args) => `Delete Project ${args.projectId}`,
  userApiCreateProject: (args) => `Create Project ${args.projectCreateDto.name}`,
  userApiUpdateProject: (args) => `Update Project ${args.projectCreateDto.name}`,
  userApiListProjects: () => 'List Projects',
  userApiGetProjectById: () => 'Get Project',
  userApiUpdateCurrentAccountProjectPreferences: () => 'Update User Project Preferences',
  userApiUpdateProjectPreferences: () => 'Update Project Preferences',
};

export function addProjectApiExtensions(api: typeof taggedApi) {
  return api.enhanceEndpoints({
    endpoints: {
      userApiListProjects: {
        providesTags: (result, _, { organizationId }) => (result
          ? [
            ...result.map((p) => CreateProjectTag(p.id)),
            CreateOrganizationTag(organizationId),
          ]
          : [CreateOrganizationTag(organizationId)]),
      },
      userApiGetProjectById: {
        providesTags: (_, __, { projectId }) => [CreateProjectTag(projectId)],
      },
      userApiCreateProject: {
        onQueryStarted: async ({ organizationId }, mutation) => {
          await pessimisticCreate(mutation, api, {
            invalidatedTags: [CreateOrganizationTag(organizationId)],
            updateHandlers: {
              userApiListProjects: ({ originalArgs }, created) => {
                return api.util.updateQueryData('userApiListProjects', originalArgs, (draft) => {
                  draft.push(created);
                });
              },
            },
          });
        },
      },
      userApiDeleteProject: {
        onQueryStarted: async ({ projectId }, mutation) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [CreateProjectTag(projectId)],
            updateHandlers: {
              userApiListProjects: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListProjects', originalArgs, (draft) => {
                  const index = draft.findIndex((p) => p.id === projectId);
                  draft.splice(index, 1);
                });
              },
            },
          });
        },
      },
      userApiUpdateProject: {
        onQueryStarted: async (
          { projectId, projectCreateDto },
          mutation,
        ) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [CreateProjectTag(projectId)],
            updateHandlers: {
              userApiListProjects: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListProjects', originalArgs, (draft) => {
                  const project = draft.find((d) => d.id === projectId);
                  if (project) {
                    Object.assign(project, projectCreateDto);
                  }
                });
              },
              userApiGetProjectById: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetProjectById', originalArgs, (draft) => {
                  Object.assign(draft, projectCreateDto);
                });
              },
            },
          });
        },
      },
      userApiUpdateProjectPreferences: {
        onQueryStarted: async (
          { projectId, updateProjectPreferencesRequest },
          mutation,
        ) => {
          await optimisticUpdate(mutation, api, {
            invalidatedTags: [CreateProjectTag(projectId)],
            updateHandlers: {
              userApiGetProjectById: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiGetProjectById', originalArgs, (draft) => {
                  draft.instancePreferences = updateProjectPreferencesRequest.jsonData;
                });
              },
            },
          });
        },
      },
    },
  });
}

function CreateProjectTag(projectId: string): { type: 'Project', id: string } {
  return { type: 'Project', id: projectId };
}

function CreateOrganizationTag(organizationId: string): { type: 'Projects', id: string } {
  return { type: 'Projects', id: `org-${organizationId}` };
}
