import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export function isSerializedError(error: unknown): error is SerializedError {
  return !!error
    && typeof error === 'object'
    && (error as SerializedError).message !== undefined;
}

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return !!error
    && typeof error === 'object'
    && 'status' in error;
}
