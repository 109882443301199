import React from 'react';
import { Icon } from '..';

export function Expander({ isCollapsed, onToggle }: {
  isCollapsed: boolean;
  onToggle: () => void;
}) {
  const icon = isCollapsed
    ? 'chevronCircleDown'
    : 'chevronCircleUp';

  return (
    <Icon
      icon={icon}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={onToggle}
      cursor="pointer"
      className="select-none" />
  );
}
