import React from 'react';
import { DiagramRectangleDto } from '../../../../app/api';
import { useSharedDragOffset } from '../dragOffsetSlice';
import { ResizableItem, SizeDefinition } from '../ResizableItem';
import styles from './DiagramRectangleOutline.module.scss';

export function DiagramRectangleOutline({
  rectangle, dragContextId, isSelected, scale, onResizeRectangle,
}: {
  rectangle: DiagramRectangleDto;
  dragContextId: string;
  isSelected: boolean;
  scale: number;
  onResizeRectangle?: (r: DiagramRectangleDto, newSize: SizeDefinition) => void;
}) {
  const {
    delta, isDragging,
  } = useSharedDragOffset(dragContextId, true);

  const location = isDragging
    ? {
      x: rectangle.x + delta.x,
      y: rectangle.y + delta.y,
    }
    : {
      x: rectangle.x,
      y: rectangle.y,
    };

  return (
    <g transform={`translate(${location.x}, ${location.y})`}>
      <ResizableItem
        size={{
          ...rectangle,
          x: 0,
          y: 0,
        }}
        scale={scale}
        isSelected={isSelected}
        resizeContextId={rectangle.id}
        onResize={(newSize) => onResizeRectangle && onResizeRectangle(rectangle, {
          ...newSize,
          x: rectangle.x + newSize.x,
          y: rectangle.y + newSize.y,
        })}>
        {(size) => (
          <rect
            className={isSelected ? styles.selected : undefined}
            pointerEvents="stroke"
            fillOpacity={0}
            x={size.x}
            y={size.y}
            width={size.width}
            height={size.height} />
        )}
      </ResizableItem>
    </g>
  );
}

DiagramRectangleOutline.defaultProps = {
  onResizeRectangle: undefined,
};
