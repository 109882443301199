import React, { useMemo, useState } from 'react';
import { Icon, SearchBox } from '../../../../components/elements';
import { useSortedOrganizations } from '../../hooks';
import { EmptyOrganizationList } from './EmptyOrganizationList';
import { OrganizationsList } from './OrganizationList';
import { OrganizationListItem } from './OrganizationListItem';

export function OrganizationsView({
  organizations,
  onFetchOrganization, onFavoriteOrganization, onUnfavoriteOrganization,
}: {
  organizations: OrganizationListItem[];
  onFetchOrganization: (id: string) => void;
  onFavoriteOrganization: (id: string) => void;
  onUnfavoriteOrganization: (id: string) => void;
}) {
  const [filter, setFilter] = useState('');

  const sortedOrganizations = useSortedOrganizations(organizations);

  const filteredOrganizations = useMemo(() => {
    return (sortedOrganizations ?? [])
      .filter((x) => {
        return !filter
          || x.name?.toUpperCase().includes(filter.toUpperCase())
          || x.mnemonic.toUpperCase().includes(filter.toUpperCase());
      });
  }, [filter, sortedOrganizations]);

  if (organizations.length === 0) {
    return (
      <EmptyOrganizationList />
    );
  }

  return (
    <>
      <h1><Icon icon="organizations" /> Organizations</h1>
      <div className="p-0 d-flex mt-2 mb-4">
        <SearchBox
          className="my-1"
          placeholder="Find an organization..."
          onFilterChange={setFilter} />
      </div>
      <div className="d-flex flex-column gap-4">
        <div className="d-flex flex-wrap gap-4 flex-grow-1 justify-content-between">
          <OrganizationsList
            title="Favorites"
            organizations={filteredOrganizations.filter((o) => o.isFavorite)}
            onFavoriteOrganization={onFavoriteOrganization}
            onUnfavoriteOrganization={onUnfavoriteOrganization}
            onFetchOrganization={onFetchOrganization} />

          <OrganizationsList
            title="Recent"
            organizations={filteredOrganizations
              .filter((o) => o.recentIndex >= 0)
              .sort((a, b) => a.recentIndex - b.recentIndex)
              .slice(0, 5)}
            onFavoriteOrganization={onFavoriteOrganization}
            onUnfavoriteOrganization={onUnfavoriteOrganization}
            onFetchOrganization={onFetchOrganization} />
        </div>

        <OrganizationsList
          title="All"
          organizations={filteredOrganizations}
          onFavoriteOrganization={onFavoriteOrganization}
          onUnfavoriteOrganization={onUnfavoriteOrganization}
          onFetchOrganization={onFetchOrganization} />
      </div>
    </>
  );
}
