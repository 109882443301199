import React, { ReactNode } from 'react';
import { Icon } from '..';

export function LoadingSpinner({
  isLoading, children, spinner, centered, className,
}: {
  isLoading: boolean;
  children?: ReactNode;
  spinner?: ReactNode;
  centered?: boolean;
  className?: string | undefined;
}) {
  const spinnerBody = spinner ?? (
    centered
      ? (
        <div className="d-flex align-items-center justify-content-center flex-grow-1">
          <span className="fs-1">
            <Icon icon="spinner" spin />
          </span>
        </div>
      )
      : (<Icon icon="spinner" spin className={className ?? 'mx-auto'} />)
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        isLoading
          ? spinnerBody
          : children
      }
    </>
  );
}

LoadingSpinner.defaultProps = {
  spinner: undefined,
  centered: false,
  children: undefined,
  className: undefined,
};
