export function insensitiveCompare(a: string | undefined | null, b: string | null | undefined) {
  if (a === b) {
    return 0;
  }

  if (!a && b) {
    return -1;
  }

  if (a && !b) {
    return 1;
  }

  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'accent' })
    : 0;
}

export function insensitiveEquals(a: string | undefined | null, b: string | null | undefined) {
  return insensitiveCompare(a, b) === 0;
}
