import React, { useRef } from 'react';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { useDrag } from 'react-dnd';
import { ProjectCategoryDto } from '../../../../app/api';
import { abbreviateCategory, getCategory, UNCATEGORIZED_CATEGORY } from '../../diagram/util';
import { CategoryPreferenceMap } from '../../settings';
import { OnTablePrefetch } from '../useTablePrefetch';

type TableInfo = {
  id: string;
  name: string;
  rowCount?: number | null;
  projectCategory?: ProjectCategoryDto | null;
  schemaName: string;
  isView?: boolean;
};

export function TableItem({
  table, categoryPrefMap, onTableDetails, onTableFetch,
}: {
  table: TableInfo;
  categoryPrefMap: CategoryPreferenceMap,
  onTableDetails: () => void;
  onTableFetch: OnTablePrefetch;
}) {
  const [, drag] = useDrag(() => ({
    type: 'Table',
    item: { schema: table.schemaName, ...table },
  }));

  return (
    <div
      ref={drag}
      onDoubleClick={() => onTableDetails()}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={() => onTableFetch(table)}
      style={{ height: '24px' }}
      className="cursor-grab d-flex p-0 text-nowrap gap-1 flex-grow-1">
      <span className="d-flex flex-grow-1 gap-1" style={{ width: 0 }}>
        <TableName table={table} />
        <CategoryIndicator
          table={table}
          categoryPrefMap={categoryPrefMap} />
      </span>
      <span>
        {table.rowCount != null && table.rowCount.toLocaleString()}
      </span>
    </div>
  );
}

function TableName({ table }: { table: TableInfo }) {
  const nameRef = useRef(null);
  return (
    <>
      <span ref={nameRef} className="text-truncate">
        {table.name}
      </span>
      <UncontrolledTooltip target={nameRef}>
        {table.name}
      </UncontrolledTooltip>
    </>
  );
}

function CategoryIndicator({ table, categoryPrefMap }: {
  table: TableInfo,
  categoryPrefMap: CategoryPreferenceMap,
}) {
  const ref = useRef(null);

  const projectCategory = getCategory(table);

  return !!projectCategory && projectCategory !== UNCATEGORIZED_CATEGORY
    ? (
      <>
        <span ref={ref}>
          <Badge
            color="none"
            className="text-body fw-normal px-2 fs-6 py-1"
            style={{ backgroundColor: categoryPrefMap[projectCategory.id]?.color }}>
            {abbreviateCategory(projectCategory.name)}
          </Badge>
        </span>
        <UncontrolledTooltip target={ref}>{projectCategory.name}</UncontrolledTooltip>
      </>
    )
    // eslint-disable-next-line react/jsx-no-useless-fragment
    : <></>;
}
