import React, { ComponentType, forwardRef } from 'react';
import {
  // eslint-disable-next-line max-len
  TableDto, MmTableDto, useTableInfo,
} from '../../../app/api';

type LoadableTable = {
  table: TableDto | MmTableDto | undefined;
  isLoading: boolean;
  tableName: string;
};
type WithTableDataProps = Omit<LoadableTable, 'isLoading' | 'table'>;

export const withTableData = <P extends LoadableTable>(Component: ComponentType<P>) => {
  return forwardRef((props: Omit<P, 'isLoading' | 'table'> & WithTableDataProps, ref) => {
    const { isLoading, table } = useTableInfo(props.tableName);

    const newProps = {
      ...props,
      isLoading,
      table,
    };

    return (<Component {...newProps as P} ref={ref} />);
  });
};
