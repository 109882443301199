import React, { ComponentType } from 'react';
import { DiagramWithChildrenDto, useUserApiUpdateDiagramMutation } from '../../../app/api';
import { useAppDispatch } from '../../../app/hooks';
import { addNotification } from '../../notifications/notificationSlice';
import { useProject } from '../../project';

type DiagramLockable = {
  onLock: (diagram: DiagramWithChildrenDto) => void;
  onUnlock: (diagram: DiagramWithChildrenDto) => void;
};

type withDiagramLockProps = Omit<DiagramLockable, 'onLock' | 'onUnlock'>;

export const withDiagramLock = <P extends DiagramLockable>(Component: ComponentType<P>) => {
  return function (props: Omit<P, 'onLock' | 'onUnlock'> & withDiagramLockProps) {
    const { projectId } = useProject();

    const [editDiagram] = useUserApiUpdateDiagramMutation();
    const dispatch = useAppDispatch();

    const executeSetLocked = async (diagram: DiagramWithChildrenDto, isLocked: boolean) => {
      const result = await editDiagram({
        projectId,
        diagramId: diagram.id,
        diagramCreateDto: {
          ...diagram,
          isLocked,
        },
      });

      if ('error' in result) {
        dispatch(addNotification({
          type: 'error',
          title: `Error ${isLocked ? 'locking' : 'unlocking'} diagram ${diagram.name}`,
          error: result.error,
        }));
      }
    };

    return (
      <Component
        {...props as P}
        onLock={(id) => executeSetLocked(id, true)}
        onUnlock={(id) => executeSetLocked(id, false)} />
    );
  };
};
