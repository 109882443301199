import {
  createEntityAdapter, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { SizeDefinition } from './SizeDefinition';

type State = {
  id: string,
  size: SizeDefinition | undefined,
}

const sizeAdapter = createEntityAdapter<State>();

type Action = {
  id: string;
  size: SizeDefinition | undefined,
}

const resizeSlice = createSlice({
  name: 'resize',
  initialState: sizeAdapter.getInitialState(),
  reducers: {
    setSize: (state, action: PayloadAction<Action>) => {
      sizeAdapter.upsertOne(state, {
        id: action.payload.id,
        size: action.payload.size,
      });
    },
  },
});

export const {
  setSize,
} = resizeSlice.actions;

const {
  selectById,
} = sizeAdapter.getSelectors((state: RootState) => state.resize);

type SetSizeDefinition = (size: SizeDefinition | undefined) => void;

const DEFAULT_SIZE_VALUE_ID = 'DEFAULT_SIZE_VALUE_ID';

export function useSharedSize(
  id: string = DEFAULT_SIZE_VALUE_ID,
): [SizeDefinition | undefined, SetSizeDefinition] {
  const dispatch = useAppDispatch();

  const currentState = useAppSelector((state) => selectById(state, id)) ?? {
    id,
    size: undefined,
  };

  const setSharedSize = useCallback((size: SizeDefinition | undefined) => {
    dispatch(setSize({
      id,
      size,
    }));
  }, [dispatch, id]);

  const [localSize, setLocalSize] = useState<SizeDefinition>();

  return id !== DEFAULT_SIZE_VALUE_ID
    ? [currentState.size, setSharedSize]
    : [localSize, setLocalSize];
}

export default resizeSlice.reducer;
