import React from 'react';
import { IconButton } from '../..';

export function FavoriteButton({
  isFavorite, onAddFavorite, onRemoveFavorite,
}: {
  isFavorite: boolean;
  onAddFavorite: () => void;
  onRemoveFavorite: () => void;
}) {
  const onClick = isFavorite
    ? onRemoveFavorite
    : onAddFavorite;

  return (
    <IconButton
      iconName={isFavorite ? 'favorite' : 'not-favorite'}
      toolTip={isFavorite ? 'Remove From Favorites' : 'Add to Favorites'}
      className="link-dark"
      onClick={onClick} />
  );
}
