import { useCallback } from 'react';
import { usePrefetch } from '../../../app/hooks';

export function useOrganizationDetailsPrefetch() {
  const prefetchProjects = usePrefetch('userApiListProjects');

  const fetchDetails = useCallback((organizationId: string) => {
    prefetchProjects({ organizationId });
  }, [prefetchProjects]);

  return fetchDetails;
}
