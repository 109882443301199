import { useRef } from 'react';
import { useDrop, XYCoord } from 'react-dnd';
import { calculateOffset } from '../util/calculateOffset';

export function useLocalDrop<E extends Element, T, U>(
  onDrop: (item: T, coordinate: XYCoord) => U,
  scale: number,
  canDrop: boolean,
): (elem: E) => void {
  const ref = useRef<E>();

  const [, drop] = useDrop(() => ({
    accept: 'Table',
    canDrop: () => canDrop,
    drop: (item: T, monitor) => {
      const dropTarget = ref.current?.getBoundingClientRect() ?? { x: 0, y: 0 };
      const dropOffset = monitor.getClientOffset() ?? { x: 0, y: 0 };

      const localOffset = {
        x: calculateOffset(dropOffset.x, dropTarget.x, scale),
        y: calculateOffset(dropOffset.y, dropTarget.y, scale),
      };

      return onDrop(item, localOffset);
    },
  }), [scale, canDrop, onDrop]);

  return (elem: E) => {
    ref.current = elem;
    drop(ref);
  };
}
