const BYTE_UNITS = [
  'B',
  'kB',
  'MB',
  'GB',
  'TB',
];

export function formatBytes(bytes: number) {
  const exponent = Math.min(
    Math.floor(Math.log10(bytes) / 3),
    BYTE_UNITS.length - 1,
  );

  const number = bytes / (1000 ** exponent);

  return `${number.toLocaleString(undefined, { maximumFractionDigits: 1 })} ${BYTE_UNITS[exponent]}`;
}
