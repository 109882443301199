import {
  Button, ListGroup,
} from 'reactstrap';
import React, { useMemo } from 'react';
import { TableSummaryDto } from '../../../app/api';
import { insensitiveCompare } from '../../../util';
import {
  // eslint-disable-next-line max-len
  ErrorWrapper, ExpansionMap, Icon, LoadingSpinner, ScrollArea, SearchBox, TreeNode, useExpansionMap,
} from '../../../components/elements';
import { TableItem } from './TableItem';
import { useDebouncedEventHandler } from '../../../hooks';
import { useFilteredTables } from '.';
import { OnTablePrefetch } from './useTablePrefetch';
import { ProjectPreferences, useProject } from '../../project';
import { CategoryPreferenceMap } from '../settings';
import { useSearchBoxValue } from '../../../components/elements/searchBox/searchBoxSlice';

type SchemaNode = {
  schemaId: string;
  schemaName: string;
  tables: TableSummaryDto[]
}

export function SchemaList({
  tables, isLoading, error, preferences, categories, onTableDetails, onTablePrefetch, onOpenFilter,
}: {
  tables: TableSummaryDto[];
  isLoading: boolean;
  error: unknown;
  preferences: ProjectPreferences | undefined;
  categories: CategoryPreferenceMap;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
  onOpenFilter: () => void;
}) {
  const { projectId } = useProject();
  const stateScopeId = `${projectId}-schemaList`;

  const [filter, setFilter] = useSearchBoxValue(stateScopeId);
  const debouncedFilter = useDebouncedEventHandler(setFilter, 300);

  const filteredTables = useFilteredTables(tables ?? [], filter, preferences);

  const schemas = useMemo(() => (
    Object.values(
      filteredTables.reduce((acc: { [k: string]: SchemaNode }, t) => {
        const index = t.schemaId;
        if (acc[index]) {
          acc[index].tables.push(t);
        } else {
          acc[index] = { ...t, tables: [t] };
        }
        return acc;
      }, {}),
    ).sort((a, b) => insensitiveCompare(a.schemaName, b.schemaName))
  ), [filteredTables]);
  const { expansionMap, setExpansion } = useExpansionMap(stateScopeId);

  return (
    <div className="d-flex flex-grow-1 flex-column gap-2">
      <div className="d-flex flex-row gap-1">
        <SearchBox
          placeholder="Find a table..."
          initialSearch={filter}
          onFilterChange={debouncedFilter} />

        <Button onClick={onOpenFilter}>
          <Icon icon="filter" />
        </Button>
      </div>

      <LoadingSpinner isLoading={isLoading}>
        <ErrorWrapper error={error} message={<h5>Unable to load table list</h5>}>
          <ScrollArea id={stateScopeId}>
            <ListGroup>
              {schemas.map((s) => (
                <SchemaGroup
                  key={s.schemaId}
                  schema={s}
                  filter={filter}
                  defaultExpanded={schemas?.length <= 1}
                  categories={categories}
                  expansionMap={expansionMap}
                  onTableDetails={onTableDetails}
                  onTablePrefetch={onTablePrefetch}
                  onSetExpanded={setExpansion} />
              ))}
            </ListGroup>
          </ScrollArea>
        </ErrorWrapper>
      </LoadingSpinner>
    </div>
  );
}

function SchemaGroup({
  schema, filter, defaultExpanded, categories, expansionMap,
  onTableDetails, onTablePrefetch, onSetExpanded,
}: {
  schema: SchemaNode;
  filter: string;
  defaultExpanded: boolean;
  categories: CategoryPreferenceMap,
  expansionMap: ExpansionMap;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
  onSetExpanded: (nodeId: string, expanded: boolean) => void;
}) {
  const tableGroups = useMemo(() => (
    schema.tables.reduce((acc: { [k: string]: TableSummaryDto[] }, t) => {
      const index = t.name[0];
      acc[index] = acc[index] ?? [];
      acc[index].push(t);
      return acc;
    }, {})
  ), [schema.tables]);

  const tableGroupNames = useMemo(() => (
    Object.keys(tableGroups)
      .sort((a, b) => insensitiveCompare(a, b))
  ), [tableGroups]);

  const childCount = schema.tables.length;

  return (
    <>
      {
        (schema.tables.length > 0) && (
          <TreeNode
            label={schema.schemaName}
            childCount={childCount}
            filter={filter}
            defaultExpanded={defaultExpanded}
            className="pe-2"
            id={`schema-${schema.schemaName}`}
            expansionMap={expansionMap}
            onSetExpanded={onSetExpanded}>
            {
              tableGroupNames
                .map((key) => (
                  <TableGroup
                    key={`${schema.schemaName}-${key}`}
                    id={`${schema.schemaName}-${key}`}
                    groupName={key}
                    tables={tableGroups[key]}
                    filter={filter}
                    defaultExpanded={tableGroupNames.length <= 1}
                    className="border-0 pt-0 pb-1"
                    categories={categories}
                    expansionMap={expansionMap}
                    onTableDetails={onTableDetails}
                    onTablePrefetch={onTablePrefetch}
                    onSetExpanded={onSetExpanded} />
                ))
            }
          </TreeNode>
        )
      }
    </>
  );
}

export function TableGroup({
  groupName,
  id,
  tables,
  filter,
  defaultExpanded,
  className,
  categories,
  expansionMap,
  onTableDetails,
  onTablePrefetch,
  onSetExpanded,
}: {
  groupName: string;
  id: string;
  tables: TableSummaryDto[];
  filter: string;
  defaultExpanded: boolean;
  className?: string;
  categories: CategoryPreferenceMap;
  expansionMap: ExpansionMap;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
  onSetExpanded: (nodeId: string, expanded: boolean) => void;
}) {
  const childCount = tables.length;

  const sortedTables = useMemo(() => (
    [...tables].sort((a, b) => insensitiveCompare(a.name, b.name))
  ), [tables]);

  return childCount <= 0
    // eslint-disable-next-line react/jsx-no-useless-fragment
    ? <></>
    : (
      <TreeNode
        label={groupName}
        childCount={childCount}
        filter={filter}
        defaultExpanded={defaultExpanded}
        className={className}
        id={`group-${id}`}
        expansionMap={expansionMap}
        onSetExpanded={onSetExpanded}>
        {sortedTables.map((t) => (
          <div
            key={`${t.schemaName}-${t.name}`}
            className="ps-3 hover">
            <TableItem
              table={t}
              categoryPrefMap={categories}
              onTableDetails={() => onTableDetails(`${t.schemaName}.${t.name}`)}
              onTableFetch={onTablePrefetch} />
          </div>
        ))}
      </TreeNode>
    );
}

TableGroup.defaultProps = {
  className: undefined,
};
