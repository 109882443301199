import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { closeTab } from '../../../features/project/projectSlice';
import { createTabIdPrefix } from '../../../util/createTabIdPrefix';
import { setSearch } from '../searchBox/searchBoxSlice';

type SortDescription = {
  id: string,
  desc?: boolean | undefined,
}
type State = {
  id: string,
  pageSize?: number,
  sorts?: SortDescription[],
}

const tablePageSizeAdapter = createEntityAdapter<State>();

type Action<T> = {
  id: string;
  value: T,
}

const tablePageSizeSlice = createSlice({
  name: 'tablePageSize',
  initialState: tablePageSizeAdapter.getInitialState(),
  reducers: {
    setPageSize: (state, action: PayloadAction<Action<number>>) => {
      tablePageSizeAdapter.upsertOne(state, {
        id: action.payload.id,
        pageSize: action.payload.value,
      });
    },
    setSorts: (state, action: PayloadAction<Action<SortDescription[]>>) => {
      tablePageSizeAdapter.upsertOne(state, {
        id: action.payload.id,
        sorts: action.payload.value,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSearch, (state, action) => {
      tablePageSizeAdapter.removeOne(state, action.payload.id);
    });
    builder.addCase(closeTab, (state, action) => {
      const ids = state.ids.filter((id) => (
        id.toString()
          .startsWith(createTabIdPrefix(action.payload.projectId, action.payload.data.id))));

      tablePageSizeAdapter.removeMany(state, ids);
    });
  },
});

const {
  setPageSize, setSorts,
} = tablePageSizeSlice.actions;

const {
  selectById,
} = tablePageSizeAdapter.getSelectors((state: RootState) => state.sessionReducers.tablePageSize);

type SetPageSize = (pageSize: number) => void;

export const DEFAULT_TABLE_STATE_ID = 'DEFAULT_TABLE_STATE_ID';

export function useTablePageSize(id: string, defaultSize: number): [number, SetPageSize] {
  const dispatch = useAppDispatch();

  const currentState = useAppSelector((state) => selectById(state, id)) ?? {
    id,
    pageSize: defaultSize,
  };

  const setPersistentPageSize = useCallback((pageSize: number) => {
    dispatch(setPageSize({
      id,
      value: pageSize,
    }));
  }, [dispatch, id]);

  const [transientPageSize, setTransientPageSize] = useState(defaultSize);

  return id !== DEFAULT_TABLE_STATE_ID
    ? [currentState.pageSize ?? defaultSize, setPersistentPageSize]
    : [transientPageSize, setTransientPageSize];
}

type SetSort = (sort: SortDescription[]) => void;

export function useTableSorts(
  id: string,
  defaultSort: SortDescription[] | undefined,
): [SortDescription[] | undefined, SetSort] {
  const dispatch = useAppDispatch();

  const currentState = useAppSelector((state) => selectById(state, id)) ?? {
    id,
    sorts: defaultSort,
  };

  const setPersistentPageSize = useCallback((sorts: SortDescription[]) => {
    dispatch(setSorts({
      id,
      value: sorts,
    }));
  }, [dispatch, id]);

  const [transientSort, setTransientSort] = useState(defaultSort);

  return id !== DEFAULT_TABLE_STATE_ID
    ? [currentState.sorts ?? defaultSort, setPersistentPageSize]
    : [transientSort, setTransientSort];
}

export default tablePageSizeSlice.reducer;
