import React from 'react';
import {
  ListGroup, ListGroupItem, ListGroupItemHeading, Progress,
} from 'reactstrap';
import { ImportJobDto } from '../../../app/api';
import {
  DeleteButton, ErrorWrapper, Icon, LoadingSpinner,
} from '../../../components/elements';
import { formatDateString, formatBytes } from '../../../util';

export function ImportQueueView({
  jobs, isLoading, error, OnDeleteJob,
}: {
  jobs: ImportJobDto[];
  isLoading: boolean;
  error: unknown;
  OnDeleteJob: (jobId: string) => void;
}) {
  return (
    <LoadingSpinner isLoading={isLoading}>
      <ErrorWrapper error={error} message={<h4>Failed to load import jobs</h4>}>
        <ListGroup>
          {jobs.map((job) => (
            <ImportJobView
              key={job.id}
              job={job}
              OnDeleteJob={() => OnDeleteJob(job.id)} />
          ))}
        </ListGroup>
      </ErrorWrapper>
    </LoadingSpinner>
  );
}

export function ImportJobView({ job, OnDeleteJob }: {
  job: ImportJobDto;
  OnDeleteJob: () => void;
}) {
  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        <div className="d-flex flex-row ">
          <div className="d-flex flex-row gap-3">
            <span><Icon icon="user" /> {job.uploadedByAccountName}</span>
            <span><Icon icon="harvest" /> {job.uploadedFileName} ({formatBytes(job.uploadedFileSize)})</span>
          </div>
          {job.status === 'Failed' && <DeleteButton onClick={OnDeleteJob} className="text-danger ms-auto" />}
        </div>
      </ListGroupItemHeading>
      <span>
        Uploaded at {formatDateString(job.importedOn)}
      </span>

      <JobProgress job={job} />
      <HarvestProgressMessage job={job} />

    </ListGroupItem>
  );
}

function JobProgress({ job }: {
  job: ImportJobDto
}) {
  if (job.percentComplete === 0 && job.status === 'Queued') {
    return null;
  }

  const precentComplete = job.status === 'InProgress'
    ? job.percentComplete
    : 100;

  return <Progress value={precentComplete} color={getHarvestJobColor(job)} />;
}

function getHarvestJobColor(job: ImportJobDto) {
  switch (job.status) {
    case 'Queued':
      return 'primary';
    case 'InProgress':
      return 'primary';
    case 'Completed':
      return 'success';
    case 'Failed':
      return 'danger';
    default:
      return 'primary';
  }
}

function HarvestProgressMessage({ job }: {
  job: ImportJobDto
}) {
  return job.status === 'Queued'
    ? null
    : (
      <span>
        {getHarvestProgressMessage(job)}
      </span>
    );
}

function getHarvestProgressMessage(job: ImportJobDto) {
  if (job.errorMessage !== '') {
    return `${job.errorMessage} (${formatDateString(job.updatedOn)})`;
  }

  if (job.progressMessage !== '') {
    return `${job.progressMessage} (${formatDateString(job.updatedOn)})`;
  }

  return `${job.status} (${formatDateString(job.updatedOn)})`;
}
