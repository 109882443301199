import { useMemo } from 'react';
import { ProjectCategoryDto, useUserApiListProjectCategoriesQuery } from '../../../../app/api';
import { ProjectCategoryPreference, useProject, useProjectPreferences } from '../../../project';
import { UNCATEGORIZED_CATEGORY, VIEW_CATEGORY } from '../../diagram/util';

export const DEFAULT_CATEGORY_COLOR = '#ffffff';

export type CategoryPreferenceMap = {
  [index: string]: ProjectCategoryPreference & { name: string };
};

export function useLoadedCategoryColors() {
  const { projectId } = useProject();

  const {
    data: categories,
    isLoading: categoriesLoading,
  } = useUserApiListProjectCategoriesQuery({
    projectId,
  });

  const {
    preferences,
    isLoading: preferencesLoading,
  } = useProjectPreferences();

  const colors = useMemo(() => {
    const fullCategories = categories
      ? [...categories, VIEW_CATEGORY, UNCATEGORIZED_CATEGORY]
      : categories;

    return mapCategoryPreferences(fullCategories, preferences?.categories);
  }, [categories, preferences?.categories]);

  return {
    categories: colors,
    isLoading: categoriesLoading || preferencesLoading,
  };
}

export function mapCategoryPreferences(
  categories?: ProjectCategoryDto[],
  preferences?: ProjectCategoryPreference[],
) {
  type PrefMap = { [index: string]: ProjectCategoryPreference; };

  const prefMap = preferences?.reduce((acc: PrefMap, obj) => {
    acc[obj.id] = obj;
    return acc;
  }, {}) ?? {};

  return (categories ?? [])
    .map((c) => ({
      id: c.id,
      name: c.name,
      color: prefMap[c.id]?.color ?? DEFAULT_CATEGORY_COLOR,
      isHidden: prefMap[c.id]?.isHidden ?? false,
    }))
    .reduce((acc: CategoryPreferenceMap, pref) => {
      acc[pref.id] = pref;
      return acc;
    }, {});
}
