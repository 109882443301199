import { User } from 'oidc-client-ts';
import { OrganizationDto } from '../app/api';
import { OrganizationRole } from './OrganizationRole';

export function hasOrgAuth(
  organization: OrganizationDto | undefined,
  role: OrganizationRole | undefined,
  user: User,
) {
  if (!organization) {
    return false;
  }

  if (!role) {
    return true;
  }

  const rawClaims = user.profile['https://www.softekinc.com/claims/application/claim'];

  const claims: SoftekClaim[] = isClaimArray(rawClaims)
    ? rawClaims.map((c: string) => JSON.parse(c))
    : [JSON.parse(rawClaims as string)];

  return claims.findIndex((c) => (
    c.Role === role && c.ClientMnemonic === organization.mnemonic)) > -1;
}

export function isClaimArray(rawClaims: unknown): rawClaims is string[] {
  return Array.isArray(rawClaims);
}

type SoftekClaim = {
  ClientMnemonic: string;
  Feature: string | null;
  Role: string | null;
  Claim: string | null;
  ClientId: string;
};
