import React, { useCallback, useMemo, useRef } from 'react';
import { CellProps, Column, Row } from 'react-table';
import { UncontrolledTooltip } from 'reactstrap';
import { useDrag } from 'react-dnd';
import {
  // eslint-disable-next-line max-len
  getCodeSetCode, getRootEntity, hasMmTableExtensions, MmTableColumnDto, MmExtendedTable, TableColumnDto, TableDto, useTableInfo,
} from '../../../app/api';
import {
  ErrorWrapper, formatBooleanColumn, formatNumberColumn, Icon, LoadingSpinner, Table as SortTable,
} from '../../../components/elements';
import { TableSubDetailProps } from './Tables';
import styles from './TableColumns.module.css';
import { useCodeSetDetails, CodeSetLink } from '../codeSets';
import { ErrorMessage } from '../../../components/elements/form';
import { OverrideCodeSet } from '../codeSets/CodeSetOverride';
import { useModalDialog } from '../../../hooks';
import { useTableDetails } from './hooks';
import { OverrideRootEntity } from './RootEntityOverride';

const initialState = {
  sortBy: [
    { id: 'position', desc: false },
  ],
  pageSize: 500,
};

export function TableColumns({ tableName, parentTabId }: TableSubDetailProps) {
  const { table: data, isLoading, error } = useTableInfo(tableName);

  const {
    isOpen, closeDialog, openDialog, data: column,
  } = useModalDialog<MmTableColumnDto>();

  const {
    isOpen: isRootEntityOpen,
    closeDialog: closeRootEntityDialog,
    openDialog: openRootEntityDialog,
    data: rootEntityColumn,
  } = useModalDialog<MmTableColumnDto>();

  const stateScopeId = `${parentTabId}-columns`;

  return (
    <div className="m-2 flex-grow-1 d-flex flex-column">
      <LoadingSpinner isLoading={isLoading} centered>
        <ErrorWrapper
          error={error}
          message={(
            <>
              <ErrorMessage statusCode={404}>
                <h4>Table {tableName} doesn&apos;t exist in the current harvest</h4>
              </ErrorMessage>
              <ErrorMessage>
                <h4>Unable to load details for table {tableName}</h4>
              </ErrorMessage>
            </>
          )}
          className="m-4">
          {hasMmTableExtensions(data)
            ? (
              <>
                <OverrideCodeSet
                  column={column}
                  table={data}
                  onClose={closeDialog}
                  isOpen={isOpen} />
                <OverrideRootEntity
                  column={rootEntityColumn}
                  table={data}
                  onClose={closeRootEntityDialog}
                  isOpen={isRootEntityOpen} />
                <MmTableColumnList
                  data={data}
                  stateScopeId={stateScopeId}
                  onEditCodeSet={openDialog}
                  onEditRootEntity={openRootEntityDialog} />
              </>
            )
            : (
              <TableColumnList
                data={data}
                stateScopeId={stateScopeId} />
            )}
        </ErrorWrapper>
      </LoadingSpinner>
    </div>
  );
}

function sortByCodeSet(a: Row<MmTableColumnDto>, b: Row<MmTableColumnDto>) {
  return (getCodeSetCode(a.original) ?? 0) - (getCodeSetCode(b.original) ?? 0);
}

function formatRootEntity(column: Pick<MmTableColumnDto, 'rootEntityAttr' | 'rootEntityName'>) {
  return column.rootEntityName && column.rootEntityAttr
    ? `${column.rootEntityName}.${column.rootEntityAttr}`
    : '';
}

function MmTableColumnList({
  data, stateScopeId, onEditCodeSet, onEditRootEntity,
}: {
  data: MmExtendedTable;
  stateScopeId: string;
  onEditCodeSet: (column: MmTableColumnDto) => void;
  onEditRootEntity: (column: MmTableColumnDto) => void;
}) {
  const openCodeSetDetails = useCodeSetDetails();
  const codeSetLink = useCallback((column: MmTableColumnDto) => {
    return (
      <>
        <CodeSetLink code={getCodeSetCode(column)} onOpenCodeSet={openCodeSetDetails} />
        {' '}
        <EditCodeSetLink onClick={() => onEditCodeSet(column)} />
      </>
    );
  }, [onEditCodeSet, openCodeSetDetails]);

  const rootEntity = useCallback((column: MmTableColumnDto) => (
    <>
      <RootEntityName column={column} />
      {' '}
      <EditRootEntityLink onClick={() => onEditRootEntity(column)} />
    </>
  ), [onEditRootEntity]);

  const columns: Column<MmTableColumnDto>[] = useMemo(
    () => [
      {
        Header: 'Position',
        accessor: 'position',
        Cell: ({ value }) => (
          formatNumberColumn(value)
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Data Type',
        accessor: 'dataType',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Definition',
        accessor: 'definition',
      },
      {
        Header: 'Comments',
        accessor: 'comments',
      },
      {
        Header: 'Is Nullable',
        accessor: 'isNullable',
        Cell: ({ value }) => (
          formatBooleanColumn(value)
        ),
        sortType: 'basic',
      },
      {
        Header: 'Code Set',
        accessor: 'mmCodeSet',
        Cell: (cellProps) => (
          codeSetLink(cellProps.row.original)
        ),
        sortType: sortByCodeSet,
      },
      {
        Header: 'Root Entity',
        accessor: formatRootEntity,
        Cell: (cellProps: React.PropsWithChildren<CellProps<MmTableColumnDto, string>>) => (
          rootEntity(cellProps.row.original)
        ),
      },
      {
        Header: 'Distinct Values',
        accessor: 'numDistinct',
        Cell: ({ value }) => (
          formatNumberColumn(value)
        ),
      },
    ],
    [codeSetLink, rootEntity],
  );

  return (
    <SortTable
      initialState={initialState}
      stateScopeId={stateScopeId}
      data={data?.tableColumns ?? []}
      columns={columns}
      sort
      borderless
      tableClassName={` table-striped table-hover ${styles.columnList}`} />
  );
}

function TableColumnList({ data, stateScopeId }: {
  data: TableDto | undefined;
  stateScopeId: string;
}) {
  const columns: Column<TableColumnDto>[] = useMemo(
    () => [
      {
        Header: 'Position',
        accessor: 'position',
        Cell: ({ value }) => (
          formatNumberColumn(value)
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Data Type',
        accessor: 'dataType',
      },
      {
        Header: 'Comments',
        accessor: 'comments',
      },
      {
        Header: 'Is Nullable',
        accessor: 'isNullable',
        Cell: ({ value }) => (
          formatBooleanColumn(value)
        ),
        sortType: 'basic',
      },
      {
        Header: 'Distinct Values',
        accessor: 'numDistinct',
        Cell: ({ value }) => (
          formatNumberColumn(value)
        ),
      },
    ],
    [],
  );

  return (
    <SortTable
      initialState={initialState}
      stateScopeId={stateScopeId}
      data={data?.tableColumns ?? []}
      columns={columns}
      sort
      borderless
      tableClassName={` table-striped table-hover ${styles.columnList}`} />
  );
}

function EditCodeSetLink({ onClick }:
  {
    onClick: () => void;
  }) {
  const ref = useRef(null);

  return (
    <>
      <span
        ref={ref}
        className="link-primary invisible showOnParentHover cursor-pointer"
        onClick={onClick}>
        <Icon icon="edit" />
      </span>
      <UncontrolledTooltip target={ref}>
        Edit code set mapping
      </UncontrolledTooltip>
    </>
  );
}

function EditRootEntityLink({ onClick }:
  {
    onClick: () => void;
  }) {
  const ref = useRef(null);

  return (
    <>
      <span
        ref={ref}
        className="link-primary invisible showOnParentHover cursor-pointer"
        onClick={onClick}>
        <Icon icon="edit" />
      </span>
      <UncontrolledTooltip target={ref}>
        Edit root entity
      </UncontrolledTooltip>
    </>
  );
}

function RootEntityName({ column }: {
  column: MmTableColumnDto
}) {
  const onTableDetails = useTableDetails();

  const [, drag] = useDrag(() => ({
    type: 'Table',
    item: { schema: 'V500', name: column.rootEntityName },
  }));

  const rootEntity = getRootEntity(column, true);

  return (
    <span
      ref={drag}
      onDoubleClick={() => onTableDetails(`V500.${rootEntity.rootEntityName}`)}
      className="cursor-grab user-select-none">
      {formatRootEntity(rootEntity)}
    </span>
  );
}
