import { useState } from 'react';

export type UsePaginationResult<T> = {
  items: T[],
  allItems: T[],
  currentPage: number,
  pageCount: number,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
  onLastPage: () => void,
  onSetPage: (newPageNumber: number) => void,
};

export function usePagination<T>(pageSize: number, items: T[]): UsePaginationResult<T> {
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(items.length / pageSize);

  return {
    items: items.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize),
    currentPage: pageNumber + 1,
    pageCount,
    allItems: items,
    onNextPage: () => setPageNumber((n) => n + 1),
    onPreviousPage: () => setPageNumber((n) => n - 1),
    onFirstPage: () => setPageNumber(0),
    onLastPage: () => setPageNumber(pageCount - 1),
    onSetPage: (newPageNumber: number) => setPageNumber(newPageNumber - 1),
  };
}
