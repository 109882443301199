import { ApiEndpointQuery } from '@reduxjs/toolkit/dist/query/core/module';
import {
  BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import { MutationLifecycleApi, TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import {
  ApiTag,
  taggedApi,
} from './api-tagged';

type ApiEndpoints = typeof taggedApi.endpoints;
/* eslint-disable @typescript-eslint/no-explicit-any */
type ApiQueryName = {
  [K in keyof ApiEndpoints]:
  ApiEndpoints[K] extends ApiEndpointQuery<QueryDefinition<any, any, any, any>, any>
  ? K
  : never;
}[keyof ApiEndpoints];

type UpdateMap = {
  [K in ApiQueryName]:
  ApiEndpoints[K] extends ApiEndpointQuery<QueryDefinition<infer QueryArg, any, any, any>, any>
  ? ({ originalArgs }: { originalArgs: QueryArg }) =>
    ThunkAction<PatchCollection, any, any, AnyAction>
  : never;
};

type CreateMap<U> = {
  [K in ApiQueryName]:
  ApiEndpoints[K] extends ApiEndpointQuery<QueryDefinition<infer QueryArg, any, any, any>, any>
  ? ({ originalArgs }: { originalArgs: QueryArg }, created: U) =>
    ThunkAction<PatchCollection, any, any, AnyAction>
  : never;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const emptyPatch: PatchCollection = {
  patches: [],
  inversePatches: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  undo: () => { },
};

function alwaysIsApiQueryName(x: string): x is ApiQueryName {
  return true;
}

export async function optimisticUpdate<T>(
  { dispatch, queryFulfilled, getState }: MutationLifecycleApi<
    T, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>, unknown, 'api'>,
  api: typeof taggedApi,
  { invalidatedTags, updateHandlers }: {
    invalidatedTags: TagDescription<ApiTag>[];
    updateHandlers: Partial<UpdateMap>;
  },
) {
  const state = getState();
  const invalidated = api.util.selectInvalidatedBy(state, invalidatedTags);

  const patchResults: PatchCollection[] = invalidated.map((i) => {
    if (alwaysIsApiQueryName(i.endpointName)) {
      const updateHandler = updateHandlers[i.endpointName];
      if (updateHandler) {
        return dispatch(updateHandler(i));
      }
    }
    // eslint-disable-next-line no-console
    console.warn(`No optimistic update defined for ${i.endpointName}`);
    return emptyPatch;
  });

  try {
    await queryFulfilled;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error applying optomistic update', error);
    patchResults.forEach((p) => p.undo());
  }

  dispatch(api.util.invalidateTags(invalidatedTags));
}

export async function pessimisticCreate<T, U>(
  { dispatch, queryFulfilled, getState }: MutationLifecycleApi<
    T, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>, U, 'api'>,
  api: typeof taggedApi,
  { invalidatedTags, updateHandlers }: {
    invalidatedTags: { type: ApiTag; id: string; }[];
    updateHandlers: Partial<CreateMap<U>>;
  },
) {
  const state = getState();
  const invalidated = api.util.selectInvalidatedBy(state, invalidatedTags);

  const patchResults: PatchCollection[] = [];

  try {
    const result = await queryFulfilled;

    patchResults.concat(
      invalidated.map((i) => {
        if (alwaysIsApiQueryName(i.endpointName)) {
          const updateHandler = updateHandlers[i.endpointName];
          if (updateHandler) {
            return dispatch(updateHandler(i, result.data));
          }
        }
        // eslint-disable-next-line no-console
        console.warn(`No pessimistic create defined for ${i.endpointName}`);
        return emptyPatch;
      }),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error applying pessimistic create', error);

    patchResults.forEach((p) => p.undo());
  }

  dispatch(api.util.invalidateTags(invalidatedTags));
}
