import React, { useCallback, useMemo, useRef } from 'react';
import { Column } from 'react-table';
import {
  Button, FormGroup, Input, Label, UncontrolledTooltip,
} from 'reactstrap';
import { MmCodeSetDto, MmCodeSetWithValueCountDto } from '../../../app/api';
import {
  Icon,
  LoadingSpinner, SearchBox, Table as SortTable,
  formatNumberColumn,
} from '../../../components/elements';
import { useSearchBoxValue } from '../../../components/elements/searchBox/searchBoxSlice';
import { useProject } from '../../project';
import styles from './CodeSetList.module.css';
import { useFilteredCodeSets } from './hooks/useFilteredCodeSets';

export function CodeSetList({
  codeSets, isLoading, isEmptyHidden, onCodeSetDetails, onSetHideEmpty, onExecuteSearch,
}: {
  codeSets: MmCodeSetWithValueCountDto[];
  isLoading: boolean;
  isEmptyHidden: boolean;
  onSetHideEmpty: (hidden: boolean) => void;
  onCodeSetDetails: (code: number) => void;
  onExecuteSearch: () => void;
}) {
  const { projectId } = useProject();
  const stateScopeId = `${projectId}-codeSetList`;

  const [filter, setFilter] = useSearchBoxValue(stateScopeId);

  const filteredCodeSets = useFilteredCodeSets(codeSets, filter, isEmptyHidden);

  const codeSetDisplay = useCallback((codeSet: MmCodeSetWithValueCountDto) => {
    return (<CodeSetDisplay codeSet={codeSet} />);
  }, []);

  const columns: Column<MmCodeSetWithValueCountDto>[] = useMemo(
    () => [
      {
        Header: 'Set',
        accessor: 'code',
      },
      {
        Header: 'Display',
        accessor: 'display',
        Cell: (cellProps) => (
          codeSetDisplay(cellProps.row.original)
        ),
      },
      {
        Header: 'Codes',
        accessor: 'valueCount',
        Cell: ({ value }) => (
          formatNumberColumn(value)
        ),
      },
    ],
    [codeSetDisplay],
  );

  const initialState = {
    sortBy: [
      { id: 'code', desc: false },
    ],
  };

  return (
    <div className={`d-flex flex-grow-1 flex-column gap-2 ${styles.codeSetList} `}>
      <div className="d-flex flex-column gap-1">
        <div className="d-flex flex-row gap-1">
          <SearchBox
            placeholder="Find a code set..."
            initialSearch={filter}
            onFilterChange={setFilter} />
          <Button onClick={onExecuteSearch}><Icon icon="openSearch" /></Button>
        </div>

        <FormGroup check>
          <Input
            type="checkbox"
            id="hide-empty-codesets"
            checked={isEmptyHidden}
            onChange={(e) => onSetHideEmpty(e.target.checked)} />
          <Label check for="hide-empty-codesets" className="mb-0 select-none">
            {' Hide empty code sets'}
          </Label>
        </FormGroup>
      </div>

      <LoadingSpinner isLoading={isLoading}>
        <SortTable
          initialState={initialState}
          data={filteredCodeSets}
          columns={columns}
          sort
          borderless
          size="sm"
          tableClassName="summary"
          onRowClick={(x: MmCodeSetDto) => onCodeSetDetails(x.code)}
          stateScopeId={stateScopeId} />
      </LoadingSpinner>
    </div>
  );
}

function CodeSetDisplay({ codeSet }: { codeSet: MmCodeSetWithValueCountDto }) {
  const ref = useRef(null);
  return (
    <>
      <span ref={ref}>{codeSet.display}</span>
      <UncontrolledTooltip target={ref}>{codeSet.display}</UncontrolledTooltip>
    </>
  );
}
