import { useEffect } from 'react';
import { areTabsEqual, Tab } from '../../../project/projectSlice';
import { useQueryParam, useFirstUpdate } from '../../../../hooks';
import { useTab } from '.';

export function useUrlTab() {
  const [tab, setQueryParam] = useQueryParam<Tab>('tab');
  const { setTab, activeTab } = useTab();
  const firstUpdate = useFirstUpdate();

  useEffect(() => {
    if (!!tab && !areTabsEqual(activeTab, tab)) {
      setTab(tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (!!tab && !firstUpdate) {
      setQueryParam(activeTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (!tab) {
      setQueryParam(activeTab, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
