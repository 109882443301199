import React, { ReactNode } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { DiagramCreateDto, useUserApiCreateDiagramMutation } from '../../../../../app/api';
import { useTab } from '../../../tabs';
import { CreateDiagramDialog } from './CreateDiagramDialog';
import { useProject } from '../../../../project';
import { isSerializedError } from '../../../../../util';
import { useToggle } from '../../../../../hooks';

const DEFAULT_DIAGRAM_PREFERENCES: Omit<DiagramCreateDto, 'name'> = {
  columnSortOrder: 'ByColumnId',
  isCategoryVisible: true,
  isCodeSetVisible: true,
  isDataTypeVisible: true,
  isDistinctCountVisible: true,
  isKeyIndicatorVisible: true,
  isLocked: true,
  isPrivate: true,
  isRowCountVisible: true,
  isSchemaNameVisible: true,
  isIndexIndicatorVisible: true,
  isFlagsIndicatorVisible: true,
  isDisabledReferencesVisible: true,
  isInferredReferencesVisible: true,
  isOverriddenReferencesVisible: true,
  hideNullableColumns: false,
};

export function CreateDiagram({ children }: {
  children: (onCreate: () => void) => ReactNode;
}) {
  const { projectId } = useProject();

  const { setTab } = useTab();

  const [createDiagram, { isLoading, error }] = useUserApiCreateDiagramMutation();
  const { value: isCreating, toggleValue: toggleOpen } = useToggle(false);

  const onCreate = async (name: string) => {
    const result = await createDiagram({
      projectId,
      diagramCreateDto: {
        ...DEFAULT_DIAGRAM_PREFERENCES,
        name,
      },
    });

    if ('data' in result) {
      setTab({ id: result.data.id, type: 'diagrams' });
      toggleOpen();
    }
  };

  return (
    <>
      {children(toggleOpen)}
      <CreateDiagramDialog
        isLoading={isLoading}
        isOpen={isCreating}
        onClose={toggleOpen}
        onCreate={onCreate}
        error={TranslateError(error)} />
    </>
  );
}

function TranslateError(error: FetchBaseQueryError | SerializedError | undefined): 'duplicate' | 'unknown' | undefined {
  if (error) {
    if (!isSerializedError(error) && error.status === 409) {
      return 'duplicate';
    }
    return 'unknown';
  }
  return undefined;
}
