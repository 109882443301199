import { useCallback } from 'react';
import { useTab } from '../../tabs';

export function useTableDetails() {
  const { setTab } = useTab();
  const openTableDetails = useCallback((tableName: string) => {
    setTab({ id: tableName, type: 'tables' });
  }, [setTab]);

  return openTableDetails;
}
