import { isEqual } from 'lodash';
import { useState, useCallback, useRef } from 'react';
import { useResizeObservable } from './useResizeObservable';

type Rectangle = Omit<DOMRect, 'toJSON'>;

export function useBoundingRectangle(element: Element | undefined | null) {
  const [, triggerUpdate] = useState<object>({});

  const boundingRectangleRef = useRef<Rectangle>();
  const currentRectangle = getBoundingClientRect(element);
  boundingRectangleRef.current = isEqual(boundingRectangleRef.current, currentRectangle)
    ? boundingRectangleRef.current
    : currentRectangle;

  const onResize = useCallback((entries: ResizeObserverEntry[]) => {
    if (!isEqual(entries[0], boundingRectangleRef.current)) {
      triggerUpdate({});
    }
  }, []);

  useResizeObservable(element, onResize);

  return boundingRectangleRef.current;
}

function getBoundingClientRect(element: Element | undefined | null) {
  if (!element) {
    return undefined;
  }

  const domRect = element.getBoundingClientRect();

  return toRectangle(domRect);
}

function toRectangle(domRect: DOMRect) {
  const {
    top, right, bottom, left, width, height, x, y,
  } = domRect;

  return {
    top, right, bottom, left, width, height, x, y,
  };
}
