import React, { useCallback } from 'react';
import {
  // eslint-disable-next-line max-len
  useUserApiCreateWikiArticleMutation, useUserApiGetWikiArticleByIdentifierQuery, useUserApiListWikiArticleVersionsByIdentifierQuery,
} from '../../../app/api';
import { useProject } from '../../project';
import { useToggle } from '../../../hooks';
import { Article, ArticleHistory, LoadingSpinner } from '../../../components/elements';
import { TableSubDetailProps } from './Tables';
import { useArticleDraft } from './articleDraftSlice';

export function TableWiki({ tableName }: TableSubDetailProps) {
  const { value: showHistory, toggleValue: toggleHistory } = useToggle(false);

  const onToggleHistory = useCallback(() => {
    toggleHistory();
  }, [toggleHistory]);

  return (
    <div className="d-flex flex-grow-1 p-2">
      {!showHistory
        ? (
          <TableArticle
            tableName={tableName}
            onHistory={onToggleHistory} />
        )
        : (
          <TableArticleHistory
            tableName={tableName}
            onExit={onToggleHistory} />
        )}
    </div>
  );
}

function TableArticle({ tableName, onHistory }: {
  tableName: string;
  onHistory: () => void;
}) {
  const { projectId } = useProject();
  const { data, isLoading } = useUserApiGetWikiArticleByIdentifierQuery({
    projectId,
    identifier: tableName,
  });

  const {
    draft, createDraft, updateDraft, closeDraft,
  } = useArticleDraft({
    projectId,
    articleId: tableName,
  });

  const [createArticle, { isLoading: isSaving }] = useUserApiCreateWikiArticleMutation();

  return (
    <LoadingSpinner isLoading={isLoading || isSaving} centered>
      <Article
        isEditing={!!draft}
        title={tableName}
        article={draft?.markdown ?? data?.body}
        onEdit={() => createDraft(data?.body ?? '')}
        onSaveDraft={updateDraft}
        onDiscard={closeDraft}
        onSave={(body) => createArticle({
          projectId,
          wikiArticleCreateDto: {
            identifier: tableName,
            body,
          },
        })}
        onHistory={onHistory} />
    </LoadingSpinner>
  );
}

function TableArticleHistory({ tableName, onExit }: {
  tableName: string;
  onExit: () => void;
}) {
  const { projectId } = useProject();
  const { data, isLoading } = useUserApiListWikiArticleVersionsByIdentifierQuery({
    identifier: tableName,
    projectId,
  });

  return (
    <LoadingSpinner isLoading={isLoading} centered>
      <ArticleHistory
        articleVersions={data}
        title={tableName}
        onExit={onExit} />
    </LoadingSpinner>
  );
}
