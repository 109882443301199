import React from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { HarvestDto } from '../../../../app/api';
import { Icon } from '../../../../components/elements';
import { HarvestDescription } from '../HarvestDescription';

export function DeleteHarvestDialog({
  harvest, isOpen, onClose, onDelete,
}: {
  harvest: HarvestDto | undefined;
  isOpen: boolean;
  onDelete: () => void;
  onClose: () => void;
}) {
  const harvestText = harvest
    ? <HarvestDescription harvest={harvest} />
    // eslint-disable-next-line react/jsx-no-useless-fragment
    : <></>;

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Delete Harvest {harvestText}
      </ModalHeader>
      <ModalBody>
        Are you sure you want to delete the harvest <em>{harvestText}</em>?
        This action cannot be undone.
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onDelete}><Icon icon="trash" /> Delete</Button>{' '}
        <Button color="secondary" onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}
