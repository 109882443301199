import { useMemo } from 'react';
import { OrganizationDto } from '../../../app/api';
import { toObjectMap } from '../../../util';

export function useFavoritedOrganizations<T extends OrganizationDto>(
  organizations: T[],
  favoriteOrganizationIds: string[],
) {
  const decoratedOrganizations = useMemo(() => {
    const favoriteOrganizationsMap = toObjectMap(
      favoriteOrganizationIds,
      (id) => [id, id],
    );

    return organizations.map((o) => {
      return ({
        ...o,
        isFavorite: !!favoriteOrganizationsMap[o.id],
      }
      );
    });
  }, [organizations, favoriteOrganizationIds]);

  return decoratedOrganizations;
}
