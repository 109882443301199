import React, { ReactNode, useEffect, useRef } from 'react';
import { useBoundingRectangle } from '../../../features/database/diagram/useBoundingRectangle';
import { useDebouncedEventHandler } from '../../../hooks';
import { DEFAULT_SCROLL_ID, useScrollPosition } from './scrollSlice';

const SCROLL_BAR_WIDTH = 20;

export function ScrollArea({
  children,
  className,
  id,
}:
  {
    children: ReactNode;
    className?: string;
    id?: string;
  }) {
  const defaultClasses = 'overflow-auto flex-grow-1';
  const classes = className
    ? `${defaultClasses} ${className}`
    : defaultClasses;

  const childRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const rect = useBoundingRectangle(parentRef.current);
  const childRect = useBoundingRectangle(childRef.current);
  const height = rect?.height ?? 0;
  const width = rect?.width ?? 0;
  const scrollsHorizontally = (childRect?.width ?? 0) >= width;
  const scrollsVertically = (childRect?.height ?? 0) >= height;

  const [scrollPosition, setScrollPosition] = useScrollPosition(id ?? DEFAULT_SCROLL_ID);
  const debouncedScroll = useDebouncedEventHandler(setScrollPosition, 100);

  useEffect(() => {
    if (parentRef.current?.scrollTop !== scrollPosition.top
      || parentRef.current.scrollLeft !== scrollPosition.left) {
      parentRef.current?.scroll(scrollPosition);
    }
  }, [scrollPosition]);

  return (
    <div
      className={classes}
      ref={parentRef}
      onScroll={(e) => debouncedScroll({
        left: e.currentTarget.scrollLeft,
        top: e.currentTarget.scrollTop,
      })}>
      <div style={{ height: 0, width: 0 }}>
        <div
          style={{
            height: `${height - (scrollsHorizontally ? SCROLL_BAR_WIDTH : 0)}px`,
            width: `${width - (scrollsVertically ? SCROLL_BAR_WIDTH : 0)}px`,
          }}>
          <div
            ref={childRef}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

ScrollArea.defaultProps = {
  className: undefined,
  id: undefined,
};
