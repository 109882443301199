import React, { ReactNode } from 'react';
import { isFetchBaseQueryError } from '../../../../util';

export function ErrorMessage({ statusCode, error, children }: {
  statusCode?: number;
  error?: unknown;
  children: ReactNode;
}) {
  if (!error || (!!statusCode && isFetchBaseQueryError(error) && error?.status !== statusCode)) {
    return null;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

ErrorMessage.defaultProps = {
  statusCode: undefined,
  error: undefined,
};
