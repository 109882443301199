import React from 'react';
import { Connector, Coordinate, Rectangle } from './Connector';
import { useBoundingRectangle } from './useBoundingRectangle';

export function RefConnector({
  parent, child, drawingOrigin, scale, markerSize,
}: {
  parent: Element | null;
  child: Element | null;
  drawingOrigin: Coordinate;
  scale: number;
  markerSize: number;
}) {
  const parentBox = useBoundingRectangle(parent);
  const childBox = useBoundingRectangle(child);

  return isZeroBox(parentBox) || isZeroBox(childBox)
    ? null
    : (
      <Connector
        parent={parentBox}
        child={childBox}
        drawingOrigin={drawingOrigin}
        scale={scale}
        markerSize={markerSize} />
    );
}

function isZeroBox(rect: Rectangle | undefined) {
  return !rect || (
    rect.height === 0
    && rect.width === 0
  );
}
