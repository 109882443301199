import React from 'react';
import { User } from 'oidc-client-ts';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import { Icon, IconName } from '..';
import { useCurrentUser } from '../../../features/authentication/useCurrentUser';

type MenuAction<T> = {
  canExecute: (target: T, user: User) => boolean;
  action: () => void;
  icon?: IconName;
  title: string;
}

type MenuActionSection<T> = {
  id: string;
  actions: MenuAction<T>[];
}

export type MenuDefinition<T> = MenuActionSection<T>[];

export function Menu<T>({ item, definition }: {
  item: T;
  definition: MenuActionSection<T>[];
}) {
  const user = useCurrentUser();
  const filteredSections = definition
    .map((s) => ({
      ...s,
      actions: s.actions.filter((a) => a.canExecute(item, user)),
    }))
    .filter((s) => s.actions.length > 0);

  return (
    <>
      {filteredSections.length > 0 && (
        <DropdownMenu>
          {
            filteredSections.map((s, i) => (
              <>
                {i > 0 && <DropdownItem divider />}
                <MenuSection s={s} key={s.id} />
              </>
            ))
          }
        </DropdownMenu>
      )}
    </>
  );
}

function MenuSection<T>({ s }: {
  s: MenuActionSection<T>,
}) {
  return (
    <>{
      s.actions.map((a) => <MenuItem key={a.title} a={a} />)
    }
    </>
  );
}

function MenuItem<T>({ a }: {
  a: MenuAction<T>;
}) {
  return (
    <DropdownItem
      onClick={a.action}>
      {a.icon && <Icon icon={a.icon} />} {a.title}
    </DropdownItem>
  );
}
