import React, { ReactNode } from 'react';

export function FooterContainer({ children }: {
  children: ReactNode;
}) {
  return (
    <footer className="d-flex flex-wrap justify-content-between align-items-center p-3 border-top text-muted">
      {children}
    </footer>
  );
}
