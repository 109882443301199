import React, { ReactNode, useEffect, useState } from 'react';
import { Dropdown } from 'reactstrap';
import { useToggle } from '../../../hooks';
import { Location } from '../../../features/database/diagram/Location';
import { ZERO_LOCATION } from '../../../features/database/diagram/DraggableItem';
import { Menu, MenuDefinition, Portal } from '..';

export function ContextMenu<T>({ target, menuDefinition, children }: {
  target: T;
  menuDefinition: MenuDefinition<T>;
  children: (
    onContextMenu: React.MouseEventHandler<Element>,
  ) => ReactNode;
}) {
  const { value: isOpen, setValue: setIsOpen, toggleValue: toggleIsOpen } = useToggle(false);
  const [position, setPosition] = useState<Location>(ZERO_LOCATION);
  const onContextMenu = (e: React.MouseEvent<Element>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
    setPosition({
      x: e.pageX,
      y: e.pageY,
    });
  };

  useEffect(() => {
    const closeMenu = () => setIsOpen(false);
    document.addEventListener('contextmenu', closeMenu, true);
    return () => {
      document.removeEventListener('contextmenu', closeMenu, true);
    };
  }, [setIsOpen]);

  return (
    <>
      {children(
        onContextMenu,
      )}
      {isOpen && (
        <Portal>
          <div style={{
            position: 'fixed',
            top: position?.y,
            left: position?.x,
          }}>
            <Dropdown
              isOpen={isOpen}
              toggle={toggleIsOpen}
              onMouseDown={(e) => e.stopPropagation()}
              onMouseUp={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}>
              <Menu
                item={target}
                definition={menuDefinition} />
            </Dropdown>
          </div>
        </Portal>
      )}
    </>
  );
}
