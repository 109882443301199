import React, { useCallback, useMemo } from 'react';
import { KeyOption, matchSorter } from 'match-sorter';
import { useUserApiListDiagramsQuery, DiagramWithChildrenDto } from '../../../../app/api';
import { useTab } from '../../tabs';
import { withDiagramCreation } from './CreateDiagram';
import { withDiagramDeletion } from './DeleteDiagram';
import { withDiagramRename } from './RenameDiagram';
import { withDiagramLock } from '../withDiagramLock';
import { DiagramList, DiagramListType } from './DiagramList';
import { useDebouncedEventHandler } from '../../../../hooks/useDebouncedEvent';
import { DiagramPreview } from '../DiagramPreview';
import { useProject, useProjectPreferences } from '../../../project';
import { useSearchBoxValue } from '../../../../components/elements/searchBox/searchBoxSlice';
import { useCurrentUser } from '../../../authentication/useCurrentUser';
import { useRecentDiagrams, useDiagramFavorites } from './hooks';
import { insensitiveCompare } from '../../../../util';
import { withDiagramVisibility } from '../withDiagramVisibility';
import { withDiagramEditTags } from './EditDiagramTags';
import { withTransferDiagramOwnership } from './TransferDiagramOwnership';
import { withCopyDiagram } from './CopyDiagram';

type DiagramFilter = (value: DiagramWithChildrenDto, index: number) => boolean;

function Diagrams({
  onCreate, onDelete, onRename, onLock, onUnlock, onHide, onShare, onEditTags, onTransferOwnership,
  onCopy,
}: {
  onCreate: () => void;
  onDelete: (diagram: DiagramWithChildrenDto) => void;
  onRename: (diagram: DiagramWithChildrenDto) => void;
  onLock: (diagram: DiagramWithChildrenDto) => void;
  onUnlock: (diagram: DiagramWithChildrenDto) => void;
  onHide: (diagram: DiagramWithChildrenDto) => void;
  onShare: (diagram: DiagramWithChildrenDto) => void;
  onEditTags: (diagram: DiagramWithChildrenDto) => void;
  onTransferOwnership: (diagram: DiagramWithChildrenDto) => void;
  onCopy: (diagram: DiagramWithChildrenDto) => void;
}) {
  const { setTab } = useTab();

  const { projectId } = useProject();
  const stateScopeId = `${projectId}-diagramList`;

  const [listType, setListType] = useDiagramListType();

  const [filter, setFilter] = useSearchBoxValue(stateScopeId);
  const debouncedFilter = useDebouncedEventHandler(setFilter, 300);
  const { data, isLoading } = useFilteredDiagrams(filter, listType);

  const { favoriteDiagrams, addToFavorites, removeFromFavorites } = useDiagramFavorites();

  const preview = useCallback((id) => (
    <DiagramPreview diagramId={id} />
  ), []);

  return (
    <DiagramList
      diagrams={data ?? []}
      favoriteMap={favoriteDiagrams}
      isLoading={isLoading}
      listType={listType}
      searchString={filter}
      stateScopeId={stateScopeId}
      onCreateDiagram={onCreate}
      onDeleteDiagram={onDelete}
      onFilterChange={debouncedFilter}
      onRenameDiagram={onRename}
      onOpenDiagram={(id) => setTab({ id, type: 'diagrams' })}
      onLockDiagram={onLock}
      onUnlockDiagram={onUnlock}
      onHideDiagram={onHide}
      onShareDiagram={onShare}
      onSetListType={setListType}
      onFavoriteDiagram={addToFavorites}
      onUnFavoriteDiagram={removeFromFavorites}
      onEditTags={onEditTags}
      onTransferOwnership={onTransferOwnership}
      onCopy={onCopy}
      diagramPreview={preview} />
  );
}

const DiagramsWithDeletion = withDiagramDeletion(
  withDiagramRename(
    withDiagramLock(
      withDiagramCreation(
        withDiagramVisibility(
          withDiagramEditTags(
            withTransferDiagramOwnership(
              withCopyDiagram(
                Diagrams,
              ),
            ),
          ),
        ),
      ),
    ),
  ),
);

export { DiagramsWithDeletion as Diagrams };

function useFilteredDiagrams(filter: string, type: DiagramListType) {
  const { projectId } = useProject();

  const sortAlgorithm = useDiagramSortAlgorithm(type);
  const filterDiagrams = useCallback((diagrams: DiagramWithChildrenDto[] | undefined) => {
    if (!diagrams || !filter) {
      return diagrams;
    }

    const tagKeys: KeyOption<DiagramWithChildrenDto>[] = [
      'tags.*.tagName',
      (item) => item.tags.flatMap((t) => t.tagName.replace(/_/g, ' ')),
    ];

    if (filter.startsWith('#')) {
      return matchSorter(
        diagrams,
        filter.substring(1),
        {
          keys: tagKeys,
          baseSort: (a, b) => sortAlgorithm(a.item, b.item),
        },
      );
    }

    return matchSorter(
      diagrams,
      filter,
      {
        keys: [
          'name',
          'ownerFullName',
          ...tagKeys,
        ],
        baseSort: (a, b) => sortAlgorithm(a.item, b.item),
      },
    );
  }, [filter, sortAlgorithm]);

  const user = useCurrentUser();
  const recentDiagrams = useRecentDiagrams();
  const { favoriteDiagrams } = useDiagramFavorites();

  const filterMap: { [K in DiagramListType]: DiagramFilter } = {
    all: () => true,
    my: (d) => d.ownerId === user.profile.sub,
    favorites: (d) => favoriteDiagrams[d.id],
    recent: (d) => !!recentDiagrams[d.id],
  };

  return useUserApiListDiagramsQuery({
    projectId,
  }, {
    selectFromResult: (result) => ({
      ...result,
      data: filterDiagrams(result.data?.filter(filterMap[type]).sort(sortAlgorithm)),
    }),
  });
}

type DiagramSort = (a: DiagramWithChildrenDto, b: DiagramWithChildrenDto) => number;

function useDiagramSortAlgorithm(type: DiagramListType) {
  const alphabetical = (a: DiagramWithChildrenDto, b: DiagramWithChildrenDto) => {
    return insensitiveCompare(a.name, b.name);
  };

  const recentDiagrams = useRecentDiagrams();

  const sortMap: { [K in DiagramListType]: DiagramSort } = useMemo(() => ({
    all: alphabetical,
    favorites: alphabetical,
    my: alphabetical,
    recent: (a, b) => recentDiagrams[a.id].index - recentDiagrams[b.id].index,
  }), [recentDiagrams]);

  return sortMap[type];
}

export type DiagramListPreferences = {
  listType: DiagramListType;
  favoriteDiagrams: string[];
}

export function useDiagramListPreferences() {
  const { preferences, setPreferences } = useProjectPreferences();

  const diagramListPreferences: DiagramListPreferences = useMemo(() => ({
    listType: 'all',
    favoriteDiagrams: [],
    ...preferences.diagramListPreferences,
  }), [preferences]);

  const setDiagramListPreferences = useCallback((newPreferences: DiagramListPreferences) => {
    setPreferences({
      ...preferences,
      diagramListPreferences: newPreferences,
    });
  }, [setPreferences, preferences]);

  return {
    preferences: diagramListPreferences,
    setPreferences: setDiagramListPreferences,
  };
}

type DiagramListTypeTuple = [DiagramListType, (newListType: DiagramListType) => void]

function useDiagramListType(): DiagramListTypeTuple {
  const { preferences, setPreferences } = useDiagramListPreferences();

  const setListType = useCallback((newListType: DiagramListType) => {
    if (newListType === preferences.listType) return;

    setPreferences({
      ...preferences,
      listType: newListType,
    });
  }, [preferences, setPreferences]);

  return [
    preferences.listType,
    setListType,
  ];
}
