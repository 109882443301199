import {
  createEntityAdapter, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { createTabIdPrefix } from '../../../util/createTabIdPrefix';
import { closeTab } from '../../project/projectSlice';

type State = {
  id: string,
  tab: string,
}

const childTabAdapter = createEntityAdapter<State>();

type Action = {
  id: string;
  tab: string;
}

const childTabSlice = createSlice({
  name: 'childTab',
  initialState: childTabAdapter.getInitialState(),
  reducers: {
    setTab: (state, action: PayloadAction<Action>) => {
      childTabAdapter.upsertOne(state, {
        id: action.payload.id,
        tab: action.payload.tab,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeTab, (state, action) => {
      const ids = state.ids.filter((id) => (
        id.toString()
          .startsWith(createTabIdPrefix(action.payload.projectId, action.payload.data.id))));

      childTabAdapter.removeMany(state, ids);
    });
  },
});

const {
  setTab,
} = childTabSlice.actions;

const {
  selectById,
} = childTabAdapter.getSelectors((state: RootState) => state.sessionReducers.childTab);

type SetTab = (tab: string) => void;

export const DEFAULT_SCROLL_ID = 'DEFAULT_TAB_ID';

export function useChildTab(id: string, defaultTab: string): [string, SetTab] {
  const dispatch = useAppDispatch();

  const currentTab = useAppSelector((state) => selectById(state, id)) ?? {
    id,
    tab: defaultTab,
  };

  const setCurrentTab = useCallback((tab: string) => {
    dispatch(setTab({
      id,
      tab,
    }));
  }, [dispatch, id]);

  const [transientTab, setTransientTab] = useState(defaultTab);

  return id !== DEFAULT_SCROLL_ID
    ? [currentTab.tab, setCurrentTab]
    : [transientTab, setTransientTab];
}

export default childTabSlice.reducer;
