import React from 'react';
import { useUserApiListMmCodeSetsQuery } from '../../../app/api';
import { useTab } from '../tabs';
import { useCurrentDatabase } from '../DatabaseContext';
import { CodeSetList } from './CodeSetList';
import { useProjectPreferences } from '../../project';
import { CodeValueSearchDialog } from './codeValueSearch';
import { useModalDialog } from '../../../hooks';

export function CodeSets() {
  const { setTab } = useTab();
  const { databaseId } = useCurrentDatabase();

  const { data, isLoading } = useUserApiListMmCodeSetsQuery({
    harvestId: databaseId,
  });

  const { preferences, setPreferences } = useProjectPreferences();

  const { isOpen, openDialog, closeDialog } = useModalDialog<string>();

  return (
    <>
      <CodeSetList
        codeSets={data ?? []}
        isLoading={isLoading}
        isEmptyHidden={preferences?.hideEmptyCodeSets ?? false}
        onCodeSetDetails={(id) => setTab({ id: `${id}`, type: 'codeSets' })}
        onSetHideEmpty={(value) => (!!preferences
          && setPreferences({ ...preferences, hideEmptyCodeSets: value }))}
        onExecuteSearch={() => openDialog('')} />
      <CodeValueSearchDialog
        isOpen={isOpen}
        onClose={closeDialog}
        onSearch={(filter) => setTab({ id: `${filter}`, type: 'codeSetSearch' })} />
    </>
  );
}
