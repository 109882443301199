import React from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';

export function DiffViewer({
  oldValue, newValue, oldTitle, newTitle, isSplitView,
}: {
  oldValue: string;
  newValue: string;
  oldTitle: string;
  newTitle: string;
  isSplitView: boolean;
}) {
  return (
    <ReactDiffViewer
      oldValue={oldValue}
      newValue={newValue}
      leftTitle={oldTitle}
      rightTitle={newTitle}
      splitView={isSplitView}
      styles={{
        gutter: {
          '&:hover': {
            cursor: 'default',
          },
        },
      }} />
  );
}
