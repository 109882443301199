import React, { ComponentType, forwardRef } from 'react';
import { TableDto } from '../../../app/api';
import { CategoryPreferenceMap } from '../settings';
import { getCategory } from './util';

type BodyCategoryColorable = {
  table: Pick<TableDto, 'projectCategory' | 'isView'> | undefined;
  bodyColor: string;
};

type WithBodyCategoryColorProps = Omit<BodyCategoryColorable, 'bodyColor'> & {
  categoryColors: CategoryPreferenceMap;
};

export const withBodyCategoryColorization = <P extends BodyCategoryColorable>
  (Component: ComponentType<P>) => {
  return forwardRef((props: Omit<P, 'bodyColor'> & WithBodyCategoryColorProps, ref) => {
    const { categoryColors, ...passThroughProps } = props;

    const projectCategory = getCategory(props.table);

    const bodyColor = categoryColors[projectCategory?.id ?? '']?.color ?? '#ffffff';

    const newProps = { ...passThroughProps, bodyColor } as P;
    return (<Component {...newProps} ref={ref} />);
  });
};
