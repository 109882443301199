import React, { ReactNode, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DatabaseRoutes } from '../../database';
import {
  ProjectContextProvider, ProjectContext, ProjectIdContextProvider,
} from '../ProjectContext';
import { ProjectDetails } from '../Project';
import { RequireOrganization } from '../../../auth/RequireOrganization';
import { ErrorWrapper, LoadingSpinner } from '../../../components/elements';

export function ProjectRoutes() {
  return (
    <Routes>
      <Route index element={<Navigate replace to="../../" />} />
      <Route
        path=":projectId/*"
        element={(
          <RequireOrganization role="Explorer User">
            <ProjectIdContextProvider>
              <ProjectContextProvider>
                <RequireProject>
                  <Routes>
                    <Route index element={<ProjectDetails />} />
                    <Route path="database/*" element={<DatabaseRoutes />} />
                  </Routes>
                </RequireProject>
              </ProjectContextProvider>
            </ProjectIdContextProvider>
          </RequireOrganization>
        )} />
    </Routes>
  );
}

function RequireProject({ children }: {
  children: ReactNode;
}) {
  const project = useContext(ProjectContext);

  return (
    <LoadingSpinner isLoading={project?.isLoading ?? true} centered>
      <ErrorWrapper error={project?.error} message="Unable to load requested project.">
        {children}
      </ErrorWrapper>
    </LoadingSpinner>
  );
}
