import React from 'react';
import {
  Button, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { ProjectCreateDto } from '../../../app/api';
import { Icon, LoadingSpinner } from '../../../components/elements';
import { EditProjectInputs } from './EditProject';
import { ErrorAlert, ErrorMessage } from '../../../components/elements/form';

export function EditProjectDialog({
  isLoading, isOpen, error, project, onClose, onSave,
}: {
  isLoading: boolean;
  isOpen: boolean;
  error: unknown | undefined;
  project: ProjectCreateDto;
  onClose: () => void;
  onSave: ({ name, description }: { name: string; description: string; }) => void;
}) {
  const {
    control, handleSubmit, formState: { errors },
  } = useForm<EditProjectInputs>({ disabled: isLoading });

  const submitForm: SubmitHandler<EditProjectInputs> = (formData) => {
    onSave({
      name: formData.projectName,
      description: formData.projectDescription,
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} autoFocus={false}>
      <Form onSubmit={handleSubmit(submitForm)}>
        <ModalHeader toggle={onClose}>Edit Project {project.name}</ModalHeader>
        <ModalBody>
          <ErrorAlert error={error}>
            <ErrorMessage statusCode={409}>Project name is already taken.</ErrorMessage>
            <ErrorMessage statusCode={404}>Project could not be found.</ErrorMessage>
            <ErrorMessage>Unable to rename project</ErrorMessage>
          </ErrorAlert>
          <FormGroup>
            <Label for="projectName">Project Name</Label>
            <Controller
              name="projectName"
              control={control}
              defaultValue={project.name}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  invalid={!!errors.projectName}
                  {...field}
                  type="text"
                  autoFocus />
              )} />
            <FormFeedback>Project name is required</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="projectDescription">Project Description</Label>
            <Controller
              name="projectDescription"
              control={control}
              defaultValue={project.description ?? undefined}
              render={({ field }) => (
                <Input
                  invalid={!!errors.projectDescription}
                  {...field}
                  type="text" />
              )} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading}>
            <LoadingSpinner isLoading={isLoading}><Icon icon="save" /></LoadingSpinner>
            <span> Submit</span>
          </Button>
          <Button color="secondary" onClick={onClose} disabled={isLoading}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
