import React, { useEffect } from 'react';
import { LoadingPage } from '../components/elements';
import userManager from '../features/authentication/userManager';

export function Logout() {
  useEffect(() => {
    sessionStorage.clear();

    userManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token') ?? undefined,
    });
  }, []);

  return (<LoadingPage message="Processing logout request..." />);
}
