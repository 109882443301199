import React from 'react';
import { ListGroupItem } from 'reactstrap';

export function TextProperty({ name, value }: {
  name: string;
  value: string | undefined | null;
}) {
  return (
    <ListGroupItem className="d-flex gap-2 flex-row">
      <strong>{name}:</strong>
      <span>{value}</span>
    </ListGroupItem>
  );
}
