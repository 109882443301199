import { addAccountApiExtensions } from './api-account';
import { addActivityLogApiExtensions } from './api-activityLog';
import { addCodeSetOverrideExtensions } from './api-codeSetOverrides';
import { addDiagramApiExtensions } from './api-diagrams';
import { addHarvestApiExtensions } from './api-harvests';
import { addOrganizationApiExtensions } from './api-organization';
import { addProjectApiExtensions } from './api-project';
import { addRootEntityOverrideExtensions } from './api-rootEntityOverrides';
import { taggedApi } from './api-tagged';
import { addWikiApiExtension } from './api-wiki';

export * from './api-generated';
export * from './util';
export * from './hooks';

const extensions = [
  addOrganizationApiExtensions,
  addProjectApiExtensions,
  addHarvestApiExtensions,
  addDiagramApiExtensions,
  addWikiApiExtension,
  addRootEntityOverrideExtensions,
  addCodeSetOverrideExtensions,
  addAccountApiExtensions,
  addActivityLogApiExtensions,
];

export const api = extensions.reduce(
  (prev, curr) => curr(prev),
  taggedApi,
);

export function isSuccessResponse<T>(response: unknown): response is { data: T } {
  return !!response && typeof response === 'object' && 'data' in response;
}

export { rtkQueryErrorLogger } from './rtkQueryErrorLogger';
export { rtkQueryUnauthorizedHandler } from './rtkQueryUnauthorizedHandler';
