import {
  createEntityAdapter, createSlice, EntityState, PayloadAction,
} from '@reduxjs/toolkit';
import { useMemo, useCallback } from 'react';
import {
  createMigrate, PersistedState, persistReducer, PersistState,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { ScrollPosition } from '../../../../components/elements/scrollArea/scrollSlice';
import { objectMap } from '../../../../util';
import { DiagramBounds } from '../hooks';

export type SelectionMap = { [s: string]: boolean };

type DiagramState = {
  id: string;
  scale?: number;
  selectedObjects?: SelectionMap;
  scrollPosition?: ScrollPosition;
  bounds?: DiagramBounds;
}

const diagramsAdapter = createEntityAdapter<DiagramState>();

type DiagramAction<T> = {
  diagramId: string;
  data: T;
}

export const diagramSlice = createSlice({
  name: 'diagram',
  initialState: diagramsAdapter.getInitialState(),
  reducers: {
    setScale: (state, action: PayloadAction<DiagramAction<number>>) => {
      diagramsAdapter.upsertOne(state, {
        id: action.payload.diagramId,
        scale: action.payload.data,
      });
    },
    setSelectedObjects: (state, action: PayloadAction<DiagramAction<string[]>>) => {
      diagramsAdapter.upsertOne(state, {
        id: action.payload.diagramId,
        selectedObjects: action.payload.data.reduce((acc: SelectionMap, curr) => {
          return {
            ...acc,
            [curr]: true,
          };
        }, {}),
      });
    },
    setSelectedObject: (state, action: PayloadAction<DiagramAction<string>>) => {
      diagramsAdapter.upsertOne(state, {
        id: action.payload.diagramId,
        selectedObjects: { [action.payload.data]: true },
      });
    },
    toggleSelection: (state, action: PayloadAction<DiagramAction<string>>) => {
      const selectedObjects = state.entities[action.payload.diagramId]?.selectedObjects ?? {};
      diagramsAdapter.upsertOne(state, {
        id: action.payload.diagramId,
        selectedObjects: {
          ...selectedObjects,
          [action.payload.data]: !selectedObjects[action.payload.data],
        },
      });
    },
    setScrollPosition: (state, action: PayloadAction<DiagramAction<ScrollPosition>>) => {
      diagramsAdapter.upsertOne(state, {
        id: action.payload.diagramId,
        scrollPosition: action.payload.data,
      });
    },
    setBounds: (state, action: PayloadAction<DiagramAction<DiagramBounds>>) => {
      diagramsAdapter.upsertOne(state, {
        id: action.payload.diagramId,
        bounds: action.payload.data,
      });
    },
  },
});

export const {
  setScale,
  setSelectedObject,
  setSelectedObjects,
  setScrollPosition,
  toggleSelection,
  setBounds,
} = diagramSlice.actions;

export const {
  selectById: selectByDiagramId,
} = diagramsAdapter.getSelectors((state: RootState) => state.diagram);

type PersistedDiagramStateV1 = EntityState<DiagramState> & { _persist: PersistState };

type DiagramStateV0 = Omit<DiagramState, 'selectedObjects'> & { selectedTables?: SelectionMap; };
type PersistedDiagramStateV0 = EntityState<DiagramStateV0> & { _persist: PersistState };

type DiagramStateVOrig = Omit<DiagramStateV0, 'selectedTables'> & { selectedTable?: string };
type PersistedDiagramStateVOrig = EntityState<DiagramStateVOrig> & { _persist: PersistState };

export const migrations = {
  0: (state: PersistedState): PersistedDiagramStateV0 | undefined => {
    if (!state) {
      return state;
    }
    const { entities, ...otherState } = state as unknown as PersistedDiagramStateVOrig;

    return {
      ...otherState,
      entities: objectMap(entities, (e) => {
        if (!e) return undefined;
        const { selectedTable, ...otherProps } = e;
        return {
          ...otherProps,
          selectedTables: selectedTable
            ? { [selectedTable]: true }
            : undefined,
        };
      }),
    };
  },
  1: (state: PersistedState): PersistedDiagramStateV1 | undefined => {
    if (!state) {
      return state;
    }
    const { entities, ...otherState } = state as unknown as PersistedDiagramStateV0;

    return {
      ...otherState,
      entities: objectMap(entities, (e) => {
        if (!e) return undefined;
        const { selectedTables, ...otherProps } = e;
        return {
          ...otherProps,
          selectedObjects: selectedTables,
        };
      }),
    };
  },
};

const storageConfig = {
  key: 'diagrams',
  storage,
  version: 0,
  migrate: createMigrate(migrations),
};

export const { reducer } = diagramSlice;
export default persistReducer(storageConfig, reducer);

export function useDiagramState(diagramId: string) {
  const dispatch = useAppDispatch();

  const savedState = useAppSelector((state) => selectByDiagramId(state, diagramId));
  const diagramState = useMemo(() => ({
    scale: 1,
    selectedObjects: {},
    scrollPosition: { left: 0, top: 0 },
    ...savedState,
  }), [savedState]);

  const setZoom = useCallback((newZoom: number) => {
    dispatch(setScale({
      diagramId,
      data: newZoom,
    }));
  }, [dispatch, diagramId]);

  const selectObject = useCallback((newSelection: string, toggle: boolean) => {
    if (toggle) {
      dispatch(toggleSelection({
        diagramId,
        data: newSelection,
      }));
    } else if (!savedState?.selectedObjects
      || !savedState.selectedObjects[newSelection]) {
      dispatch(setSelectedObject({
        diagramId,
        data: newSelection,
      }));
    }
  }, [savedState?.selectedObjects, dispatch, diagramId]);

  const selectObjects = useCallback((newSelection: string[]) => {
    dispatch(setSelectedObjects({
      diagramId,
      data: newSelection,
    }));
  }, [diagramId, dispatch]);

  const setScroll = useCallback((newPosition: ScrollPosition) => {
    dispatch(setScrollPosition({
      diagramId,
      data: newPosition,
    }));
  }, [dispatch, diagramId]);

  const setDiagramBounds = useCallback((newBounds: DiagramBounds) => {
    dispatch(setBounds({
      diagramId,
      data: newBounds,
    }));
  }, [dispatch, diagramId]);

  return {
    diagramState,
    setZoom,
    selectObject,
    selectObjects,
    setScroll,
    setDiagramBounds,
  };
}
