import { emptyApi as api } from "./api-base";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        userApiCreateWikiArticle: build.mutation<
            UserApiCreateWikiArticleApiResponse,
            UserApiCreateWikiArticleApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/article`,
                method: "POST",
                body: queryArg.wikiArticleCreateDto,
            }),
        }),
        userApiGetWikiArticleByIdentifier: build.query<
            UserApiGetWikiArticleByIdentifierApiResponse,
            UserApiGetWikiArticleByIdentifierApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/articles/${queryArg.identifier}`,
            }),
        }),
        userApiListWikiArticlesByProject: build.query<
            UserApiListWikiArticlesByProjectApiResponse,
            UserApiListWikiArticlesByProjectApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/articles`,
            }),
        }),
        userApiListWikiArticleVersionsByIdentifier: build.query<
            UserApiListWikiArticleVersionsByIdentifierApiResponse,
            UserApiListWikiArticleVersionsByIdentifierApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/articles/${queryArg.identifier}/history`,
            }),
        }),
        userApiCreateProject: build.mutation<
            UserApiCreateProjectApiResponse,
            UserApiCreateProjectApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/organizations/${queryArg.organizationId}/projects`,
                method: "POST",
                body: queryArg.projectCreateDto,
            }),
        }),
        userApiListProjects: build.query<
            UserApiListProjectsApiResponse,
            UserApiListProjectsApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/organizations/${queryArg.organizationId}/projects`,
            }),
        }),
        userApiDeleteProject: build.mutation<
            UserApiDeleteProjectApiResponse,
            UserApiDeleteProjectApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/organizations/${queryArg.organizationId}/projects/${queryArg.projectId}`,
                method: "DELETE",
            }),
        }),
        userApiGetProjectById: build.query<
            UserApiGetProjectByIdApiResponse,
            UserApiGetProjectByIdApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/organizations/${queryArg.organizationId}/projects/${queryArg.projectId}`,
            }),
        }),
        userApiUpdateProject: build.mutation<
            UserApiUpdateProjectApiResponse,
            UserApiUpdateProjectApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/organizations/${queryArg.organizationId}/projects/${queryArg.projectId}`,
                method: "PUT",
                body: queryArg.projectCreateDto,
            }),
        }),
        userApiListProjectCategories: build.query<
            UserApiListProjectCategoriesApiResponse,
            UserApiListProjectCategoriesApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/categories`,
            }),
        }),
        userApiUpdateProjectPreferences: build.mutation<
            UserApiUpdateProjectPreferencesApiResponse,
            UserApiUpdateProjectPreferencesApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/preferences`,
                method: "PUT",
                body: queryArg.updateProjectPreferencesRequest,
            }),
        }),
        userApiGetOrganizationById: build.query<
            UserApiGetOrganizationByIdApiResponse,
            UserApiGetOrganizationByIdApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/organizations/${queryArg.organizationId}`,
            }),
        }),
        userApiListOrganizationAccounts: build.query<
            UserApiListOrganizationAccountsApiResponse,
            UserApiListOrganizationAccountsApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/organizations/${queryArg.organizationId}/accounts`,
            }),
        }),
        userApiListOrganizations: build.query<
            UserApiListOrganizationsApiResponse,
            UserApiListOrganizationsApiRequest
        >({
            query: () => ({ url: `/api/user/v1/organizations` }),
        }),
        userApiCreateMmCodeSetOverride: build.mutation<
            UserApiCreateMmCodeSetOverrideApiResponse,
            UserApiCreateMmCodeSetOverrideApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/codeSetOverrides`,
                method: "POST",
                body: queryArg.mmCodeSetOverrideCreateDto,
            }),
        }),
        userApiCreateMmRootEntityOverride: build.mutation<
            UserApiCreateMmRootEntityOverrideApiResponse,
            UserApiCreateMmRootEntityOverrideApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/rootEntityOverrides`,
                method: "POST",
                body: queryArg.mmRootEntityOverrideCreateDto,
            }),
        }),
        userApiDeleteMmCodeSetOverride: build.mutation<
            UserApiDeleteMmCodeSetOverrideApiResponse,
            UserApiDeleteMmCodeSetOverrideApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/codeSetOverrides/${queryArg.codeSetOverrideId}`,
                method: "DELETE",
            }),
        }),
        userApiDeleteMmRootEntityOverride: build.mutation<
            UserApiDeleteMmRootEntityOverrideApiResponse,
            UserApiDeleteMmRootEntityOverrideApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/rootEntityOverrides/${queryArg.rootEntityOverrideId}`,
                method: "DELETE",
            }),
        }),
        userApiGetMmHarvestById: build.query<
            UserApiGetMmHarvestByIdApiResponse,
            UserApiGetMmHarvestByIdApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/mmharvests/${queryArg.harvestId}`,
            }),
        }),
        userApiGetMmTableById: build.query<
            UserApiGetMmTableByIdApiResponse,
            UserApiGetMmTableByIdApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/mmtables/${queryArg.tableId}`,
            }),
        }),
        userApiGetMmTableByName: build.query<
            UserApiGetMmTableByNameApiResponse,
            UserApiGetMmTableByNameApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/harvests/${queryArg.harvestId}/mmtables/${queryArg.schemaName}.${queryArg.tableName}`,
            }),
        }),
        userApiListMmCodeSets: build.query<
            UserApiListMmCodeSetsApiResponse,
            UserApiListMmCodeSetsApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/mmharvests/${queryArg.harvestId}/mmcodesets`,
            }),
        }),
        userApiListMmCodeValuesByCodeSet: build.query<
            UserApiListMmCodeValuesByCodeSetApiResponse,
            UserApiListMmCodeValuesByCodeSetApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/mmcodesets/${queryArg.codeSetId}/mmcodevalues`,
            }),
        }),
        userApiListMmDataModels: build.query<
            UserApiListMmDataModelsApiResponse,
            UserApiListMmDataModelsApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/mmharvests/${queryArg.harvestId}/mmdatamodels`,
            }),
        }),
        userApiListMmHarvests: build.query<
            UserApiListMmHarvestsApiResponse,
            UserApiListMmHarvestsApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/mmharvests`,
            }),
        }),
        userApiListMmTableColumnsByCodeSet: build.query<
            UserApiListMmTableColumnsByCodeSetApiResponse,
            UserApiListMmTableColumnsByCodeSetApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/mmharvests/${queryArg.harvestId}/mmcodesets/${queryArg.codeSetCode}/mmtablecolumns`,
            }),
        }),
        userApiSearchMmCodeValues: build.query<
            UserApiSearchMmCodeValuesApiResponse,
            UserApiSearchMmCodeValuesApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/mmharvests/${queryArg.harvestId}/mmcodevalues`,
                params: { Filter: queryArg.filter },
            }),
        }),
        userApiDeleteImportJob: build.mutation<
            UserApiDeleteImportJobApiResponse,
            UserApiDeleteImportJobApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/import-jobs/${queryArg.importJobId}`,
                method: "DELETE",
            }),
        }),
        userApiListImportJobs: build.query<
            UserApiListImportJobsApiResponse,
            UserApiListImportJobsApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/harvest-import-jobs`,
                params: {
                    IncludeInProgress: queryArg.includeInProgress,
                    IncludeQueued: queryArg.includeQueued,
                    IncludeFailed: queryArg.includeFailed,
                    IncludeCompleted: queryArg.includeCompleted,
                },
            }),
        }),
        userApiDeleteHarvest: build.mutation<
            UserApiDeleteHarvestApiResponse,
            UserApiDeleteHarvestApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/harvests/${queryArg.harvestId}`,
                method: "DELETE",
            }),
        }),
        userApiGetHarvestById: build.query<
            UserApiGetHarvestByIdApiResponse,
            UserApiGetHarvestByIdApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/harvests/${queryArg.harvestId}`,
            }),
        }),
        userApiGetTableById: build.query<
            UserApiGetTableByIdApiResponse,
            UserApiGetTableByIdApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/tables/${queryArg.tableId}`,
            }),
        }),
        userApiGetTableByName: build.query<
            UserApiGetTableByNameApiResponse,
            UserApiGetTableByNameApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/harvests/${queryArg.harvestId}/tables/${queryArg.schemaName}.${queryArg.tableName}`,
            }),
        }),
        userApiGetViewById: build.query<
            UserApiGetViewByIdApiResponse,
            UserApiGetViewByIdApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/views/${queryArg.viewId}`,
            }),
        }),
        userApiGetViewByName: build.query<
            UserApiGetViewByNameApiResponse,
            UserApiGetViewByNameApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/harvests/${queryArg.harvestId}/views/${queryArg.schemaName}.${queryArg.viewName}`,
            }),
        }),
        userApiListHarvests: build.query<
            UserApiListHarvestsApiResponse,
            UserApiListHarvestsApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/harvests`,
            }),
        }),
        userApiListHarvestTables: build.query<
            UserApiListHarvestTablesApiResponse,
            UserApiListHarvestTablesApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/harvests/${queryArg.harvestId}/tables`,
            }),
        }),
        userApiListSchemas: build.query<
            UserApiListSchemasApiResponse,
            UserApiListSchemasApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/harvests/${queryArg.harvestId}/schemas`,
            }),
        }),
        userApiListTableChildConstraintsByTableId: build.query<
            UserApiListTableChildConstraintsByTableIdApiResponse,
            UserApiListTableChildConstraintsByTableIdApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/tables/${queryArg.tableId}/child-constraints`,
            }),
        }),
        userApiListTableChildConstraintsByTableName: build.query<
            UserApiListTableChildConstraintsByTableNameApiResponse,
            UserApiListTableChildConstraintsByTableNameApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/harvests/${queryArg.harvestId}/tables/${queryArg.schemaName}.${queryArg.tableName}/child-constraints`,
            }),
        }),
        userApiListTables: build.query<
            UserApiListTablesApiResponse,
            UserApiListTablesApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/schemas/${queryArg.schemaId}/tables`,
            }),
        }),
        userApiSearchColumns: build.query<
            UserApiSearchColumnsApiResponse,
            UserApiSearchColumnsApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/harvests/${queryArg.harvestId}/columns`,
                params: { Filter: queryArg.filter },
            }),
        }),
        userApiCreateDiagram: build.mutation<
            UserApiCreateDiagramApiResponse,
            UserApiCreateDiagramApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/diagrams`,
                method: "POST",
                body: queryArg.diagramCreateDto,
            }),
        }),
        userApiListDiagrams: build.query<
            UserApiListDiagramsApiResponse,
            UserApiListDiagramsApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/diagrams`,
            }),
        }),
        userApiCreateDiagramRectangle: build.mutation<
            UserApiCreateDiagramRectangleApiResponse,
            UserApiCreateDiagramRectangleApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/diagrams/${queryArg.diagramId}/rectangles`,
                method: "POST",
                body: queryArg.diagramRectangleCreateDto,
            }),
        }),
        userApiCreateDiagramTable: build.mutation<
            UserApiCreateDiagramTableApiResponse,
            UserApiCreateDiagramTableApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/diagrams/${queryArg.diagramId}/tables`,
                method: "POST",
                body: queryArg.diagramTableCreateDto,
            }),
        }),
        userApiListDiagramTables: build.query<
            UserApiListDiagramTablesApiResponse,
            UserApiListDiagramTablesApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/diagrams/${queryArg.diagramId}/tables`,
            }),
        }),
        userApiCreateDiagramTag: build.mutation<
            UserApiCreateDiagramTagApiResponse,
            UserApiCreateDiagramTagApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/diagrams/${queryArg.diagramId}/tags`,
                method: "POST",
                body: queryArg.diagramTagCreateDto,
            }),
        }),
        userApiDeleteDiagram: build.mutation<
            UserApiDeleteDiagramApiResponse,
            UserApiDeleteDiagramApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/diagrams/${queryArg.diagramId}`,
                method: "DELETE",
            }),
        }),
        userApiGetDiagramById: build.query<
            UserApiGetDiagramByIdApiResponse,
            UserApiGetDiagramByIdApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/diagrams/${queryArg.diagramId}`,
            }),
        }),
        userApiUpdateDiagram: build.mutation<
            UserApiUpdateDiagramApiResponse,
            UserApiUpdateDiagramApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/diagrams/${queryArg.diagramId}`,
                method: "PUT",
                body: queryArg.diagramCreateDto,
            }),
        }),
        userApiDeleteDiagramRectangle: build.mutation<
            UserApiDeleteDiagramRectangleApiResponse,
            UserApiDeleteDiagramRectangleApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/diagrams/${queryArg.diagramId}/rectangles/${queryArg.diagramRectangleId}`,
                method: "DELETE",
            }),
        }),
        userApiUpdateDiagramRectangle: build.mutation<
            UserApiUpdateDiagramRectangleApiResponse,
            UserApiUpdateDiagramRectangleApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/diagrams/${queryArg.diagramId}/rectangles/${queryArg.diagramRectangleId}`,
                method: "PUT",
                body: queryArg.diagramRectangleUpdateDto,
            }),
        }),
        userApiDeleteDiagramTable: build.mutation<
            UserApiDeleteDiagramTableApiResponse,
            UserApiDeleteDiagramTableApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/diagrams/${queryArg.diagramId}/tables/${queryArg.diagramTableId}`,
                method: "DELETE",
            }),
        }),
        userApiUpdateDiagramTable: build.mutation<
            UserApiUpdateDiagramTableApiResponse,
            UserApiUpdateDiagramTableApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/diagrams/${queryArg.diagramId}/tables/${queryArg.diagramTableId}`,
                method: "PUT",
                body: queryArg.diagramTableUpdateDto,
            }),
        }),
        userApiDeleteDiagramTag: build.mutation<
            UserApiDeleteDiagramTagApiResponse,
            UserApiDeleteDiagramTagApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/diagrams/${queryArg.diagramId}/tags/${queryArg.diagramTagId}`,
                method: "DELETE",
            }),
        }),
        userApiUpdateDiagramOwner: build.mutation<
            UserApiUpdateDiagramOwnerApiResponse,
            UserApiUpdateDiagramOwnerApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/diagrams/${queryArg.diagramId}/owner`,
                method: "PUT",
                body: queryArg.diagramOwnerUpdateDto,
            }),
        }),
        userApiUpdateDiagramPreferences: build.mutation<
            UserApiUpdateDiagramPreferencesApiResponse,
            UserApiUpdateDiagramPreferencesApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/diagrams/${queryArg.diagramId}/preferences`,
                method: "PUT",
                body: queryArg.diagramPreferencesUpdateDto,
            }),
        }),
        userApiListActivityLogEntriesByProject: build.query<
            UserApiListActivityLogEntriesByProjectApiResponse,
            UserApiListActivityLogEntriesByProjectApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/projects/${queryArg.projectId}/activity-log`,
            }),
        }),
        userApiGetCurrentAccountPreferences: build.query<
            UserApiGetCurrentAccountPreferencesApiResponse,
            UserApiGetCurrentAccountPreferencesApiRequest
        >({
            query: () => ({ url: `/api/user/v1/accounts/current/preferences` }),
        }),
        userApiUpdateCurrentAccountPreferences: build.mutation<
            UserApiUpdateCurrentAccountPreferencesApiResponse,
            UserApiUpdateCurrentAccountPreferencesApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/accounts/current/preferences`,
                method: "PUT",
                body: queryArg.updateCurrentAccountPreferencesRequest,
            }),
        }),
        userApiUpdateCurrentAccount: build.mutation<
            UserApiUpdateCurrentAccountApiResponse,
            UserApiUpdateCurrentAccountApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/accounts/current`,
                method: "PUT",
                body: queryArg.accountCreateDto,
            }),
        }),
        userApiUpdateCurrentAccountDiagramPreferences: build.mutation<
            UserApiUpdateCurrentAccountDiagramPreferencesApiResponse,
            UserApiUpdateCurrentAccountDiagramPreferencesApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/accounts/current/preferences/diagram`,
                method: "PUT",
                body: queryArg.updateCurrentAccountDiagramPreferencesRequest,
            }),
        }),
        userApiUpdateCurrentAccountProjectPreferences: build.mutation<
            UserApiUpdateCurrentAccountProjectPreferencesApiResponse,
            UserApiUpdateCurrentAccountProjectPreferencesApiRequest
        >({
            query: (queryArg) => ({
                url: `/api/user/v1/accounts/current/preferences/project`,
                method: "PUT",
                body: queryArg.updateCurrentAccountProjectPreferencesRequest,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as api };
export type UserApiCreateWikiArticleApiResponse =
    /** status 200  */ WikiArticleDto;
export type UserApiCreateWikiArticleApiRequest = {
    projectId: string;
    wikiArticleCreateDto: WikiArticleCreateDto;
};
export type UserApiGetWikiArticleByIdentifierApiResponse =
    /** status 200  */ WikiArticleDto;
export type UserApiGetWikiArticleByIdentifierApiRequest = {
    projectId: string;
    identifier: string;
};
export type UserApiListWikiArticlesByProjectApiResponse =
    /** status 200  */ WikiArticleDto[];
export type UserApiListWikiArticlesByProjectApiRequest = {
    projectId: string;
};
export type UserApiListWikiArticleVersionsByIdentifierApiResponse =
    /** status 200  */ WikiArticleDto[];
export type UserApiListWikiArticleVersionsByIdentifierApiRequest = {
    projectId: string;
    identifier: string;
};
export type UserApiCreateProjectApiResponse =
    /** status 200  */ ProjectWithCountsDto;
export type UserApiCreateProjectApiRequest = {
    organizationId: string;
    projectCreateDto: ProjectCreateDto;
};
export type UserApiListProjectsApiResponse =
    /** status 200  */ ProjectWithCountsDto[];
export type UserApiListProjectsApiRequest = {
    organizationId: string;
};
export type UserApiDeleteProjectApiResponse = unknown;
export type UserApiDeleteProjectApiRequest = {
    organizationId: string;
    projectId: string;
};
export type UserApiGetProjectByIdApiResponse =
    /** status 200  */ ProjectWithPreferencesDto;
export type UserApiGetProjectByIdApiRequest = {
    organizationId: string;
    projectId: string;
};
export type UserApiUpdateProjectApiResponse = /** status 200  */ ProjectDto;
export type UserApiUpdateProjectApiRequest = {
    organizationId: string;
    projectId: string;
    projectCreateDto: ProjectCreateDto;
};
export type UserApiListProjectCategoriesApiResponse =
    /** status 200  */ ProjectCategoryDto[];
export type UserApiListProjectCategoriesApiRequest = {
    projectId: string;
};
export type UserApiUpdateProjectPreferencesApiResponse = unknown;
export type UserApiUpdateProjectPreferencesApiRequest = {
    projectId: string;
    updateProjectPreferencesRequest: UpdateProjectPreferencesRequest;
};
export type UserApiGetOrganizationByIdApiResponse =
    /** status 200  */ OrganizationDto;
export type UserApiGetOrganizationByIdApiRequest = {
    organizationId: string;
};
export type UserApiListOrganizationAccountsApiResponse =
    /** status 200  */ AccountDto[];
export type UserApiListOrganizationAccountsApiRequest = {
    organizationId: string;
};
export type UserApiListOrganizationsApiResponse =
    /** status 200  */ OrganizationDto[];
export type UserApiListOrganizationsApiRequest = void;
export type UserApiCreateMmCodeSetOverrideApiResponse =
    /** status 200  */ MmCodeSetOverrideDto;
export type UserApiCreateMmCodeSetOverrideApiRequest = {
    projectId: string;
    mmCodeSetOverrideCreateDto: MmCodeSetOverrideCreateDto;
};
export type UserApiCreateMmRootEntityOverrideApiResponse =
    /** status 200  */ MmRootEntityOverrideDto;
export type UserApiCreateMmRootEntityOverrideApiRequest = {
    projectId: string;
    mmRootEntityOverrideCreateDto: MmRootEntityOverrideCreateDto;
};
export type UserApiDeleteMmCodeSetOverrideApiResponse = unknown;
export type UserApiDeleteMmCodeSetOverrideApiRequest = {
    projectId: string;
    codeSetOverrideId: string;
};
export type UserApiDeleteMmRootEntityOverrideApiResponse = unknown;
export type UserApiDeleteMmRootEntityOverrideApiRequest = {
    projectId: string;
    rootEntityOverrideId: string;
};
export type UserApiGetMmHarvestByIdApiResponse =
    /** status 200  */ MmHarvestDto;
export type UserApiGetMmHarvestByIdApiRequest = {
    harvestId: string;
};
export type UserApiGetMmTableByIdApiResponse = /** status 200  */ MmTableDto;
export type UserApiGetMmTableByIdApiRequest = {
    tableId: string;
};
export type UserApiGetMmTableByNameApiResponse = /** status 200  */ MmTableDto;
export type UserApiGetMmTableByNameApiRequest = {
    harvestId: string;
    schemaName: string;
    tableName: string;
};
export type UserApiListMmCodeSetsApiResponse =
    /** status 200  */ MmCodeSetWithValueCountDto[];
export type UserApiListMmCodeSetsApiRequest = {
    harvestId: string;
};
export type UserApiListMmCodeValuesByCodeSetApiResponse =
    /** status 200  */ MmCodeValueDto[];
export type UserApiListMmCodeValuesByCodeSetApiRequest = {
    codeSetId: string;
};
export type UserApiListMmDataModelsApiResponse =
    /** status 200  */ MmDataModelDto[];
export type UserApiListMmDataModelsApiRequest = {
    harvestId: string;
};
export type UserApiListMmHarvestsApiResponse =
    /** status 200  */ MmHarvestDto[];
export type UserApiListMmHarvestsApiRequest = {
    projectId: string;
};
export type UserApiListMmTableColumnsByCodeSetApiResponse =
    /** status 200  */ MmTableColumnDescriptorDto[];
export type UserApiListMmTableColumnsByCodeSetApiRequest = {
    codeSetCode: number;
    harvestId: string;
};
export type UserApiSearchMmCodeValuesApiResponse =
    /** status 200  */ MmCodeValueDto[];
export type UserApiSearchMmCodeValuesApiRequest = {
    harvestId: string;
    filter?: string;
};
export type UserApiDeleteImportJobApiResponse = unknown;
export type UserApiDeleteImportJobApiRequest = {
    projectId: string;
    importJobId: string;
};
export type UserApiListImportJobsApiResponse =
    /** status 200  */ ImportJobDto[];
export type UserApiListImportJobsApiRequest = {
    projectId: string;
    includeInProgress?: boolean;
    includeQueued?: boolean;
    includeFailed?: boolean;
    includeCompleted?: boolean;
};
export type UserApiDeleteHarvestApiResponse = unknown;
export type UserApiDeleteHarvestApiRequest = {
    projectId: string;
    harvestId: string;
};
export type UserApiGetHarvestByIdApiResponse = /** status 200  */ HarvestDto;
export type UserApiGetHarvestByIdApiRequest = {
    harvestId: string;
};
export type UserApiGetTableByIdApiResponse = /** status 200  */ TableDto;
export type UserApiGetTableByIdApiRequest = {
    tableId: string;
};
export type UserApiGetTableByNameApiResponse = /** status 200  */ TableDto;
export type UserApiGetTableByNameApiRequest = {
    harvestId: string;
    schemaName: string;
    tableName: string;
};
export type UserApiGetViewByIdApiResponse = /** status 200  */ ViewDto;
export type UserApiGetViewByIdApiRequest = {
    viewId: string;
};
export type UserApiGetViewByNameApiResponse = /** status 200  */ ViewDto;
export type UserApiGetViewByNameApiRequest = {
    harvestId: string;
    schemaName: string;
    viewName: string;
};
export type UserApiListHarvestsApiResponse = /** status 200  */ HarvestDto[];
export type UserApiListHarvestsApiRequest = {
    projectId: string;
};
export type UserApiListHarvestTablesApiResponse = /** status 200  */ TableDto[];
export type UserApiListHarvestTablesApiRequest = {
    harvestId: string;
};
export type UserApiListSchemasApiResponse = /** status 200  */ SchemaDto[];
export type UserApiListSchemasApiRequest = {
    harvestId: string;
};
export type UserApiListTableChildConstraintsByTableIdApiResponse =
    /** status 200  */ ReferentialConstraintDto[];
export type UserApiListTableChildConstraintsByTableIdApiRequest = {
    tableId: string;
};
export type UserApiListTableChildConstraintsByTableNameApiResponse =
    /** status 200  */ ReferentialConstraintDto[];
export type UserApiListTableChildConstraintsByTableNameApiRequest = {
    harvestId: string;
    schemaName: string;
    tableName: string;
};
export type UserApiListTablesApiResponse = /** status 200  */ TableSummaryDto[];
export type UserApiListTablesApiRequest = {
    schemaId: string;
};
export type UserApiSearchColumnsApiResponse =
    /** status 200  */ SearchColumnsResponseItem[];
export type UserApiSearchColumnsApiRequest = {
    harvestId: string;
    filter: string;
};
export type UserApiCreateDiagramApiResponse = /** status 200  */ DiagramDto;
export type UserApiCreateDiagramApiRequest = {
    projectId: string;
    diagramCreateDto: DiagramCreateDto;
};
export type UserApiListDiagramsApiResponse =
    /** status 200  */ DiagramWithChildrenDto[];
export type UserApiListDiagramsApiRequest = {
    projectId: string;
};
export type UserApiCreateDiagramRectangleApiResponse =
    /** status 200  */ DiagramRectangleDto;
export type UserApiCreateDiagramRectangleApiRequest = {
    diagramId: string;
    diagramRectangleCreateDto: DiagramRectangleCreateDto;
};
export type UserApiCreateDiagramTableApiResponse =
    /** status 200  */ DiagramTableDto;
export type UserApiCreateDiagramTableApiRequest = {
    diagramId: string;
    diagramTableCreateDto: DiagramTableCreateDto;
};
export type UserApiListDiagramTablesApiResponse =
    /** status 200  */ DiagramTableDto[];
export type UserApiListDiagramTablesApiRequest = {
    diagramId: string;
};
export type UserApiCreateDiagramTagApiResponse =
    /** status 200  */ DiagramTagDto;
export type UserApiCreateDiagramTagApiRequest = {
    diagramId: string;
    diagramTagCreateDto: DiagramTagCreateDto;
};
export type UserApiDeleteDiagramApiResponse = unknown;
export type UserApiDeleteDiagramApiRequest = {
    projectId: string;
    diagramId: string;
};
export type UserApiGetDiagramByIdApiResponse =
    /** status 200  */ DiagramWithChildrenDto;
export type UserApiGetDiagramByIdApiRequest = {
    projectId: string;
    diagramId: string;
};
export type UserApiUpdateDiagramApiResponse = /** status 200  */ DiagramDto;
export type UserApiUpdateDiagramApiRequest = {
    projectId: string;
    diagramId: string;
    diagramCreateDto: DiagramCreateDto;
};
export type UserApiDeleteDiagramRectangleApiResponse = unknown;
export type UserApiDeleteDiagramRectangleApiRequest = {
    diagramId: string;
    diagramRectangleId: string;
};
export type UserApiUpdateDiagramRectangleApiResponse =
    /** status 200  */ DiagramRectangleDto;
export type UserApiUpdateDiagramRectangleApiRequest = {
    diagramId: string;
    diagramRectangleId: string;
    diagramRectangleUpdateDto: DiagramRectangleUpdateDto;
};
export type UserApiDeleteDiagramTableApiResponse = unknown;
export type UserApiDeleteDiagramTableApiRequest = {
    diagramId: string;
    diagramTableId: string;
};
export type UserApiUpdateDiagramTableApiResponse =
    /** status 200  */ DiagramTableDto;
export type UserApiUpdateDiagramTableApiRequest = {
    diagramId: string;
    diagramTableId: string;
    diagramTableUpdateDto: DiagramTableUpdateDto;
};
export type UserApiDeleteDiagramTagApiResponse = unknown;
export type UserApiDeleteDiagramTagApiRequest = {
    diagramId: string;
    diagramTagId: string;
};
export type UserApiUpdateDiagramOwnerApiResponse =
    /** status 200  */ DiagramOwnerDto;
export type UserApiUpdateDiagramOwnerApiRequest = {
    diagramId: string;
    diagramOwnerUpdateDto: DiagramOwnerUpdateDto;
};
export type UserApiUpdateDiagramPreferencesApiResponse = unknown;
export type UserApiUpdateDiagramPreferencesApiRequest = {
    diagramId: string;
    diagramPreferencesUpdateDto: DiagramPreferencesUpdateDto;
};
export type UserApiListActivityLogEntriesByProjectApiResponse =
    /** status 200  */ ActivityLogEntryDto[];
export type UserApiListActivityLogEntriesByProjectApiRequest = {
    projectId: string;
};
export type UserApiGetCurrentAccountPreferencesApiResponse =
    /** status 200  */ GetCurrentAccountPreferencesResponse;
export type UserApiGetCurrentAccountPreferencesApiRequest = void;
export type UserApiUpdateCurrentAccountPreferencesApiResponse = unknown;
export type UserApiUpdateCurrentAccountPreferencesApiRequest = {
    updateCurrentAccountPreferencesRequest: UpdateCurrentAccountPreferencesRequest;
};
export type UserApiUpdateCurrentAccountApiResponse =
    /** status 200  */ AccountDto;
export type UserApiUpdateCurrentAccountApiRequest = {
    accountCreateDto: AccountCreateDto;
};
export type UserApiUpdateCurrentAccountDiagramPreferencesApiResponse = unknown;
export type UserApiUpdateCurrentAccountDiagramPreferencesApiRequest = {
    updateCurrentAccountDiagramPreferencesRequest: UpdateCurrentAccountDiagramPreferencesRequest;
};
export type UserApiUpdateCurrentAccountProjectPreferencesApiResponse = unknown;
export type UserApiUpdateCurrentAccountProjectPreferencesApiRequest = {
    updateCurrentAccountProjectPreferencesRequest: UpdateCurrentAccountProjectPreferencesRequest;
};
export type WikiArticleCreateDto = {
    body: string;
    identifier: string;
};
export type WikiArticleDto = WikiArticleCreateDto & {
    id: string;
    authorId: string;
    authorFullName: string;
    createdOn: string;
};
export type ProjectCreateDto = {
    name: string;
    description: string;
};
export type ProjectDto = ProjectCreateDto & {
    id: string;
    organizationId: string;
};
export type ProjectWithCountsDto = ProjectDto & {
    harvestCount: number;
    diagramCount: number;
};
export type ProjectWithPreferencesDto = ProjectDto & {
    globalPreferences: string;
    instancePreferences: string;
};
export type ProjectCategoryDto = {
    id: string;
    name: string;
};
export type UpdateProjectPreferencesRequest = {
    jsonData: string;
};
export type OrganizationCreateDto = {
    name: string;
    mnemonic: string;
    shortName: string;
};
export type OrganizationDto = OrganizationCreateDto & {
    id: string;
};
export type AccountCreateDto = {
    fullName: string;
    email: string;
    title?: string | null;
    officePhone?: string | null;
    mobilePhone?: string | null;
};
export type AccountDto = AccountCreateDto & {
    id: string;
};
export type MmCodeSetOverrideDto = {
    id: string;
    code?: number | null;
    createdById: string;
    createdBy: string;
    createdOn: string;
    reason: string;
};
export type MmCodeSetOverrideCreateDto = {
    sourceTable: string;
    sourceColumn: string;
    code?: number | null;
    reason: string;
};
export type MmRootEntityOverrideDto = {
    id: string;
    rootEntityName?: string | null;
    rootEntityAttr?: string | null;
    createdById: string;
    createdBy: string;
    createdOn: string;
    reason: string;
};
export type MmRootEntityOverrideCreateDto = {
    sourceTable: string;
    sourceColumn: string;
    rootEntityName?: string | null;
    rootEntityAttr?: string | null;
    reason: string;
};
export type HarvestDto = {
    id: string;
    databaseEngine: string;
    databaseVersion: string;
    databaseInstance: string;
    harvestedBy: string;
    importedOn: string;
    startedOn: string;
    completedOn: string;
    catalogName: string;
    extractionId: string;
    projectId?: string | null;
    organizationId?: string | null;
    tags: string[];
};
export type MmHarvestDto = HarvestDto & {
    clientName: string;
    clientShortName: string;
    clientMnemonic: string;
    domainName: string;
};
export type MmTableReferenceDto = {
    overrideId?: string | null;
    columnName: string;
    tableName: string;
    rootEntityName?: string | null;
    rootEntityAttr?: string | null;
};
export type MmCodeSetDto = {
    id: string;
    code: number;
    display?: string | null;
    displayKey?: string | null;
    description?: string | null;
    definition?: string | null;
    hasExtension: boolean;
    canUsersAddValues: boolean;
    canUsersChangeValues: boolean;
    canUsersDeleteValues: boolean;
    canUsersViewValues: boolean;
    isCdfMeaningUnique: boolean;
    isDisplayKeyUnique: boolean;
    isActiveStatusUnique: boolean;
    isDisplayUnique: boolean;
    isAliasUnique: boolean;
    isDefinitionUnique: boolean;
};
export type MmTableColumnFlagDto = {
    value: number;
    description: string;
    definition: string;
};
export type MmTableColumnDto = {
    id: string;
    name: string;
    position: number;
    dataType: string;
    isNullable: boolean;
    numDistinct?: number | null;
    comments?: string | null;
    isDocumented: boolean;
    sequenceName?: string | null;
    description?: string | null;
    definition?: string | null;
    rootEntityName?: string | null;
    rootEntityAttr?: string | null;
    constantValue?: string | null;
    parentEntityCol?: string | null;
    className?: string | null;
    isFlag?: boolean | null;
    isUniqueIdentifier?: boolean | null;
    isDefiningAttribute?: boolean | null;
    isAbsoluteDate?: boolean | null;
    missingCodeSetCode?: number | null;
    mmCodeSet?: MmCodeSetDto | null;
    rootEntityOverride?: MmRootEntityOverrideDto | null;
    mmCodeSetOverride?: MmCodeSetOverrideDto | null;
    tableColumnFlags: MmTableColumnFlagDto[];
};
export type TableSummaryDto = {
    id: string;
    name: string;
    rowCount?: number | null;
    isView: boolean;
    schemaId: string;
    schemaName: string;
    projectCategory?: ProjectCategoryDto | null;
};
export type MmDataModelDto = {
    id: string;
    name: string;
    tables: TableSummaryDto[];
};
export type MmTableDto = {
    id: string;
    name: string;
    comments?: string | null;
    schemaId: string;
    schemaName: string;
    harvestId: string;
    projectId?: string | null;
    organizationId?: string | null;
    childReferences: MmTableReferenceDto[];
    mmTableColumns: MmTableColumnDto[];
    isDocumented: boolean;
    description?: string | null;
    definition?: string | null;
    isCore?: boolean | null;
    isReference?: boolean | null;
    fullTableName?: string | null;
    tableSuffix?: string | null;
    suffixedTableName?: string | null;
    hasDefaultRow?: boolean | null;
    factoryName?: string | null;
    mmDataModel?: MmDataModelDto | null;
};
export type MmCodeSetWithValueCountDto = MmCodeSetDto & {
    valueCount: number;
};
export type MmCodeValueDto = {
    id: string;
    code: number;
    sequence: number;
    cdfMeaning?: string | null;
    display?: string | null;
    displayKey?: string | null;
    description?: string | null;
    definition?: string | null;
    cki?: string | null;
    conceptCki?: string | null;
    codeSetId: string;
    codeSetCode: number;
    activeTypeCd?: number | null;
    activeInd?: number | null;
    activeDtTm?: string | null;
    inactiveDtTm?: string | null;
    beginEffectiveDtTm?: string | null;
    endEffectiveDtTm?: string | null;
    dataStatusCd?: number | null;
    dataStatusDtTm?: string | null;
    dataStatusPrsnlId?: number | null;
    activeStatusPrsnlId?: number | null;
    displayKeyNls?: string | null;
    displayKeyANls?: string | null;
    lastUtcTs?: string | null;
};
export type MmTableColumnDescriptorDto = {
    id: string;
    name: string;
    tableId: string;
    tableName: string;
    schemaId: string;
    schemaName: string;
    mmCodeSetOverrideId?: string | null;
};
export type ImportJobDto = {
    id: string;
    projectId: string;
    uploadedByAccountId: string;
    uploadedByAccountName: string;
    uploadedFileName: string;
    uploadedFileSize: number;
    importedOn: string;
    startedOn?: string | null;
    completedOn?: string | null;
    updatedOn?: string | null;
    percentComplete: number;
    progressMessage: string;
    errorMessage: string;
    backgroundJobId?: string | null;
    status: string;
};
export type TableColumnDto = {
    id: string;
    name: string;
    position: number;
    dataType: string;
    isNullable: boolean;
    numDistinct?: number | null;
    comments?: string | null;
};
export type TableIndexColumnDto = {
    id: string;
    name: string;
    position: number;
    isDescending: boolean;
    tableColumnId: string;
    tableColumnName: string;
};
export type TableIndexDto = {
    id: string;
    name: string;
    isUnique: boolean;
    tableId: string;
    tableName: string;
    schemaId: string;
    schemaName: string;
    tableIndexColumns: TableIndexColumnDto[];
};
export type ConstraintColumnDto = {
    id: string;
    name: string;
    position: number;
    tableColumn: TableColumnDto;
};
export type ConstraintDto = {
    constraintType: string;
    id: string;
    name: string;
    isEnabled: boolean;
    tableId: string;
    tableName: string;
    tableRowCount: number;
    schemaId: string;
    schemaName: string;
    tableProjectCategory?: ProjectCategoryDto | null;
    constraintColumns: ConstraintColumnDto[];
};
export type UniqueConstraintDto = ConstraintDto & {
    tableIndex?: TableIndexDto | null;
};
export type PrimaryKeyConstraintDto = UniqueConstraintDto & object;
export type CheckConstraintDto = ConstraintDto & {
    searchCondition: string;
};
export type ReferentialConstraintDto = ConstraintDto & {
    parentConstraint: UniqueConstraintDto;
};
export type TableDto = {
    id: string;
    name: string;
    rowCount?: number | null;
    comments?: string | null;
    isView: boolean;
    schemaId: string;
    schemaName: string;
    projectCategory?: ProjectCategoryDto | null;
    harvestId: string;
    projectId?: string | null;
    organizationId?: string | null;
    tableColumns: TableColumnDto[];
    tableIndexes: TableIndexDto[];
    primaryKeyConstraints: PrimaryKeyConstraintDto[];
    uniqueConstraints: UniqueConstraintDto[];
    checkConstraints: CheckConstraintDto[];
    referentialConstraints: ReferentialConstraintDto[];
    otherConstraints: ConstraintDto[];
};
export type ViewDto = TableDto & {
    queryExpression: string;
    queryExpressionIsTruncated: boolean;
};
export type SchemaDto = {
    id: string;
    name: string;
    harvestId: string;
    projectId?: string | null;
    organizationId?: string | null;
};
export type SearchColumnsResponseItem = {
    id: string;
    name: string;
    rowCount?: number | null;
    isView: boolean;
    schemaId: string;
    schemaName: string;
    projectCategory?: ProjectCategoryDto | null;
    tableColumns: TableColumnDto[];
};
export type DiagramCreateDto = {
    name: string;
    isLocked: boolean;
    isPrivate: boolean;
    isCategoryVisible: boolean;
    isCodeSetVisible: boolean;
    isDataTypeVisible: boolean;
    isDistinctCountVisible: boolean;
    isSchemaNameVisible: boolean;
    isKeyIndicatorVisible: boolean;
    isRowCountVisible: boolean;
    isIndexIndicatorVisible: boolean;
    isFlagsIndicatorVisible: boolean;
    isDisabledReferencesVisible: boolean;
    isInferredReferencesVisible: boolean;
    isOverriddenReferencesVisible: boolean;
    hideNullableColumns: boolean;
    columnSortOrder: string;
};
export type DiagramDto = DiagramCreateDto & {
    id: string;
    ownerId: string;
    ownerFullName: string;
    projectId: string;
    organizationId: string;
    tableCount: number;
};
export type DiagramTableCreateDto = {
    tableName: string;
    x: number;
    y: number;
};
export type DiagramTableUpdateDto = DiagramTableCreateDto & {
    order: number;
};
export type DiagramTableDto = DiagramTableUpdateDto & {
    id: string;
};
export type DiagramRectangleCreateDto = {
    content: string;
    x: number;
    y: number;
    width: number;
    height: number;
    borderColor: number;
    backgroundColor: number;
};
export type DiagramRectangleUpdateDto = DiagramRectangleCreateDto & {
    order: number;
};
export type DiagramRectangleDto = DiagramRectangleUpdateDto & {
    id: string;
};
export type DiagramTagCreateDto = {
    tagName: string;
};
export type DiagramTagDto = DiagramTagCreateDto & {
    id: string;
};
export type DiagramWithChildrenDto = DiagramDto & {
    globalPreferences: string;
    instancePreferences: string;
    tables: DiagramTableDto[];
    rectangles: DiagramRectangleDto[];
    tags: DiagramTagDto[];
};
export type DiagramOwnerUpdateDto = {
    ownerId: string;
};
export type DiagramOwnerDto = DiagramOwnerUpdateDto & {
    id: string;
    ownerFullName: string;
};
export type DiagramPreferencesUpdateDto = {
    jsonData: string;
};
export type ActivityLogEntryDto = {
    id: string;
    projectId: string;
    activityOn: string;
    targetType: string;
    actionType: string;
    actorEmail: string;
    actorFullName: string;
    targetRefType: string;
    targetRef: string;
    targetName?: string | null;
    newValue?: string | null;
};
export type GetCurrentAccountPreferencesResponse = {
    preferences: string;
};
export type UpdateCurrentAccountPreferencesRequest = {
    jsonData: string;
};
export type UpdateCurrentAccountDiagramPreferencesRequest = {
    jsonData: string;
};
export type UpdateCurrentAccountProjectPreferencesRequest = {
    jsonData: string;
};
export const {
    useUserApiCreateWikiArticleMutation,
    useUserApiGetWikiArticleByIdentifierQuery,
    useUserApiListWikiArticlesByProjectQuery,
    useUserApiListWikiArticleVersionsByIdentifierQuery,
    useUserApiCreateProjectMutation,
    useUserApiListProjectsQuery,
    useUserApiDeleteProjectMutation,
    useUserApiGetProjectByIdQuery,
    useUserApiUpdateProjectMutation,
    useUserApiListProjectCategoriesQuery,
    useUserApiUpdateProjectPreferencesMutation,
    useUserApiGetOrganizationByIdQuery,
    useUserApiListOrganizationAccountsQuery,
    useUserApiListOrganizationsQuery,
    useUserApiCreateMmCodeSetOverrideMutation,
    useUserApiCreateMmRootEntityOverrideMutation,
    useUserApiDeleteMmCodeSetOverrideMutation,
    useUserApiDeleteMmRootEntityOverrideMutation,
    useUserApiGetMmHarvestByIdQuery,
    useUserApiGetMmTableByIdQuery,
    useUserApiGetMmTableByNameQuery,
    useUserApiListMmCodeSetsQuery,
    useUserApiListMmCodeValuesByCodeSetQuery,
    useUserApiListMmDataModelsQuery,
    useUserApiListMmHarvestsQuery,
    useUserApiListMmTableColumnsByCodeSetQuery,
    useUserApiSearchMmCodeValuesQuery,
    useUserApiDeleteImportJobMutation,
    useUserApiListImportJobsQuery,
    useUserApiDeleteHarvestMutation,
    useUserApiGetHarvestByIdQuery,
    useUserApiGetTableByIdQuery,
    useUserApiGetTableByNameQuery,
    useUserApiGetViewByIdQuery,
    useUserApiGetViewByNameQuery,
    useUserApiListHarvestsQuery,
    useUserApiListHarvestTablesQuery,
    useUserApiListSchemasQuery,
    useUserApiListTableChildConstraintsByTableIdQuery,
    useUserApiListTableChildConstraintsByTableNameQuery,
    useUserApiListTablesQuery,
    useUserApiSearchColumnsQuery,
    useUserApiCreateDiagramMutation,
    useUserApiListDiagramsQuery,
    useUserApiCreateDiagramRectangleMutation,
    useUserApiCreateDiagramTableMutation,
    useUserApiListDiagramTablesQuery,
    useUserApiCreateDiagramTagMutation,
    useUserApiDeleteDiagramMutation,
    useUserApiGetDiagramByIdQuery,
    useUserApiUpdateDiagramMutation,
    useUserApiDeleteDiagramRectangleMutation,
    useUserApiUpdateDiagramRectangleMutation,
    useUserApiDeleteDiagramTableMutation,
    useUserApiUpdateDiagramTableMutation,
    useUserApiDeleteDiagramTagMutation,
    useUserApiUpdateDiagramOwnerMutation,
    useUserApiUpdateDiagramPreferencesMutation,
    useUserApiListActivityLogEntriesByProjectQuery,
    useUserApiGetCurrentAccountPreferencesQuery,
    useUserApiUpdateCurrentAccountPreferencesMutation,
    useUserApiUpdateCurrentAccountMutation,
    useUserApiUpdateCurrentAccountDiagramPreferencesMutation,
    useUserApiUpdateCurrentAccountProjectPreferencesMutation,
} = injectedRtkApi;
