import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { ZERO_LOCATION } from './DraggableItem';
import { Location } from './Location';

type LocationDelta = {
  deltaX: number,
  deltaY: number,
}

type ShiftDragOffsetRequest = {
  id: string,
  value: LocationDelta,
}

type StartDragRequest = {
  id: string,
  initialViewBoxOrigin: Location,
  initialDiagramOrigin: Location,
}

type EndDragRequest = {
  id: string,
}

export type DragOffsetValue = {
  id: string,
  offset: Location,
  isDragging: boolean,
  initialViewBoxOrigin: Location,
  initialDiagramOrigin: Location,
};

const dragOffsetAdapter = createEntityAdapter<DragOffsetValue>();

const dragOffsetSlice = createSlice({
  name: 'dragOffset',
  initialState: dragOffsetAdapter.getInitialState(),
  reducers: {
    startDrag: (state, action: PayloadAction<StartDragRequest>) => {
      dragOffsetAdapter.setOne(state, {
        id: action.payload.id,
        isDragging: true,
        offset: ZERO_LOCATION,
        initialViewBoxOrigin: action.payload.initialViewBoxOrigin,
        initialDiagramOrigin: action.payload.initialDiagramOrigin,
      });
    },
    endDrag: (state, action: PayloadAction<EndDragRequest>) => {
      dragOffsetAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          isDragging: false,
          offset: ZERO_LOCATION,
        },
      });
    },
    shiftOffsetValue: (state, action: PayloadAction<ShiftDragOffsetRequest>) => {
      const draft = state.entities[action.payload.id];
      if (draft) {
        draft.offset = {
          x: draft.offset.x + action.payload.value.deltaX,
          y: draft.offset.y + action.payload.value.deltaY,
        };
      }
    },
  },
});

export const {
  startDrag,
  endDrag,
  shiftOffsetValue,
} = dragOffsetSlice.actions;

export const {
  selectById: selectDragOffset,
} = dragOffsetAdapter.getSelectors((state: RootState) => state.dragOffset);

export default dragOffsetSlice.reducer;

const defaultState = {
  offset: ZERO_LOCATION,
  isDragging: false,
  initialViewBoxOrigin: ZERO_LOCATION,
  initialDiagramOrigin: ZERO_LOCATION,
};

export function useSharedDragOffset(id: string, isSelected: boolean) {
  const dispatch = useAppDispatch();

  const savedState = useAppSelector((state) => {
    return isSelected
      ? selectDragOffset(state, id)
      : defaultState;
  });

  const shiftDelta = useCallback((delta: LocationDelta) => {
    dispatch(shiftOffsetValue({
      id,
      value: delta,
    }));
  }, [dispatch, id]);

  const onStartDrag = useCallback((
    initialViewBoxOrigin: Location,
    initialDiagramOrigin: Location,
  ) => {
    dispatch(startDrag({
      id,
      initialViewBoxOrigin,
      initialDiagramOrigin,
    }));
  }, [dispatch, id]);

  const onEndDrag = useCallback(() => {
    dispatch(endDrag({ id }));
  }, [dispatch, id]);

  return {
    delta: savedState?.offset ?? ZERO_LOCATION,
    isDragging: savedState?.isDragging ?? false,
    initialViewBoxOrigin: savedState?.initialViewBoxOrigin ?? ZERO_LOCATION,
    initialDiagramOrigin: savedState?.initialDiagramOrigin ?? ZERO_LOCATION,
    shiftDelta,
    startDrag: onStartDrag,
    endDrag: onEndDrag,
  };
}
