import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import brain from '../img/Brain2.svg';
import go from '../img/Go.png';
import lightbulb from '../img/Lightbulb.png';

export function Home() {
  return (
    <Container fluid className="p-5">
      <div className="row">

        <div className="card mb-4 shadow col-12">
          <div className="row no-gutters">
            <div className="text-center col-md-4 col-lg-3">
              <img src={lightbulb} width="200" alt="Understand database schemas in record time" />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="card-body">
                <h1 className="card-title text-center text-md-left">
                  Understand database schemas in record time
                </h1>
                <p className="card-text">
                  Quickly design and customize database schema diagrams with Softek&lsquo;s
                  Database Explorer. Our unique Database Explorer was created to help you understand
                  complex database schemas, featuring in-depth views into tables, columns,
                  indexes, and constraints.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-4 shadow col-12">
          <div className="row no-gutters">
            <div className="text-center col-md-4 col-lg-3">
              <img src={brain} width="200" alt="Share your knowledge" style={{ padding: '15px' }} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="card-body">
                <h1 className="card-title text-center text-md-left">Share your knowledge</h1>
                <p className="card-text">
                  Easily share your knowledge and collaborate with others in your organization
                  by creating meta-data that is hosted within the Database Explorer.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-4 shadow col-12">
          <div className="row no-gutters">
            <div className="text-center col-md-4 col-lg-3">
              <img src={go} width="200" alt="Get started now" />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="card-body">
                <h1 className="card-title text-center text-md-left">Get started now</h1>
                <p className="card-text">Getting started is easy!</p>
                <ol>
                  <li>Create an account by <Link to="/login">signing up here</Link>.</li>
                  <li>Request a demo license by <a href="https://www.softekinc.com/contact-us">contacting our sales team here</a>.</li>
                  <li>Collect a database schema.</li>
                  <li>Explore, have fun, and learn!</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Container>
  );
}
