import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RequireOrganization } from '../../../auth/RequireOrganization';
import { ProjectRoutes } from '../../project';
import { OrganizationContextConsumer, OrganizationContextProvider } from '../../organizations';
import { Organization } from '../components/Organization';
import { CreateProject } from '../components/CreateProject';
import { useOrganizationHistory } from '../../organizations/hooks/useOrganizationHistory';

export function OrganizationRoutes() {
  const [, onChangeOrganization] = useOrganizationHistory();

  return (
    <OrganizationContextProvider>
      <RequireOrganization>
        <OrganizationContextConsumer>
          {(org) => {
            onChangeOrganization(org.id);

            return (
              <Routes>
                <Route index element={<Organization />} />
                <Route path="newProject" element={<CreateProject />} />
                <Route path="projects/*" element={<ProjectRoutes />} />
              </Routes>
            );
          }}
        </OrganizationContextConsumer>
      </RequireOrganization>
    </OrganizationContextProvider>
  );
}
