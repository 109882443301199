import { useMemo } from 'react';
import { ProjectWithCountsDto } from '../../../app/api';
import { insensitiveCompare } from '../../../util';

export function useSortedProjects(projects: ProjectWithCountsDto[]) {
  return useMemo(() => {
    return [...projects].sort((a, b) => {
      return insensitiveCompare(a.name, b.name);
    });
  }, [projects]);
}
