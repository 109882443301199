import React, { ComponentType, useState } from 'react';
import { DiagramWithChildrenDto, useUserApiUpdateDiagramMutation } from '../../../../../app/api';
import { useProject } from '../../../../project';
import { RenameDiagramDialog } from './RenameDiagramDialog';

type DiagramRenamable = {
  onRename: (diagram: DiagramWithChildrenDto) => void;
};

type withDiagramRenameProps = Omit<DiagramRenamable, 'onRename'>;

export const withDiagramRename = <P extends DiagramRenamable>(Component: ComponentType<P>) => {
  return function (props: Omit<P, 'onRename'> & withDiagramRenameProps) {
    const { projectId } = useProject();

    const [modal, setModal] = useState(false);
    const [diagram, setDiagram] = useState<DiagramWithChildrenDto>();
    const [renameDiagram, { isLoading }] = useUserApiUpdateDiagramMutation();

    const executeRename = async (name: string) => {
      if (diagram) {
        const result = await renameDiagram({
          projectId,
          diagramId: diagram.id,
          diagramCreateDto: {
            ...diagram,
            name,
          },
        });
        if ('data' in result) {
          setModal(false);
        }
      }
    };

    return (
      <>
        <RenameDiagramDialog
          diagramName={diagram?.name ?? ''}
          isLoading={isLoading}
          isOpen={modal}
          onRename={executeRename}
          onClose={() => setModal(false)} />
        <Component
          {...props as P}
          onRename={(d) => {
            setDiagram(d);
            setModal(true);
          }} />
      </>
    );
  };
};
