import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { RootState } from '../../app/store';

export type NotificationType = 'error' | 'warn' | 'info' | 'success';

export type Notification = {
  id: string;
  type: NotificationType,
  title: string;
  body?: string;
  error?: unknown;
};

type NotificationState = {
  notifications: Notification[]
}

const initialState: NotificationState = {
  notifications: [],
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Omit<Notification, 'id'>>) => {
      state.notifications.push({
        id: uuid(),
        ...action.payload,
      });
    },
    dismissNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter((n) => n.id !== action.payload);
    },
  },
});

export const { addNotification, dismissNotification } = notificationSlice.actions;
export const selectNotifications = (state: RootState) => state.notifications.notifications;

export default notificationSlice.reducer;
