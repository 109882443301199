import { useCallback, useState } from 'react';
import { useToggle } from './useToggle';

export function useModalDialog<T>() {
  const { value: isOpen, setValue: setOpen } = useToggle(false);
  const [data, setData] = useState<T | undefined>(undefined);

  const openDialog = useCallback((newData: T) => {
    setOpen(true);
    setData(newData);
  }, [setOpen]);

  const closeDialog = useCallback(() => {
    setOpen(false);
    setData(undefined);
  }, [setOpen]);

  return {
    isOpen,
    data,
    openDialog,
    closeDialog,
  };
}
