import {
  useMemo, useRef, useState, useCallback, useEffect,
} from 'react';
import { isEqual } from 'lodash';
import { useStore } from 'react-redux';
import { useResizeObservable } from '../useResizeObservable';
import { useDebouncedEventHandler } from '../../../../hooks';
import { DiagramObjectBounds, RefMap } from '../DiagramViewer';
import { Location } from '../Location';
import { DiagramWithChildrenDto } from '../../../../app/api';
import { selectDragOffset } from '../dragOffsetSlice';
import { RootState } from '../../../../app/store';
import { ZERO_LOCATION } from '../DraggableItem';
import { calculateOffset } from '../util';
import { SelectionMap } from '../diagramSlice';

export function useDiagramAutoSize(
  zoom: number,
  objectRefs: React.MutableRefObject<RefMap>,
  diagram: DiagramWithChildrenDto | undefined,
  origin: Location,
  defaultBounds: DiagramBounds,
  selected: SelectionMap,
) {
  const store = useStore();

  const refSignature = Object.keys(objectRefs.current).join();
  const refs = useMemo(() => {
    return Object.values(objectRefs.current)
      .map((r) => r.element)
      .filter((r) => r !== null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refSignature]);

  const originRef = useRef(origin);
  originRef.current = origin;

  const [diagramBounds, setDiagramBounds] = useState(
    scaleBounds(defaultBounds, zoom),
  );

  const currentBounds = useRef(diagramBounds);
  currentBounds.current = diagramBounds;

  const currentZoom = useRef(zoom);
  currentZoom.current = zoom;

  const currentRefs = useRef(objectRefs);
  currentRefs.current = objectRefs;

  const recalculateSize = useCallback(() => {
    const rects = Object.values(currentRefs.current.current)
      .filter((r) => r.element !== null);

    if (rects.length > 0) {
      setDiagramBounds((current) => {
        const offsetData = selectDragOffset(store.getState() as RootState, 'diagram');
        const offset = offsetData?.isDragging
          ? {
            x: offsetData.offset.x
              - calculateOffset(
                originRef.current.x,
                offsetData.initialDiagramOrigin.x,
                currentZoom.current,
              )
              + calculateOffset(
                current.origin.x,
                offsetData.initialViewBoxOrigin.x,
                currentZoom.current,
              ),
            y: offsetData.offset.y
              - calculateOffset(
                originRef.current.y,
                offsetData.initialDiagramOrigin.y,
                currentZoom.current,
              )
              + calculateOffset(
                current.origin.y,
                offsetData.initialViewBoxOrigin.y,
                currentZoom.current,
              ),
          }
          : ZERO_LOCATION;
        const offsetBounds = rects
          .map((r) => (
            !r.bounds
              ? undefined
              : {
                ...r.bounds,
                x: selected[r.id]
                  ? r.bounds.x + offset.x
                  : r.bounds.x,
                y: selected[r.id]
                  ? r.bounds.y + offset.y
                  : r.bounds.y,
              }))
          .filter((r) => r) as DiagramObjectBounds[];

        const newMax = {
          top: Math.min(...(offsetBounds.map((r) => r.y))),
          left: Math.min(...(offsetBounds.map((r) => r.x))),
          right: Math.max(...(offsetBounds.map((r) => r.x + r.width))),
          bottom: Math.max(...(offsetBounds.map((r) => r.y + r.height))),
        };

        const newBounds = calculateDiagramBounds(newMax, currentZoom.current);

        return isEqual(current, newBounds)
          ? current
          : newBounds;
      });
    } else {
      setDiagramBounds((current) => {
        const newBounds = scaleBounds(defaultBounds, currentZoom.current);
        return isEqual(current, newBounds)
          ? current
          : newBounds;
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refSignature, selected, defaultBounds, store]);

  const debouncedTriggerResize = useDebouncedEventHandler(recalculateSize, 25);

  useEffect(() => {
    debouncedTriggerResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom, diagram]);

  useResizeObservable(refs as SVGGElement[], debouncedTriggerResize);

  return {
    diagramBounds,
    triggerResize: debouncedTriggerResize,
  };
}

export const PAGE_SIZE = 1000;
const MARGIN = 100;
const MAX_PAGES = 8;
const MAX_SIZE = PAGE_SIZE * MAX_PAGES;

export const DEFAULT_DOCUMENT: DiagramBounds = {
  origin: {
    x: 0,
    y: 0,
  },
  height: PAGE_SIZE,
  width: PAGE_SIZE,
};

type MaxBounds = {
  top: number,
  left: number,
  right: number,
  bottom: number,
}

export type DiagramBounds = {
  origin: {
    x: number,
    y: number,
  },
  height: number,
  width: number,
}

function calculateDiagramBounds(
  maxBounds: MaxBounds,
  scale: number,
) {
  const roundedBounds = {
    left: roundToPage(maxBounds.left, 'down'),
    top: roundToPage(maxBounds.top, 'down'),
    right: roundToPage(maxBounds.right, 'up'),
    bottom: roundToPage(maxBounds.bottom, 'up'),
  };

  const newBounds = {
    width: Math.max(roundedBounds.right - roundedBounds.left, PAGE_SIZE),
    height: Math.max(roundedBounds.bottom - roundedBounds.top, PAGE_SIZE),
    origin: {
      x: roundedBounds.left,
      y: roundedBounds.top,
    },
  };

  if (newBounds.width > MAX_SIZE) {
    newBounds.width = MAX_SIZE;
    newBounds.origin.x = maxBounds && newBounds.origin.x + PAGE_SIZE < maxBounds.left
      ? newBounds.origin.x + PAGE_SIZE
      : newBounds.origin.x;
  }

  if (newBounds.height > MAX_SIZE) {
    newBounds.height = MAX_SIZE;
    newBounds.origin.y = maxBounds && newBounds.origin.y + PAGE_SIZE < maxBounds.top
      ? newBounds.origin.y + PAGE_SIZE
      : newBounds.origin.y;
  }

  return scaleBounds(newBounds, scale);
}

function scaleBounds(bounds: DiagramBounds, scale: number) {
  return {
    width: bounds.width * scale,
    height: bounds.height * scale,
    origin: {
      x: bounds.origin.x * scale,
      y: bounds.origin.y * scale,
    },
  };
}

export function unscaleBounds(bounds: DiagramBounds, scale: number) {
  return {
    width: bounds.width / scale,
    height: bounds.height / scale,
    origin: {
      x: bounds.origin.x / scale,
      y: bounds.origin.y / scale,
    },
  };
}

type Direction = 'up' | 'down';

function roundToPage(point: number, direction: Direction) {
  if (direction === 'up') {
    return Math.ceil((point + MARGIN) / PAGE_SIZE) * PAGE_SIZE;
  }
  return Math.floor((point - MARGIN) / PAGE_SIZE) * PAGE_SIZE;
}
