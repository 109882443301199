import React, { ComponentType } from 'react';
import { DiagramWithChildrenDto } from '../../../../../app/api';
import { CopyDiagram } from './CopyDiagram';

type DiagramCopyable = {
  onCopy: (diagram: DiagramWithChildrenDto) => void;
};

type WithCopyDiagramProps = Omit<DiagramCopyable, 'onCopy'>;

export const withCopyDiagram = <P extends DiagramCopyable>(
  Component: ComponentType<P>,
) => {
  return function (props: Omit<P, 'onCopy'> & WithCopyDiagramProps) {
    return (
      <CopyDiagram>
        {(onCopy) => (
          <Component
            {...props as P}
            onCopy={onCopy} />
        )}
      </CopyDiagram>
    );
  };
};
