import React, { ReactNode } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  // eslint-disable-next-line max-len
  DiagramWithChildrenDto, useUserApiCreateDiagramMutation, useUserApiCreateDiagramRectangleMutation, useUserApiCreateDiagramTableMutation, useUserApiCreateDiagramTagMutation, useUserApiGetDiagramByIdQuery,
} from '../../../../../app/api';
import { useTab } from '../../../tabs';
import { CopyDiagramDialog } from './CopyDiagramDialog';
import { useProject } from '../../../../project';
import { isSerializedError } from '../../../../../util';
import { useModalDialog } from '../../../../../hooks';

export function CopyDiagram({ children }: {
  children: (onCopy: (diagram: DiagramWithChildrenDto) => void) => ReactNode;
}) {
  const { projectId } = useProject();

  const { setTab } = useTab();

  const [createDiagram, { isLoading, error }] = useUserApiCreateDiagramMutation();
  const {
    data: diagram,
    openDialog,
    closeDialog,
    isOpen,
  } = useModalDialog<DiagramWithChildrenDto>();

  const { data: diagramDetails, isLoading: isLoadingDetails } = useUserApiGetDiagramByIdQuery({
    projectId,
    diagramId: diagram?.id ?? '',
  }, { skip: !diagram });

  const [createDiagramTable] = useUserApiCreateDiagramTableMutation();
  const [createDiagramTag] = useUserApiCreateDiagramTagMutation();
  const [createDiagramRectangle] = useUserApiCreateDiagramRectangleMutation();

  const onCopy = async (name: string) => {
    if (!diagram || !diagramDetails) return;
    const result = await createDiagram({
      projectId,
      diagramCreateDto: {
        ...diagram,
        isLocked: true,
        isPrivate: true,
        name,
      },
    });

    if ('data' in result) {
      setTab({ id: result.data.id, type: 'diagrams' });

      [...diagramDetails.tables].sort((a, b) => b.order - a.order).forEach(async (t) => {
        await createDiagramTable({
          diagramId: result.data.id,
          diagramTableCreateDto: {
            tableName: t.tableName,
            x: t.x,
            y: t.y,
          },
        });
      });

      diagramDetails.tags.forEach(async (t) => {
        await createDiagramTag({
          diagramId: result.data.id,
          diagramTagCreateDto: {
            tagName: t.tagName,
          },
        });
      });

      [...diagramDetails.rectangles].sort((a, b) => b.order - a.order).forEach(async (r) => {
        await createDiagramRectangle({
          diagramId: result.data.id,
          diagramRectangleCreateDto: {
            backgroundColor: r.backgroundColor,
            borderColor: r.borderColor,
            content: r.content,
            height: r.height,
            width: r.width,
            x: r.x,
            y: r.y,
          },
        });
      });

      closeDialog();
    }
  };

  return (
    <>
      {children(openDialog)}
      <CopyDiagramDialog
        diagram={diagram}
        isLoading={isLoading || isLoadingDetails}
        isOpen={isOpen}
        onClose={closeDialog}
        onCopy={onCopy}
        error={TranslateError(error)} />
    </>
  );
}

function TranslateError(error: FetchBaseQueryError | SerializedError | undefined): 'duplicate' | 'unknown' | undefined {
  if (error) {
    if (!isSerializedError(error) && error.status === 409) {
      return 'duplicate';
    }
    return 'unknown';
  }
  return undefined;
}
