import { User } from 'oidc-client-ts';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CallbackComponent } from '@ericlamb/redux-oidc';
import { useUserApiUpdateCurrentAccountMutation } from '../app/api';
import { LoadingPage } from '../components/elements';
import userManager from '../features/authentication/userManager';

export function LoginCallback() {
  const DEFAULT_URI = '/';
  const navigate = useNavigate();

  const redirectUri = localStorage.getItem('redirectUri') ?? DEFAULT_URI;
  const [updateAccount] = useUserApiUpdateCurrentAccountMutation();

  const onSuccess = useCallback((user: User) => {
    if (user.profile.email === undefined) {
      throw new Error('Email address cannot be empty.');
    }

    updateAccount({
      accountCreateDto: {
        fullName: user.profile.FullName as string,
        email: user.profile.email,
        title: user.profile.Title as string,
        officePhone: user.profile.OfficePhone as string,
        mobilePhone: user.profile.MobilePhone as string,
      },
    });
    navigate(redirectUri, { replace: true });
  }, [redirectUri, navigate, updateAccount]);

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={onSuccess}
      errorCallback={(error) => {
        navigate(DEFAULT_URI, { replace: true });
        // eslint-disable-next-line no-console
        console.error(error);
      }}>
      <LoadingPage message="Processing login request..." />
    </CallbackComponent>
  );
}
