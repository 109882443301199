import React from 'react';
import { Tab } from '../../../project/projectSlice';
import { DbTabHeader, TabHeaderProps } from '../../tabs';

export function CodeValueSearchTabHeader({ tab }: TabHeaderProps) {
  return (
    <DbTabHeader
      tab={tab}
      display={formatSearch(tab.id)}
      isError={false}
      isLoading={false} />
  );
}

export function CodeValueSearchTabHistoryItem({ tab }: {
  tab: Tab,
}) {
  return <>{formatSearch(tab.id)}</>;
}

function formatSearch(search: string) {
  return `Search: ${search}`;
}
