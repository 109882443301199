import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { UserPreference } from '../UserPreference';
import { selectUserPreference, setPreferenceValue } from '../userPreferencesSlice';

export type UserPreferenceHookResult<T> = [T, (newValue: T) => void];

export function useUserPreferences(name: UserPreference, defaultValue: unknown)
  : UserPreferenceHookResult<unknown> {
  const dispatch = useAppDispatch();

  const savedState = useAppSelector((state) => selectUserPreference(state, name));

  const setValue = useCallback((newValue: unknown) => {
    dispatch(setPreferenceValue({
      name,
      value: newValue,
    }));
  }, [dispatch, name]);

  return [
    savedState?.value ?? defaultValue,
    setValue,
  ];
}
