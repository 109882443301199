import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import {
  Button, ButtonGroup, Table as BsTable, UncontrolledTooltip,
} from 'reactstrap';
import { DateTime } from 'luxon';
import { DiffViewer } from '../diff';
import { Icon } from '../icons';
import { ArticleVersion, ArticleViewer } from './Article';
import { ScrollArea } from '../scrollArea';
import { IconButton } from '../buttons';

const MAX_DIFF_LENGTH = 100000;

export function ArticleHistory({ articleVersions, title, onExit }: {
  articleVersions: ArticleVersion[] | undefined;
  title: string;
  onExit: () => void;
}) {
  const sortedArticles = useMemo(() => [...articleVersions ?? []]
    .sort(compareArticleDates), [articleVersions]);

  const [selectedArticle, setSelectedArticle] = useState<ArticleVersion | null>(null);
  const [diffedArticle, setDiffedArticle] = useState<ArticleVersion | null>(null);

  const onViewArticle = useCallback((article: ArticleVersion) => {
    setSelectedArticle(article);
    setDiffedArticle(null);
  }, []);

  const onDiffArticle = useCallback((article: ArticleVersion) => {
    setDiffedArticle(article);
  }, []);

  const isDiffAllowed = (selectedArticle?.body.length ?? 0) > MAX_DIFF_LENGTH;

  return (
    <div className="d-flex flex-grow-1 flex-column gap-2 mt-2">
      <h5 className="d-flex gap-1 justify-content-between">
        {title} History
        <IconButton
          iconName="exit"
          toolTip="Exit"
          onClick={onExit} />
      </h5>

      <div className="overflow-auto border" style={{ flex: '1 2 25%' }}>
        <div style={{ height: '1px' }}>
          <BsTable hover borderless size="sm">
            <thead />
            <th>Action</th>
            <th>Version on</th>
            <th>Version by</th>
            <th>Markdown</th>
            <tbody>
              {sortedArticles.map((a) => (
                <tr>
                  <td>
                    <div className="d-flex gap-1">
                      <Button onClick={() => onViewArticle(a)} disabled={selectedArticle === a} size="sm">
                        <Icon icon="openArticle" />
                      </Button>
                      {selectedArticle
                        && selectedArticle !== a
                        && (
                          <DiffButton
                            article={a}
                            isSelected={diffedArticle === a}
                            isDiffAllowed={isDiffAllowed}
                            onDiffArticle={() => onDiffArticle(a)} />
                        )}
                    </div>
                  </td>
                  <td>{formatArticleDate(a)}</td>
                  <td>{a.authorFullName}</td>
                  <td>{a.body.substring(0, 100)}</td>
                </tr>
              ))}
            </tbody>
          </BsTable>
        </div>
      </div>

      {selectedArticle && (
        <div className="d-flex" style={{ flex: '2 1 75%' }}>
          {diffedArticle
            && <ArticleDiff articles={[selectedArticle, diffedArticle]} />}

          {diffedArticle === null
            && (
              <ScrollArea className="d-flex">
                <ArticleViewer markdown={selectedArticle?.body} />
              </ScrollArea>
            )}
        </div>
      )}
    </div>
  );
}

function DiffButton({
  onDiffArticle, article, isSelected, isDiffAllowed,
}: {
  article: ArticleVersion;
  isSelected: boolean
  isDiffAllowed: boolean
  onDiffArticle: () => void;
}) {
  const disabled = isDiffAllowed || article.body.length > MAX_DIFF_LENGTH;
  const buttonRef = useRef(null);

  return (
    <>
      <div ref={buttonRef}>
        <Button
          onClick={() => onDiffArticle()}
          disabled={isSelected || disabled}
          size="sm">
          <Icon icon="compare" />
        </Button>
      </div>
      {disabled && (
        <UncontrolledTooltip target={buttonRef} placement="right">
          {isDiffAllowed
            ? 'Selected article version is too large to compare'
            : 'This article version is too large to compare'}
        </UncontrolledTooltip>
      )}
    </>
  );
}

function ArticleDiff({ articles }: {
  articles: ArticleVersion[];
}) {
  const sortedArticles = useMemo(() => [...articles].sort(compareArticleDates), [articles]);

  const formatArticleTitle = (article: ArticleVersion) => {
    return `${formatArticleDate(article)} (${article.authorFullName})`;
  };

  const [split, setSplit] = useState(true);

  return (
    <div className="d-flex flex-grow-1 flex-column gap-2">
      <div>
        <ButtonGroup>
          <Button
            color="primary"
            active={!split}
            onClick={() => setSplit(false)}>
            Inline
          </Button>
          <Button
            color="primary"
            active={split}
            onClick={() => setSplit(true)}>
            Split
          </Button>
        </ButtonGroup>
      </div>
      <div className="d-flex flex-grow-1 overflow-auto">
        <div style={{ height: '1px' }}>
          <DiffViewer
            newValue={sortedArticles[0].body}
            newTitle={formatArticleTitle(sortedArticles[0])}
            oldValue={sortedArticles[1].body}
            oldTitle={formatArticleTitle(sortedArticles[1])}
            isSplitView={split} />
        </div>
      </div>
    </div>
  );
}

function formatArticleDate(a: ArticleVersion) {
  return `${DateTime.fromISO(a.createdOn).toLocaleString(DateTime.DATETIME_SHORT)}`;
}

function compareArticleDates(x: ArticleVersion, y: ArticleVersion) {
  return DateTime.fromISO(y.createdOn).toMillis() - DateTime.fromISO(x.createdOn).toMillis();
}
