import { noop } from 'lodash';
import { taggedApi } from './api-tagged';
import { ErrorMap } from './rtkQueryErrorLogger';
import { pessimisticCreate } from './api-util';
import { ActivityFeedTag } from './api-activityLog';

type WikiErrorMap = Pick<ErrorMap,
  'userApiCreateWikiArticle'
  | 'userApiGetWikiArticleByIdentifier'
  | 'userApiListWikiArticleVersionsByIdentifier'
  | 'userApiListWikiArticlesByProject'>;

export const wikiErrorMap: WikiErrorMap = {
  userApiCreateWikiArticle: (args) => `Create Article ${args.wikiArticleCreateDto.identifier}`,
  userApiGetWikiArticleByIdentifier: (args) => `Get Article ${args.identifier}`,
  userApiListWikiArticleVersionsByIdentifier: (args) => `Get Article History ${args.identifier}`,
  userApiListWikiArticlesByProject: () => 'List Articles',
};

export function addWikiApiExtension(api: typeof taggedApi) {
  return api.enhanceEndpoints({
    endpoints: {
      userApiListWikiArticlesByProject: {
        providesTags: (result, _, { projectId }) => (result
          ? [
            ...result.map((a) => CreateArticleTag(a.identifier, projectId)),
            CreateProjectTag(projectId),
          ]
          : [CreateProjectTag(projectId)]),
      },
      userApiGetWikiArticleByIdentifier: {
        providesTags: (_, __, { identifier, projectId }) => ([
          CreateArticleTag(identifier, projectId),
        ]),
      },
      userApiListWikiArticleVersionsByIdentifier: {
        providesTags: (_, __, { identifier, projectId }) => ([
          CreateArticleTag(identifier, projectId),
        ]),
      },
      userApiCreateWikiArticle: {
        onQueryStarted: async ({ projectId, wikiArticleCreateDto }, mutation) => {
          await pessimisticCreate(mutation, api, {
            invalidatedTags: [
              CreateArticleTag(wikiArticleCreateDto.identifier, projectId),
              CreateProjectTag(projectId),
              ActivityFeedTag,
            ],
            updateHandlers: {
              userApiGetWikiArticleByIdentifier: ({ originalArgs }, created) => {
                return api.util.updateQueryData('userApiGetWikiArticleByIdentifier', originalArgs, (draft) => {
                  if (draft) {
                    draft.body = created.body;
                    draft.authorFullName = created.authorFullName;
                    draft.authorId = created.authorId;
                    draft.createdOn = created.createdOn;
                    draft.id = created.id;
                  }
                });
              },
              userApiListWikiArticleVersionsByIdentifier: ({ originalArgs }, created) => {
                return api.util.updateQueryData('userApiListWikiArticleVersionsByIdentifier', originalArgs, (draft) => {
                  draft?.unshift(created);
                });
              },
              userApiListActivityLogEntriesByProject: ({ originalArgs }) => {
                return api.util.updateQueryData('userApiListActivityLogEntriesByProject', originalArgs, noop);
              },
            },
          });
        },
      },
    },
  });
}

function CreateArticleTag(identifier: string | null, projectId: string): { type: 'Article', id: string } {
  return { type: 'Article', id: `${projectId}-${identifier}` };
}

function CreateProjectTag(projectId: string): { type: 'Articles', id: string } {
  return { type: 'Articles', id: `articles-${projectId}` };
}
