import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { Icon } from '../../../components/elements';

export function ColorSettingsButton({ onClick }: {
  onClick: () => void;
}) {
  return (
    <>
      <Button
        className="btn-sm"
        onClick={() => onClick()}
        id="color-settings-button">
        <Icon icon="colors" fixedWidth />
      </Button>
      <UncontrolledTooltip placement="top" target="color-settings-button">
        Color settings
      </UncontrolledTooltip>
    </>
  );
}
