import React from 'react';
import { IconButton, IconButtonProps } from '../iconButton';

export function DeleteButton(props: Omit<IconButtonProps, 'iconName' | 'toolTip'>) {
  return (
    <IconButton
      {...props}
      iconName="trash"
      toolTip="Delete" />
  );
}
