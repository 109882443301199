import React, { ReactNode } from 'react';
import { useAppSelector } from '../app/hooks';
import { isAdmin, selectAuth } from '../features/authentication/authSlice';
import { useOrganizations } from '../features/organizations';
import { hasOrgAuth } from './hasOrgAuth';
import { OrganizationRole } from './OrganizationRole';

export function Privileged({ children, role }: {
  children: ReactNode;
  role: OrganizationRole;
}) {
  const {
    organizations, error, isLoading, organization,
  } = useOrganizations();
  const { user, isLoadingUser } = useAppSelector(selectAuth);

  if (isLoadingUser || !user || isLoading || !organizations) {
    return null;
  }

  if (error) {
    return (<p><em>Error checking organization permissions</em></p>);
  }

  return (
    <>
      {isAdmin(user) || hasOrgAuth(organization, role, user)
        ? children
        : null}
    </>
  );
}
