import React, {
  LegacyRef, ReactNode, RefObject,
} from 'react';
import { Resizable, ResizeHandle } from 'react-resizable';
import { Location } from '../Location';
import styles from './ResizableItem.module.scss';
import { SizeDefinition } from './SizeDefinition';
import { useResize } from './useResize';

export function ResizableItem({
  size, scale, isSelected, resizeContextId, children, onResize,
}: {
  size: SizeDefinition;
  scale: number;
  isSelected?: boolean;
  resizeContextId?: string;
  children: (childSize: SizeDefinition) => ReactNode
  onResize: (newDef: SizeDefinition) => void;
}) {
  const {
    calculatedSize, startResize, endResize, handleResize,
  } = useResize(size, resizeContextId);

  const renderHandle = (handle: ResizeHandle, ref: RefObject<SVGCircleElement>) => (
    <Handle
      rectangle={{
        ...calculatedSize,
      }}
      handle={handle}
      ref={ref} />
  );

  return (
    <Resizable
      width={calculatedSize.width}
      height={calculatedSize.height}
      transformScale={scale}
      axis="both"
      handle={renderHandle}
      resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
      onResizeStart={(e) => {
        e.stopPropagation();
        startResize();
      }}
      onResizeStop={() => {
        if (calculatedSize) {
          onResize({
            ...calculatedSize,
          });
        }
        endResize();
      }}
      onResize={(_, data) => handleResize(data)}>
      <g className={isSelected ? styles.selected : undefined}>
        {children(calculatedSize)}
      </g>
    </Resizable>
  );
}

ResizableItem.defaultProps = {
  resizeContextId: undefined,
  isSelected: false,
};

type HandleProps = {
  handle: ResizeHandle;
  rectangle: SizeDefinition;
}

const Handle = React.forwardRef((
  props: HandleProps,
  ref: LegacyRef<SVGCircleElement> | undefined,
) => {
  const location = positionHandle(props.rectangle, props.handle);

  return (
    <circle
      {...props}
      ref={ref}
      className={styles[`handle-${props.handle}`]}
      cx={location.x}
      cy={location.y}
      r="7" />
  );
});

function positionHandle(rectangle: SizeDefinition, axis: ResizeHandle): Location {
  switch (axis) {
    case 's':
      return {
        x: rectangle.x + rectangle.width / 2,
        y: rectangle.y + rectangle.height,
      };
    case 'n':
      return {
        x: rectangle.x + rectangle.width / 2,
        y: rectangle.y,
      };
    case 'e':
      return {
        x: rectangle.x + rectangle.width,
        y: rectangle.y + rectangle.height / 2,
      };
    case 'w':
      return {
        x: rectangle.x,
        y: rectangle.y + rectangle.height / 2,
      };
    case 'sw':
      return {
        x: rectangle.x,
        y: rectangle.y + rectangle.height,
      };
    case 'se':
      return {
        x: rectangle.x + rectangle.width,
        y: rectangle.y + rectangle.height,
      };
    case 'nw':
      return {
        x: rectangle.x,
        y: rectangle.y,
      };
    case 'ne':
      return {
        x: rectangle.x + rectangle.width,
        y: rectangle.y,
      };

    default:
      throw new Error('Invalid resize handle');
  }
}
