import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  // eslint-disable-next-line max-len
  Alert, Button, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { DiagramWithChildrenDto } from '../../../../../../app/api';
import { Icon, LoadingSpinner } from '../../../../../../components/elements';

type CopyDiagramInputs = {
  diagramName: string;
};

type CopyDiagramError = 'duplicate' | 'unknown' | undefined;
const CopyDiagramErrorMap: { [K in Exclude<CopyDiagramError, undefined>]: string } = {
  duplicate: 'A diagram with that name already exists.',
  unknown: 'An error occurred creating a diagram. Please contact support if this continues.',
};

export function CopyDiagramDialog({
  diagram, isLoading, isOpen, onClose, onCopy, error,
}: {
  diagram: DiagramWithChildrenDto | undefined,
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onCopy: (name: string) => void;
  error: CopyDiagramError;
}) {
  const {
    control, handleSubmit, formState: { errors }, reset,
  } = useForm<CopyDiagramInputs>({ disabled: isLoading });
  const submitForm: SubmitHandler<CopyDiagramInputs> = async (formData) => {
    onCopy(formData.diagramName);
  };

  useEffect(() => {
    reset({
      diagramName: diagram
        ? `${diagram.name} (Copy)`
        : undefined,
    });
  }, [diagram, reset]);

  const close = !isLoading
    ? () => onClose()
    : undefined;

  const errorMessage = !error
    ? null
    : (
      <Alert color="danger">
        <h6>Error Creating Diagram</h6>
        <p className="mb-0">{CopyDiagramErrorMap[error]}</p>
      </Alert>
    );

  return (
    <Modal isOpen={isOpen} toggle={close} autoFocus={false}>
      <ModalHeader toggle={close}>Copy Diagram {diagram?.name}</ModalHeader>
      <ModalBody>
        {errorMessage}
        <Form onSubmit={handleSubmit(submitForm)}>
          <FormGroup>
            <Label for="diagramName">Diagram Name</Label>
            <Controller
              name="diagramName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  invalid={!!errors.diagramName}
                  {...field}
                  autoFocus
                  type="text" />
              )} />
            <FormFeedback>Diagram name is required</FormFeedback>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={isLoading} onClick={handleSubmit(submitForm)}>
          <LoadingSpinner isLoading={isLoading}><Icon icon="copy" /></LoadingSpinner> Copy
        </Button>
        <Button color="secondary" disabled={isLoading} onClick={close}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}
