import React from 'react';
import { Button } from 'reactstrap';
import { Icon } from '../../icons';

export function ClearButton({ disabled, onClick }: {
  disabled?: boolean;
  onClick: () => void;
}) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}>
      <Icon icon="clear" />
    </Button>
  );
}

ClearButton.defaultProps = {
  disabled: undefined,
};
