import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DatabaseContextProvider } from '../DatabaseContext';
import { Database } from '../Database';

export function DatabaseRoutes() {
  return (
    <Routes>
      <Route index element={<Navigate replace to="../../" />} />
      <Route
        path=":databaseId"
        element={(
          <DatabaseContextProvider>
            <Database />
          </DatabaseContextProvider>
        )} />
    </Routes>
  );
}
