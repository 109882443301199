import React, { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useDrop } from 'react-dnd';
import { useUserApiGetDiagramByIdQuery, useUserApiListDiagramsQuery } from '../../../app/api';
import { DbTabHeader, TabHeaderProps, useTab } from '../tabs';
import { Icon, LoadingSpinner } from '../../../components/elements';
import { useEditLock } from './hooks';
import { useProject } from '../../project';
import { Tab } from '../../project/projectSlice';

export function DiagramTabHeader({ tab }: TabHeaderProps) {
  const { projectId } = useProject();
  const { data, isLoading, error } = useUserApiGetDiagramByIdQuery(
    {
      projectId,
      diagramId: tab.id,
    },
  );

  const { setTab } = useTab();
  const isLocked = useEditLock(data);
  const [, drop] = useDrop(() => ({
    accept: 'Table',
    hover: () => {
      setTab(tab);
    },
    canDrop: () => false,
  }));
  const title = (
    <span ref={drop}>
      {data?.name}
      {isLocked && <LockIndicator />}
    </span>
  );
  return (<DbTabHeader tab={tab} display={title} isLoading={isLoading} isError={!!error} />);
}

function LockIndicator() {
  const ref = useRef(null);

  return (
    <>
      <span ref={ref}> <Icon icon="locked" /></span>
      <UncontrolledTooltip target={ref} placement="auto">Diagram is read only</UncontrolledTooltip>
    </>
  );
}

export function DiagramTabHistoryItem({ tab }: { tab: Tab }) {
  const { projectId } = useProject();

  const { data, isLoading } = useUserApiListDiagramsQuery({
    projectId,
  }, {
    selectFromResult: (result) => ({
      ...result,
      data: result.data?.find((d) => d.id === tab.id),
    }),
  });

  return (
    <LoadingSpinner isLoading={isLoading}>
      {data?.name}
    </LoadingSpinner>
  );
}
