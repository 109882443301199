import { useCallback } from 'react';
import { UserPreference } from '../UserPreference';
import { useUserPreferences } from './useUserPreferences';

export function useUserPreferenceToggle(name: UserPreference, defaultValue = false)
  : [boolean, () => void] {
  const [value, setValue] = useUserPreferences(name, defaultValue);
  const currentValue = !!(value ?? defaultValue);

  const toggle = useCallback(() => {
    setValue(!currentValue);
  }, [currentValue, setValue]);

  return [
    currentValue,
    toggle,
  ];
}
