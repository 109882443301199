import React, { ReactNode } from 'react';
import { useToggle } from '../../../hooks';
import { UploadHarvestDialog } from './UploadHarvestDialog';

export function HarvestUploader({ children }: {
  children: (onUpload: () => void) => ReactNode;
}) {
  const { value: modal, toggleValue: toggleModal } = useToggle();

  return (
    <>
      {children(toggleModal)}
      <UploadHarvestDialog modal={modal} toggleModal={toggleModal} />
    </>
  );
}
