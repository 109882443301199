import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { matchSorter } from 'match-sorter';
import {
  // eslint-disable-next-line max-len
  MmCodeSetDto, MmTableColumnDto, MmExtendedTable, useUserApiCreateMmCodeSetOverrideMutation, useUserApiDeleteMmCodeSetOverrideMutation, useUserApiListMmCodeSetsQuery,
} from '../../../../app/api';
import {
  ErrorAlert, ErrorMessage, Icon, LoadingSpinner, DropdownSelect,
} from '../../../../components/elements';
import { useCurrentDatabase } from '../../DatabaseContext';
import { useProject } from '../../../project';

export function OverrideCodeSet({
  table, column, isOpen, onClose,
}:
  {
    table: MmExtendedTable | undefined;
    column: MmTableColumnDto | undefined;
    isOpen: boolean;
    onClose: () => void;
  }) {
  const { projectId } = useProject();
  const { databaseId } = useCurrentDatabase();
  const { data: codeSets, isLoading } = useUserApiListMmCodeSetsQuery({
    harvestId: databaseId,
  });

  const [
    createOverride,
    { isLoading: isCreating },
  ] = useUserApiCreateMmCodeSetOverrideMutation();
  const [
    persistDeleteOverride,
    { isLoading: isDeleting },
  ] = useUserApiDeleteMmCodeSetOverrideMutation();

  const saveOverride = useCallback(async (
    code: number | undefined,
    reason: string,
  ) => {
    if (table && column) {
      if (reason) {
        await createOverride({
          projectId,
          mmCodeSetOverrideCreateDto: {
            sourceTable: table?.name,
            sourceColumn: column?.name,
            code,
            reason,
          },
        });
      }
      onClose();
    }
  }, [column, createOverride, onClose, projectId, table]);

  const deleteOverride = useCallback(async () => {
    if (column?.mmCodeSetOverride) {
      await persistDeleteOverride({
        projectId,
        codeSetOverrideId: column.mmCodeSetOverride?.id,
      });
    }
    onClose();
  }, [column?.mmCodeSetOverride, onClose, persistDeleteOverride, projectId]);

  return (
    <CodeSetOverrideDialog
      column={column}
      codeSets={codeSets}
      isLoading={isLoading}
      isSaving={isCreating || isDeleting}
      error={undefined}
      isOpen={isOpen}
      onClose={onClose}
      onSetOverride={saveOverride}
      onDeleteOverride={deleteOverride} />
  );
}

type OverrideInputs = {
  codeSet: MmCodeSetDto | undefined,
  reason: string;
}

export function CodeSetOverrideDialog({
  column, codeSets, isLoading, isSaving, error, isOpen, onSetOverride, onClose, onDeleteOverride,
}: {
  column: MmTableColumnDto | undefined;
  codeSets: MmCodeSetDto[] | undefined;
  isLoading: boolean;
  isSaving: boolean;
  error: unknown;
  isOpen: boolean;
  onDeleteOverride: () => void;
  onSetOverride: (code: number | undefined, reason: string) => void;
  onClose: () => void;
}) {
  const {
    control, handleSubmit, formState: { errors }, reset,
  } = useForm<OverrideInputs>({ disabled: isSaving });
  const submitForm: SubmitHandler<OverrideInputs> = (formData) => {
    onSetOverride(formData.codeSet?.code, formData.reason);
  };

  useEffect(() => {
    reset({
      reason: column?.mmCodeSetOverride?.reason,
      codeSet: codeSets?.find((c) => c.code === column?.mmCodeSetOverride?.code),
    });
  }, [codeSets, column, reset]);

  return (
    <Modal isOpen={isOpen} toggle={onClose} autoFocus={false}>
      <ModalHeader toggle={onClose}>{column?.name} Code Set</ModalHeader>
      <ModalBody className="d-flex flex-column gap-3">
        <div>System Defined: <CodeSetText codeSet={column?.mmCodeSet} /></div>

        <ErrorAlert error={error}>
          <ErrorMessage>Unable to set override</ErrorMessage>
        </ErrorAlert>

        <LoadingSpinner isLoading={isLoading} centered>
          <Form onSubmit={handleSubmit(submitForm)}>
            <Controller
              name="codeSet"
              control={control}
              render={({ field }) => (
                <DropdownSelect
                  items={codeSets ?? []}
                  initialItem={field.value}
                  disabled={field.disabled ?? false}
                  autofocus
                  label="Code Set Override"
                  onChange={field.onChange}
                  itemToString={CodeSetToText}
                  filterItems={(items, filter) => matchSorter(items, filter ?? '', { keys: ['code', 'display'] })}
                  getItemKey={(item) => item.code}
                  placeholder="No Code Set">
                  {(item) => <CodeSetText codeSet={item} />}
                </DropdownSelect>
              )} />
            <FormGroup>
              <Label for="reason">Reason</Label>
              <Controller
                name="reason"
                control={control}
                defaultValue={column?.mmCodeSetOverride?.reason}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    invalid={!!errors.reason}
                    {...field}
                    type="text" />
                )} />
              <FormFeedback>Reason is required</FormFeedback>
            </FormGroup>
          </Form>
        </LoadingSpinner>
      </ModalBody>
      <ModalFooter>
        {
          !!column?.mmCodeSetOverride
          && (
            <div className="d-flex flex-column flex-grow-1" style={{ fontSize: '.75rem' }}>
              <span>Overridden By: {column.mmCodeSetOverride.createdBy}</span>
            </div>
          )
        }
        {
          !column?.mmCodeSetOverride && (
            <Button color="primary" disabled={isSaving} onClick={handleSubmit(submitForm)}>
              <LoadingSpinner isLoading={isSaving}><Icon icon="save" /></LoadingSpinner> Save
            </Button>
          )
        }
        {
          !!column?.mmCodeSetOverride && (
            <Button color="danger" disabled={isSaving} onClick={onDeleteOverride}>
              <LoadingSpinner isLoading={isSaving}><Icon icon="trash" /></LoadingSpinner> Delete
            </Button>
          )
        }
        <Button color="secondary" disabled={isSaving} onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

function CodeSetText({ codeSet }: {
  codeSet: MmCodeSetDto | undefined | null;
}) {
  return codeSet
    ? <>{CodeSetToText(codeSet)}</>
    : <>{'<None>'}</>;
}

function CodeSetToText(item: MmCodeSetDto | null) {
  return (item ? `(${item.code}) ${item.display}` : '');
}
