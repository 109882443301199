import React from 'react';
import { IconButton, IconButtonProps } from '../iconButton';

export function RemoveButton(props: Omit<IconButtonProps, 'iconName' | 'toolTip'>) {
  return (
    <IconButton
      {...props}
      iconName="remove"
      toolTip="Remove" />
  );
}
