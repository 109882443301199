import React from 'react';
import {
  useUserApiSearchMmCodeValuesQuery,
} from '../../../../app/api';
import {
  LoadingSpinner, ErrorWrapper,
} from '../../../../components/elements';
import { ErrorMessage } from '../../../../components/elements/form';
import { useParentTabId } from '../../../project';
import { useCurrentDatabase } from '../../DatabaseContext';
import { CodeValueTable } from '../CodeValueTable';

export function CodeValueSearchResult({ filter }: {
  filter: string;
}) {
  const { databaseId } = useCurrentDatabase();
  const { data: codeValues, isLoading, error } = useUserApiSearchMmCodeValuesQuery({
    harvestId: databaseId,
    filter,
  });

  const tabId = useParentTabId();
  const stateScopeId = `${tabId}-code-set-search`;

  return (
    <div className="m-2 flex-grow-1 d-flex flex-column">
      <LoadingSpinner isLoading={isLoading} centered>
        <ErrorWrapper
          error={error}
          message={(
            <>
              <ErrorMessage statusCode={404}>
                <h4>The current harvest could not be found</h4>
              </ErrorMessage>
              <ErrorMessage>
                <h4>Unable to load Code values.</h4>
              </ErrorMessage>
            </>
          )}>
          <CodeValueTable
            codeValues={codeValues ?? []}
            showCodeSet
            stateScopeId={stateScopeId} />
        </ErrorWrapper>
      </LoadingSpinner>
    </div>
  );
}
