import React, { useState } from 'react';
import { Location } from '../Location';

export type Box = Omit<DOMRectReadOnly, 'toJSON'>;
type MouseLocationEvent = Pick<React.MouseEvent<Element, MouseEvent>, 'clientX' | 'clientY'>;
type MouseButtonEvent = Pick<React.MouseEvent<Element, MouseEvent>, 'button'>;

export type UseSelectAreaResult = {
  containerProps: {
    onMouseDown: (e: MouseLocationEvent & MouseButtonEvent) => void,
    onMouseMove: (e: MouseLocationEvent) => void,
    onMouseUp: () => void,
  },
  selectionProps: Box | undefined;
}

export function useSelectArea(
  onSelect: (box: Box) => void,
  containerLocation: Location,
): UseSelectAreaResult {
  const [selectArea, setSelectArea] = useState<{
    start: Location;
    startContainerLocation: Location;
    end: Location;
  }>();

  const offsetStart = selectArea
    ? {
      x: selectArea.start.x + containerLocation.x - selectArea.startContainerLocation.x,
      y: selectArea.start.y + containerLocation.y - selectArea.startContainerLocation.y,
    }
    : { x: 0, y: 0 };

  const box = selectArea
    ? {
      width: Math.abs(selectArea.end.x - offsetStart.x),
      height: Math.abs(selectArea.end.y - offsetStart.y),
      x: Math.min(offsetStart.x, selectArea.end.x),
      y: Math.min(offsetStart.y, selectArea.end.y),
      top: Math.min(offsetStart.y, selectArea.end.y),
      bottom: Math.max(offsetStart.y, selectArea.end.y),
      left: Math.min(offsetStart.x, selectArea.end.x),
      right: Math.max(offsetStart.x, selectArea.end.x),
    }
    : undefined;

  const onMouseDown = (e: MouseLocationEvent & MouseButtonEvent) => {
    if (e.button === 0) {
      const diagramPoint = {
        x: e.clientX,
        y: e.clientY,
      };

      setSelectArea({
        start: diagramPoint,
        startContainerLocation: containerLocation,
        end: diagramPoint,
      });
    }
  };

  const onMouseMove = (e: MouseLocationEvent) => (
    selectArea
    && setSelectArea((a) => (!a
      ? a
      : {
        ...a,
        end: {
          x: e.clientX,
          y: e.clientY,
        },
      }))
  );

  const onMouseUp = () => {
    if (box) { onSelect(box); }

    setSelectArea(undefined);
  };

  return {
    containerProps: {
      onMouseDown,
      onMouseMove,
      onMouseUp,
    },
    selectionProps: box,
  };
}
