import React, { useMemo, useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useDrag } from 'react-dnd';
import {
  // eslint-disable-next-line max-len
  TableDto,
  TableColumnDto,
  ReferentialConstraintDto,
  TableIndexDto,
  MmTableColumnDto,
  MmTableColumnFlagDto,
  isMmColumn,
  getRootEntity,
  getCodeSetCode,
  ConstraintDto,
} from '../../../../../app/api';
import { Icon } from '../../../../../components/elements';
import { insensitiveCompare } from '../../../../../util';
import { TableBodySort } from '../util';
import { CodeSetLink } from '../../../codeSets';
import styles from './DiagramTableBody.module.css';
import { OnTablePrefetch } from '../../../tables/useTablePrefetch';

export type DiagramTableColumnStyles = {
  notNullableStyle: string;
  emptyStyle: { class: string, threshold: number };
}

function constrainsColumn(constraint: ConstraintDto, column: TableColumnDto) {
  return !!constraint.constraintColumns.find((cc) => cc.tableColumn.id === column.id);
}

function findConstraints<T extends ConstraintDto>(
  constraints: T[],
  column: TableColumnDto,
  includeDisabled: boolean,
) {
  return constraints.filter((c) => c.isEnabled || includeDisabled)
    .filter((c) => constrainsColumn(c, column));
}

export function DiagramTableBody({
  table,
  showCodeSet,
  showDataType,
  showDistinctValues,
  showPkFkIndicator,
  showIndexIndicator,
  showFlagsIndicator,
  showNullableColumns,
  showDisabledForeignKeys,
  showInferredForeignKeys,
  showOverriddenReferences,
  backgroundColor,
  sort,
  columnStyles,
  onOpenCodeSet,
  onTableDetails,
  onTablePrefetch,
}: {
  table: TableDto,
  showCodeSet: boolean;
  showDataType: boolean;
  showDistinctValues: boolean;
  showPkFkIndicator: boolean;
  showIndexIndicator: boolean;
  showFlagsIndicator: boolean;
  showNullableColumns: boolean;
  showDisabledForeignKeys: boolean;
  showInferredForeignKeys: boolean;
  showOverriddenReferences: boolean;
  backgroundColor: string;
  sort: TableBodySort;
  columnStyles: DiagramTableColumnStyles;
  onOpenCodeSet: (set: number) => void;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
}) {
  const isPk = (c: TableColumnDto) => {
    return findConstraints(table.primaryKeyConstraints, c, false).length > 0;
  };

  const isFk = (column: TableColumnDto | MmTableColumnDto) => {
    const hasRootEntity = (c: TableColumnDto) => {
      if (isMmColumn(c)) {
        const rootEntity = getRootEntity(c, showOverriddenReferences);
        return !!rootEntity.rootEntityName && !!rootEntity.rootEntityAttr;
      }
      return false;
    };

    return findConstraints(table.referentialConstraints, column, showDisabledForeignKeys).length > 0
      || (showInferredForeignKeys && hasRootEntity(column));
  };

  const sorts: {
    [K in TableBodySort]: (a: TableColumnDto, b: TableColumnDto) => number
  } = {
    ByColumnId: (a, b) => a.position - b.position,
    ByColumnName: (a, b) => insensitiveCompare(a.name, b.name),
    ByDistinctCount: (a, b) => (b.numDistinct ?? 0) - (a.numDistinct ?? 0),
    ForeignKeysFirst:
      (a, b) => {
        if (isFk(a) === isFk(b)) {
          return insensitiveCompare(a.name, b.name);
        }
        return isFk(a) ? -1 : 1;
      },
  };

  const displayFlags = useMemo(() => {
    return showFlagsIndicator && table.tableColumns.some((c) => isMmColumn(c) && c.isFlag);
  }, [showFlagsIndicator, table.tableColumns]);

  const displayColumns = (cols: TableColumnDto[]) => cols.sort((a, b) => sorts[sort](a, b))
    .map((c) => (
      <DiagramTableRow
        key={c.name}
        column={c}
        columnStyles={columnStyles}
        onOpenCodeSet={onOpenCodeSet}
        showCodeSet={showCodeSet}
        showDataType={showDataType}
        showDistinctValues={showDistinctValues}
        showPkFkIndicator={showPkFkIndicator}
        showIndexIndicator={showIndexIndicator}
        showFlagsIndicator={displayFlags}
        showDisabledForeignKeys={showDisabledForeignKeys}
        showInferredForeignKeys={showInferredForeignKeys}
        showOverriddenReferences={showOverriddenReferences}
        isPrimaryKey={isPk(c)}
        isForeignKey={isFk(c)}
        table={table}
        onTableDetails={onTableDetails}
        onTablePrefetch={onTablePrefetch} />
    ));

  return (
    <div style={{ background: backgroundColor ?? '#ffffff' }}>
      <table className={`w-100 table table-hover table-borderless mb-0 table-xs ${styles['diagram-table-body']}`}>
        <tbody className="border-bottom border-dark">
          {
            displayColumns(table.tableColumns.filter(isPk))
          }
        </tbody>
        <tbody>
          {
            displayColumns(
              table.tableColumns.filter((c) => !isPk(c)
                && (showNullableColumns || !c.isNullable)),
            )
          }
        </tbody>
      </table>
    </div>
  );
}

type ColumnDecorators = {
  [Property in keyof DiagramTableColumnStyles as `${Property}Decorator`]: (column: TableColumnDto) => string
};

function DiagramTableRow({
  column,
  showDataType,
  showCodeSet,
  showDistinctValues,
  showPkFkIndicator,
  showIndexIndicator,
  showFlagsIndicator,
  showDisabledForeignKeys,
  showInferredForeignKeys,
  showOverriddenReferences,
  columnStyles,
  isPrimaryKey,
  isForeignKey,
  onOpenCodeSet,
  onTableDetails,
  onTablePrefetch,
  table,
}: {
  column: TableColumnDto | MmTableColumnDto;
  showDataType: boolean;
  showCodeSet: boolean;
  showDistinctValues: boolean;
  showPkFkIndicator: boolean;
  showIndexIndicator: boolean;
  showFlagsIndicator: boolean;
  showDisabledForeignKeys: boolean;
  showInferredForeignKeys: boolean;
  showOverriddenReferences: boolean;
  columnStyles: DiagramTableColumnStyles;
  isPrimaryKey: boolean;
  isForeignKey: boolean;
  table: TableDto;
  onOpenCodeSet: (set: number) => void;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
}) {
  const decorators: ColumnDecorators = {
    notNullableStyleDecorator: ((c: TableColumnDto) => (
      !c.isNullable
        ? columnStyles.notNullableStyle
        : '')),
    emptyStyleDecorator: ((c: TableColumnDto) => (
      (c.numDistinct ?? 0) <= columnStyles.emptyStyle.threshold
        ? columnStyles.emptyStyle.class
        : '')),
  };

  const indexes = useMemo(() => {
    return table.tableIndexes.filter(
      (i) => i.tableIndexColumns.find((c) => c.tableColumnId === column.id),
    );
  }, [column.id, table.tableIndexes]);

  const keys = useMemo(() => {
    return findConstraints(table.referentialConstraints, column, showDisabledForeignKeys);
  }, [column, showDisabledForeignKeys, table.referentialConstraints]);

  return (
    <tr className={Object.values(decorators).map((d) => d(column)).join(' ')}>

      {showPkFkIndicator && (
        <td className="user-select-none">
          {isPrimaryKey && '<PK>'}
          {isForeignKey && (
            <FkIndicator
              referentialConstraints={keys}
              column={column}
              showInferredForeignKeys={showInferredForeignKeys}
              showOverriddenReferences={showOverriddenReferences}
              onTableDetails={onTableDetails}
              onTablePrefetch={onTablePrefetch} />
          )}
        </td>
      )}

      {showIndexIndicator && (
        <td>{indexes.length > 0 && <IndexIndicator indexes={indexes} />}</td>
      )}

      <td>
        <span
          className="cursor-text"
          onMouseDown={(e) => e.stopPropagation()}>
          {column.name}
        </span>
      </td>

      {showDataType && <td className="user-select-none">{column.dataType}</td>}

      {showCodeSet && isMmColumn(column) && (
        <td className="text-end user-select-none">
          <CodeSetLink code={getCodeSetCode(column)} onOpenCodeSet={onOpenCodeSet} />
        </td>
      )}

      {showFlagsIndicator && isMmColumn(column) && (
        <td>
          {column.isFlag && <FlagIndicator flags={column.tableColumnFlags} />}
        </td>
      )}

      {showDistinctValues && (
        <td className="text-end user-select-none">
          {!!column.numDistinct && column.numDistinct.toLocaleString()}
        </td>
      )}
    </tr>
  );
}

function FkIndicator({
  column, referentialConstraints,
  showInferredForeignKeys, showOverriddenReferences,
  onTableDetails, onTablePrefetch,
}: {
  column: TableColumnDto,
  referentialConstraints: ReferentialConstraintDto[],
  showInferredForeignKeys: boolean;
  showOverriddenReferences: boolean;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
}) {
  const fkIndRef = useRef(null);

  return (
    <>
      <span ref={fkIndRef}>{'<FK>'}</span>
      <UncontrolledTooltip
        target={fkIndRef}
        autohide={false}
        onMouseDown={(e) => e.stopPropagation()}>
        <h5>References</h5>
        {referentialConstraints.map((c) => (
          <ReferenceConstraintDetail
            key={c.id}
            constraint={c}
            column={column}
            onTableDetails={onTableDetails}
            onTablePrefetch={onTablePrefetch} />
        ))}
        {showInferredForeignKeys && isMmColumn(column)
          && (
            <RootEntityDetail
              column={column}
              showOverriddenReferences={showOverriddenReferences}
              onTableDetails={onTableDetails}
              onTablePrefetch={onTablePrefetch} />
          )}
      </UncontrolledTooltip>
    </>
  );
}

function RootEntityDetail({
  column, showOverriddenReferences, onTableDetails, onTablePrefetch,
}: {
  column: MmTableColumnDto,
  showOverriddenReferences: boolean;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
}) {
  const rootEntity = getRootEntity(column, showOverriddenReferences);

  return (
    <>
      {!!rootEntity && !!rootEntity.rootEntityName && !!rootEntity.rootEntityAttr
        && (
          <FkDetail
            constraintName="Root Entity"
            parentTableSchema="V500"
            parentTableName={rootEntity.rootEntityName}
            parentTableColumns={[rootEntity.rootEntityAttr]}
            sourceColumnIndex={1}
            onTableDetails={() => onTableDetails(`V500.${rootEntity.rootEntityName}`)}
            onTablePrefetch={onTablePrefetch} />
        )}
    </>
  );
}

function ReferenceConstraintDetail({
  constraint, column, onTableDetails, onTablePrefetch,
}: {
  constraint: ReferentialConstraintDto;
  column: TableColumnDto;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
}) {
  const columns = useMemo(() => {
    return [
      ...(constraint.parentConstraint?.constraintColumns ?? []),
    ].sort((a, b) => a.position - b.position)
      .map((c) => c.name);
  }, [constraint.parentConstraint?.constraintColumns]);

  const sourceColumnIndex = useMemo(() => {
    return constraint.constraintColumns.find((c) => c.tableColumn.id === column.id)?.position ?? 0;
  }, [column.id, constraint.constraintColumns]);

  return (
    <>
      {!!constraint.parentConstraint && (
        <FkDetail
          constraintName={constraint.name}
          parentTableSchema={constraint.parentConstraint?.schemaName}
          parentTableName={constraint.parentConstraint?.tableName}
          parentTableColumns={columns}
          sourceColumnIndex={sourceColumnIndex}
          onTableDetails={() => onTableDetails(`${constraint.parentConstraint?.schemaName}.${constraint.parentConstraint?.tableName}`)}
          onTablePrefetch={onTablePrefetch} />
      )}
    </>
  );
}

function FkDetail({
  constraintName, parentTableName, parentTableSchema, parentTableColumns, sourceColumnIndex,
  onTableDetails, onTablePrefetch,
}: {
  constraintName: string;
  parentTableName: string;
  parentTableSchema: string;
  parentTableColumns: string[];
  sourceColumnIndex: number;
  onTableDetails: () => void;
  onTablePrefetch: OnTablePrefetch;
}) {
  const [, drag] = useDrag(() => ({
    type: 'Table',
    item: {
      schema: parentTableSchema,
      name: parentTableName,
    },
  }));

  const fullyQualifiedName = `${parentTableSchema}.${parentTableName}`;

  return (
    <div className={styles['constraint-detail']}>
      <h6 className={styles.title}>{constraintName}</h6>
      <div
        ref={drag}
        onDoubleClick={() => onTableDetails()}
        onMouseDown={() => onTablePrefetch({
          name: parentTableName,
          schemaName: parentTableSchema,
        })}
        className={`h6 ${styles.table} cursor-grab`}>
        {fullyQualifiedName}
      </div>
      <ul>
        {
          parentTableColumns.map((c, i) => {
            return (
              <li key={c} className={i === sourceColumnIndex - 1 ? 'fw-bold' : ''}>
                {c}
              </li>
            );
          })
        }
      </ul>
    </div>
  );
}

function IndexIndicator({ indexes }: {
  indexes: TableIndexDto[];
}) {
  const indexIndRef = useRef(null);

  const sortedIndexes = useMemo(() => {
    return [...indexes].sort((a, b) => insensitiveCompare(a.name, b.name));
  }, [indexes]);

  return (
    <>
      <span ref={indexIndRef}>
        <Icon icon="index" />
      </span>
      <UncontrolledTooltip
        target={indexIndRef}
        autohide={false}
        onMouseDown={(e) => e.stopPropagation()}>
        <h5>Indexes</h5>
        {sortedIndexes.map((i) => <IndexDetail key={i.id} index={i} />)}
      </UncontrolledTooltip>
    </>
  );
}

function IndexDetail({ index }: {
  index: TableIndexDto;
}) {
  const columns = useMemo(() => {
    return [...index.tableIndexColumns].sort((a, b) => a.position - b.position);
  }, [index.tableIndexColumns]);

  return (
    <div className={styles['index-detail']}>
      <div className={`h6 ${styles.title}`}>{index.name}:</div>
      <ul>
        {
          columns.map((c) => {
            return (
              <li key={c.id}>
                {c.tableColumnName} {c.isDescending ? 'desc' : 'asc'}
              </li>
            );
          })
        }
      </ul>
    </div>
  );
}

function FlagIndicator({ flags }: {
  flags: MmTableColumnFlagDto[];
}) {
  const flagIndRef = useRef(null);

  const sortedFlags = useMemo(() => {
    return [...flags].sort((a, b) => a.value - b.value);
  }, [flags]);

  return (
    <>
      <span ref={flagIndRef}>
        <Icon icon="flag" />
      </span>
      <UncontrolledTooltip
        target={flagIndRef}
        autohide={false}
        onMouseDown={(e) => e.stopPropagation()}>
        <h5>Flags</h5>
        <table className={styles.flags}>
          <thead>
            <tr>
              <td>Value</td>
              <td>Definition</td>
              <td>Description</td>
            </tr>
          </thead>
          <tbody>
            {sortedFlags.map((f) => <FlagDetail key={f.value} flag={f} />)}
          </tbody>
        </table>
      </UncontrolledTooltip>
    </>
  );
}

function FlagDetail({ flag }: {
  flag: MmTableColumnFlagDto;
}) {
  return (
    <tr>
      <td>{flag.value}</td>
      <td>{flag.definition}</td>
      <td>{flag.description}</td>
    </tr>
  );
}
