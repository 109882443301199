export function objectMap<T, U>(
  obj: { [s: string]: T },
  fn: (v: T, k: string, i: number) => U,
) {
  return Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v, k, i)],
    ),
  );
}
