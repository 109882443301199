import { useMemo } from 'react';
import { OrganizationDto } from '../../../app/api';
import { insensitiveCompare } from '../../../util';

export function useSortedOrganizations<T extends OrganizationDto>(
  organizations: T[],
) {
  return useMemo(() => {
    return organizations
      ? [...organizations].sort((a, b) => insensitiveCompare(a.name, b.name))
      : organizations;
  }, [organizations]);
}
