import React from 'react';
import { TableSubDetailProps } from '..';
import { hasMmTableExtensions, useTableInfo } from '../../../../app/api';
import {
  ErrorMessage, ErrorWrapper, LoadingSpinner, ScrollArea,
} from '../../../../components/elements';
import { BooleanProperty, PropertyGroup, TextProperty } from '../../../../components/elements/summary';
import { useCodeSetDetails } from '../../codeSets';
import { COLUMN_STYLES } from '../../diagram';
import { DiagramTable } from '../../diagram/DiagramTable';
import { withBodyCategoryColorization } from '../../diagram/withBodyCategoryColorization';
import { withHeaderRowCountColorization } from '../../diagram/withHeaderRowCountColorization';
import { useLoadedCategoryColors, useLoadedThresholdColors } from '../../settings';
import styles from './TableSummary.module.css';

const ColorizedTable = withHeaderRowCountColorization(
  withBodyCategoryColorization(
    DiagramTable,
  ),
);

export function TableSummary({ tableName, parentTabId }: TableSubDetailProps) {
  const { table: data, isLoading, error } = useTableInfo(tableName);

  const { categories } = useLoadedCategoryColors();
  const { thresholds } = useLoadedThresholdColors();

  const openCodeSetDetails = useCodeSetDetails();

  return (
    <div className="m-2 d-flex flex-grow-1">
      <LoadingSpinner isLoading={isLoading} centered>
        <ErrorWrapper
          error={error}
          message={(
            <>
              <ErrorMessage statusCode={404}>
                <h4>Table {tableName} doesn&apos;t exist in the current harvest</h4>
              </ErrorMessage>
              <ErrorMessage>
                <h4>Unable to load details for table {tableName}</h4>
              </ErrorMessage>
            </>
          )}
          className="m-4">
          <ScrollArea id={`${parentTabId}-summary`}>
            <div className={`d-flex gap-2 ${styles.summary}`}>
              {!!data && (
                <div>
                  <ColorizedTable
                    categoryColors={categories}
                    headerColors={thresholds}
                    isSelected={false}
                    tableName={`${data.schemaName}.${data.name}`}
                    columnStyles={COLUMN_STYLES}
                    isLoading={isLoading}
                    onOpenCodeSet={openCodeSetDetails}
                    table={data}
                    sort="ByColumnId" />
                </div>
              )}

              <div className="d-flex flex-column gap-2 flex-grow-1">

                <PropertyGroup title="General">
                  <TextProperty name="Schema" value={data?.schemaName} />
                  <TextProperty name="Name" value={data?.name} />
                  <TextProperty name="Category" value={data?.projectCategory?.name} />
                  <TextProperty name="Comments" value={data?.comments} />
                  <TextProperty name="Row Count" value={data?.rowCount?.toLocaleString()} />
                  <BooleanProperty name="Is View" value={data?.isView} />
                </PropertyGroup>

                {hasMmTableExtensions(data)
                  && (
                    <PropertyGroup title="Millennium">
                      <TextProperty name="Suffixed Table Name" value={data.suffixedTableName} />
                      <TextProperty name="Table Suffix" value={data?.tableSuffix} />
                      <TextProperty name="Data Model" value={data?.mmDataModel?.name} />
                      <TextProperty name="Factory Name" value={data?.factoryName} />
                      <TextProperty name="Definition" value={data?.definition} />
                      <TextProperty name="Description" value={data?.description} />
                      <BooleanProperty name="Has Default Row" value={data?.hasDefaultRow} />
                      <BooleanProperty name="Is Core" value={data?.isCore} />
                      <BooleanProperty name="Is Documented" value={data?.isDocumented} />
                      <BooleanProperty name="Is Reference" value={data?.isReference} />
                    </PropertyGroup>
                  )}

              </div>
            </div>
          </ScrollArea>
        </ErrorWrapper>
      </LoadingSpinner>
    </div>
  );
}
