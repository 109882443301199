import React, { useEffect, useMemo } from 'react';
import {
  Alert,
  Button,
  Form,
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { matchSorter } from 'match-sorter';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Icon, LoadingSpinner, DropdownSelect, ErrorMessage, ErrorAlert,
} from '../../../../../../components/elements';
import { DiagramWithChildrenDto, AccountDto } from '../../../../../../app/api';

type TransferOwnershipInputs = {
  user: AccountDto | undefined;
}

export function TransferOwnershipDialog({
  diagram, error, users, isLoading, isOpen, isSaving,
  onClose, onTransferOwnership,
}: {
  diagram: DiagramWithChildrenDto | undefined;
  error: unknown;
  users: AccountDto[];
  isLoading: boolean;
  isOpen: boolean;
  isSaving: boolean;
  onClose: () => void;
  onTransferOwnership: (newOwner: AccountDto) => void;
}) {
  const validationIssues = useTransferOwnershipValidation(diagram);

  const {
    control, handleSubmit, formState: { errors, isValid }, reset,
  } = useForm<TransferOwnershipInputs>();

  useEffect(() => reset(), [diagram, reset]);

  const submitForm: SubmitHandler<TransferOwnershipInputs> = (formData) => {
    if (formData.user) onTransferOwnership(formData.user);
  };

  const canSave = !isSaving
    && !isLoading
    && users.length > 0
    && isValid;

  return (
    <Modal isOpen={isOpen} toggle={onClose} autoFocus={false}>
      <ModalHeader toggle={onClose}>
        <LoadingSpinner isLoading={isSaving || isLoading}><Icon icon="diagram" /></LoadingSpinner>
        {' '}Transfer Ownership of Diagram {diagram?.name}
      </ModalHeader>
      <ModalBody className="d-flex flex-column gap-3">
        <div>
          Transferring ownership gives control of the diagram to the selected user.
          This action cannot be undone.
        </div>

        <ErrorAlert error={error}>
          <ErrorMessage statusCode={404}>Diagram could not be found.</ErrorMessage>
          <ErrorMessage statusCode={403}>
            Insufficient privileges to transfer ownership.
          </ErrorMessage>
          <ErrorMessage>Unable to transfer ownership.</ErrorMessage>
        </ErrorAlert>

        {validationIssues.map((w) => (
          <TransferValidationIssueAlert
            key={w.issueType}
            issue={w} />
        ))}

        {!isLoading && users.length <= 0 && (
          <Alert color="danger" className="d-flex gap-1 mb-0">
            <span><Icon icon="error" /></span>
            <span>There are no valid users to transfer ownership too.</span>
          </Alert>
        )}

        <Form onSubmit={handleSubmit(submitForm)}>
          <Controller
            name="user"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DropdownSelect
                items={users}
                initialItem={field.value}
                disabled={users.length <= 0}
                autofocus
                label="Transfer to User:"
                onChange={field.onChange}
                itemToString={(item) => (item ? `${item.fullName} (${item.email})` : '')}
                filterItems={(items, filter) => matchSorter(items, filter ?? '', { keys: ['fullName', 'email'] })}
                getItemKey={(item) => item.id}
                placeholder="Select a user"
                invalid={!!errors.user}
                validationMessage="A user must be selected to transfer ownership">
                {(item) => `${item.fullName} (${item.email})`}
              </DropdownSelect>
            )} />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleSubmit(submitForm)} disabled={!canSave}>
          <Icon icon="changeUser" /> Transfer
        </Button>
        <Button color="secondary" onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

type TransferOwnershipValidationIssueType = 'locked' | 'private';

type TransferOwnershipValidator = {
  hasError: (diagram: DiagramWithChildrenDto) => boolean;
  issueType: TransferOwnershipValidationIssueType;
  message: string;
}

type TransferOwnershipValidatorIssue = Pick<TransferOwnershipValidator, 'issueType' | 'message'>;

const TransferOwnershipValidators: TransferOwnershipValidator[] = [
  {
    issueType: 'locked',
    hasError: (diagram) => diagram.isLocked,
    message: 'This diagram is locked. Transferring ownership will prevent you from making further edits to this diagram.',
  },
  {
    issueType: 'private',
    hasError: (diagram) => diagram.isPrivate,
    message: 'This diagram is private. Transferring ownership will prevent you from viewing this diagram.',
  },
];

function useTransferOwnershipValidation(
  diagram: DiagramWithChildrenDto | undefined,
): TransferOwnershipValidatorIssue[] {
  return useMemo(() => {
    return TransferOwnershipValidators
      .filter((v) => diagram && v.hasError(diagram))
      .map((v) => ({ issueType: v.issueType, message: v.message }));
  }, [diagram]);
}

function TransferValidationIssueAlert({ issue }: {
  issue: TransferOwnershipValidatorIssue;
}) {
  return (
    <Alert color="warning" className="d-flex gap-1 mb-0">
      <span><Icon icon="error" /></span>
      <span>{issue.message}</span>
    </Alert>
  );
}
