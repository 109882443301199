import React, { useMemo, useRef } from 'react';
import {
  Button, ListGroup, UncontrolledTooltip,
} from 'reactstrap';
import { SearchColumnsResponseItem, TableColumnDto, useUserApiSearchColumnsQuery } from '../../../app/api';
import {
  // eslint-disable-next-line max-len
  ErrorWrapper, ExpansionMap, Icon, LoadingSpinner, ScrollArea, SearchBox, TreeNode, useExpansionMap,
} from '../../../components/elements';
import { useSearchBoxValue } from '../../../components/elements/searchBox/searchBoxSlice';
import { useDebouncedEventHandler, useToggle } from '../../../hooks';
import { insensitiveCompare } from '../../../util';
import { useProject, useProjectPreferences } from '../../project';
import { useCurrentDatabase } from '../DatabaseContext';
import { CategoryPreferenceMap, useLoadedCategoryColors } from '../settings';
import {
  TableFilterDialog, TableItem, useFilteredTables, useTableDetails,
} from '../tables';
import { OnTablePrefetch, useTablePrefetch } from '../tables/useTablePrefetch';

type SchemaNode = {
  schemaId: string;
  schemaName: string;
  tables: SearchColumnsResponseItem[]
}

export function ColumnSearch() {
  const { databaseId } = useCurrentDatabase();

  const { projectId } = useProject();
  const stateScopeId = `${projectId}-columnSearch`;

  const [filter, setFilter] = useSearchBoxValue(stateScopeId);
  const trimmedFilter = filter.trim();
  const debouncedFilter = useDebouncedEventHandler(setFilter, 1000);

  const { currentData: tables, isFetching: isLoading, error } = useUserApiSearchColumnsQuery({
    harvestId: databaseId,
    filter: trimmedFilter,
  }, { skip: trimmedFilter.length < 3 });

  const onTableDetails = useTableDetails();
  const onTablePrefetch = useTablePrefetch();

  const { value: filterOpen, setValue: setFilterOpen } = useToggle(false);

  const { categories, isLoading: categoriesLoading } = useLoadedCategoryColors();

  const {
    preferences,
    isSaving,
    setCategory,
    isLoading: isLoadingPreferences,
    setRowCount,
  } = useProjectPreferences();

  const onSetRowCountFilter = useDebouncedEventHandler(setRowCount, 300);

  const filteredTables = useFilteredTables(tables ?? [], '', preferences);

  return (
    <>
      <ColumnSearchView
        tables={filteredTables}
        filter={filter}
        isLoading={isLoading}
        categories={categories}
        error={error}
        stateScopeId={stateScopeId}
        onSetFilter={debouncedFilter}
        onTableDetails={onTableDetails}
        onTablePrefetch={onTablePrefetch}
        onOpenFilter={() => setFilterOpen(true)} />

      <TableFilterDialog
        isLoading={isLoadingPreferences || categoriesLoading}
        isOpen={filterOpen}
        isSaving={isSaving}
        preferences={preferences}
        categories={categories}
        onSetRowCountFilter={onSetRowCountFilter}
        onClose={() => setFilterOpen(false)}
        onSaveCategory={setCategory} />
    </>
  );
}

export function ColumnSearchView({
  tables: columns, filter, isLoading, error, categories, stateScopeId,
  onSetFilter, onTableDetails, onTablePrefetch, onOpenFilter,
}: {
  tables: SearchColumnsResponseItem[];
  filter: string;
  isLoading: boolean;
  error: unknown;
  categories: CategoryPreferenceMap;
  stateScopeId: string;
  onSetFilter: (filterText: string) => void;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
  onOpenFilter: () => void;
}) {
  const { expansionMap, setExpansion } = useExpansionMap(stateScopeId);

  const schemas = useMemo(() => (
    Object.values(
      columns.reduce((acc: { [k: string]: SchemaNode }, t) => {
        const index = t.schemaId;
        if (acc[index]) {
          acc[index].tables.push(t);
        } else {
          acc[index] = {
            schemaId: t.schemaId,
            schemaName: t.schemaName,
            tables: [t],
          };
        }
        return acc;
      }, {}),
    ).sort((a, b) => insensitiveCompare(a.schemaName, b.schemaName))
  ), [columns]);

  return (
    <div className="d-flex flex-grow-1 flex-column gap-2">
      <div className="d-flex flex-row gap-1">
        <SearchBox
          placeholder="Find a column..."
          initialSearch={filter}
          onFilterChange={onSetFilter} />

        <Button onClick={onOpenFilter}>
          <Icon icon="filter" />
        </Button>
      </div>
      <LoadingSpinner isLoading={isLoading}>
        <ErrorWrapper error={error} message="Unable to complete search">
          {filter.length < 3
            ? <p>Enter a search term at least 3 characters long</p>
            : (
              <ScrollArea id={stateScopeId}>
                <ListGroup>
                  {schemas.map((s) => (
                    <SchemaGroup
                      key={s.schemaId}
                      schema={s}
                      filter={filter}
                      categories={categories}
                      expansionMap={expansionMap}
                      onTableDetails={onTableDetails}
                      onTablePrefetch={onTablePrefetch}
                      onSetExpanded={setExpansion} />
                  ))}
                </ListGroup>
              </ScrollArea>
            )}
        </ErrorWrapper>
      </LoadingSpinner>
    </div>
  );
}

function SchemaGroup({
  schema, filter, categories, expansionMap,
  onTableDetails, onTablePrefetch, onSetExpanded,
}: {
  schema: SchemaNode;
  filter: string;
  categories: CategoryPreferenceMap,
  expansionMap: ExpansionMap;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
  onSetExpanded: (nodeId: string, expanded: boolean) => void;
}) {
  const childCount = schema.tables.length;
  return (
    <>
      {
        (childCount > 0) && (
          <TreeNode
            label={schema.schemaName}
            childCount={childCount}
            filter={filter}
            defaultExpanded
            autoExpandThreshold={Number.MAX_VALUE}
            className="pe-2"
            id={`schema-${schema.schemaId}`}
            expansionMap={expansionMap}
            onSetExpanded={onSetExpanded}>
            {
              schema.tables
                .sort((a, b) => insensitiveCompare(a.name, b.name))
                .map((t) => (
                  <TableGroup
                    key={`${schema.schemaId}-${t.id}`}
                    table={t}
                    categories={categories}
                    filter={filter}
                    expansionMap={expansionMap}
                    onTableDetails={onTableDetails}
                    onTablePrefetch={onTablePrefetch}
                    onSetExpanded={onSetExpanded} />
                ))
            }
          </TreeNode>
        )
      }
    </>
  );
}

function TableGroup({
  table, categories, filter, expansionMap,
  onTableDetails, onTablePrefetch, onSetExpanded,
}: {
  table: SearchColumnsResponseItem;
  categories: CategoryPreferenceMap;
  filter: string;
  expansionMap: ExpansionMap;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
  onSetExpanded: (nodeId: string, expanded: boolean) => void;
}) {
  const childCount = table.tableColumns.length;
  return (
    <TreeNode
      childCount={childCount}
      filter={filter}
      defaultExpanded
      autoExpandThreshold={Number.MAX_VALUE}
      className="pe-0 pt-0 pb-1 border-0"
      id={`table-${table.schemaName}.${table.name}`}
      expansionMap={expansionMap}
      onSetExpanded={onSetExpanded}
      showChildCount={false}
      label={(
        <TableItem
          table={table}
          categoryPrefMap={categories}
          onTableDetails={() => onTableDetails(`${table.schemaName}.${table.name}`)}
          onTableFetch={onTablePrefetch} />
      )}>

      {
        table.tableColumns
          .map((c) => (
            <ColumnName column={c} key={c.id} />
          ))
      }
    </TreeNode>
  );
}

function ColumnName({ column }: {
  column: TableColumnDto;
}) {
  const tooltipRef = useRef(null);

  return (
    <>
      <li ref={tooltipRef} className="ps-3 hover text-truncate">
        {column.name}
      </li>
      <UncontrolledTooltip target={tooltipRef}>
        {column.name}
      </UncontrolledTooltip>
    </>
  );
}
