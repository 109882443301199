import { ResizeCallbackData } from 'react-resizable';
import { SizeDefinition } from './SizeDefinition';
import { useSharedSize } from './sizeSlice';

type HandleResizeCallback = (data: Omit<ResizeCallbackData, 'node'>) => void;

export type UseResizeResult = {
  calculatedSize: SizeDefinition;
  startResize: () => void;
  endResize: () => void;
  handleResize: HandleResizeCallback;
}

const ORIGIN_SCALE_ADJUSTMENT = 2;

export function useResize(size: SizeDefinition, id?: string): UseResizeResult {
  const [newSize, setNewSize] = useSharedSize(id);

  const calculatedSize: SizeDefinition = {
    height: newSize?.height ?? size.height,
    width: newSize?.width ?? size.width,
    x: newSize?.x ?? size.x,
    y: newSize?.y ?? size.y,
  };

  const handleResize: HandleResizeCallback = (data) => {
    if (!newSize) return;

    setNewSize({
      x: data.handle.includes('w')
        ? newSize.x + (newSize.width - data.size.width) / ORIGIN_SCALE_ADJUSTMENT
        : newSize.x,
      y: data.handle.includes('n')
        ? newSize.y + (newSize.height - data.size.height) / ORIGIN_SCALE_ADJUSTMENT
        : newSize.y,
      height: data.handle.includes('n')
        ? newSize.height - (newSize.height - data.size.height) / ORIGIN_SCALE_ADJUSTMENT
        : newSize.height - (newSize.height - data.size.height),
      width: data.handle.includes('w')
        ? newSize.width - (newSize.width - data.size.width) / ORIGIN_SCALE_ADJUSTMENT
        : newSize.width - (newSize.width - data.size.width),
    });
  };

  const startResize = () => setNewSize(calculatedSize);
  const endResize = () => setNewSize(undefined);

  return {
    calculatedSize,
    startResize,
    endResize,
    handleResize,
  };
}
