import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './userPreferencesSlice';

const storageConfig = {
  key: 'userPreferences',
  storage,
};
const persistedReducer = persistReducer(storageConfig, reducer);

export * from './userPreferencesSlice';
export default persistedReducer;
