import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import {
  // eslint-disable-next-line max-len
  TableDto, MmTableDto, useUserApiGetTableByNameQuery, useUserApiGetMmTableByIdQuery, MmTableColumnDto,
} from '..';
import { useCurrentDatabase } from '../../../features/database/DatabaseContext';

export type MmExtendedTable = Omit<TableDto, 'tableColumns'>
  & Omit<MmTableDto, 'mmTableColumns'>
  & { tableColumns: MmTableColumnDto[] };

export type TableInfo = TableDto
  | MmExtendedTable;

export function useEncodedTableName(fullTableName: string | undefined) {
  const schemaName = encodeURIComponent(fullTableName?.split('.')[0] ?? '');
  const tableName = encodeURIComponent(fullTableName?.split('.')[1] ?? '');

  return {
    schemaName,
    tableName,
  };
}

export function useTableInfo(fullTableName: string | undefined, refetchOnMountOrArgChange = 300): {
  isLoading: boolean;
  isFetching: boolean;
  table: TableInfo | undefined;
  error: FetchBaseQueryError | SerializedError | undefined;
} {
  const { databaseId } = useCurrentDatabase();

  const { schemaName, tableName } = useEncodedTableName(fullTableName);
  const isMillennium = schemaName.toUpperCase() === 'V500';

  const {
    currentData: data, isLoading, isFetching, error,
  } = useUserApiGetTableByNameQuery({
    harvestId: databaseId,
    schemaName,
    tableName,
  }, {
    skip: !fullTableName,
    refetchOnMountOrArgChange,
  });

  const {
    currentData: mmData, isLoading: mmIsLoading, isFetching: mmIsFetching, error: mmError,
  } = useUserApiGetMmTableByIdQuery({
    tableId: data?.id ?? '',
  }, {
    skip: !data?.id || !isMillennium || (data?.isView ?? true),
    refetchOnMountOrArgChange,
  });

  const table = useMemo(() => {
    return data
      ? {
        ...data,
        ...mmData,
        tableColumns: mmData?.mmTableColumns ?? data.tableColumns,
      }
      : undefined;
  }, [mmData, data]);

  return {
    isLoading: isLoading || mmIsLoading,
    isFetching: isFetching || mmIsFetching,
    table,
    error: error ?? mmError,
  };
}
