import {
  Nav, TabContent,
} from 'reactstrap';
import React from 'react';
import {
  TableDto,
  useTableInfo,
  useUserApiListHarvestTablesQuery,
} from '../../../app/api';
import {
  DbTabHeader, TabHeader, TabHeaderProps,
} from '../tabs';
import { useCurrentDatabase } from '../DatabaseContext';
import { SchemaList } from './SchemaList';
import { TableFilterDialog } from './TableFilterDialog';
import { useProjectPreferences, useParentTabId } from '../../project';
import { useDebouncedEventHandler, useToggle } from '../../../hooks';
import { useTableDetails } from './hooks';
import { TableColumns } from './TableColumns';
import { useTablePrefetch } from './useTablePrefetch';
import { TableIndexes } from './TableIndexes';
import { TableConstraints } from './TableConstraints';
import { TableWiki } from './TableWiki';
import { useLoadedCategoryColors } from '../settings';
import { ViewText } from './ViewText';
import { useChildTab } from '../tabs/childTabSlice';
import { TableSummary } from './TableSummary';
import { RelatedTables } from './RelatedTables';
import styles from './Tables.module.css';

export function Tables() {
  const { databaseId } = useCurrentDatabase();

  const { data, isLoading, error } = useUserApiListHarvestTablesQuery({
    harvestId: databaseId,
  });

  const onTableDetails = useTableDetails();

  const {
    preferences,
    isSaving,
    setCategory,
    isLoading: isLoadingPreferences,
    setRowCount,
  } = useProjectPreferences();

  const onSetRowCountFilter = useDebouncedEventHandler(setRowCount, 300);

  const { value: filterOpen, setValue: setFilterOpen } = useToggle(false);

  const onTablePrefetch = useTablePrefetch();

  const { categories, isLoading: categoriesLoading } = useLoadedCategoryColors();

  return (
    <>
      <SchemaList
        tables={data ?? []}
        isLoading={isLoading}
        preferences={preferences}
        categories={categories}
        error={error}
        onTableDetails={onTableDetails}
        onTablePrefetch={onTablePrefetch}
        onOpenFilter={() => setFilterOpen(true)} />

      <TableFilterDialog
        isLoading={isLoadingPreferences || categoriesLoading}
        isOpen={filterOpen}
        isSaving={isSaving}
        preferences={preferences}
        categories={categories}
        onSetRowCountFilter={onSetRowCountFilter}
        onClose={() => setFilterOpen(false)}
        onSaveCategory={setCategory} />
    </>
  );
}

const tableDetailTabTypes = ['summary', 'wiki', 'columns', 'constraints', 'indexes', 'viewText', 'relatedTables'] as const;
type TableDetailTabType = typeof tableDetailTabTypes[number];

function isTableDetailTabType(value: string): value is TableDetailTabType {
  return tableDetailTabTypes.includes(value as TableDetailTabType);
}

type TableDetailTabDefinition = {
  type: TableDetailTabType;
  name: string;
  body: (props: TableSubDetailProps) => JSX.Element;
  isShown: (table: TableDto | undefined) => boolean;
};

const tableTabMap: { [K in TableDetailTabType]: TableDetailTabDefinition } = {
  summary: {
    type: 'summary',
    name: 'Summary',
    body: (props) => <TableSummary {...props} key={`${props.tableName}-summary`} />,
    isShown: () => true,
  },
  columns: {
    type: 'columns',
    name: 'Columns',
    body: (props) => <TableColumns {...props} key={`${props.tableName}-columns`} />,
    isShown: () => true,
  },
  constraints: {
    type: 'constraints',
    name: 'Constraints',
    body: (props) => <TableConstraints {...props} key={`${props.tableName}-constraints`} />,
    isShown: (table) => !!table && !table.isView,
  },
  indexes: {
    type: 'indexes',
    name: 'Indexes',
    body: (props) => <TableIndexes {...props} key={`${props.tableName}-indexes`} />,
    isShown: (table) => !!table && !table.isView,
  },
  relatedTables: {
    type: 'relatedTables',
    name: 'Related Tables',
    body: (props) => <RelatedTables {...props} key={`${props.tableName}-relatedTables`} />,
    isShown: (table) => !!table && !table.isView,
  },
  viewText: {
    type: 'viewText',
    name: 'View Text',
    body: (props) => <ViewText {...props} key={`${props.tableName}-viewtext`} />,
    isShown: (table) => !!table && table.isView,
  },
  wiki: {
    type: 'wiki',
    name: 'Wiki',
    body: (props) => <TableWiki {...props} key={`${props.tableName}-wiki`} />,
    isShown: () => true,
  },
};

export function TableDetailTabHeader({ tab }: TabHeaderProps) {
  return (<DbTabHeader tab={tab} display={tab.id} isLoading={false} isError={false} />);
}

export function TableDetailTabHistoryItem({ tab }: TabHeaderProps) {
  return <>{tab.id}</>;
}

export type TableDetailProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  tableName: string;
};

export type TableSubDetailProps = TableDetailProps & {
  // eslint-disable-next-line react/no-unused-prop-types
  parentTabId: string;
}

export function TableDetail(props: TableDetailProps) {
  const tabId = useParentTabId();

  const [tab, setTab] = useChildTab(tabId, 'summary');

  if (!isTableDetailTabType(tab)) {
    throw new Error(`Invalid table tab type of ${tab}. Valid values are ${JSON.stringify(tableDetailTabTypes)}`);
  }

  const { tableName } = props;
  const { table } = useTableInfo(tableName);

  return (
    <div className="ms-2 mt-2 flex-grow-1 d-flex flex-column">
      <Nav tabs>
        {Object.values(tableTabMap)
          .filter((tabDef) => tabDef.isShown(table))
          .map((tabDef) => (
            <TableTabHeader
              selectedTab={tab}
              definition={tabDef}
              setTab={setTab}
              key={tabDef.type}
              {...props} />
          ))}
      </Nav>
      <TabContent className={`d-flex flex-grow-1 flex-column ${styles['table-details-tab']}`} key={tab}>
        {tableTabMap[tab].body({
          ...props,
          parentTabId: tabId,
        })}
      </TabContent>
    </div>
  );
}

type TableTabHeaderProps = TableDetailProps & {
  selectedTab: TableDetailTabType;
  definition: TableDetailTabDefinition;
  setTab: (tab: TableDetailTabType) => void;
};

function TableTabHeader({ selectedTab, definition, setTab }: TableTabHeaderProps) {
  return (
    <TabHeader
      isActive={selectedTab === definition.type}
      onClick={() => setTab(definition.type)}
      title={definition.name} />
  );
}
