import { useCallback } from 'react';
import { UserPreference } from '../UserPreference';
import { UserPreferenceHookResult, useUserPreferences } from './useUserPreferences';

export function useUserPreferenceNumber(name: UserPreference, defaultValue: number)
  : UserPreferenceHookResult<number> {
  const [value, setValue] = useUserPreferences(name, defaultValue);
  const currentValue = value && typeof value === 'number'
    ? value
    : defaultValue;

  const updatePreference = useCallback((newValue: number) => {
    setValue(newValue);
  }, [setValue]);

  return [
    currentValue,
    updatePreference,
  ];
}
