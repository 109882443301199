import React from 'react';
import { OrganizationsMenu } from '../features/organizations/routes/OrganizationsMenu';
import { ProjectMenu } from '../features/project';
import { HarvestMenu } from '../features/database/HarvestMenu';
import { RequireAuth } from '../auth/requireAuth';

export function BreadCrumb() {
  return (
    <RequireAuth redirect={false}>
      <OrganizationsMenu>
        <ProjectMenu>
          <HarvestMenu />
        </ProjectMenu>
      </OrganizationsMenu>
    </RequireAuth>
  );
}
