import React from 'react';
import { CopyrightInfo } from '../../about';
import { FooterContainer } from './FooterContainer';

export function StandardFooter() {
  return (
    <FooterContainer>
      <CopyrightInfo />
    </FooterContainer>
  );
}
