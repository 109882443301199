import React from 'react';
import {
  Container, Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import pluralize from 'pluralize';
import {
  useUserApiDeleteImportJobMutation,
  useUserApiDeleteHarvestMutation,
  useUserApiListImportJobsQuery,
} from '../../app/api';
import { Icon } from '../../components/elements';
import { useModalDialog, useToggle } from '../../hooks';
import { HarvestList } from './components/HarvestList';
import { useProject } from './ProjectContext';
import { FirstHarvest } from './components/FirstHarvest';
import { useFavoriteHarvests } from './useFavoriteHarvests';
import { ImportQueueView } from './components/ImportQueue';
import { useHarvests } from '../../app/api/hooks/useHarvests';
import { DeleteHarvestDialog } from './components/DeleteHarvestDialog';
import { HarvestListItem } from '../database/useSortedHarvests';
import { HarvestUploader } from './components/HarvestUploader';

export function ProjectDetails() {
  const { projectName } = useProject();

  return (
    <Container fluid className="p-5">
      <h1>
        <Icon icon="project" /> {projectName}
      </h1>
      <ImportProgress />
      <LoadedHarvestList />
    </Container>
  );
}

function LoadedHarvestList() {
  const { harvests: data, error, isLoading } = useHarvests({ pollingInterval: 30000 });
  const { projectId } = useProject();

  const {
    favoriteHarvests,
    addFavoriteHarvest,
    removeFavoriteHarvest,
  } = useFavoriteHarvests(data);

  const [deleteHarvest] = useUserApiDeleteHarvestMutation();

  const {
    isOpen: isDeleteDialogOpen,
    closeDialog: closeDeleteDialog,
    openDialog: openDeleteDialog,
    data: currentHarvest,
  } = useModalDialog<HarvestListItem>();

  const onDelete = () => {
    if (currentHarvest) {
      deleteHarvest({
        projectId,
        harvestId: currentHarvest.id,
      });
    }
    closeDeleteDialog();
  };

  if (data?.length === 0) {
    return (
      <HarvestUploader>
        {(onUpload) => <FirstHarvest onUpload={onUpload} />}
      </HarvestUploader>
    );
  }
  return (
    <HarvestUploader>
      {(onUpload) => (
        <>
          <HarvestList
            harvests={favoriteHarvests}
            onUpload={onUpload}
            onFavoriteHarvest={addFavoriteHarvest}
            onUnFavoriteHarvest={removeFavoriteHarvest}
            onDeleteHarvest={(h) => openDeleteDialog(h)}
            isLoading={isLoading}
            error={error} />
          <DeleteHarvestDialog
            isOpen={isDeleteDialogOpen}
            harvest={currentHarvest}
            onClose={closeDeleteDialog}
            onDelete={onDelete} />
        </>
      )}
    </HarvestUploader>
  );
}

function ImportProgress() {
  const { projectId } = useProject();

  const { currentData: jobs, isLoading, error } = useUserApiListImportJobsQuery({
    projectId,
    includeFailed: true,
    includeCompleted: false,
    includeInProgress: true,
    includeQueued: true,
  }, { pollingInterval: 30000 });

  const [persistDeleteJob, { reset: resetDelete }] = useUserApiDeleteImportJobMutation();

  const deleteJob = async (jobId: string) => {
    await persistDeleteJob({
      projectId,
      importJobId: jobId,
    });
    resetDelete();
  };

  const { value: isOpen, toggleValue: toggleOpen, setValue: setOpen } = useToggle(false);
  const { value: showFailed, setValue: setShowFailed } = useToggle(false);

  const failedJobs = jobs?.filter((j) => j.status === 'Failed') ?? [];
  const otherJobs = jobs?.filter((j) => j.status !== 'Failed') ?? [];

  if (isOpen) {
    if (!showFailed && otherJobs.length === 0) {
      if (failedJobs.length >= 1) {
        setShowFailed(true);
      } else {
        setOpen(false);
      }
    } else if (showFailed && failedJobs.length === 0) {
      setOpen(false);
    }
  }

  return (
    <>
      {!!jobs && jobs.length > 0
        && (
          <p className="lead">
            {otherJobs.length > 0 && (
              <>
                <span
                  className="link-primary cursor-pointer"
                  onClick={() => {
                    setShowFailed(false);
                    toggleOpen();
                  }}>
                  {pluralize('Harvest', otherJobs.length, true)}
                </span> Pending Import
              </>
            )}
            {failedJobs.length > 0 && (
              <>
                {otherJobs.length > 0 && <>{', '}</>}
                <span
                  className="link-primary cursor-pointer"
                  onClick={() => {
                    setShowFailed(true);
                    toggleOpen();
                  }}>
                  <Icon icon="error" className="text-warning" />
                  {' '}
                  {pluralize('Harvest', failedJobs.length, true)}
                </span> Failed to Import
              </>
            )}
          </p>
        )}
      <Modal isOpen={isOpen} size="xl">
        <ModalHeader toggle={toggleOpen}>
          {showFailed
            ? 'Failed Import Jobs'
            : 'Import Jobs'}
        </ModalHeader>
        <ModalBody>
          <ImportQueueView
            jobs={showFailed ? failedJobs : otherJobs}
            isLoading={isLoading}
            error={error}
            OnDeleteJob={deleteJob} />
        </ModalBody>
      </Modal>
    </>
  );
}
