import {
  createEntityAdapter, createSlice, EntityState, PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type SideBarType = 'tables' | 'models' | 'codeSets' | 'diagrams' | 'home' | 'columnSearch' | 'codeSetSearch';

export type TabType = Exclude<SideBarType, 'colors' | 'models' | 'columnSearch'>;

export type Tab = {
  type: TabType,
  id: string,
};

interface ProjectState {
  id: string,
  sideBar?: SideBarType
  tabs: Tab[];
  activeTab: Tab;
}

const homeTab: Tab = {
  id: 'home',
  type: 'home',
};

const projectsAdapter = createEntityAdapter<ProjectState>();

export const getInitialProjectState = (id: string): ProjectState => ({
  id,
  tabs: [homeTab],
  activeTab: homeTab,
});

const initialState = projectsAdapter.getInitialState();

interface ProjectAction<T> {
  projectId: string;
  data: T;
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setSidebar: (state, action: PayloadAction<ProjectAction<SideBarType>>) => {
      const project = getOrRegisterProject(state, action.payload.projectId);
      if (project.sideBar === action.payload.data) {
        project.sideBar = undefined;
      } else {
        project.sideBar = action.payload.data;
      }
    },
    openTab: (state, action: PayloadAction<ProjectAction<Tab>>) => {
      const project = getOrRegisterProject(state, action.payload.projectId);
      const existingTab = project?.tabs.find((t) => areTabsEqual(t, action.payload.data));
      if (!existingTab) { project.tabs.push(action.payload.data); }
      project.activeTab = existingTab ?? action.payload.data;
    },
    closeTab: (state, action: PayloadAction<ProjectAction<Tab>>) => {
      const project = getOrRegisterProject(state, action.payload.projectId);
      const index = project.tabs.findIndex((t) => areTabsEqual(t, action.payload.data));
      project.tabs = project.tabs.filter((t) => !areTabsEqual(t, action.payload.data));
      if (areTabsEqual(project.activeTab, action.payload.data)) {
        project.activeTab = project.tabs[Math.max(index - 1, 0)];
      }
    },
  },
});

function getOrRegisterProject(state: EntityState<ProjectState>, projectId: string) {
  let project = state.entities[projectId];
  if (project) {
    return project;
  }
  project = getInitialProjectState(projectId);
  projectsAdapter.addOne(state, project);
  return project;
}

export function areTabsEqual(a?: Tab, b?: Tab) {
  return a?.id === b?.id && a?.type === b?.type;
}

export const { setSidebar, openTab, closeTab } = projectSlice.actions;

export const {
  selectById: selectProjectById,
} = projectsAdapter.getSelectors((state: RootState) => state.sessionReducers.project);

export default projectSlice.reducer;
