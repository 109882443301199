import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, Col, Form, FormGroup, Input, Label,
} from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { DEFAULT_CATEGORY_COLOR } from '..';
import {
  ColorInput, DeleteButton, Icon, LoadingSpinner,
} from '../../../../components/elements';

type Threshold = {
  id: string;
  threshold: number;
  color: string;
}

export function ThresholdSettings({ thresholds, isLoading, onChangeThreshold }: {
  thresholds: Threshold[];
  isLoading: boolean;
  onChangeThreshold: (newThresholds: Threshold[]) => void;
}) {
  const removeThreshold = (t: Threshold) => thresholds.filter((tr) => t.id !== tr.id);

  return (
    <>
      <h5 className="m-0 d-flex flex-row">
        <span className="flex-grow-1">Row Count/Header Colors</span>
        <NewThresholdButton
          onCreate={() => onChangeThreshold([
            ...thresholds,
            { id: uuid(), threshold: 0, color: '#ffffff' },
          ])} />
      </h5>
      <LoadingSpinner isLoading={isLoading}>
        <Form>
          {[...thresholds]
            .sort((a, b) => a.threshold - b.threshold)
            .map((t) => (
              <ThresholdSetting
                key={t.id}
                threshold={t}
                onChange={(threshold) => (
                  onChangeThreshold([...removeThreshold(t), threshold]))}
                onDelete={() => onChangeThreshold(removeThreshold(t))} />
            ))}
        </Form>
      </LoadingSpinner>
    </>
  );
}

function ThresholdSetting({ threshold, onChange, onDelete }: {
  threshold: Threshold;
  onChange: (t: Threshold) => void;
  onDelete: () => void;
}) {
  const [value, setValue] = useState(threshold.threshold);

  useEffect(() => {
    setValue(threshold.threshold);
  }, [threshold.threshold]);

  const onThresholdChange = useCallback((newValue: number) => {
    if (!newValue || newValue < Number.MAX_SAFE_INTEGER) {
      onChange({ id: threshold.id, color: threshold.color, threshold: newValue });
      setValue(newValue);
    }
  }, [threshold, onChange]);

  const isMaxThreshold = value >= Number.MAX_SAFE_INTEGER;

  return (
    <FormGroup row>
      <Col sm={4}>
        {!isMaxThreshold
          ? (
            <Input
              type="number"
              className="form-control"
              value={value}
              min={0}
              max={Number.MAX_SAFE_INTEGER - 1}
              onChange={(e) => onThresholdChange(parseInt(e.target.value, 10))} />
          )
          : (
            <Input
              type="text"
              className="form-control"
              disabled
              value="MAX" />
          )}
      </Col>
      <Label sm={3}>
        or fewer rows
      </Label>
      <Col sm={4}>
        <ColorInput
          defaultColor={DEFAULT_CATEGORY_COLOR}
          value={threshold.color}
          onChange={(color) => onChange({ ...threshold, color })} />
      </Col>
      <Label sm={1}>
        {!isMaxThreshold && <DeleteButton onClick={onDelete} color="danger" />}
      </Label>
    </FormGroup>
  );
}

function NewThresholdButton({ onCreate }: { onCreate: () => void; }) {
  return (
    <Button size="sm" color="success" onClick={() => onCreate()}>
      <Icon icon="plus" /> New Threshold
    </Button>
  );
}
