import React, { useMemo, useRef } from 'react';
import {
  // eslint-disable-next-line max-len
  Button, DropdownToggle, ListGroup, ListGroupItem, ListGroupItemHeading, Nav, NavItem, NavLink, UncontrolledDropdown, UncontrolledTooltip,
} from 'reactstrap';
import { DiagramWithChildrenDto, DiagramTagDto } from '../../../../../app/api';
import {
  Icon, IconName, LoadingSpinner, Menu, MenuDefinition, ScrollArea, SearchBox,
} from '../../../../../components/elements';
import styles from './DiagramList.module.css';
import { useIsOwner } from '../../hooks';
import { FavoriteDiagramMap } from '../hooks';
import { insensitiveCompare } from '../../../../../util';
import { DiagramTag } from '../DiagramTag';
import { isDiagramOwner } from '../../util';

export type DiagramListType = 'all' | 'favorites' | 'my' | 'recent';

export type DiagramListItem = DiagramWithChildrenDto & {
  isFavorite: boolean;
}

export function DiagramList({
  diagrams,
  favoriteMap,
  isLoading,
  listType,
  searchString,
  stateScopeId,
  onCreateDiagram,
  onDeleteDiagram,
  onFilterChange,
  onRenameDiagram,
  onLockDiagram,
  onUnlockDiagram,
  onOpenDiagram,
  onSetListType,
  onFavoriteDiagram,
  onUnFavoriteDiagram,
  onHideDiagram,
  onShareDiagram,
  onEditTags,
  onTransferOwnership,
  onCopy,
  diagramPreview,
}: {
  diagrams: DiagramWithChildrenDto[];
  favoriteMap: FavoriteDiagramMap;
  isLoading: boolean;
  listType: DiagramListType;
  searchString: string;
  stateScopeId: string;
  onCreateDiagram: () => void;
  onDeleteDiagram: (diagram: DiagramWithChildrenDto) => void;
  onFilterChange: (filter: string) => void;
  onRenameDiagram: (diagram: DiagramWithChildrenDto) => void;
  onLockDiagram: (diagram: DiagramWithChildrenDto) => void
  onUnlockDiagram: (diagram: DiagramWithChildrenDto) => void
  onOpenDiagram: (id: string) => void;
  onSetListType: (type: DiagramListType) => void;
  onFavoriteDiagram: (id: string) => void;
  onUnFavoriteDiagram: (id: string) => void;
  onHideDiagram: (diagram: DiagramWithChildrenDto) => void;
  onShareDiagram: (diagram: DiagramWithChildrenDto) => void;
  onEditTags: (diagram: DiagramWithChildrenDto) => void;
  onTransferOwnership: (diagram: DiagramWithChildrenDto) => void;
  onCopy: (diagram: DiagramWithChildrenDto) => void;
  diagramPreview: (diagramId: string) => React.ReactElement<{ diagramId: string }>
}) {
  return (
    <div className="d-flex flex-grow-1 flex-column gap-2">
      <NewDiagramButton onCreate={() => onCreateDiagram()} />

      <LoadingSpinner isLoading={isLoading}>
        <SearchBox
          placeholder="Find a diagram..."
          initialSearch={searchString}
          onFilterChange={onFilterChange} />

        <Nav pills justified>
          <DiagramListTypeLink
            currentType={listType}
            icon="favorite"
            name="Favorites"
            type="favorites"
            setType={onSetListType} />
          <DiagramListTypeLink
            currentType={listType}
            icon="recent"
            name="Recent"
            type="recent"
            setType={onSetListType} />
          <DiagramListTypeLink
            currentType={listType}
            icon="user"
            name="My Diagrams"
            type="my"
            setType={onSetListType} />
          <DiagramListTypeLink
            currentType={listType}
            icon="diagram"
            name="All Diagrams"
            type="all"
            setType={onSetListType} />
        </Nav>

        <ScrollArea id={stateScopeId}>
          <ListGroup className="flex-grow-1">
            {diagrams
              .map((d) => (
                <DiagramListItem
                  key={d.id}
                  diagram={{
                    ...d,
                    isFavorite: favoriteMap[d.id],
                  }}
                  onDelete={() => onDeleteDiagram(d)}
                  onRename={() => onRenameDiagram(d)}
                  onOpenDiagram={() => onOpenDiagram(d.id)}
                  onLock={() => onLockDiagram(d)}
                  onUnlock={() => onUnlockDiagram(d)}
                  onFavoriteDiagram={() => onFavoriteDiagram(d.id)}
                  onUnFavoriteDiagram={() => onUnFavoriteDiagram(d.id)}
                  onHide={() => onHideDiagram(d)}
                  onShare={() => onShareDiagram(d)}
                  onEditTags={() => onEditTags(d)}
                  onTransferOwnership={() => onTransferOwnership(d)}
                  onCopy={() => onCopy(d)}
                  preview={diagramPreview(d.id)} />
              ))}
          </ListGroup>
        </ScrollArea>
      </LoadingSpinner>
    </div>
  );
}

function DiagramListTypeLink({
  type, icon, name, currentType, setType,
}: {
  type: DiagramListType;
  icon: IconName;
  name: string;
  currentType: DiagramListType;
  setType: (t: DiagramListType) => void;
}) {
  return (
    <NavItem className="cursor-pointer">
      <NavLink
        active={currentType === type}
        onClick={() => setType(type)}
        className={`d-flex flex-column pt-1 pb-1 ${styles['list-type-selector']}`}>
        <span>
          <Icon icon={icon} />
        </span>
        <small>
          {name}
        </small>
      </NavLink>
    </NavItem>
  );
}

function DiagramListItem({
  diagram,
  onDelete, onRename, onOpenDiagram, onLock, onUnlock, onFavoriteDiagram, onUnFavoriteDiagram,
  onHide, onShare, onEditTags, onTransferOwnership, onCopy,
  preview,
}: {
  diagram: DiagramListItem;
  onDelete: () => void;
  onRename: () => void;
  onOpenDiagram: () => void;
  onLock: () => void;
  onUnlock: () => void;
  onFavoriteDiagram: () => void;
  onUnFavoriteDiagram: () => void;
  onHide: () => void;
  onShare: () => void;
  onEditTags: () => void;
  onTransferOwnership: () => void;
  onCopy: () => void;
  preview: React.ReactElement<{ diagramId: string }>
}) {
  const ref = useRef(null);
  const lockRef = useRef(null);
  const isOwner = useIsOwner(diagram);

  const lockIcon = diagram.isLocked
    ? <span ref={lockRef}><Icon icon="locked" /></span>
    : null;

  return (
    <ListGroupItem action>
      <div ref={ref}>
        <ListGroupItemHeading>
          <div className="d-flex align-items-start">
            <span
              className="me-auto cursor-pointer link-primary text-underline-hover pe-1"
              onClick={() => onOpenDiagram()}>
              {diagram.name}
            </span>
            <UncontrolledDropdown>
              <DropdownToggle className={`btn-sm ${styles['dropdown-toggle']}`} caret />
              <DiagramListItemMenu
                diagram={diagram}
                onDelete={() => onDelete()}
                onRename={() => onRename()}
                onLock={() => onLock()}
                onUnlock={() => onUnlock()}
                onFavoriteDiagram={() => onFavoriteDiagram()}
                onUnFavoriteDiagram={() => onUnFavoriteDiagram()}
                onHide={() => onHide()}
                onShare={() => onShare()}
                onEditTags={() => onEditTags()}
                onTransferOwnership={() => onTransferOwnership()}
                onCopy={() => onCopy()} />
            </UncontrolledDropdown>
          </div>
        </ListGroupItemHeading>
        <span className="d-flex flex-wrap gap-2">
          <span className="text-muted d-flex gap-2 flex-grow-1">
            <span
              className="text-nowrap"
              style={{ whiteSpace: 'nowrap' }}>
              <Icon icon="table" /> {diagram.tableCount.toLocaleString()}
            </span>
            <span><Icon icon="user" /> {diagram.ownerFullName}</span>
            {lockIcon}
            {diagram.isFavorite && <span><Icon icon="favorite" /></span>}
            <DiagramVisibilityIcon diagram={diagram} isOwner={isOwner} />
            <UncontrolledTooltip
              target={lockRef}
              placement="right">Edits restricted to {diagram.ownerFullName}
            </UncontrolledTooltip>
          </span>
          <div className="align-bottom">
            <DiagramTags tags={diagram.tags} />
          </div>
        </span>
      </div>
      <UncontrolledTooltip target={ref} placement="right" delay={{ show: 250, hide: 0 }}>
        {preview}
      </UncontrolledTooltip>
    </ListGroupItem>
  );
}

function DiagramListItemMenu({
  diagram,
  onDelete, onRename, onLock, onUnlock, onFavoriteDiagram, onUnFavoriteDiagram,
  onHide, onShare, onEditTags, onTransferOwnership, onCopy,
}: {
  diagram: DiagramListItem;
  onDelete: () => void;
  onRename: () => void;
  onLock: () => void;
  onUnlock: () => void;
  onFavoriteDiagram: () => void;
  onUnFavoriteDiagram: () => void;
  onHide: () => void;
  onShare: () => void;
  onEditTags: () => void;
  onTransferOwnership: () => void;
  onCopy: () => void;
}) {
  const menuActions: MenuDefinition<DiagramListItem> = [
    {
      id: 'edit',
      actions: [
        {
          icon: 'edit',
          title: 'Rename',
          action: onRename,
          canExecute: isDiagramOwner,
        },
        {
          icon: 'trash',
          title: 'Delete',
          action: onDelete,
          canExecute: isDiagramOwner,
        },
      ],
    },
    {
      id: 'access',
      actions: [
        {
          icon: 'locked',
          title: 'Lock',
          action: onLock,
          canExecute: (d, u) => !d.isLocked && isDiagramOwner(d, u),
        },
        {
          icon: 'unlock',
          title: 'Unlock',
          action: onUnlock,
          canExecute: (d, u) => d.isLocked && isDiagramOwner(d, u),
        },
        {
          icon: 'hidden',
          title: 'Make Private',
          action: onHide,
          canExecute: (d, u) => !d.isPrivate && isDiagramOwner(d, u),
        },
        {
          icon: 'visible',
          title: 'Make Public',
          action: onShare,
          canExecute: (d, u) => d.isPrivate && isDiagramOwner(d, u),
        },
      ],
    },
    {
      id: 'ownership',
      actions: [
        {
          icon: 'changeUser',
          title: 'Transfer Ownership',
          action: onTransferOwnership,
          canExecute: isDiagramOwner,
        },
        {
          icon: 'copy',
          title: 'Copy',
          action: onCopy,
          canExecute: () => true,
        },
      ],
    },
    {
      id: 'organize',
      actions: [
        {
          icon: 'tag',
          title: 'Edit Tags',
          action: onEditTags,
          canExecute: isDiagramOwner,
        },
      ],
    },
    {
      id: 'favorite',
      actions: [
        {
          icon: 'favorite',
          title: 'Favorite',
          action: onFavoriteDiagram,
          canExecute: (d) => !d.isFavorite,
        },
        {
          icon: 'not-favorite',
          title: 'Unfavorite',
          action: onUnFavoriteDiagram,
          canExecute: (d) => d.isFavorite,
        },
      ],
    },
  ];

  return (
    <Menu
      item={diagram}
      definition={menuActions} />
  );
}

function DiagramTags({ tags }: {
  tags: DiagramTagDto[];
}) {
  const sortedTags = useMemo(() => {
    return [...tags].sort((a, b) => insensitiveCompare(a.tagName, b.tagName));
  }, [tags]);

  return (
    <span className="d-flex gap-1 flex-wrap align-bottom">
      {
        sortedTags.map((t) => (
          <DiagramTag tag={t} key={t.id} />
        ))
      }
    </span>
  );
}

function NewDiagramButton({ onCreate }: { onCreate: () => void; }) {
  return (
    <Button color="success" onClick={() => onCreate()}>
      New Diagram <Icon icon="plus" />
    </Button>
  );
}

function DiagramVisibilityIcon({ isOwner, diagram }: {
  isOwner: boolean;
  diagram: DiagramListItem;
}) {
  if (!isOwner) return null;

  const icon: IconName = diagram.isPrivate
    ? 'hidden'
    : 'visible';

  return (
    <span>
      <Icon icon={icon} />
    </span>
  );
}
