import React from 'react';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { useUserApiListDiagramsQuery } from '../../../app/api';
import { Icon, ScrollArea } from '../../../components/elements';
import { useProject } from '../../project';
import { HarvestUploader } from '../../project/components/HarvestUploader';
import { UploadHarvestButton } from '../../project/components/UploadHarvestButton';
import { areTabsEqual, Tab, TabType } from '../../project/projectSlice';
import { CodeSetDetailTabHistoryItem } from '../codeSets';
import { CodeValueSearchTabHistoryItem } from '../codeSets/codeValueSearch';
import { iconMap } from '../Database';
import { DiagramTabHistoryItem } from '../diagram';
import { CreateDiagram } from '../diagram/Diagrams/CreateDiagram';
import { TableDetailTabHistoryItem } from '../tables';
import {
  TabHeader, TabHeaderProps, useTab, useTabHistory,
} from '../tabs';
import { ActivityFeed } from './ActivityFeed';

export function HomeTabHeader({ tab }: TabHeaderProps) {
  const { setTab, activeTab } = useTab();

  return (
    <TabHeader
      isActive={areTabsEqual(activeTab, tab)}
      title="Home"
      icon="home"
      onClick={() => setTab(tab)} />
  );
}
export function HomeTab() {
  return (
    <ScrollArea>
      <div className="d-flex flex-grow-1">
        <div className="d-flex flex-column gap-2 m-2 flex-grow-1">
          <HarvestUploader>
            {(onUpload) => (
              <CreateDiagram>
                {(onCreateDiagram) => (
                  <HomeTabBody
                    onCreateDiagram={onCreateDiagram}
                    onUploadHarvest={onUpload} />
                )}
              </CreateDiagram>
            )}
          </HarvestUploader>
        </div>
      </div>
    </ScrollArea>
  );
}

function HomeTabBody({ onCreateDiagram, onUploadHarvest }: {
  onCreateDiagram: () => void;
  onUploadHarvest: () => void;
}) {
  return (
    <div className="d-flex gap-5 m-2 flex-wrap">
      <div className="d-flex flex-column gap-4 flex-shrink-1">
        <Actions
          onCreateDiagram={onCreateDiagram}
          onUploadHarvest={onUploadHarvest} />
        <Recent />
      </div>
      <ActivityFeed />
    </div>
  );
}

function Actions({ onCreateDiagram, onUploadHarvest }: {
  onCreateDiagram: () => void;
  onUploadHarvest: () => void;
}) {
  return (
    <div className="d-flex flex-column gap-2">
      <h1>Actions</h1>
      <NewDiagramButton onClick={onCreateDiagram} />
      <UploadHarvestButton onClick={onUploadHarvest} />
    </div>
  );
}

function NewDiagramButton({ onClick }: { onClick: () => void; }) {
  return (
    <Button color="success" onClick={() => onClick()}>
      <Icon icon="diagram" /> New Diagram
    </Button>
  );
}

const tabHistoryItemMap: { [K in TabType]: (tab: Tab) => JSX.Element } = {
  tables: (tab) => <TableDetailTabHistoryItem tab={tab} />,
  diagrams: (tab) => <DiagramTabHistoryItem tab={tab} />,
  codeSets: (tab) => <CodeSetDetailTabHistoryItem tab={tab} />,
  codeSetSearch: (tab) => <CodeValueSearchTabHistoryItem tab={tab} />,
  home: () => <>Home</>,
};

function Recent() {
  const [tabHistory] = useTabHistory();
  const { setTab } = useTab();

  const { projectId } = useProject();
  const { data: diagrams } = useUserApiListDiagramsQuery({
    projectId,
  });

  return tabHistory.length <= 0
    ? null
    : (
      <div className="d-flex flex-column">
        <h1>Recently Viewed</h1>
        <ListGroup>
          {tabHistory
            .filter((t) => !diagrams || t.type !== 'diagrams' || diagrams.find((d) => d.id === t.id))
            .slice(0, 5)
            .map((t) => (
              <ListGroupItem
                key={t.id}
                onClick={() => setTab(t)}
                action
                tag="button">
                <Icon icon={iconMap[t.type]} /> {tabHistoryItemMap[t.type](t)}
              </ListGroupItem>
            ))}
        </ListGroup>
      </div>
    );
}
