import { useMemo } from 'react';
import { TableSummaryDto } from '../../../../app/api';
import { ProjectPreferences } from '../../../project';
import { getCategory } from '../../diagram/util';

export function useFilteredTables<T extends TableSummaryDto>(
  tables: T[],
  filter: string,
  preferences?: ProjectPreferences,
) {
  const categoryMap = useMemo(() => (
    preferences?.categories?.reduce((acc: { [k: string]: boolean; }, c) => {
      const index = c.id;
      acc[index] = c.isHidden;
      return acc;
    }, {}) ?? {}
  ), [preferences?.categories]);

  const trimmedFilter = filter.trim();

  const filteredSchemas = useMemo(() => {
    const filterText = trimmedFilter.toUpperCase();
    const tableNameMatchesFilter = (t: T) => {
      return `${t.schemaName}.${t.name}`.toUpperCase().includes(filterText);
    };

    const hasRowCount = (t: T) => (
      preferences?.rowCountFilter === undefined
            || preferences?.rowCountFilter === null
            || t.isView
            || (t.rowCount ?? 0) >= preferences.rowCountFilter);

    const isCategoryShown = (t: T) => !(categoryMap[getCategory(t)?.id ?? ''] ?? false);

    return tables.filter((t) => (
      tableNameMatchesFilter(t)
        && hasRowCount(t)
        && isCategoryShown(t)
    ));
  }, [trimmedFilter, tables, preferences?.rowCountFilter, categoryMap]);

  return filteredSchemas;
}
