import React from 'react';
import { UploadHarvestButton } from './UploadHarvestButton';

export function FirstHarvest({ onUpload }: {
  onUpload: () => void;
}) {
  return (
    <>
      <h2>Generate your first harvest</h2>
      <p>
        In order to use Database Explorer you need to extract schema information from your database
        and upload it.
      </p>
      <ol>
        <li>Download the <a href="/SchemaExtractor/SchemaExtractor.exe">Schema Extractor.</a></li>
        <li>Run the schema extractor on your database.</li>
        <li>Upload your harvest.</li>
      </ol>
      <div>
        <UploadHarvestButton onClick={onUpload} />
      </div>
    </>
  );
}
