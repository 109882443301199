import React, { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useUserApiListProjectsQuery } from '../../../app/api';
import { useOrganization } from '../../organizations';
import { ProjectContextProvider, ProjectIdContextProvider } from '../ProjectContext';
import { ProjectSwitcher } from '../components/ProjectSwitcher';

export function ProjectMenu({ children }: {
  children: ReactNode;
}) {
  const { id } = useOrganization();
  const { data } = useUserApiListProjectsQuery({ organizationId: id });

  return (
    <Routes>
      <Route
        path="projects/:projectId/*"
        element={(
          <ProjectIdContextProvider>
            <ProjectContextProvider>
              <>
                {data
                  ? <ProjectSwitcher projects={data ?? []} />
                  : null}
                {children}
              </>
            </ProjectContextProvider>
          </ProjectIdContextProvider>
        )} />
    </Routes>
  );
}
