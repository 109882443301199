/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext, ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState,
} from 'react';

type TriggerUpdate = (value: () => void) => void;

const UpdateContext = createContext<object|undefined>(undefined);
const UpdateWrapperContext = createContext<{
  triggerUpdate:() => void,
  setUpdateTrigger: TriggerUpdate
    }>({
      triggerUpdate: () => { },
      setUpdateTrigger: () => { },
    });

function UpdateProvider({ children }: {
  children: ReactNode;
}) {
  const { setUpdateTrigger } = useContext(UpdateWrapperContext);
  const [value, setRender] = useState({});
  useEffect(() => {
    setUpdateTrigger(() => setRender({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <UpdateContext.Provider value={value}>{children}</UpdateContext.Provider>;
}

function WrapperProvider({ children }: {
  children: ReactNode;
}) {
  const [state, setState] = useState({ update: () => { } });
  const triggerUpdate = state.update;
  const setUpdateTrigger = (update: () => void) => setState({ update });
  const contextValue = useMemo(() => ({ triggerUpdate, setUpdateTrigger }), [triggerUpdate]);
  return (
    <UpdateWrapperContext.Provider
      value={contextValue}>
      {children}
    </UpdateWrapperContext.Provider>
  );
}

export function UpdateWrapper({ children }: {
  children: ReactNode;
}) {
  return (
    <WrapperProvider>
      <UpdateProvider>{children}</UpdateProvider>
    </WrapperProvider>
  );
}

export function useTriggerUpdate() {
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  const update = useContext(UpdateWrapperContext);

  useLayoutEffect(() => {
    update.triggerUpdate();
  });

  return forceUpdate;
}

export function useSubscribeToUpdates() {
  useContext(UpdateContext);
}
