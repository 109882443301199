import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { HarvestDto, MmHarvestDto } from '../../../app/api';

export function useFilteredHarvests<
  HarvestItem extends HarvestDto | MmHarvestDto
>(harvests: HarvestItem[]): [
    HarvestItem[],
    (filter: string) => void,
  ] {
  const [filter, setFilter] = useState('');
  const filteredHarvests = useMemo(() => {
    return harvests.filter((h) => !filter
      || h.harvestedBy.toUpperCase().includes(filter.toUpperCase())
      || h.databaseInstance.toUpperCase().includes(filter.toUpperCase())
      || ('domainName' in h
        && (
          h.domainName?.toUpperCase().includes(filter.toUpperCase())
          || h.clientMnemonic?.toUpperCase().includes(filter.toUpperCase())
          || h.clientName?.toUpperCase().includes(filter.toUpperCase())

        )
      )
      || DateTime.fromISO(h.completedOn)
        .toLocaleString(DateTime.DATETIME_MED)
        .toUpperCase()
        .includes(filter.toUpperCase())
      || h.tags.filter((t) => t.toUpperCase().includes(filter.toUpperCase())).length > 0);
  }, [filter, harvests]);

  return [filteredHarvests, setFilter];
}
