import { DateTime } from 'luxon';

export function formatDateString(
  date: string | null | undefined,
  format = DateTime.DATETIME_SHORT,
) {
  return date
    ? `${DateTime.fromISO(date).toLocaleString(format)}`
    : date;
}
