import { useCallback } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { closeTab, openTab, Tab } from '../../../project/projectSlice';
import { useProjectSettings as useProjectState, useProject } from '../../../project';
import { useTabHistory } from '.';

export function useTab() {
  const { projectId } = useProject();
  const dispatch = useAppDispatch();
  const projectState = useProjectState();
  const [, updateTabHistory] = useTabHistory();

  const setTab = useCallback((newValue: Tab) => {
    dispatch(openTab({ projectId, data: newValue }));
    updateTabHistory(newValue);
  }, [dispatch, projectId, updateTabHistory]);

  const exitTab = useCallback((oldTab: Tab) => {
    dispatch(closeTab({ projectId, data: oldTab }));
  }, [dispatch, projectId]);

  return {
    setTab, exitTab, activeTab: projectState?.activeTab, tabs: projectState?.tabs,
  };
}
