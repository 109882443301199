import { selectAuth } from './authSlice';
import { useAppSelector } from '../../app/hooks';

export function useCurrentUser() {
  const { user } = useAppSelector(selectAuth);

  if (user === undefined) {
    throw new Error('useCurrentUser must be used within a route that requires authentication');
  }

  return user;
}
