import React from 'react';
import { ListGroupItem } from 'reactstrap';
import { CheckIndicator } from './CheckIndicator';

export function BooleanProperty({ name, value }: {
  name: string;
  value: boolean | undefined | null;
}) {
  return (
    <ListGroupItem className="d-flex gap-2 flex-row">
      <strong>{name}:</strong>
      <CheckIndicator isChecked={value} />
    </ListGroupItem>
  );
}
