import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledDropdown,
} from 'reactstrap';
import { useAppSelector } from '../app/hooks';
import { selectAuth } from '../features/authentication/authSlice';
import { IDENTITY_CONFIG } from '../features/authentication/userManager';

export default function LoginMenu() {
  const auth = useAppSelector(selectAuth);
  const location = useLocation();

  if (auth.user && !auth.user?.expired) {
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          {auth.user.profile.FullName ?? auth.user.profile.name}
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem tag={Link} to="/logout">
            Logout
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <a
              className="text-dark"
              href={`${IDENTITY_CONFIG.authority}?returnUrl=${window.location.href}`}>
              Profile
            </a>
          </DropdownItem>
          <DropdownItem tag={Link} to="/about">
            About
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  return (
    <NavItem>
      <NavLink
        tag={Link}
        to={{ pathname: '/login', search: `returnUrl=${location.pathname}` }}>
        Login
      </NavLink>
    </NavItem>
  );
}
