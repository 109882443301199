import React, { useCallback } from 'react';
import {
  ProjectWithCountsDto, useUserApiUpdateProjectMutation,
  isSuccessResponse,
} from '../../../app/api';
import { useToggle } from '../../../hooks';
import { EditButton } from '../../../components/elements';

import { EditProjectDialog } from './EditProjectDialog';
import { useOrganization } from '../../organizations';

export type EditProjectInputs = {
  projectName: string;
  projectDescription: string;
};
export function EditProject({ project }: {
  project: ProjectWithCountsDto,
}) {
  const { value: modal, toggleValue: toggle } = useToggle();
  const { saveChanges, isLoading, error } = useEditProject({
    projectId: project.id ?? '',
    onSuccess: toggle,
  });

  return (
    <div>
      <EditButton onClick={toggle} />
      <EditProjectDialog
        isLoading={isLoading}
        isOpen={modal}
        project={project}
        error={error}
        onClose={toggle}
        onSave={saveChanges} />
    </div>
  );
}

export function useEditProject({ projectId, onSuccess }: {
  projectId: string,
  onSuccess: () => void,
}) {
  const organization = useOrganization();
  const [editProject, { isLoading, error, isError }] = useUserApiUpdateProjectMutation();

  const saveChanges = useCallback((
    { name, description }: { name: string, description: string },
  ) => {
    editProject({
      projectId,
      organizationId: organization.id,
      projectCreateDto: { name, description },
    }).then((y) => {
      if (isSuccessResponse(y)) {
        onSuccess();
      }
    });
  }, [editProject, onSuccess, organization.id, projectId]);

  return { saveChanges, isLoading, error: isError && error };
}
