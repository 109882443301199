import React from 'react';
import { Icon, IconName } from '..';

export function InfoLink({ icon, onClick }: {
  icon?: IconName
  onClick: () => void,
}) {
  return (
    <Icon
      icon={icon ?? 'info'}
      onMouseDown={(e) => e.stopPropagation()}
      cursor="pointer"
      className="select-none"
      onClick={onClick} />
  );
}

InfoLink.defaultProps = {
  icon: 'info',
};
