import React, { useState } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { Icon } from '..';

export function SearchBox({
  className, placeholder, initialSearch, onFilterChange,
}: {
  className?: string;
  placeholder: string;
  initialSearch?: string;
  onFilterChange: (filter: string) => void;
}) {
  const [searchString, setSearchString] = useState(initialSearch ?? '');

  return (
    <InputGroup className={className}>
      <InputGroupText><Icon icon="search" /></InputGroupText>
      <Input
        type="text"
        value={searchString}
        placeholder={placeholder}
        onChange={(e) => {
          setSearchString(e.target.value);
          onFilterChange(e.target.value);
        }}
        autoFocus />
    </InputGroup>
  );
}

SearchBox.defaultProps = {
  className: undefined,
  initialSearch: undefined,
};
