import { areTabsEqual, Tab } from '../../../project/projectSlice';
import { useProjectPreferences } from '../../../project';

export function useTabHistory(): [Tab[], (newTab: Tab) => void] {
  const { preferences, setPreferences } = useProjectPreferences();

  const recentTabs = preferences.recentTabs ?? [];

  const onChangeTab = (newTab: Tab) => {
    if (newTab.type === 'home') { return; }

    setPreferences({
      ...preferences,
      recentTabs: [
        newTab,
        ...(recentTabs
          .filter((t) => !areTabsEqual(t, newTab))
          .filter((t, i) => i < 10 || t.type === 'diagrams')),
      ].slice(0, 20),
    });
  };

  return [recentTabs, onChangeTab];
}
