import React from 'react';
import { useUserApiListMmDataModelsQuery } from '../../../app/api';
import { useDebouncedEventHandler, useToggle } from '../../../hooks';
import { useProjectPreferences } from '../../project';
import { useCurrentDatabase } from '../DatabaseContext';
import { useLoadedCategoryColors } from '../settings';
import { TableFilterDialog, useTableDetails } from '../tables';
import { useTablePrefetch } from '../tables/useTablePrefetch';
import { ModelList } from './ModelList';

export function Models() {
  const { databaseId } = useCurrentDatabase();

  const { data, isLoading, error } = useUserApiListMmDataModelsQuery({
    harvestId: databaseId,
  });

  const onTableDetails = useTableDetails();
  const onTablePrefetch = useTablePrefetch();

  const {
    preferences,
    isSaving,
    setCategory,
    isLoading: isLoadingPreferences,
    setRowCount,
  } = useProjectPreferences();

  const onSetRowCountFilter = useDebouncedEventHandler(setRowCount, 300);

  const { value: filterOpen, setValue: setFilterOpen } = useToggle(false);

  const { categories, isLoading: categoriesLoading } = useLoadedCategoryColors();

  return (
    <>
      <ModelList
        models={data ?? []}
        isLoading={isLoading}
        error={error}
        onTableDetails={onTableDetails}
        preferences={preferences}
        categories={categories}
        onOpenFilter={() => setFilterOpen(true)}
        onTablePrefetch={onTablePrefetch} />

      <TableFilterDialog
        isLoading={isLoadingPreferences || categoriesLoading}
        isOpen={filterOpen}
        isSaving={isSaving}
        preferences={preferences}
        categories={categories}
        onSetRowCountFilter={onSetRowCountFilter}
        onClose={() => setFilterOpen(false)}
        onSaveCategory={setCategory} />
    </>
  );
}
