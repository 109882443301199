import React, { useEffect, useState } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { isFetchBaseQueryError } from '../../../util';
import { NotificationType, Notification } from '../notificationSlice';

const backgroundMap: { [K in NotificationType]: string } = {
  error: 'bg-danger',
  warn: 'bg-warning',
  info: 'bg-info',
  success: 'bg-success',
};

const textColorMap: { [K in NotificationType]: string } = {
  error: 'text-light',
  warn: '',
  info: '',
  success: 'text-light',
};

type ToastNotificationProps = Notification & {
  onDismiss: () => void,
};

export function ToastNotification({
  title,
  type,
  body,
  error,
  onDismiss,
}: ToastNotificationProps) {
  const [mouseIn, setMouseIn] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!mouseIn) {
      const timer = setTimeout(
        () => onDismiss(),
        5000,
      );
      return () => clearTimeout(timer);
    }
  });

  return (
    <Toast
      className={backgroundMap[type]}
      style={{ zIndex: 1050 }}
      onMouseEnter={() => setMouseIn(true)}
      onMouseLeave={() => setMouseIn(false)}>
      <ToastHeader toggle={() => onDismiss()}>
        {title}
      </ToastHeader>
      <ToastBody className={textColorMap[type]}>
        {body && <div>{body}</div>}
        <Error error={error} body={body} />
      </ToastBody>
    </Toast>
  );
}

function Error({ error, body }: { error: unknown, body: string | undefined }) {
  if (isFetchBaseQueryError(error) && typeof error.status === 'number') {
    const message = statusMap[error.status] ?? 'Unknown error';
    return <div className={`pt-1 ${body && 'border-top'}`}>Status: {message}</div>;
  }

  return null;
}

const statusMap: { [k: number]: string } = {
  401: 'Authorization failure. Please log out and in if these errors persist.',
  403: 'Operation is not permitted',
  404: 'Not found',
};
