import { useCallback, useMemo } from 'react';
import { useUserApiGetCurrentAccountPreferencesQuery, useUserApiUpdateCurrentAccountPreferencesMutation } from '../../../app/api';

export type UserPreferences = {
  favoriteOrganizations?: string[];
  recentOrganizations?: string[];
};

export function useUserPreferences() {
  const { data, isLoading } = useUserApiGetCurrentAccountPreferencesQuery();

  const preferences: UserPreferences = useMemo(() => ({
    ...JSON.parse(data?.preferences ?? '{}'),
  }), [data?.preferences]);

  const [
    persistPreferences,
    { isLoading: isSaving },
  ] = useUserApiUpdateCurrentAccountPreferencesMutation();

  const updatePreferences = useCallback((request: Partial<UserPreferences>) => persistPreferences({
    updateCurrentAccountPreferencesRequest: {
      jsonData: JSON.stringify({
        ...preferences,
        ...request,
      }),
    },
  }), [persistPreferences, preferences]);

  return {
    preferences, isLoading, updatePreferences, isSaving,
  };
}
