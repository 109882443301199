import React from 'react';
import { Nav, TabContent } from 'reactstrap';
import {
  MmCodeSetWithValueCountDto,
  useUserApiListMmCodeSetsQuery,
} from '../../../app/api';
import {
  LoadingSpinner, ErrorWrapper,
} from '../../../components/elements';
import { ErrorMessage } from '../../../components/elements/form';
import { useParentTabId } from '../../project';
import { useCurrentDatabase } from '../DatabaseContext';
import { TabHeader } from '../tabs';
import { useChildTab } from '../tabs/childTabSlice';
import { CodeSetColumns } from './CodeSetColumns';
import { CodeSetSummary } from './CodeSetSummary';
import { CodeSetValues } from './CodeSetValues';

export function CodeSetDetail({ codeSetId }: {
  codeSetId: number;
}) {
  const { databaseId } = useCurrentDatabase();

  const { data: codeSets, isFetching, error } = useUserApiListMmCodeSetsQuery({
    harvestId: databaseId,
  });

  const currentSet = codeSets?.find((s) => s.code === codeSetId);

  const tabId = useParentTabId();
  const [tab, setTab] = useChildTab(tabId, 'summary');

  if (!isCodeSetDetailTabType(tab)) {
    throw new Error(`Invalid code set tab type of ${tab}. Valid values are ${JSON.stringify(detailTabTypes)}`);
  }

  return (
    <div className="ms-2 mt-2 flex-grow-1 d-flex flex-column">
      <LoadingSpinner isLoading={!codeSets && isFetching}>
        <ErrorWrapper
          error={error}
          message={(
            <>
              <ErrorMessage statusCode={404}>
                <h4>Code Set {codeSetId} doesn&apos;t exist in the current harvest</h4>
              </ErrorMessage>
              <ErrorMessage>
                <h4>Unable to load Code Set details.</h4>
              </ErrorMessage>
            </>
          )}>
          <Nav tabs>
            {Object.values(tableTabMap)
              .map((tabDef) => (
                <CodeSetTabHeader
                  selectedTab={tab}
                  definition={tabDef}
                  setTab={setTab}
                  key={tabDef.type} />
              ))}
          </Nav>
          <TabContent className="d-flex flex-grow-1">
            <div className="d-flex flex-column flex-grow-1">
              {tableTabMap[tab].body({ codeSet: currentSet })}
            </div>
          </TabContent>
        </ErrorWrapper>
      </LoadingSpinner>
    </div>
  );
}

type CodeSetDetailProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  codeSet: MmCodeSetWithValueCountDto | undefined;
};

const detailTabTypes = ['values', 'usages', 'summary'] as const;
type CodeSetDetailTabType = typeof detailTabTypes[number];

function isCodeSetDetailTabType(value: string): value is CodeSetDetailTabType {
  return detailTabTypes.includes(value as CodeSetDetailTabType);
}

type CodeSetDetailTabDefinition = {
  type: CodeSetDetailTabType;
  name: string;
  body: (props: CodeSetDetailProps) => JSX.Element;
};

const tableTabMap: { [K in CodeSetDetailTabType]: CodeSetDetailTabDefinition } = {
  summary: {
    type: 'summary',
    name: 'Summary',
    body: (props) => <CodeSetSummary {...props} key={`${props.codeSet?.id}-summary`} />,
  },
  values: {
    type: 'values',
    name: 'Values',
    body: (props) => <CodeSetValues {...props} key={`${props.codeSet?.id}-values`} />,
  },
  usages: {
    type: 'usages',
    name: 'Referenced by',
    body: (props) => <CodeSetColumns {...props} key={`${props.codeSet?.id}-usages`} />,
  },
};

type CodeSetTabHeaderProps = {
  selectedTab: CodeSetDetailTabType;
  definition: CodeSetDetailTabDefinition;
  setTab: (tab: CodeSetDetailTabType) => void;
};

function CodeSetTabHeader({ selectedTab, definition, setTab }: CodeSetTabHeaderProps) {
  return (
    <TabHeader
      isActive={selectedTab === definition.type}
      onClick={() => setTab(definition.type)}
      title={definition.name} />
  );
}
