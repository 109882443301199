import React, { ReactNode, useMemo } from 'react';
import {
  Card, CardBody, CardSubtitle, CardText, CardTitle,
} from 'reactstrap';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useProject } from '../../../project';
import {
  // eslint-disable-next-line max-len
  ActivityLogEntryDto, useHarvests, useUserApiListActivityLogEntriesByProjectQuery, useUserApiListDiagramsQuery,
} from '../../../../app/api';
import {
  ErrorWrapper, Icon, IconName, LoadingSpinner, Paginator,
} from '../../../../components/elements';
import { useTab } from '../../tabs';
import { useCodeSetTitle } from '../../codeSets';
import { usePagination } from '../../../../hooks';
import { toObjectMap } from '../../../../util';
import { addNotification } from '../../../notifications/notificationSlice';
import { useAppDispatch } from '../../../../app/hooks';

export function ActivityFeed() {
  const { projectId } = useProject();
  const { data, isLoading, error } = useUserApiListActivityLogEntriesByProjectQuery({
    projectId,
  });

  const { data: diagrams } = useUserApiListDiagramsQuery({
    projectId,
  });

  const { harvests } = useHarvests();

  const filteredActivity = useMemo(() => {
    const diagramMap = toObjectMap(diagrams ?? [], (d) => [d.id, d]);
    const harvestMap = toObjectMap(harvests ?? [], (h) => [h.id, h]);
    return (data ?? []).filter((t) => !diagrams || t.targetType !== 'Diagram' || diagramMap[t.targetRef])
      .filter((t) => !harvests || t.targetType !== 'Harvest' || harvestMap[t.targetRef]);
  }, [data, diagrams, harvests]);

  const { items: activity, ...pagination } = usePagination(5, filteredActivity);

  return (
    <div className="d-flex flex-column flex-grow-1">
      <h1>Activity Feed</h1>
      <LoadingSpinner centered isLoading={isLoading}>
        <ErrorWrapper
          error={error}
          message={(
            <h4>Unable to load activity feed.</h4>
          )}>
          {activity?.map((a) => (
            <ActivityFeedItem action={a}>
              {(icon, description, onClick) => (
                <Card className="m-2 ms-0 hover">
                  <CardBody>
                    <CardTitle
                      tag="h4"
                      className="cursor-pointer link-primary text-underline-hover"
                      onClick={onClick}>
                      <Icon icon={icon} /> {a.targetName ?? a.targetRef}
                    </CardTitle>
                    <CardSubtitle tag="h6">By {a.actorFullName} on {DateTime.fromISO(a.activityOn).toLocaleString(DateTime.DATETIME_SHORT)}</CardSubtitle>
                    <CardText>
                      {description}
                    </CardText>
                  </CardBody>
                </Card>
              )}
            </ActivityFeedItem>
          ))}
          <Paginator {...pagination} />
        </ErrorWrapper>
      </LoadingSpinner>
    </div>
  );
}

type ActivityTargetType =
  'Diagram' |
  'Harvest' |
  'MmCodeSetOverride' |
  'MmRootEntityOverride' |
  'WikiArticle';

function ActivityFeedItem({ action, children }: {
  action: ActivityLogEntryDto,
  children: (
    icon: IconName,
    description: string,
    onClick: React.MouseEventHandler<Element>,
  ) => ReactNode;
}) {
  const feedItemComponent = tabMap[action.targetType as ActivityTargetType];

  return (
    <>
      {feedItemComponent(action, children)}
    </>
  );
}

type ActivityFeedItemComponentFactory = (
  action: ActivityLogEntryDto,
  children: (
    icon: IconName,
    description: string,
    onClick: React.MouseEventHandler<Element>,
  ) => ReactNode,
) => JSX.Element;

const tabMap: { [K in ActivityTargetType]: ActivityFeedItemComponentFactory } = {
  Diagram: (action, children) => (
    <ActivityFeedDiagram action={action}>
      {children}
    </ActivityFeedDiagram>
  ),
  Harvest: (action, children) => (
    <ActivityFeedHarvest action={action}>
      {children}
    </ActivityFeedHarvest>
  ),
  MmCodeSetOverride: (action, children) => (
    <ActivityFeedCodeSetOverride action={action}>
      {children}
    </ActivityFeedCodeSetOverride>
  ),
  MmRootEntityOverride: (action, children) => (
    <ActivityFeedRootEntityOverride action={action}>
      {children}
    </ActivityFeedRootEntityOverride>
  ),
  WikiArticle: (action, children) => (
    <ActivityFeedWikiArticle action={action}>
      {children}
    </ActivityFeedWikiArticle>
  ),
};

const actionTypeMap: { [K: string]: string } = {
  Create: 'Created',
  Update: 'Updated',
  Delete: 'Deleted',
};

function translateActionTypeText(actionType: string) {
  return actionTypeMap[actionType] ?? '<Unknown Action>';
}

function ActivityFeedDiagram({ action, children }: {
  action: ActivityLogEntryDto,
  children: (
    icon: IconName,
    description: string,
    onClick: React.MouseEventHandler<Element>,
  ) => ReactNode;
}) {
  const { setTab } = useTab();

  return (
    <>
      {children(
        'diagram',
        `Diagram ${translateActionTypeText(action.actionType)}`,
        () => setTab(({ type: 'diagrams', id: action.targetRef })),
      )}
    </>
  );
}

function ActivityFeedHarvest({ action, children }: {
  action: ActivityLogEntryDto,
  children: (
    icon: IconName,
    description: string,
    onClick: React.MouseEventHandler<Element>,
  ) => ReactNode;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <>
      {children(
        'harvest',
        `Harvest ${translateActionTypeText(action.actionType)}`,
        () => {
          navigate(`../${action.targetRef}`);
          dispatch(addNotification({
            type: 'success',
            title: 'Switching Harvest',
            body: `Switching to harvest ${action.targetName}`,
          }));
        },
      )}
    </>
  );
}

function ActivityFeedWikiArticle({ action, children }: {
  action: ActivityLogEntryDto,
  children: (
    icon: IconName,
    description: string,
    onClick: React.MouseEventHandler<Element>,
  ) => ReactNode;
}) {
  const { setTab } = useTab();

  return (
    <>
      {children(
        'article',
        `Wiki Article ${translateActionTypeText(action.actionType)}`,
        () => setTab(({ type: 'tables', id: action.targetRef })),
      )}
    </>
  );
}

function ActivityFeedCodeSetOverride({ action, children }: {
  action: ActivityLogEntryDto,
  children: (
    icon: IconName,
    description: string,
    onClick: React.MouseEventHandler<Element>,
  ) => ReactNode;
}) {
  const codSetDetails = useCodeSetTitle(action.newValue ?? '0');
  const description = `CodeSet Override ${translateActionTypeText(action.actionType)}${action.actionType === 'Create'
    ? `, pointed to ${codSetDetails.title}`
    : ''}`;

  const { setTab } = useTab();

  return (
    <>
      {children(
        'codeSets',
        description,
        () => setTab(({
          type: 'tables',
          id: trimColumnFromFullyQualifiedName(action.targetRef),
        })),
      )}
    </>
  );
}

function ActivityFeedRootEntityOverride({ action, children }: {
  action: ActivityLogEntryDto,
  children: (
    icon: IconName,
    description: string,
    onClick: React.MouseEventHandler<Element>,
  ) => ReactNode;
}) {
  const { setTab } = useTab();

  const description = `Root Entity Override ${translateActionTypeText(action.actionType)}${action.actionType === 'Create'
    ? `, pointed to ${action.newValue}`
    : ''}`;

  return (
    <>
      {children(
        'relationships',
        description,
        () => setTab(({
          type: 'tables',
          id: trimColumnFromFullyQualifiedName(action.targetRef),
        })),
      )}
    </>
  );
}

function trimColumnFromFullyQualifiedName(name: string) {
  return name.slice(0, name.lastIndexOf('.'));
}
