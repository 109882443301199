import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import {
  LoginCallback, Logout, Login, LogoutCallback, RequireAuth,
} from './auth';
import { Organizations } from './features/organizations';
import { useAppSelector } from './app/hooks';
import { selectAuth, isAuthenticated } from './features/authentication/authSlice';

import './custom.scss';
import { About } from './features/about';

export default function App() {
  const auth = useAppSelector(selectAuth);

  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={(
            <Layout>
              {isAuthenticated(auth)
                ? <Navigate to="/organizations" replace />
                : <Home />}
            </Layout>
          )} />

        <Route path="signin-oidc" element={<LoginCallback />} />
        <Route path="logout" element={<Logout />} />
        <Route path="login" element={<Login />} />
        <Route path="signout-callback-oidc" element={<LogoutCallback />} />

        <Route
          path="organizations/*"
          element={(
            <Layout>
              <RequireAuth>
                <Organizations />
              </RequireAuth>
            </Layout>
          )} />

        <Route
          path="about"
          element={
            <Layout><About /></Layout>
          } />
      </Route>
    </Routes>
  );
}
