import React from 'react';
import { Button } from 'reactstrap';
import { Icon } from '../../../components/elements';

export function UploadHarvestButton({ onClick }: { onClick: () => void; }) {
  return (
    <Button color="success" onClick={onClick}>
      <Icon icon="upload" /> Upload Harvest
    </Button>
  );
}
