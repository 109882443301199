import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';

export type ExpansionMap = { [nodeId: string]: boolean | string };

type ExpansionState = ExpansionMap & {
  id: string,
}

const treeExpansionAdapter = createEntityAdapter<ExpansionState>();

type ExpansionAction = {
  id: string;
  nodeId: string;
  isExpanded: boolean;
}

const treeExpansionSlice = createSlice({
  name: 'treeExpansion',
  initialState: treeExpansionAdapter.getInitialState(),
  reducers: {
    setExpanded: (state, action: PayloadAction<ExpansionAction>) => {
      treeExpansionAdapter.upsertOne(state, {
        id: action.payload.id,
        [action.payload.nodeId]: action.payload.isExpanded,
      });
    },
  },
});

const {
  setExpanded,
} = treeExpansionSlice.actions;

const {
  selectById: selectByMapId,
} = treeExpansionAdapter.getSelectors((state: RootState) => state.sessionReducers.treeExpansion);

export function useExpansionMap(id: string) {
  const dispatch = useAppDispatch();

  const expansionMap = useAppSelector((state) => selectByMapId(state, id)) ?? {};

  const setExpansion = useCallback((nodeId: string, isExpanded: boolean) => {
    dispatch(setExpanded({
      id,
      nodeId,
      isExpanded,
    }));
  }, [dispatch, id]);

  return { setExpansion, expansionMap };
}

export default treeExpansionSlice.reducer;
