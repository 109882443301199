import React, {
  forwardRef, Ref, useImperativeHandle, useRef,
} from 'react';
import { DiagramElementRef } from '..';
import { DiagramWithChildrenDto, DiagramRectangleDto } from '../../../../app/api';
import { ArticleViewer, EditButton, MenuDefinition } from '../../../../components/elements';
import { ContextMenu } from '../../../../components/elements/contextMenu';
import { decimalToHexColor } from '../../../../util';
import styles from './DiagramRectangle.module.scss';

export const DiagramRectangle = forwardRef(({
  canEdit, diagram, isSelected, rectangle, onDelete, onEdit, onSelect, onSetOrder,
}: {
  canEdit: boolean;
  diagram: DiagramWithChildrenDto;
  isSelected: boolean;
  onDelete: () => void;
  onEdit: () => void;
  onSetOrder: (order: number) => void;
  onSelect: (toggle: boolean) => void;
  rectangle: DiagramRectangleDto;
}, ref: Ref<DiagramElementRef>) => {
  const canMoveForward = (r: DiagramRectangleDto) => canEdit && r.order !== 0;
  const canMoveBackward = (r: DiagramRectangleDto) => (
    canEdit && r.order < Math.max(...diagram.rectangles.map((dr) => dr.order))
  );
  const elementRef = useRef(null);
  useImperativeHandle(ref, () => {
    return {
      id: rectangle.id,
      name: 'none',
      element: elementRef.current,
      bounds: {
        ...rectangle,
        isScaled: false,
      },
    };
  }, [rectangle]);

  const menuDefinition: MenuDefinition<DiagramRectangleDto> = [
    {
      id: 'edit',
      actions: [
        {
          title: 'Edit',
          action: onEdit,
          icon: 'edit',
          canExecute: () => canEdit,
        },
        {
          title: 'Delete',
          action: onDelete,
          icon: 'trash',
          canExecute: () => canEdit,
        },
      ],
    },
    {
      id: 'rectangle-move-single',
      actions: [
        {
          title: 'Bring Forward',
          action: () => onSetOrder(rectangle.order - 1),
          canExecute: canMoveForward,
        },
        {
          title: 'Send Backward',
          action: () => onSetOrder(rectangle.order + 2),
          canExecute: canMoveBackward,
        },
      ],
    },
    {
      id: 'rectangle-move-multiple',
      actions: [
        {
          title: 'To Front',
          action: () => onSetOrder(0),
          canExecute: canMoveForward,
        },
        {
          title: 'To Back',
          action: () => onSetOrder(1 + Math.max(...diagram.rectangles.map((dr) => dr.order))),
          canExecute: canMoveBackward,
        },
      ],
    },
  ];

  return (
    <ContextMenu
      target={rectangle}
      menuDefinition={menuDefinition}>
      {(onContextMenu) => (
        <g
          className={isSelected ? styles.selected : ''}
          onContextMenu={(e) => {
            onSelect(false);
            onContextMenu(e);
          }}
          onDoubleClick={() => canEdit && onEdit()}
          onMouseDown={(e) => {
            onSelect(e.ctrlKey);
          }}
          ref={elementRef}>
          <rect
            stroke={decimalToHexColor(rectangle.borderColor)}
            fill={decimalToHexColor(rectangle.backgroundColor)}
            width={rectangle.width}
            height={rectangle.height} />
          <foreignObject
            width={rectangle.width}
            height={rectangle.height}>
            <div style={{ position: 'absolute', width: '100%', height: '100%' }} />
            {canEdit && (
              <EditButton
                className={styles['edit-button']}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  onEdit();
                  e.stopPropagation();
                }} />
            )}
            <div className="px-2">
              <ArticleViewer
                markdown={rectangle.content}
                customHTMLRenderer={{
                  image() {
                    return [];
                  },
                }} />
            </div>
          </foreignObject>
        </g>
      )}
    </ContextMenu>
  );
});
