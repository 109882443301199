import { useProject, useProjectSettings } from '..';
import { useAppDispatch } from '../../../app/hooks';
import { SideBarType, setSidebar } from '../projectSlice';

export function useProjectSidebar(): [
  SideBarType | undefined,
  (sideBarType: SideBarType) => void
  ] {
  const { projectId } = useProject();
  const projectState = useProjectSettings();
  const sidebarType = projectState.sideBar;

  const dispatch = useAppDispatch();
  const x = (type: SideBarType) => dispatch(setSidebar({ projectId, data: type }));

  return [sidebarType, x];
}
