import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AboutPage } from '../components';

export function About() {
  return (
    <Routes>
      <Route index element={<AboutPage />} />
    </Routes>
  );
}
