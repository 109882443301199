import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { format, SqlLanguage } from 'sql-formatter';
import { useUserApiGetViewByNameQuery } from '../../../app/api';
import { useCurrentDatabase } from '../DatabaseContext';
import { ErrorBoundary, LoadingSpinner, ScrollArea } from '../../../components/elements';
import { TableSubDetailProps } from './Tables';

export function ViewText({ tableName, parentTabId }: TableSubDetailProps) {
  const { databaseId, databaseEngine } = useCurrentDatabase();

  const { data, isLoading } = useUserApiGetViewByNameQuery({
    harvestId: databaseId,
    schemaName: encodeURIComponent(tableName.split('.')[0]),
    viewName: encodeURIComponent(tableName.split('.')[1]),
  });

  const queryExpression = data?.queryExpression
    ? data.queryExpression
    : '-- View text is unavailable.';

  return (
    <LoadingSpinner isLoading={isLoading} centered>
      <ErrorBoundary>
        <ScrollArea id={`${parentTabId}-viewText`}>
          <SqlViewer
            databaseEngine={databaseEngine}
            queryExpression={queryExpression} />
        </ScrollArea>
      </ErrorBoundary>
    </LoadingSpinner>
  );
}

function SqlViewer({ queryExpression, databaseEngine }: {
  databaseEngine: string | undefined;
  queryExpression: string | undefined
}) {
  return (
    <SyntaxHighlighter
      language="sql"
      style={docco}
      wrapLongLines
      wrapLines>
      {
        queryExpression
          ? format(queryExpression, { language: getSqlFormat(databaseEngine) })
          : ''
      }
    </SyntaxHighlighter>
  );
}

function getSqlFormat(databaseEngine: string | undefined) {
  return SqlFormatMap[databaseEngine ?? ''] ?? 'sql';
}

const SqlFormatMap: { [k: string]: SqlLanguage } = {
  Millennium: 'plsql',
  Oracle: 'plsql',
  SqlServer: 'tsql',
};
