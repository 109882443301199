import { insensitiveEquals } from '../../../util';
import { useUserPreferences } from './useUserPreferences';

export type OnOrganizationChange = (newOrganization: string) => void;

export function useOrganizationHistory(): [string[], OnOrganizationChange] {
  const { preferences, updatePreferences, isLoading } = useUserPreferences();

  const recentOrganizations = preferences.recentOrganizations ?? [];

  const onChangeOrganization = (newOrganization: string) => {
    if (isLoading || recentOrganizations[0] === newOrganization) {
      return;
    }

    updatePreferences({
      recentOrganizations: [
        newOrganization,
        ...(recentOrganizations.filter((t) => !insensitiveEquals(t, newOrganization))),
      ].slice(0, 10),
    });
  };

  return [recentOrganizations, onChangeOrganization];
}
