import React from 'react';
import { TableSubDetailProps, useTableDetails } from '..';
import {
  // eslint-disable-next-line max-len
  useEncodedTableName, useTableInfo, useUserApiListHarvestTablesQuery, useUserApiListTableChildConstraintsByTableNameQuery,
} from '../../../../app/api';
import { useCurrentDatabase } from '../../DatabaseContext';
import { RelationshipList, Relationships } from '../../diagram/Relationships';
import { useLoadedCategoryColors } from '../../settings';
import { useTablePrefetch } from '../useTablePrefetch';

export function RelatedTables({ tableName }: TableSubDetailProps) {
  const { databaseId } = useCurrentDatabase();

  const { table, isLoading, isFetching } = useTableInfo(tableName);

  const { currentData: tables } = useUserApiListHarvestTablesQuery({
    harvestId: databaseId,
  });

  const { schemaName, tableName: encodedTableName } = useEncodedTableName(tableName);
  const {
    data: childConstraints,
    isLoading: isLoadingChildConstraints,
  } = useUserApiListTableChildConstraintsByTableNameQuery({
    harvestId: databaseId,
    schemaName,
    tableName: encodedTableName,
  });

  const onTableDetails = useTableDetails();
  const onTablePrefetch = useTablePrefetch();

  const { categories } = useLoadedCategoryColors();

  return (
    <div className="m-2 flex-grow-1 d-flex flex-row gap-2">
      <Relationships
        table={table}
        tables={tables}
        childConstraints={childConstraints}
        showDisabledReferences
        showInferredReferences
        showOverriddenReferences>
        {(parentTables, childTables) => (
          <>
            <RelationshipList
              items={parentTables}
              categories={categories}
              title="Parents"
              isLoading={isLoading || isFetching}
              onTableDetails={onTableDetails}
              onTablePrefetch={onTablePrefetch} />
            <RelationshipList
              items={childTables}
              categories={categories}
              title="Children"
              isLoading={isLoadingChildConstraints}
              onTableDetails={onTableDetails}
              onTablePrefetch={onTablePrefetch} />
          </>
        )}
      </Relationships>
    </div>
  );
}
