import React, { ReactNode } from 'react';
import { Card, CardHeader, ListGroup } from 'reactstrap';

export function PropertyGroup({ title, children, className }: {
  title: string;
  children: ReactNode;
  className?: string;
}) {
  return (
    <Card className={className}>
      <CardHeader><h5 className="m-0">{title}</h5></CardHeader>
      <ListGroup flush>
        {children}
      </ListGroup>
    </Card>
  );
}
PropertyGroup.defaultProps = {
  className: undefined,
};
