import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
} from 'reactstrap';
import { ProjectWithCountsDto } from '../../../app/api';
import { Icon, SearchBox } from '../../../components/elements';
import { EditProject } from './EditProject';
import { DeleteProject } from './DeleteProject';
import { useSortedProjects } from '../../project/hooks/useSortedProjects';
import { Privileged } from '../../../auth';
import { PROJECT_DESCRIPTION } from './projectDescription';

export function ProjectList({ projects, onFetchProject, onCreateProject }: {
  projects: ProjectWithCountsDto[];
  onFetchProject: (projectName: string) => void;
  onCreateProject: () => void;
}) {
  const [filter, setFilter] = useState('');
  const listedProjects = useFilteredProjects(
    useSortedProjects(projects),
    filter,
  );

  return (
    <>
      <p>
        {PROJECT_DESCRIPTION}
      </p>
      <div className="p-0 d-flex mb-4">
        <div className="flex-grow-1">
          <SearchBox
            placeholder="Find a project..."
            onFilterChange={setFilter} />
        </div>
        <Button
          color="success"
          className="ms-2"
          onClick={onCreateProject}>
          <Icon icon="addProject" /> New Project
        </Button>
      </div>
      <ListGroup>
        {listedProjects
          .map((p) => (
            <ProjectSummary
              key={p.id}
              project={p}
              onPreFetch={() => onFetchProject(p.id)} />
          ))}
      </ListGroup>
    </>
  );
}

function ProjectSummary({ project, onPreFetch }: {
  project: ProjectWithCountsDto;
  onPreFetch: () => void;
}) {
  return (
    <ListGroupItem action>
      <ListGroupItemHeading>
        <div className="d-flex align-items-end">
          <span className="me-auto">
            <Link to={`projects/${project.id}`} onMouseEnter={onPreFetch}>
              <Icon icon="openProject" /> {project.name}
            </Link>
          </span>
          <span className="d-flex gap-1">
            <EditProject project={project} />
            <Privileged role="Explorer Admin">
              <DeleteProject project={project} />
            </Privileged>
          </span>
        </div>
      </ListGroupItemHeading>
      <ListGroupItemText>
        {project.description}
      </ListGroupItemText>
      <span className="text-muted">
        <span className="me-3"><Icon icon="harvest" /> {project.harvestCount} </span>
        <span className="me-3"><Icon icon="diagram" /> {project.diagramCount}</span>
      </span>
    </ListGroupItem>
  );
}

function useFilteredProjects(
  projects: ProjectWithCountsDto[],
  filter: string,
) {
  return useMemo(() => {
    return projects.filter((x) => !filter || x.name?.toUpperCase().includes(filter.toUpperCase()));
  }, [filter, projects]);
}
