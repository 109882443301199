import { useUserPreferences } from './useUserPreferences';

export type UseFavoriteOrganizationsHookResult = {
  favorites: string[];
  addFavorite: (organizationId: string) => void;
  removeFavorite: (organizationId: string) => void;
};

export function useFavoriteOrganizations(): UseFavoriteOrganizationsHookResult {
  const { preferences, updatePreferences } = useUserPreferences();

  const favoriteOrganizations = preferences.favoriteOrganizations ?? [];

  const addFavorite = (organizationId: string) => {
    if (favoriteOrganizations.find((o) => o === organizationId)) {
      return;
    }

    updatePreferences({
      favoriteOrganizations: [
        organizationId,
        ...favoriteOrganizations,
      ],
    });
  };

  const removeFavorite = (organizationId: string) => {
    if (!favoriteOrganizations.find((o) => o === organizationId)) {
      return;
    }

    updatePreferences({
      favoriteOrganizations: [
        ...favoriteOrganizations.filter((o) => o !== organizationId),
      ],
    });
  };

  return {
    favorites: favoriteOrganizations,
    addFavorite,
    removeFavorite,
  };
}
