/* eslint-disable react/destructuring-assignment */
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Alert } from 'reactstrap';

interface Props {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Alert color="danger">
          <h1>Sorry.. there was an error</h1>
          <p>Please, open a support ticket with the below text so we can investigate.</p>
          <pre>{this.state.error?.stack}</pre>
        </Alert>
      );
    }

    return this.props.children;
  }
}
