import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

export const DEFAULT_OPTIONS: { debounceTime: number, config: ResizeObserverOptions } = {
  debounceTime: 0,
  config: {
    box: 'content-box',
  },
};

/**
 * This custom hooks abstracts the usage of the Resize Observer with React components.
 * Watch for changes being made to the elements size and trigger a custom callback.
 * Based on https://blog.logrocket.com/guide-to-custom-react-hooks-with-mutationobserver/
 * @param {Element} targetEl DOM element to be observed
 * @param {Function} cb callback that will run when there's a change in targetEl or any
 * child element (depending on the provided options)
 * @param {Object} options
 * @param {Object} options.config check \[options\](https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/observe)
 * @param {number} [options.debounceTime=0] a number that represents the amount of time in ms
 * that you which to debounce the call to the provided callback function
 */
export function useResizeObservable(
  targetEl: Element | Element[] | undefined | null,
  cb: ResizeObserverCallback,
  options = DEFAULT_OPTIONS,
) {
  const [observer, setObserver] = useState<ResizeObserver>();
  const elementRef = useRef<Element | Element[]>();
  elementRef.current = targetEl ?? undefined;

  useEffect(() => {
    if (!cb || typeof cb !== 'function') {
      throw new Error(
        `You must provide a valid callback function, instead you've provided ${cb}`,
      );
    }
    const { debounceTime } = options;
    const obs = new ResizeObserver(
      debounceTime > 0 ? debounce(cb, debounceTime) : cb,
    );
    setObserver(obs);
  }, [cb, options, setObserver]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (observer && elementRef.current) {
      const { config } = options;

      ([] as Element[]).concat(elementRef.current).forEach((e) => {
        observer.observe(e, config);
      });

      return () => {
        if (observer) {
          observer.disconnect();
        }
      };
    }
  }, [observer, options]);
}
