import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { matchSorter } from 'match-sorter';
import { uniqWith } from 'lodash';
import {
  // eslint-disable-next-line max-len
  MmTableColumnDto, MmExtendedTable, TableColumnDto, TableSummaryDto, useUserApiCreateMmRootEntityOverrideMutation, useUserApiDeleteMmRootEntityOverrideMutation, useUserApiListHarvestTablesQuery, useUserApiGetTableByIdQuery,
} from '../../../../app/api';
import {
  ErrorAlert, ErrorMessage, Icon, LoadingSpinner, DropdownSelect,
} from '../../../../components/elements';
import { useCurrentDatabase } from '../../DatabaseContext';
import { useProject } from '../../../project';
import { insensitiveCompare } from '../../../../util';

export function OverrideRootEntity({
  table, column, isOpen, onClose,
}:
  {
    table: MmExtendedTable | undefined;
    column: MmTableColumnDto | undefined;
    isOpen: boolean;
    onClose: () => void;
  }) {
  const { projectId } = useProject();
  const { databaseId } = useCurrentDatabase();
  const { data: tables, isLoading } = useUserApiListHarvestTablesQuery({
    harvestId: databaseId,
  });

  const [currentTable, setCurrentTable] = useState<TableSummaryDto>();

  const {
    currentData: currentTableDetails,
    isFetching: isLoadingTable,
  } = useUserApiGetTableByIdQuery({
    tableId: currentTable?.id ?? '',
  }, { skip: !currentTable && !column?.rootEntityOverride?.rootEntityName });

  const [
    createOverride,
    { isLoading: isCreating },
  ] = useUserApiCreateMmRootEntityOverrideMutation();
  const [
    persistDeleteOverride,
    { isLoading: isDeleting },
  ] = useUserApiDeleteMmRootEntityOverrideMutation();

  const saveOverride = useCallback(async (
    override: {
      table: string,
      column: string,
    } | undefined,
    reason: string,
  ) => {
    if (table && column) {
      if (reason) {
        await createOverride({
          projectId,
          mmRootEntityOverrideCreateDto: {
            sourceTable: table?.name,
            sourceColumn: column?.name,
            rootEntityName: override?.table,
            rootEntityAttr: override?.column,
            reason,
          },
        });
      }
      onClose();
    }
  }, [column, createOverride, onClose, projectId, table]);

  const deleteOverride = useCallback(async () => {
    if (column?.rootEntityOverride) {
      await persistDeleteOverride({
        projectId,
        rootEntityOverrideId: column.rootEntityOverride?.id,
      });
    }
    onClose();
  }, [column?.rootEntityOverride, onClose, persistDeleteOverride, projectId]);

  return (
    <RootEntityOverrideDialog
      column={column}
      tables={tables}
      columns={currentTableDetails?.tableColumns}
      isLoading={isLoading}
      isLoadingColumns={isLoadingTable}
      isSaving={isCreating || isDeleting}
      error={undefined}
      isOpen={isOpen}
      onClose={onClose}
      onSetOverride={saveOverride}
      onDeleteOverride={deleteOverride}
      onSelectTable={setCurrentTable} />
  );
}

type OverrideInputs = {
  table: TableSummaryDto | undefined,
  column: Pick<TableColumnDto, 'name'> | undefined,
  reason: string;
}

export function RootEntityOverrideDialog({
  column, tables, columns, isLoading, isLoadingColumns, isSaving, error, isOpen,
  onSetOverride, onClose, onSelectTable, onDeleteOverride,
}: {
  column: MmTableColumnDto | undefined;
  tables: TableSummaryDto[] | undefined;
  columns: TableColumnDto[] | undefined;
  isLoading: boolean;
  isLoadingColumns: boolean;
  isSaving: boolean;
  error: unknown;
  isOpen: boolean;
  onSetOverride: (
    override: {
      table: string,
      column: string,
    } | undefined,
    reason: string
  ) => void;
  onDeleteOverride: () => void;
  onClose: () => void;
  onSelectTable: (table: TableSummaryDto) => void;
}) {
  const {
    control, handleSubmit, formState: { errors }, watch, reset, getValues,
  } = useForm<OverrideInputs>(
    {
      disabled: isSaving,
      defaultValues: {
        reason: column?.rootEntityOverride?.reason,
        table: tables?.find(
          (t) => t.name.toUpperCase()
            === column?.rootEntityOverride?.rootEntityName?.toUpperCase(),
        ),
        column: columns?.find(
          (c) => c.name.toUpperCase()
            === column?.rootEntityOverride?.rootEntityAttr?.toUpperCase(),
        ),
      },
    },
  );

  const submitForm: SubmitHandler<OverrideInputs> = (formData) => {
    onSetOverride(
      formData.table && formData.column
        ? { table: formData.table.name, column: formData.column.name }
        : undefined,
      formData.reason,
    );
  };

  const table = watch('table');

  const formColumns = useMemo(() => {
    let cols: { name: string }[] = columns ?? [];
    if (column?.rootEntityOverride?.rootEntityAttr
      && (!table
        || insensitiveCompare(table.name, column.rootEntityOverride.rootEntityName) === 0)) {
      cols = uniqWith(
        [
          ...cols,
          createOverrideColumn(column.rootEntityOverride.rootEntityAttr),
        ],
        (a, b) => insensitiveCompare(a.name, b.name) === 0,
      );
    }
    return cols;
  }, [column?.rootEntityOverride, columns, table]);

  useEffect(() => {
    reset({
      reason: column?.rootEntityOverride?.reason,
      table: tables?.find(
        (t) => t.name.toUpperCase()
          === column?.rootEntityOverride?.rootEntityName?.toUpperCase(),
      ),
      column: (column?.rootEntityOverride?.rootEntityAttr
        ? createOverrideColumn(column.rootEntityOverride.rootEntityAttr)
        : undefined),
    });
  }, [column, reset, tables]);

  const hasOverride = !!column?.rootEntityOverride;

  return (
    <Modal isOpen={isOpen} toggle={onClose} autoFocus={false}>
      <ModalHeader toggle={onClose}>{column?.name} Root Entity</ModalHeader>
      <ModalBody className="d-flex flex-column gap-3">
        <div>System Defined: {formatRootEntity(column)}</div>

        <ErrorAlert error={error}>
          <ErrorMessage>Unable to set override</ErrorMessage>
        </ErrorAlert>

        <LoadingSpinner isLoading={isLoading} centered>
          <Form onSubmit={handleSubmit(submitForm)}>
            <Controller
              name="table"
              control={control}
              render={({ field }) => (
                <DropdownSelect
                  items={tables?.filter((t) => t.schemaName === 'V500') ?? []}
                  initialItem={field.value}
                  disabled={isSaving || hasOverride}
                  autofocus
                  label="Root Entity Name (Table) Override"
                  onChange={(x) => {
                    field.onChange(x);
                    if (x) {
                      onSelectTable(x);
                    }
                  }}
                  itemToString={tableToText}
                  filterItems={dropDownFilter}
                  getItemKey={(item) => item.id}
                  placeholder="No Reference"
                  invalid={!!errors.table}>
                  {(item) => <TableText table={item} />}
                </DropdownSelect>
              )} />
            <Controller
              name="column"
              control={control}
              rules={{
                validate: (value) => (!getValues('table') || !!value),
              }}
              render={({ field }) => (
                <DropdownSelect
                  items={formColumns}
                  isLoading={isLoadingColumns}
                  initialItem={field.value}
                  disabled={isSaving || !table || !columns || isLoadingColumns || hasOverride}
                  label="Root Entity Attribute (Column) Override"
                  onChange={field.onChange}
                  itemToString={columnToText}
                  filterItems={dropDownFilter}
                  getItemKey={(item) => item.name}
                  placeholder="No Reference"
                  invalid={!!errors.column}
                  validationMessage="Column is required when table is selected">
                  {(item) => <ColumnText column={item} />}
                </DropdownSelect>
              )} />
            <FormGroup>
              <Label for="reason">Reason</Label>
              <Controller
                name="reason"
                control={control}
                defaultValue={column?.rootEntityOverride?.reason}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    invalid={!!errors.reason}
                    {...field}
                    disabled={field.disabled || hasOverride}
                    type="text" />
                )} />
              <FormFeedback>Reason is required</FormFeedback>
            </FormGroup>
          </Form>
        </LoadingSpinner>
      </ModalBody>
      <ModalFooter>
        {
          !!column?.rootEntityOverride
          && (
            <div className="d-flex flex-column flex-grow-1" style={{ fontSize: '.75rem' }}>
              <span>Overridden By: {column.rootEntityOverride.createdBy}</span>
            </div>
          )
        }
        {
          !column?.rootEntityOverride && (
            <Button color="primary" disabled={isSaving} onClick={handleSubmit(submitForm)}>
              <LoadingSpinner isLoading={isSaving}><Icon icon="save" /></LoadingSpinner> Save
            </Button>
          )
        }
        {
          !!column?.rootEntityOverride && (
            <Button color="danger" disabled={isSaving} onClick={onDeleteOverride}>
              <LoadingSpinner isLoading={isSaving}><Icon icon="trash" /></LoadingSpinner> Delete
            </Button>
          )
        }
        <Button color="secondary" disabled={isSaving} onClick={onClose}>Cancel</Button>

      </ModalFooter>
    </Modal>
  );
}

function TableText({ table }: {
  table: TableSummaryDto | undefined | null;
}) {
  return <>{tableToText(table)}</>;
}

function formatRootEntity(column: MmTableColumnDto | undefined) {
  return column && column.rootEntityName && column.rootEntityAttr
    ? `${column.rootEntityName}.${column.rootEntityAttr}`
    : '<None>';
}

function tableToText(table: TableSummaryDto | null | undefined) {
  return table?.name ?? '';
}

function ColumnText({ column }: {
  column: Pick<TableColumnDto, 'name'> | undefined | null;
}) {
  return <>{columnToText(column)}</>;
}

function columnToText(column: Pick<TableColumnDto, 'name'> | null | undefined) {
  return column?.name ?? '';
}

function dropDownFilter<T extends { name: string }>(items: T[], filter: string | null) {
  return matchSorter(items, filter ?? '', { keys: [(item) => item.name.replace(/_/g, ' '), 'name'] });
}

function createOverrideColumn(rootEntityAttr: string) {
  return {
    name: rootEntityAttr,
  };
}
