import { MmExtendedTable } from '.';
import {
  MmTableColumnDto, TableColumnDto, TableDto,
} from './api-generated';

export function hasMmTableExtensions(table: TableDto | undefined): table is MmExtendedTable {
  return !!table && 'isDocumented' in table;
}

export function isMmColumn(column: TableColumnDto | MmTableColumnDto): column is MmTableColumnDto {
  return 'tableColumnFlags' in column;
}

export function getRootEntity(column: MmTableColumnDto, applyOverride: boolean) {
  return applyOverride
    ? column.rootEntityOverride ?? column
    : column;
}

export function getCodeSetCode(column: MmTableColumnDto) {
  return column.mmCodeSetOverride
    ? column.mmCodeSetOverride?.code
    : column.mmCodeSet?.code;
}
