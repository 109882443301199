import React from 'react';
import {
  Nav, NavItem, NavLink, UncontrolledTooltip,
} from 'reactstrap';
import { Resizable } from 'react-resizable';
import { clamp, difference } from 'lodash';
import { SideBarType } from '../project/projectSlice';
import { useProjectSidebar } from '../project';
import { CodeSets } from './codeSets';
import { Tables } from './tables';
import { Diagrams } from './diagram/Diagrams';
import { Icon, IconName } from '../../components/elements';
import { Tabs } from './tabs';
import { Models } from './models/Models';
import { ColumnSearch } from './columnSearch';
import { useUserPreferenceNumber } from '../userPreferences';
import { useHarvests } from '../../app/api';
import { useCurrentDatabase } from './DatabaseContext';

type HarvestExtension = 'Millennium';

type sideBarMemberDefinition = SideBarMemberProps & {
  requiredHarvestExtensions?: HarvestExtension[];
  child: () => JSX.Element;
}

export const iconMap: { [K in SideBarType]: IconName } = {
  tables: 'table',
  diagrams: 'diagram',
  models: 'models',
  codeSets: 'codeSets',
  home: 'home',
  columnSearch: 'columns',
  codeSetSearch: 'codeSets',
};

const sidebars: sideBarMemberDefinition[] = [
  {
    type: 'diagrams',
    toolTip: 'Diagrams',
    child: () => <Diagrams />,
  },
  {
    type: 'tables',
    toolTip: 'Tables',
    child: () => <Tables />,
  },
  {
    type: 'columnSearch',
    toolTip: 'Column Search',
    child: () => <ColumnSearch />,
  },
  {
    type: 'models',
    toolTip: 'Models',
    child: () => <Models />,
    requiredHarvestExtensions: ['Millennium'],
  },
  {
    type: 'codeSets',
    toolTip: 'CodeSets',
    child: () => <CodeSets />,
    requiredHarvestExtensions: ['Millennium'],
  },
];

export function Database() {
  return (
    <main className="d-flex flex-row flex-grow-1">
      <SideBar />
      <Tabs />
    </main>
  );
}

function useHarvestExtensions(): HarvestExtension[] {
  const harvests = useHarvests();
  const { databaseId } = useCurrentDatabase();

  const currentHarvest = harvests.harvests?.find((h) => h.id === databaseId);

  return (currentHarvest && 'domainName' in currentHarvest)
    ? ['Millennium']
    : [];
}

export function SideBar() {
  const minWidth = 370;
  const maxWidth = 800;

  const [sidebarType] = useProjectSidebar();
  const [savedWidth, setWidth] = useUserPreferenceNumber('Harvest.SideBarWidth', 400);

  const width = clamp(savedWidth, minWidth, maxWidth);

  const extensions = useHarvestExtensions();

  return (
    <>
      <DatabaseMenu extensions={extensions} />
      {
        sidebarType && (
          <Resizable
            width={width}
            height={0}
            minConstraints={[minWidth, 0]}
            maxConstraints={[maxWidth, 0]}
            axis="x"
            resizeHandles={['e']}
            handle={<span className="p-1" style={{ cursor: 'col-resize' }} />}
            onResize={(_, data) => setWidth(data.size.width)}>
            <div className="d-flex flex-shrink-0">
              <div className="d-flex" style={{ width: `${width}px` }}>
                <div className="d-flex py-2 ps-2 flex-grow-1">
                  {sidebars.find((x) => x.type === sidebarType)?.child()}
                </div>
              </div>
            </div>
          </Resizable>
        )
      }
    </>
  );
}

function DatabaseMenu({ extensions }: {
  extensions: HarvestExtension[],
}) {
  return (
    <div className="bg-light">
      <Nav pills className="nav-pills flex-column mb-auto text-center bg-light">
        {
          sidebars
            .filter((x) => difference(x.requiredHarvestExtensions, extensions).length === 0)
            .map((x) => <SideBarMember key={x.type} type={x.type} toolTip={x.toolTip} />)
        }
      </Nav>
    </div>
  );
}

type SideBarMemberProps = {
  type: SideBarType;
  toolTip: string;
}

function SideBarMember({ type, toolTip }: SideBarMemberProps) {
  const [sidebarType, setSidebar] = useProjectSidebar();
  const toolTipId = `${type}-id`;

  return (
    <NavItem id={toolTipId}>
      <NavLink
        className="cursor-pointer"
        active={sidebarType === type}
        onClick={() => setSidebar(type)}>
        <Icon icon={iconMap[type]} />
      </NavLink>
      <UncontrolledTooltip
        target={toolTipId}
        placement="right">
        {toolTip}
      </UncontrolledTooltip>
    </NavItem>
  );
}
