import { useCallback } from 'react';
import { useUserPreferences } from '../../../userPreferences/hooks/useUserPreferences';

const mouseModes = ['Select', 'DrawRectangle'] as const;
export type MouseMode = typeof mouseModes[number];

export function isMouseMode(maybeMode: unknown): maybeMode is MouseMode {
  return typeof maybeMode === 'string' && mouseModes.includes(maybeMode as MouseMode);
}

const DEFAULT_MODE = 'Select';

export function useMouseMode(): [MouseMode, (newMode: MouseMode) => void] {
  const [value, setValue] = useUserPreferences('Diagram.MouseMode', DEFAULT_MODE);

  const currentValue = isMouseMode(value)
    ? value
    : DEFAULT_MODE;

  const updatePreference = useCallback((newValue: MouseMode) => {
    setValue(newValue);
  }, [setValue]);

  return [
    currentValue,
    updatePreference,
  ];
}
