export function toObjectMap<T, U>(
  arr: T[],
  fn: (v: T, i: number) => [string, U],
) {
  type Map = { [index: string]: U; };

  return arr?.reduce((acc: Map, obj, i) => {
    const [id, res] = fn(obj, i);
    acc[id] = res;
    return acc;
  }, {}) ?? {};
}
