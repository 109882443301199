import React, { ReactNode } from 'react';
import { NavMenu } from './NavMenu';
import { NotificationList } from '../features/notifications';
import { Footer } from '../features/footer';

export function Layout({ children }: { children: ReactNode }) {
  return (
    <div className="d-flex flex-column">
      <NavMenu />
      <div className="d-flex flex-grow-1">
        {children}
      </div>
      <NotificationList />
      <Footer />
    </div>
  );
}
