import React, { ComponentType, useMemo, useState } from 'react';
import {
  // eslint-disable-next-line max-len
  DiagramWithChildrenDto, DiagramTagDto, useUserApiCreateDiagramTagMutation, useUserApiDeleteDiagramTagMutation, useUserApiListDiagramsQuery,
} from '../../../../../app/api';
import { useProject } from '../../../../project';
import { EditDiagramTagsDialog, WeightedTag } from './EditDiagramTagsDialog';

type DiagramTagsEditable = {
  onEditTags: (diagram: DiagramWithChildrenDto) => void;
};

type WithDiagramEditTagsProps = Omit<DiagramTagsEditable, 'onEditTags'>;

export const withDiagramEditTags = <P extends DiagramTagsEditable>(Component: ComponentType<P>) => {
  return function (props: Omit<P, 'onEditTags'> & WithDiagramEditTagsProps) {
    const { projectId } = useProject();

    const [createTag, { isLoading: isCreatingTag }] = useUserApiCreateDiagramTagMutation();
    const [deleteTag, { isLoading: isDeletingTag }] = useUserApiDeleteDiagramTagMutation();

    const [selectedDiagram, setDiagram] = useState<DiagramWithChildrenDto>();

    const [modal, setModal] = useState(false);

    const { data: diagrams, isLoading } = useUserApiListDiagramsQuery({
      projectId,
    });

    const diagram = diagrams?.find((d) => d.id === selectedDiagram?.id);

    const allTags = useMemo(() => {
      if (!diagrams) return [];

      return Object.values(
        diagrams.flatMap((d) => d.tags)
          .reduce((acc: { [index: string]: WeightedTag }, t) => {
            const key = t.tagName.toUpperCase();
            const w = acc[key] ?? { count: 0, tagName: t.tagName };
            acc[key] = {
              ...w,
              count: w.count + 1,
            };
            return acc;
          }, {}),
      );
    }, [diagrams]);

    const executeCreate = async (tagName: string) => {
      if (diagram) {
        await createTag({
          diagramId: diagram?.id,
          diagramTagCreateDto: {
            tagName,
          },
        });
      }
    };

    const executeDelete = async (tag: DiagramTagDto) => {
      if (diagram) {
        await deleteTag({
          diagramId: diagram?.id,
          diagramTagId: tag.id,
        });
      }
    };

    return (
      <>
        <EditDiagramTagsDialog
          diagram={diagram}
          allTags={allTags}
          isLoading={isLoading}
          isOpen={modal}
          isSaving={isCreatingTag || isDeletingTag}
          onAddTag={executeCreate}
          onRemoveTag={executeDelete}
          onClose={() => setModal(false)} />
        <Component
          {...props as P}
          onEditTags={(d) => {
            setDiagram(d);
            setModal(true);
          }} />
      </>
    );
  };
};
